/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Button, FormItem, FormContainer, Dialog } from 'components/ui'
import { Form, Formik, input } from 'formik'
import { IoIosArrowDown } from 'react-icons/io'
import { IoIosArrowUp } from 'react-icons/io'
import { setLoading } from 'store/auth/superAdminLicense'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

const UsersRolePermissionUpdate = ({ singleRoleData, RoleId, getRedirect }) => {
    const [roleName, setRoleName] = useState('')
    const [roleDesc, setRoleDesc] = useState('')
    const [salesMenu, setSalesMenu] = useState(false)
    const [purchaseMenu, setPurchaseMenu] = useState(false)
    const [rolePermissionId, setRolePermissionId] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [getSingleRoleData, setGetSingleRoleData] = useState(null)
    const [settingMenu, setSettingMenu] = useState(false)
    const [roleKeyName, setRoleKeyName] = useState('')

    // -----------Invoice -----------------------
    const [InvoiceFullAccess, setInvoiceFullAccess] = useState(false)
    const [InvoiceView, setInvoiceView] = useState(false)
    const [InvoiceCreate, setInvoiceCreate] = useState(false)
    const [InvoiceEdit, setInvoiceEdit] = useState(false)
    const [InvoiceDelete, setInvoiceDelete] = useState(false)
    const [InvoiceApprove, setInvoiceApprove] = useState(false)
    const [InvoiceExport, setInvoiceExport] = useState(false)
    const [InvoiceShare, setInvoiceShare] = useState(false)
    const [InvoiceMail, setInvoiceMail] = useState(false)
    // -----------Quotes -----------------------
    // const [QuotesFullAccess, setQuotesFullAccess] = useState(false)
    // const [QuotesView, setQuotesView] = useState(false)
    // const [QuotesCreate, setQuotesCreate] = useState(false)
    // const [QuotesEdit, setQuotesEdit] = useState(false)
    // const [QuotesDelete, setQuotesDelete] = useState(false)
    // const [QuotesApprove, setQuotesApprove] = useState(false)
    // -----------CreditNote-----------------------
    const [CreditNoteFullAccess, setCreditNoteFullAccess] = useState(false)
    const [CreditNoteView, setCreditNoteView] = useState(false)
    const [CreditNoteCreate, setCreditNoteCreate] = useState(false)
    const [CreditNoteEdit, setCreditNoteEdit] = useState(false)
    const [CreditNoteDelete, setCreditNoteDelete] = useState(false)
    const [CreditNoteApprove, setCreditNoteApprove] = useState(false)
    const [CreditNoteExport, setCreditNoteExport] = useState(false)
    const [CreditNoteShare, setCreditNoteShare] = useState(false)
    const [CreditNoteMail, setCreditNoteMail] = useState(false)
    // -----------Receipt-----------------------
    const [ReceiptFullAccess, setReceiptFullAccess] = useState(false)
    const [ReceiptView, setReceiptView] = useState(false)
    const [ReceiptCreate, setReceiptCreate] = useState(false)
    const [ReceiptEdit, setReceiptEdit] = useState(false)
    const [ReceiptDelete, setReceiptDelete] = useState(false)
    const [ReceiptApprove, setReceiptApprove] = useState(false)
    const [ReceiptExport, setReceiptExport] = useState(false)
    const [ReceiptShare, setReceiptShare] = useState(false)
    const [ReceiptMail, setReceiptMail] = useState(false)

    // -----------Receivable-----------------------
    const [ReceivableFullAccess, setReceivableFullAccess] = useState(false)
    const [ReceivableView, setReceivableView] = useState(false)
    const [ReceivableCreate, setReceivableCreate] = useState(false)
    const [ReceivableEdit, setReceivableEdit] = useState(false)
    const [ReceivableDelete, setReceivableDelete] = useState(false)
    const [ReceivableApprove, setReceivableApprove] = useState(false)
    const [ReceivableExport, setReceivableExport] = useState(false)
    const [ReceivableShare, setReceivableShare] = useState(false)
    const [ReceivableMail, setReceivableMail] = useState(false)

    // -----------Bills-----------------------

    const [BillsFullAccess, setBillsFullAccess] = useState(false)
    const [BillsView, setBillsView] = useState(false)
    const [BillsCreate, setBillsCreate] = useState(false)
    const [BillsEdit, setBillsEdit] = useState(false)
    const [BillsDelete, setBillsDelete] = useState(false)
    const [BillsApprove, setBillsApprove] = useState(false)
    const [BillsExport, setBillsExport] = useState(false)
    const [BillsShare, setBillsShare] = useState(false)
    const [BillsMail, setBillsMail] = useState(false)

    // -----------DebitNotes-----------------------

    const [DebitNotesFullAccess, setDebitNotesFullAccess] = useState(false)
    const [DebitNotesView, setDebitNotesView] = useState(false)
    const [DebitNotesCreate, setDebitNotesCreate] = useState(false)
    const [DebitNotesEdit, setDebitNotesEdit] = useState(false)
    const [DebitNotesDelete, setDebitNotesDelete] = useState(false)
    const [DebitNotesApprove, setDebitNotesApprove] = useState(false)
    const [DebitNotesExport, setDebitNotesExport] = useState(false)
    const [DebitNotesShare, setDebitNotesShare] = useState(false)
    const [DebitNotesMail, setDebitNotesMail] = useState(false)

    // -----------Payment-----------------------

    const [PaymentFullAccess, setPaymentFullAccess] = useState(false)
    const [PaymentView, setPaymentView] = useState(false)
    const [PaymentCreate, setPaymentCreate] = useState(false)
    const [PaymentEdit, setPaymentEdit] = useState(false)
    const [PaymentDelete, setPaymentDelete] = useState(false)
    const [PaymentApprove, setPaymentApprove] = useState(false)
    const [PaymentExport, setPaymentExport] = useState(false)
    const [PaymentShare, setPaymentShare] = useState(false)
    const [PaymentMail, setPaymentMail] = useState(false)

    // -----------Payable-----------------------
    const [PayableFullAccess, setPayableFullAccess] = useState(false)
    const [PayableView, setPayableView] = useState(false)
    const [PayableCreate, setPayableCreate] = useState(false)
    const [PayableEdit, setPayableEdit] = useState(false)
    const [PayableDelete, setPayableDelete] = useState(false)
    const [PayableApprove, setPayableApprove] = useState(false)
    const [PayableExport, setPayableExport] = useState(false)
    const [PayableShare, setPayableShare] = useState(false)
    const [PayableMail, setPayableMail] = useState(false)

    // -----------Settings-----------------------
    const [setting, setSetting] = useState(false)
    const [updateOrgProfile, setUpdateOrgProfile] = useState(false)
    const [generalSetting, setGeneralSettings] = useState(false)
    const [notification, setNotification] = useState(false)
    const [supportAndFeedback, setSupportAndFeedback] = useState(false)
    const [preferenceSetting, setPreferenceSetting] = useState(false)

    // Required data
    let tokenData = JSON.parse(localStorage.getItem('setuFiToken'))
    const { user, companyData } = useSelector((state) => state.auth.user)
    const name = getSingleRoleData?.roleName
    const RoleKey = name?.replace(/\s/g, '').toUpperCase()

    useEffect(() => {
        if (InvoiceFullAccess) {
            setInvoiceFullAccess(true)
            setInvoiceView(true)
            // setInvoiceCreate(true)
            // setInvoiceEdit(true)
            // setInvoiceDelete(true)
            // setInvoiceApprove(true)
            setInvoiceExport(true)
            setInvoiceShare(true)
            setInvoiceMail(true)
        } else if (
            !InvoiceFullAccess &&
            InvoiceView &&
            // InvoiceCreate &&
            // InvoiceEdit &&
            // InvoiceDelete &&
            // InvoiceApprove &&
            InvoiceExport && 
            InvoiceShare && 
            InvoiceMail
        ) {
            setInvoiceView(false)
            // setInvoiceCreate(false)
            // setInvoiceEdit(false)
            // setInvoiceDelete(false)
            // setInvoiceApprove(false)
            setInvoiceExport(false)
            setInvoiceShare(false)
            setInvoiceMail(false)
        }

        if (CreditNoteFullAccess) {
            setCreditNoteFullAccess(true)
            setCreditNoteView(true)
            // setCreditNoteCreate(true)
            // setCreditNoteEdit(true)
            // setCreditNoteDelete(true)
            // setCreditNoteApprove(true)
            setCreditNoteExport(true)
            setCreditNoteShare(true)
            setCreditNoteMail(true)
        } else if (
            !CreditNoteFullAccess &&
            CreditNoteView &&
            // CreditNoteCreate &&
            // CreditNoteEdit &&
            // CreditNoteDelete &&
            // CreditNoteApprove &&
            CreditNoteExport &&
            CreditNoteShare &&
            CreditNoteMail
        ) {
            setCreditNoteView(false)
            // setCreditNoteCreate(false)
            // setCreditNoteEdit(false)
            // setCreditNoteDelete(false)
            // setCreditNoteApprove(false)
            setCreditNoteExport(false)
            setCreditNoteShare(false)
            setCreditNoteMail(false)
        }

        if (ReceiptFullAccess) {
            setReceiptFullAccess(true)
            setReceiptView(true)
            // setReceiptCreate(true)
            // setReceiptEdit(true)
            // setReceiptDelete(true)
            // setReceiptApprove(true)
            setReceiptExport(true)
            setReceiptShare(true)
            setReceiptMail(true)
        } else if (
            !ReceiptFullAccess &&
            ReceiptView &&
            // ReceiptCreate &&
            // ReceiptEdit &&
            // ReceiptDelete &&
            // ReceiptApprove &&
            ReceiptExport &&
            ReceiptShare &&
            ReceiptMail
        ) {
            setReceiptView(false)
            // setReceiptCreate(false)
            // setReceiptEdit(false)
            // setReceiptDelete(false)
            // setReceiptApprove(false)
            setReceiptExport(false)
            setReceiptShare(false)
            setReceiptMail(false)
        }

        if (ReceivableFullAccess) {
            setReceivableFullAccess(true)
            setReceivableView(true)
            // setReceivableCreate(true)
            // setReceivableEdit(true)
            // setReceivableDelete(true)
            // setReceivableApprove(true)
            setReceivableExport(true)
            setReceivableShare(true)
            setReceivableMail(true)
        } else if (
            !ReceivableFullAccess &&
            ReceivableView &&
            // ReceivableCreate &&
            // ReceivableEdit &&
            // ReceivableDelete &&
            // ReceivableApprove &&
            ReceivableExport &&
            ReceivableShare &&
            ReceivableMail
        ) {
            setReceivableView(false)
            // setReceivableCreate(false)
            // setReceivableEdit(false)
            // setReceivableDelete(false)
            // setReceivableApprove(false)
            setReceivableExport(false)
            setReceivableShare(false)
            setReceivableMail(false)
        }

        if (BillsFullAccess) {
            setBillsFullAccess(true)
            setBillsView(true)
            // setBillsCreate(true)
            // setBillsEdit(true)
            // setBillsDelete(true)
            // setBillsApprove(true)
            setBillsExport(true)
            setBillsShare(true)
            setBillsMail(true)
        } else if (
            !BillsFullAccess &&
            BillsView &&
            // BillsCreate &&
            // BillsEdit &&
            // BillsDelete &&
            // BillsApprove &&
            BillsExport &&
            BillsShare &&
            BillsMail 
        ) {
            setBillsView(false)
            // setBillsCreate(false)
            // setBillsEdit(false)
            // setBillsDelete(false)
            // setBillsApprove(false)
            setBillsExport(false)
            setBillsShare(false)
            setBillsMail(false)
        }

        if (DebitNotesFullAccess) {
            setDebitNotesFullAccess(true)
            setDebitNotesView(true)
            // setDebitNotesCreate(true)
            // setDebitNotesEdit(true)
            // setDebitNotesDelete(true)
            // setDebitNotesApprove(true)
            setDebitNotesExport(true)
            setDebitNotesShare(true)
            setDebitNotesMail(true)
        } else if (
            !DebitNotesFullAccess &&
            DebitNotesView &&
            // DebitNotesCreate &&
            // DebitNotesEdit &&
            // DebitNotesDelete &&
            // DebitNotesApprove &&
            DebitNotesExport &&
            DebitNotesShare &&
            DebitNotesMail
        ) {
            setDebitNotesView(false)
            // setDebitNotesCreate(false)
            // setDebitNotesEdit(false)
            // setDebitNotesDelete(false)
            // setDebitNotesApprove(false)
            setDebitNotesExport(false)
            setDebitNotesShare(false)
            setDebitNotesMail(false)
        }

        if (PaymentFullAccess) {
            setPaymentFullAccess(true)
            setPaymentView(true)
            // setPaymentCreate(true)
            // setPaymentEdit(true)
            // setPaymentDelete(true)
            // setPaymentApprove(true)
            setPaymentExport(true)
            setPaymentShare(true)
            setPaymentMail(true)
        } else if (
            !PaymentFullAccess &&
            PaymentView &&
            // PaymentCreate &&
            // PaymentEdit &&
            // PaymentDelete &&
            // PaymentApprove &&
            PaymentExport &&
            PaymentShare &&
            PaymentMail  
        ) {
            setPaymentView(false)
            // setPaymentCreate(false)
            // setPaymentEdit(false)
            // setPaymentDelete(false)
            // setPaymentApprove(false)
            setPaymentExport(false)
            setPaymentShare(false)
            setPaymentMail(false)
        }

        if (PayableFullAccess) {
            setPayableFullAccess(true)
            setPayableView(true)
            // setPayableCreate(true)
            // setPayableEdit(true)
            // setPayableDelete(true)
            // setPayableApprove(true)
            setPayableExport(true)
            setPayableShare(true)
            setPayableMail(true)
        } else if (
            !PayableFullAccess &&
            PayableView &&
            // PayableCreate &&
            // PayableEdit &&
            // PayableDelete &&
            // PayableApprove &&
            PayableExport &&
            PayableShare &&
            PayableMail  
        ) {
            setPayableView(false)
            // setPayableCreate(false)
            // setPayableEdit(false)
            // setPayableDelete(false)
            // setPayableApprove(false)
            setPayableExport(false)
            setPayableShare(false)
            setPayableMail(false)
        }

        // if (setting) {
        //     setSetting(true)
        //     setUpdateOrgProfile(true)
        //     setGeneralSettings(true)
        //     setNotification(true)
        //     setSupportAndFeedback(true)
        //     setPreferenceSetting(true)
        // } else if (
        //     !setting &&
        //     updateOrgProfile &&
        //     generalSetting &&
        //     notification &&
        //     supportAndFeedback &&
        //     preferenceSetting
        // ) {
        //     setUpdateOrgProfile(false)
        //     setGeneralSettings(false)
        //     setNotification(false)
        //     setSupportAndFeedback(false)
        //     setPreferenceSetting(false)
        // }
    })

    var dbName = companyData.databaseName
    // alert(dbName)
    const data = {
        dbName: dbName,
        id: RoleId,
        description: roleDesc,
        [`${RoleKey}InvoicesFullAccess`]: InvoiceFullAccess,
        [`${RoleKey}InvoicesView`]: InvoiceView,
        // [`${RoleKey}InvoicesCreate`]: InvoiceCreate,
        // [`${RoleKey}InvoicesEdit`]: InvoiceEdit,
        // [`${RoleKey}InvoicesDelete`]: InvoiceDelete,
        // [`${RoleKey}InvoicesApprove`]: InvoiceApprove,
        [`${RoleKey}InvoicesExport`]: InvoiceExport,
        [`${RoleKey}InvoicesShare`]: InvoiceShare,
        [`${RoleKey}InvoicesMail`]: InvoiceMail,

        // ----------------------------------
        // QuotesFullAccess: QuotesFullAccess,
        // QuotesView: QuotesView,
        // QuotesCreate: QuotesCreate,
        // QuotesEdit: QuotesEdit,
        // QuotesDelete: QuotesDelete,
        // QuotesApprove: QuotesApprove,

        // ---------------Credit Note -------------------

        [`${RoleKey}CreditNotesFullAccess`]: CreditNoteFullAccess,
        [`${RoleKey}CreditNotesView`]: CreditNoteView,
        // [`${RoleKey}CreditNoteCreate`]: CreditNoteCreate,
        // [`${RoleKey}CreditNoteEdit`]: CreditNoteEdit,
        // [`${RoleKey}CreditNoteDelete`]: CreditNoteDelete,
        // [`${RoleKey}CreditNoteApprove`]: CreditNoteApprove,
        [`${RoleKey}CreditNotesExport`]: CreditNoteExport,
        [`${RoleKey}CreditNotesShare`]: CreditNoteShare,
        [`${RoleKey}CreditNotesMail`]: CreditNoteMail,

        // ---------------Receipt -------------------
        //  Receipt object mapping goes here
        [`${RoleKey}ReceiptFullAccess`]: ReceiptFullAccess,
        [`${RoleKey}ReceiptView`]: ReceiptView,
        // [`${RoleKey}ReceiptCreate`]: ReceiptCreate,
        // [`${RoleKey}ReceiptEdit`]: ReceiptEdit,
        // [`${RoleKey}ReceiptDelete`]: ReceiptDelete,
        // [`${RoleKey}ReceiptApprove`]: ReceiptApprove,
        [`${RoleKey}ReceiptExport`]: ReceiptExport,
        [`${RoleKey}ReceiptShare`]: ReceiptShare,
        [`${RoleKey}ReceiptMail`]: ReceiptMail,

        // ---------------Receivable -------------------
        //  Receipt object mapping goes here
        [`${RoleKey}ReceivableFullAccess`]: ReceivableFullAccess,
        [`${RoleKey}ReceivableView`]: ReceivableView,
        // [`${RoleKey}ReceivableCreate`]: ReceivableCreate,
        // [`${RoleKey}ReceivableEdit`]: ReceivableEdit,
        // [`${RoleKey}ReceivableDelete`]: ReceivableDelete,
        // [`${RoleKey}ReceivableApprove`]: ReceivableApprove,
        [`${RoleKey}ReceivableExport`]: ReceivableExport,
        [`${RoleKey}ReceivableShare`]: ReceivableShare,
        [`${RoleKey}ReceivableMail`]: ReceivableMail,

        // ---------------Bills -------------------
        //  Bills object mapping goes here
        [`${RoleKey}BillsFullAccess`]: BillsFullAccess,
        [`${RoleKey}BillsView`]: BillsView,
        // [`${RoleKey}BillsCreate`]: BillsCreate,
        // [`${RoleKey}BillsEdit`]: BillsEdit,
        // [`${RoleKey}BillsDelete`]: BillsDelete,
        // [`${RoleKey}BillsApprove`]: BillsApprove,
        [`${RoleKey}BillsExport`]: BillsExport,
        [`${RoleKey}BillsShare`]: BillsShare,
        [`${RoleKey}BillsMail`]: BillsMail,

        // ---------------DebitNotes -------------------
        //  DebitNotes object mapping goes here
        [`${RoleKey}DebitNotesFullAccess`]: DebitNotesFullAccess,
        [`${RoleKey}DebitNotesView`]: DebitNotesView,
        // [`${RoleKey}DebitNotesCreate`]: DebitNotesCreate,
        // [`${RoleKey}DebitNotesEdit`]: DebitNotesEdit,
        // [`${RoleKey}DebitNotesDelete`]: DebitNotesDelete,
        // [`${RoleKey}DebitNotesApprove`]: DebitNotesApprove,
        [`${RoleKey}DebitNotesExport`]: DebitNotesExport,
        [`${RoleKey}DebitNotesShare`]: DebitNotesShare,
        [`${RoleKey}DebitNotesMail`]: DebitNotesMail,

        // ---------------Payment -------------------
        //  Payment object mapping goes here

        [`${RoleKey}PaymentFullAccess`]: PaymentFullAccess,
        [`${RoleKey}PaymentView`]: PaymentView,
        // [`${RoleKey}PaymentCreate`]: PaymentCreate,
        // [`${RoleKey}PaymentEdit`]: PaymentEdit,
        // [`${RoleKey}PaymentDelete`]: PaymentDelete,
        // [`${RoleKey}PaymentApprove`]: PaymentApprove,
        [`${RoleKey}PaymentExport`]: PaymentExport,
        [`${RoleKey}PaymentShare`]: PaymentShare,
        [`${RoleKey}PaymentMail`]: PaymentMail,

         // ---------------Payable -------------------
        //  Payment object mapping goes here

        [`${RoleKey}PayableFullAccess`]: PayableFullAccess,
        [`${RoleKey}PayableView`]: PayableView,
        // [`${RoleKey}PayableCreate`]: PayableCreate,
        // [`${RoleKey}PayableEdit`]: PayableEdit,
        // [`${RoleKey}PayableDelete`]: PayableDelete,
        // [`${RoleKey}PayableApprove`]: PayableApprove,
        [`${RoleKey}PayableExport`]: PayableExport,
        [`${RoleKey}PayableShare`]: PayableShare,
        [`${RoleKey}PayableMail`]: PayableMail,

        // ---------------Settings -------------------
        //  DebitNotes object mapping goes here
        // [`${RoleKey}Settings`]: setting,
        [`${RoleKey}GeneralSettings`]: generalSetting,
        // [`${RoleKey}Notifications`]: notification,
        [`${RoleKey}SupportsAndFeedback`]: supportAndFeedback,
        [`${RoleKey}PreferencesSettings`]: preferenceSetting,
        [`${RoleKey}SettingsUpdateOrganizationpProfile`]: updateOrgProfile, //[`${RoleKey}`]
    }
    const HandleFormUpdate = async (e) => {
        e.preventDefault()
        try {
            setLoading(true)

            let resp = await fetch(
                `${process.env.REACT_APP_BASE_URL}/rolePermission/updateRolePermissions`,
                {
                    method: 'PATCH',
                    body: JSON.stringify(data),
                    headers: {
                        Authorization: `Bearer ${tokenData}`,
                        'Content-Type': 'application/json',

                        'Access-Control-Allow-Origin': '*',
                        'access-control-allow-credentials': 'true',
                        'access-control-allow-origin': '*',
                    },
                }
            )
            resp = await resp.json()
            toast.success(resp.message)
        } catch (err) {
            console.log(err)
            toast.error(err.message)
        } finally {
            setLoading(false)
            getRedirect(true)
        }
    }

    // const outputString = inputString.replace(/\s/g, '').toUpperCase()
    useEffect(() => {
        const getSingleRoleIdData = async (id) => {
            try {
                const dbId = companyData?.databaseName
                setIsLoading(true)
                const bodyData = {
                    dbName: dbId,
                    id: id,
                }
                let resp = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/rolePermission/getSingleRole`,
                    {
                        method: 'POST',
                        body: JSON.stringify(bodyData),
                        headers: {
                            Authorization: `Bearer ${tokenData}`,
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            'access-control-allow-credentials': 'true',
                            'access-control-allow-origin': '*',
                        },
                    }
                )
                resp = await resp.json()
                setGetSingleRoleData(resp.role)
            } catch (err) {
                console.log(err)
            } finally {
                setIsLoading(false)
            }
        }
        getSingleRoleIdData(RoleId)
    }, [RoleId])

    useEffect(() => {
        const name = getSingleRoleData?.roleName
        // const Ro = name.replace(/\s/g, '').toUpperCase()
        // console.log(outputString)
        setRoleDesc(getSingleRoleData?.description)
        setInvoiceFullAccess(
            getSingleRoleData?.permissions?.[`${RoleKey}InvoicesFullAccess`]
        )
        setInvoiceView(
            getSingleRoleData?.permissions?.[`${RoleKey}InvoicesView`]
        )
        // setInvoiceCreate(
        //     getSingleRoleData?.permissions?.[`${RoleKey}InvoicesCreate`]
        // )
        // setInvoiceEdit(
        //     getSingleRoleData?.permissions?.[`${RoleKey}InvoicesEdit`]
        // )
        // setInvoiceDelete(
        //     getSingleRoleData?.permissions?.[`${RoleKey}InvoicesDelete`]
        // )
        // setInvoiceApprove(
        //     getSingleRoleData?.permissions?.[`${RoleKey}InvoicesApprove`]
        // )
        setInvoiceExport(
            getSingleRoleData?.permissions?.[`${RoleKey}InvoicesExport`]
        )
        setInvoiceShare(
            getSingleRoleData?.permissions?.[`${RoleKey}InvoicesShare`]
        )
        setInvoiceMail(
            getSingleRoleData?.permissions?.[`${RoleKey}InvoicesMail`]
        )
        setCreditNoteFullAccess(
            getSingleRoleData?.permissions?.[`${RoleKey}CreditNotesFullAccess`]
        )
        setCreditNoteView(
            getSingleRoleData?.permissions?.[`${RoleKey}CreditNotesView`]
        )
        // setCreditNoteCreate(
        //     getSingleRoleData?.permissions?.[`${RoleKey}CreditNoteCreate`]
        // )
        // setCreditNoteEdit(
        //     getSingleRoleData?.permissions?.[`${RoleKey}CreditNoteEdit`]
        // )
        // setCreditNoteDelete(
        //     getSingleRoleData?.permissions?.[`${RoleKey}CreditNoteDelete`]
        // )
        // setCreditNoteApprove(
        //     getSingleRoleData?.permissions?.[`${RoleKey}CreditNoteApprove`]
        // )
        setCreditNoteExport(
            getSingleRoleData?.permissions?.[`${RoleKey}CreditNotesExport`]
        )
        setCreditNoteShare(
            getSingleRoleData?.permissions?.[`${RoleKey}CreditNotesShare`]
        )
        setCreditNoteMail(
            getSingleRoleData?.permissions?.[`${RoleKey}CreditNotesMail`]
        )
        setReceiptFullAccess(
            getSingleRoleData?.permissions?.[`${RoleKey}ReceiptFullAccess`]
        )
        setReceiptView(
            getSingleRoleData?.permissions?.[`${RoleKey}ReceiptView`]
        )
        // setReceiptCreate(
        //     getSingleRoleData?.permissions?.[`${RoleKey}ReceiptCreate`]
        // )
        // setReceiptEdit(
        //     getSingleRoleData?.permissions?.[`${RoleKey}ReceiptEdit`]
        // )
        // setReceiptDelete(
        //     getSingleRoleData?.permissions?.[`${RoleKey}ReceiptDelete`]
        // )
        // setReceiptApprove(
        //     getSingleRoleData?.permissions?.[`${RoleKey}ReceiptApprove`]
        // )
        setReceiptExport(
            getSingleRoleData?.permissions?.[`${RoleKey}ReceiptExport`]
        )
        setReceiptShare(
            getSingleRoleData?.permissions?.[`${RoleKey}ReceiptShare`]
        )
        setReceiptMail(
            getSingleRoleData?.permissions?.[`${RoleKey}ReceiptMail`]
        )
        setReceivableFullAccess(
            getSingleRoleData?.permissions?.[`${RoleKey}ReceivableFullAccess`]
        )
        setReceivableView(
            getSingleRoleData?.permissions?.[`${RoleKey}ReceivableView`]
        )
        // setReceivableCreate(
        //     getSingleRoleData?.permissions?.[`${RoleKey}ReceivableCreate`]
        // )
        // setReceivableEdit(
        //     getSingleRoleData?.permissions?.[`${RoleKey}ReceivableEdit`]
        // )
        // setReceivableDelete(
        //     getSingleRoleData?.permissions?.[`${RoleKey}ReceivableDelete`]
        // )
        // setReceivableApprove(
        //     getSingleRoleData?.permissions?.[`${RoleKey}ReceivableApprove`]
        // )
        setReceivableExport(
            getSingleRoleData?.permissions?.[`${RoleKey}ReceivableExport`]
        )
        setReceivableShare(
            getSingleRoleData?.permissions?.[`${RoleKey}ReceivableShare`]
        )
        setReceivableMail(
            getSingleRoleData?.permissions?.[`${RoleKey}ReceivableMail`]
        )
        setBillsFullAccess(
            getSingleRoleData?.permissions?.[`${RoleKey}BillsFullAccess`]
        )
        setBillsView(getSingleRoleData?.permissions?.[`${RoleKey}BillsView`])
        // setBillsCreate(
        //     getSingleRoleData?.permissions?.[`${RoleKey}BillsCreate`]
        // )
        // setBillsEdit(getSingleRoleData?.permissions?.[`${RoleKey}BillsEdit`])
        // setBillsDelete(
        //     getSingleRoleData?.permissions?.[`${RoleKey}BillsDelete`]
        // )
        // setBillsApprove(
        //     getSingleRoleData?.permissions?.[`${RoleKey}BillsApprove`]
        // )
        setBillsExport(
            getSingleRoleData?.permissions?.[`${RoleKey}BillsExport`]
        )
        setBillsShare(
            getSingleRoleData?.permissions?.[`${RoleKey}BillsShare`]
        )
        setBillsMail(
            getSingleRoleData?.permissions?.[`${RoleKey}BillsMail`]
        )
        setDebitNotesFullAccess(
            getSingleRoleData?.permissions?.[`${RoleKey}DebitNotesFullAccess`]
        )
        setDebitNotesView(
            getSingleRoleData?.permissions?.[`${RoleKey}DebitNotesView`]
        )
        // setDebitNotesCreate(
        //     getSingleRoleData?.permissions?.[`${RoleKey}DebitNotesCreate`]
        // )
        // setDebitNotesEdit(
        //     getSingleRoleData?.permissions?.[`${RoleKey}DebitNotesEdit`]
        // )
        // setDebitNotesDelete(
        //     getSingleRoleData?.permissions?.[`${RoleKey}DebitNotesDelete`]
        // )
        // setDebitNotesApprove(
        //     getSingleRoleData?.permissions?.[`${RoleKey}DebitNotesApprove`]
        // )
        setDebitNotesExport(
            getSingleRoleData?.permissions?.[`${RoleKey}DebitNotesExport`]
        )
        setDebitNotesShare(
            getSingleRoleData?.permissions?.[`${RoleKey}DebitNotesShare`]
        )
        setDebitNotesMail(
            getSingleRoleData?.permissions?.[`${RoleKey}DebitNotesMail`]
        )
        setPaymentFullAccess(
            getSingleRoleData?.permissions?.[`${RoleKey}PaymentFullAccess`]
        )
        setPaymentView(
            getSingleRoleData?.permissions?.[`${RoleKey}PaymentView`]
        )
        // setPaymentCreate(
        //     getSingleRoleData?.permissions?.[`${RoleKey}PaymentCreate`]
        // )
        // setPaymentEdit(
        //     getSingleRoleData?.permissions?.[`${RoleKey}PaymentEdit`]
        // )
        // setPaymentDelete(
        //     getSingleRoleData?.permissions?.[`${RoleKey}PaymentDelete`]
        // )
        // setPaymentApprove(
        //     getSingleRoleData?.permissions?.[`${RoleKey}PaymentApprove`]
        // )
        // setSetting(getSingleRoleData?.permissions?.[`${RoleKey}Settings`])
        setPaymentExport(
            getSingleRoleData?.permissions?.[`${RoleKey}PaymentExport`]
        )
        setPaymentShare(
            getSingleRoleData?.permissions?.[`${RoleKey}PaymentShare`]
        )
        setPaymentMail(
            getSingleRoleData?.permissions?.[`${RoleKey}PaymentMail`]
        )
        setPayableFullAccess(
            getSingleRoleData?.permissions?.[`${RoleKey}PayableFullAccess`]
        )
        setPayableView(
            getSingleRoleData?.permissions?.[`${RoleKey}PayableView`]
        )
        // setPayableCreate(
        //     getSingleRoleData?.permissions?.[`${RoleKey}PayableCreate`]
        // )
        // setPayableEdit(
        //     getSingleRoleData?.permissions?.[`${RoleKey}PayableEdit`]
        // )
        // setPayableDelete(
        //     getSingleRoleData?.permissions?.[`${RoleKey}PayableDelete`]
        // )
        // setPayableApprove(
        //     getSingleRoleData?.permissions?.[`${RoleKey}PayableApprove`]
        // )
       
        setPayableExport(
            getSingleRoleData?.permissions?.[`${RoleKey}PayableExport`]
        )
        setPayableShare(
            getSingleRoleData?.permissions?.[`${RoleKey}PayableShare`]
        )
        setPayableMail(
            getSingleRoleData?.permissions?.[`${RoleKey}PayableMail`]
        )
        setUpdateOrgProfile(getSingleRoleData?.permissions?.[`${RoleKey}SettingsUpdateOrganizationpProfile`])
         // setSetting(getSingleRoleData?.permissions?.[`${RoleKey}Settings`])
        setGeneralSettings(getSingleRoleData?.permissions?.[`${RoleKey}GeneralSettings`])
        // setNotification(
        //     getSingleRoleData?.permissions?.[`${RoleKey}Notifications`]
        // )
        setSupportAndFeedback(getSingleRoleData?.permissions?.[`${RoleKey}SupportsAndFeedback`])
        setPreferenceSetting(getSingleRoleData?.permissions?.[`${RoleKey}PreferencesSettings`])
    }, [getSingleRoleData, RoleKey])

    return (
        <div>
            <Formik>
                <Form>
                    <FormContainer>
                        <FormItem>
                            <div className="flex">
                                <label
                                    htmlFor="roleName"
                                    className="w-1/4 px-6 font-semibold text-[#1F2937]"
                                >
                                    Role Name
                                </label>
                                <input
                                    className="w-full px-3 border-2 border-grey-600 rounded py-3 text-[#737A87]"
                                    type="text"
                                    id="roleName"
                                    autoComplete="off"
                                    // value={roleName}
                                    value={getSingleRoleData?.roleName}
                                    onChange={(e) => {
                                        setRoleName(e.target.value)
                                    }}
                                    name="roleName"
                                    placeholder="Role Name"
                                    required
                                />
                            </div>
                        </FormItem>
                        <FormItem>
                            <div className="flex">
                                <label
                                    htmlFor="roleName"
                                    className="w-1/4 px-6 font-semibold text-[#1F2937]"
                                >
                                    Description
                                </label>
                                <textarea
                                    className="w-full px-3 border-2 border-grey-600 rounded py-3 min-h-[100px] max-h-[150px] text-[#737A87]"
                                    type="text"
                                    autoComplete="off"
                                    value={roleDesc}
                                    onChange={(e) => {
                                        setRoleDesc(e.target.value)
                                    }}
                                    name="description"
                                    placeholder="Role Description"
                                    required
                                />
                            </div>
                        </FormItem>

                        <div className="flex items-center w-full">
                            <p className="text-lg px-6 py-2 font-normal text-[#1F2937] bg-blue-50 w-full">
                                Permissions
                            </p>
                        </div>

                        <div className="flex items-center w-full mt-3 px-6 py-2 bg-blue-50 gap-2">
                            {salesMenu ? (
                                <IoIosArrowDown
                                    size="20px"
                                    className="font-bold cursor-pointer"
                                    fontWeight={600}
                                    onClick={() => {
                                        setSalesMenu(!salesMenu)
                                    }}
                                />
                            ) : (
                                <IoIosArrowUp
                                    size="20px"
                                    className="font-bold cursor-pointer"
                                    fontWeight={600}
                                    onClick={() => {
                                        setSalesMenu(!salesMenu)
                                    }}
                                />
                            )}
                            <p className="text-lg  font-normal text-[#1F2937]  w-full">
                                Sales
                            </p>
                        </div>
                        {salesMenu && (
                            <div className="w-full">
                                <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-3">
                                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3"
                                                ></th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-center"
                                                >
                                                    Full Access
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-center"
                                                >
                                                    View
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-center"
                                                >
                                                    Export
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-center"
                                                >
                                                    Share
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-center"
                                                >
                                                    Mail
                                                </th>
                                                {/* <th
                                                    scope="col"
                                                    className="px-6 py-3 text-center"
                                                >
                                                    Create
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-center"
                                                >
                                                    Edit
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-center"
                                                >
                                                    Delete
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-center"
                                                >
                                                    Approve
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-center"
                                                >
                                                    <span className="sr-only">
                                                        Edit
                                                    </span>
                                                </th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* ------------Invoice ---------- */}
                                            <tr
                                                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                                                role="group"
                                                aria-labelledby="checkbox-group"
                                            >
                                                <th
                                                    scope="row"
                                                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                >
                                                    Invoices
                                                </th>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="InvoiceFullAccess"
                                                            checked={
                                                                InvoiceFullAccess
                                                            }
                                                            onClick={() => {
                                                                setInvoiceFullAccess(
                                                                    !InvoiceFullAccess
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="InvoiceView"
                                                            checked={
                                                                InvoiceFullAccess
                                                                    ? true
                                                                    : InvoiceView
                                                            }
                                                            onClick={() => {
                                                                setInvoiceView(
                                                                    !InvoiceView
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="InvoiceExport"
                                                            checked={
                                                                InvoiceFullAccess
                                                                    ? true
                                                                    : InvoiceExport
                                                            }
                                                            onClick={() => {
                                                                setInvoiceExport(
                                                                    !InvoiceExport
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="InvoiceShare"
                                                            checked={
                                                                InvoiceFullAccess
                                                                    ? true
                                                                    : InvoiceShare
                                                            }
                                                            onClick={() => {
                                                                setInvoiceShare(
                                                                    !InvoiceShare
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="InvoiceMail"
                                                            checked={
                                                                InvoiceFullAccess
                                                                    ? true
                                                                    : InvoiceMail
                                                            }
                                                            onClick={() => {
                                                                setInvoiceMail(
                                                                    !InvoiceMail
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                {/* <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="InvoiceCreate"
                                                            checked={
                                                                InvoiceFullAccess
                                                                    ? true
                                                                    : InvoiceCreate
                                                            }
                                                            onClick={() => {
                                                                setInvoiceCreate(
                                                                    !InvoiceCreate
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="InvoiceEdit"
                                                            checked={
                                                                InvoiceFullAccess
                                                                    ? true
                                                                    : InvoiceEdit
                                                            }
                                                            onClick={() => {
                                                                setInvoiceEdit(
                                                                    !InvoiceEdit
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="InvoiceDelete"
                                                            checked={
                                                                InvoiceFullAccess
                                                                    ? true
                                                                    : InvoiceDelete
                                                            }
                                                            onClick={() => {
                                                                setInvoiceDelete(
                                                                    !InvoiceDelete
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="InvoiceApprove"
                                                            checked={
                                                                InvoiceFullAccess
                                                                    ? true
                                                                    : InvoiceApprove
                                                            }
                                                            onClick={() => {
                                                                setInvoiceApprove(
                                                                    !InvoiceApprove
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td> */}
                                                {/* <td className="px-6 py-4 text-center">
                                                    <a
                                                        href="#"
                                                        className="font-normal text-[#4F3889]"
                                                    >
                                                        More Permissions
                                                    </a>
                                                </td> */}
                                            </tr>
                                            {/* ------------Quotes ---------- */}
                                            {/* <tr
                                            className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                                            role="group"
                                            aria-labelledby="checkbox-group"
                                        >
                                            <th
                                                scope="row"
                                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                            >
                                                Quotes
                                            </th>
                                            <td className="px-6 py-4 text-center">
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        name="QuotesFullAccess"
                                                    />
                                                </label>
                                            </td>
                                            <td className="px-6 py-4 text-center">
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        name="QuotesView"
                                                    />
                                                </label>
                                            </td>
                                            <td className="px-6 py-4 text-center">
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        name="QuotesCreate"
                                                    />
                                                </label>
                                            </td>
                                            <td className="px-6 py-4 text-center">
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        name="QuotesEdit"
                                                    />
                                                </label>
                                            </td>
                                            <td className="px-6 py-4 text-center">
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        name="QuotesDelete"
                                                    />
                                                </label>
                                            </td>
                                            <td className="px-6 py-4 text-center">
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        name="QuotesApprove"
                                                    />
                                                </label>
                                            </td>
                                            <td className="px-6 py-4 text-center">
                                                <a
                                                    href="#"
                                                    className="font-normal text-[#4F3889]"
                                                >
                                                    More Permissions
                                                </a>
                                            </td>
                                        </tr> */}
                                            {/* ------------Credit Notes ---------- */}
                                            <tr
                                                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                                                role="group"
                                                aria-labelledby="checkbox-group"
                                            >
                                                <th
                                                    scope="row"
                                                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                >
                                                    Credit Notes
                                                </th>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="CreditNoteFullAccess"
                                                            checked={
                                                                CreditNoteFullAccess
                                                            }
                                                            onClick={() => {
                                                                setCreditNoteFullAccess(
                                                                    !CreditNoteFullAccess
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="CreditNoteView"
                                                            checked={
                                                                CreditNoteFullAccess
                                                                    ? true
                                                                    : CreditNoteView
                                                            }
                                                            onClick={() => {
                                                                setCreditNoteView(
                                                                    !CreditNoteView
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="CreditNoteExport"
                                                            checked={
                                                                CreditNoteFullAccess
                                                                    ? true
                                                                    : CreditNoteExport
                                                            }
                                                            onClick={() => {
                                                                setCreditNoteExport(
                                                                    !CreditNoteExport
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="CreditNoteShare"
                                                            checked={
                                                                CreditNoteFullAccess
                                                                    ? true
                                                                    : CreditNoteShare
                                                            }
                                                            onClick={() => {
                                                                setCreditNoteShare(
                                                                    !CreditNoteShare
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="CreditNoteMail"
                                                            checked={
                                                                CreditNoteFullAccess
                                                                    ? true
                                                                    : CreditNoteMail
                                                            }
                                                            onClick={() => {
                                                                setCreditNoteMail(
                                                                    !CreditNoteMail
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                {/* <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="CreditNoteCreate"
                                                            checked={
                                                                CreditNoteFullAccess
                                                                    ? true
                                                                    : CreditNoteCreate
                                                            }
                                                            onClick={() => {
                                                                setCreditNoteCreate(
                                                                    !CreditNoteCreate
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="CreditNoteEdit"
                                                            checked={
                                                                CreditNoteFullAccess
                                                                    ? true
                                                                    : CreditNoteEdit
                                                            }
                                                            onClick={() => {
                                                                setCreditNoteEdit(
                                                                    !CreditNoteEdit
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="CreditNoteDelete"
                                                            checked={
                                                                CreditNoteFullAccess
                                                                    ? true
                                                                    : CreditNoteDelete
                                                            }
                                                            onClick={() => {
                                                                setCreditNoteDelete(
                                                                    !CreditNoteDelete
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="CreditNoteApprove"
                                                            checked={
                                                                CreditNoteFullAccess
                                                                    ? true
                                                                    : CreditNoteApprove
                                                            }
                                                            onClick={() => {
                                                                setCreditNoteApprove(
                                                                    !CreditNoteApprove
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td> */}
                                                {/* <td className="px-6 py-4 text-center">
                                                    <a
                                                        href="#"
                                                        className="font-normal text-[#4F3889]"
                                                    >
                                                        More Permissions
                                                    </a>
                                                </td> */}
                                            </tr>
                                            {/* ------------Receipt ---------- */}
                                            <tr
                                                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                                                role="group"
                                                aria-labelledby="checkbox-group"
                                            >
                                                <th
                                                    scope="row"
                                                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                >
                                                    Receipt
                                                </th>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="ReceiptFullAccess"
                                                            checked={
                                                                ReceiptFullAccess
                                                            }
                                                            onClick={() => {
                                                                setReceiptFullAccess(
                                                                    !ReceiptFullAccess
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="ReceiptView"
                                                            checked={
                                                                ReceiptFullAccess
                                                                    ? true
                                                                    : ReceiptView
                                                            }
                                                            onClick={() => {
                                                                setReceiptView(
                                                                    !ReceiptView
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="ReceiptExport"
                                                            checked={
                                                                ReceiptFullAccess
                                                                    ? true
                                                                    : ReceiptExport
                                                            }
                                                            onClick={() => {
                                                                setReceiptExport(
                                                                    !ReceiptExport
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="ReceiptShare"
                                                            checked={
                                                                ReceiptFullAccess
                                                                    ? true
                                                                    : ReceiptShare
                                                            }
                                                            onClick={() => {
                                                                setReceiptShare(
                                                                    !ReceiptShare
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="ReceiptMail"
                                                            checked={
                                                                ReceiptFullAccess
                                                                    ? true
                                                                    : ReceiptMail
                                                            }
                                                            onClick={() => {
                                                                setReceiptMail(
                                                                    !ReceiptMail
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                {/* <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="ReceiptCreate"
                                                            checked={
                                                                ReceiptFullAccess
                                                                    ? true
                                                                    : ReceiptCreate
                                                            }
                                                            onClick={() => {
                                                                setReceiptCreate(
                                                                    !ReceiptCreate
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="ReceiptEdit"
                                                            checked={
                                                                ReceiptFullAccess
                                                                    ? true
                                                                    : ReceiptEdit
                                                            }
                                                            onClick={() => {
                                                                setReceiptEdit(
                                                                    !ReceiptEdit
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="ReceiptDelete"
                                                            checked={
                                                                ReceiptFullAccess
                                                                    ? true
                                                                    : ReceiptDelete
                                                            }
                                                            onClick={() => {
                                                                setReceiptDelete(
                                                                    !ReceiptDelete
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="ReceiptApprove"
                                                            checked={
                                                                ReceiptFullAccess
                                                                    ? true
                                                                    : ReceiptApprove
                                                            }
                                                            onClick={() => {
                                                                setReceiptApprove(
                                                                    !ReceiptApprove
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td> */}
                                                {/* <td className="px-6 py-4 text-center">
                                                    <a
                                                        href="#"
                                                        className="font-normal text-[#4F3889]"
                                                    >
                                                        More Permissions
                                                    </a>
                                                </td> */}
                                            </tr>
                                            {/* ------------Receivable ---------- */}
                                            <tr
                                                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                                                role="group"
                                                aria-labelledby="checkbox-group"
                                            >
                                                <th
                                                    scope="row"
                                                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                >
                                                    Receivable
                                                </th>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="ReceivableFullAccess"
                                                            checked={
                                                                ReceivableFullAccess
                                                            }
                                                            onClick={() => {
                                                                setReceivableFullAccess(
                                                                    !ReceivableFullAccess
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="ReceivableView"
                                                            checked={
                                                                ReceivableFullAccess
                                                                    ? true
                                                                    : ReceivableView
                                                            }
                                                            onClick={() => {
                                                                setReceivableView(
                                                                    !ReceivableView
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="ReceivableExport"
                                                            checked={
                                                                ReceivableFullAccess
                                                                    ? true
                                                                    : ReceivableExport
                                                            }
                                                            onClick={() => {
                                                                setReceivableExport(
                                                                    !ReceivableExport
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="ReceivableShare"
                                                            checked={
                                                                ReceivableFullAccess
                                                                    ? true
                                                                    : ReceivableShare
                                                            }
                                                            onClick={() => {
                                                                setReceivableShare(
                                                                    !ReceivableShare
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="ReceivableMail"
                                                            checked={
                                                                ReceivableFullAccess
                                                                    ? true
                                                                    : ReceivableMail
                                                            }
                                                            onClick={() => {
                                                                setReceivableMail(
                                                                    !ReceivableMail
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                {/* <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="ReceivableCreate"
                                                            checked={
                                                                ReceivableFullAccess
                                                                    ? true
                                                                    : RReceivableCreate
                                                            }
                                                            onClick={() => {
                                                                setReceivableCreate(
                                                                    !ReceivableCreate
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="ReceivableEdit"
                                                            checked={
                                                                ReceivableFullAccess
                                                                    ? true
                                                                    : ReceivableEdit
                                                            }
                                                            onClick={() => {
                                                                setReceivableEdit(
                                                                    !ReceivableEdit
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="ReceivableDelete"
                                                            checked={
                                                                ReceivableFullAccess
                                                                    ? true
                                                                    : ReceivableDelete
                                                            }
                                                            onClick={() => {
                                                                setReceivableDelete(
                                                                    !ReceivableDelete
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="ReceivableApprove"
                                                            checked={
                                                                ReceivableFullAccess
                                                                    ? true
                                                                    : ReceivableApprove
                                                            }
                                                            onClick={() => {
                                                                setReceivableApprove(
                                                                    !ReceivableApprove
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td> */}
                                                {/* <td className="px-6 py-4 text-center">
                                                    <a
                                                        href="#"
                                                        className="font-normal text-[#4F3889]"
                                                    >
                                                        More Permissions
                                                    </a>
                                                </td> */}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}

                        {/* ----------------Purchase Menu------------------- */}

                        <div className="flex items-center w-full mt-3 px-6 py-2 bg-blue-50 gap-2">
                            {purchaseMenu ? (
                                <IoIosArrowDown
                                    size="20px"
                                    className="font-bold cursor-pointer"
                                    fontWeight={600}
                                    onClick={() => {
                                        setPurchaseMenu(!purchaseMenu)
                                    }}
                                />
                            ) : (
                                <IoIosArrowUp
                                    size="20px"
                                    className="font-bold cursor-pointer"
                                    fontWeight={600}
                                    onClick={() => {
                                        setPurchaseMenu(!purchaseMenu)
                                    }}
                                />
                            )}
                            <p className="text-lg  font-normal text-[#1F2937]  w-full">
                                Purchase
                            </p>
                        </div>
                        {purchaseMenu && (
                            <div className="w-full">
                                <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-3">
                                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3"
                                                ></th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-center"
                                                >
                                                    Full Access
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-center"
                                                >
                                                    View
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-center"
                                                >
                                                    Export
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-center"
                                                >
                                                    Share
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-center"
                                                >
                                                    Mail
                                                </th>
                                                {/* <th
                                                    scope="col"
                                                    className="px-6 py-3 text-center"
                                                >
                                                    Create
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-center"
                                                >
                                                    Edit
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-center"
                                                >
                                                    Delete
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-center"
                                                >
                                                    Approve
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-center"
                                                >
                                                    <span className="sr-only">
                                                        Edit
                                                    </span>
                                                </th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* ------------Bills ---------- */}
                                            <tr
                                                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                                                role="group"
                                                aria-labelledby="checkbox-group"
                                            >
                                                <th
                                                    scope="row"
                                                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                >
                                                    Bills
                                                </th>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="BillsFullAccess"
                                                            checked={
                                                                BillsFullAccess
                                                            }
                                                            onClick={() => {
                                                                setBillsFullAccess(
                                                                    !BillsFullAccess
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="BillsView"
                                                            checked={
                                                                BillsFullAccess
                                                                    ? true
                                                                    : BillsView
                                                            }
                                                            onClick={() => {
                                                                setBillsView(
                                                                    !BillsView
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="BillsExport"
                                                            checked={
                                                                BillsFullAccess
                                                                    ? true
                                                                    : BillsExport
                                                            }
                                                            onClick={() => {
                                                                setBillsExport(
                                                                    !BillsExport
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="BillsShare"
                                                            checked={
                                                                BillsFullAccess
                                                                    ? true
                                                                    : BillsShare
                                                            }
                                                            onClick={() => {
                                                                setBillsShare(
                                                                    !BillsShare
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="BillsMail"
                                                            checked={
                                                                BillsFullAccess
                                                                    ? true
                                                                    : BillsMail
                                                            }
                                                            onClick={() => {
                                                                setBillsMail(
                                                                    !BillsMail
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                {/* <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="BillsCreate"
                                                            checked={
                                                                BillsFullAccess
                                                                    ? true
                                                                    : BillsCreate
                                                            }
                                                            onClick={() => {
                                                                setBillsCreate(
                                                                    !BillsCreate
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="BillsEdit"
                                                            checked={
                                                                BillsFullAccess
                                                                    ? true
                                                                    : BillsEdit
                                                            }
                                                            onClick={() => {
                                                                setBillsEdit(
                                                                    !BillsEdit
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="BillsDelete"
                                                            checked={
                                                                BillsFullAccess
                                                                    ? true
                                                                    : BillsDelete
                                                            }
                                                            onClick={() => {
                                                                setBillsDelete(
                                                                    !BillsDelete
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="BillsApprove"
                                                            checked={
                                                                BillsFullAccess
                                                                    ? true
                                                                    : BillsApprove
                                                            }
                                                            onClick={() => {
                                                                setBillsApprove(
                                                                    !BillsApprove
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td> */}
                                                {/* <td className="px-6 py-4 text-center">
                                                    <a
                                                        href="#"
                                                        className="font-normal text-[#4F3889]"
                                                    >
                                                        More Permissions
                                                    </a>
                                                </td> */}
                                            </tr>

                                            {/* ------------Debit Notes ---------- */}
                                            <tr
                                                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                                                role="group"
                                                aria-labelledby="checkbox-group"
                                            >
                                                <th
                                                    scope="row"
                                                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                >
                                                    Debit Notes
                                                </th>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="DebitNotesFullAccess"
                                                            checked={
                                                                DebitNotesFullAccess
                                                            }
                                                            onClick={() => {
                                                                setDebitNotesFullAccess(
                                                                    !DebitNotesFullAccess
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="DebitNotesView"
                                                            checked={
                                                                DebitNotesFullAccess
                                                                    ? true
                                                                    : DebitNotesView
                                                            }
                                                            onClick={() => {
                                                                setDebitNotesView(
                                                                    !DebitNotesView
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="DebitNotesExport"
                                                            checked={
                                                                DebitNotesFullAccess
                                                                    ? true
                                                                    : DebitNotesExport
                                                            }
                                                            onClick={() => {
                                                                setDebitNotesExport(
                                                                    !DebitNotesExport
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="DebitNotesShare"
                                                            checked={
                                                                DebitNotesFullAccess
                                                                    ? true
                                                                    : DebitNotesShare
                                                            }
                                                            onClick={() => {
                                                                setDebitNotesShare(
                                                                    !DebitNotesShare
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="DebitNotesMail"
                                                            checked={
                                                                DebitNotesFullAccess
                                                                    ? true
                                                                    : DebitNotesMail
                                                            }
                                                            onClick={() => {
                                                                setDebitNotesMail(
                                                                    !DebitNotesMail
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                {/* <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="DebitNotesCreate"
                                                            checked={
                                                                DebitNotesFullAccess
                                                                    ? true
                                                                    : DebitNotesCreate
                                                            }
                                                            onClick={() => {
                                                                setDebitNotesCreate(
                                                                    !DebitNotesCreate
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="DebitNotesEdit"
                                                            checked={
                                                                DebitNotesFullAccess
                                                                    ? true
                                                                    : DebitNotesEdit
                                                            }
                                                            onClick={() => {
                                                                setDebitNotesEdit(
                                                                    !DebitNotesEdit
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="DebitNotesDelete"
                                                            checked={
                                                                DebitNotesFullAccess
                                                                    ? true
                                                                    : DebitNotesDelete
                                                            }
                                                            onClick={() => {
                                                                setDebitNotesDelete(
                                                                    !DebitNotesDelete
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="DebitNotesApprove"
                                                            checked={
                                                                DebitNotesFullAccess
                                                                    ? true
                                                                    : DebitNotesApprove
                                                            }
                                                            onClick={() => {
                                                                setDebitNotesApprove(
                                                                    !DebitNotesApprove
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td> */}
                                                {/* <td className="px-6 py-4 text-center">
                                                    <a
                                                        href="#"
                                                        className="font-normal text-[#4F3889]"
                                                    >
                                                        More Permissions
                                                    </a>
                                                </td> */}
                                            </tr>
                                            {/* ------------Payment ---------- */}
                                            <tr
                                                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                                                role="group"
                                                aria-labelledby="checkbox-group"
                                            >
                                                <th
                                                    scope="row"
                                                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                >
                                                    Payment
                                                </th>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="PaymentFullAccess"
                                                            checked={
                                                                PaymentFullAccess
                                                            }
                                                            onClick={() => {
                                                                setPaymentFullAccess(
                                                                    !PaymentFullAccess
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="PaymentView"
                                                            checked={
                                                                PaymentFullAccess
                                                                    ? true
                                                                    : PaymentView
                                                            }
                                                            onClick={() => {
                                                                setPaymentView(
                                                                    !PaymentView
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="PaymentExport"
                                                            checked={
                                                                PaymentFullAccess
                                                                    ? true
                                                                    : PaymentExport
                                                            }
                                                            onClick={() => {
                                                                setPaymentExport(
                                                                    !PaymentExport
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="PaymentShare"
                                                            checked={
                                                                PaymentFullAccess
                                                                    ? true
                                                                    : PaymentShare
                                                            }
                                                            onClick={() => {
                                                                setPaymentShare(
                                                                    !PaymentShare
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="PaymentMail"
                                                            checked={
                                                                PaymentFullAccess
                                                                    ? true
                                                                    : PaymentMail
                                                            }
                                                            onClick={() => {
                                                                setPaymentMail(
                                                                    !PaymentMail
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                {/* <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="PaymentCreate"
                                                            checked={
                                                                PaymentFullAccess
                                                                    ? true
                                                                    : PaymentCreate
                                                            }
                                                            onClick={() => {
                                                                setPaymentCreate(
                                                                    !PaymentCreate
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="PaymentEdit"
                                                            checked={
                                                                PaymentFullAccess
                                                                    ? true
                                                                    : PaymentEdit
                                                            }
                                                            onClick={() => {
                                                                setPaymentEdit(
                                                                    !PaymentEdit
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="PaymentDelete"
                                                            checked={
                                                                PaymentFullAccess
                                                                    ? true
                                                                    : PaymentDelete
                                                            }
                                                            onClick={() => {
                                                                setPaymentDelete(
                                                                    !PaymentDelete
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="PaymentApprove"
                                                            checked={
                                                                PaymentFullAccess
                                                                    ? true
                                                                    : PaymentApprove
                                                            }
                                                            onClick={() => {
                                                                setPaymentApprove(
                                                                    !PaymentApprove
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td> */}
                                                {/* <td className="px-6 py-4 text-center">
                                                    <a
                                                        href="#"
                                                        className="font-normal text-[#4F3889]"
                                                    >
                                                        More Permissions
                                                    </a>
                                                </td> */}
                                            </tr>
                                            {/* ------------Payable ---------- */}
                                            <tr
                                                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                                                role="group"
                                                aria-labelledby="checkbox-group"
                                            >
                                                <th
                                                    scope="row"
                                                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                >
                                                    Payable
                                                </th>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="PayableFullAccess"
                                                            checked={
                                                                PayableFullAccess
                                                            }
                                                            onClick={() => {
                                                                setPayableFullAccess(
                                                                    !PayableFullAccess
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="PayableView"
                                                            checked={
                                                                PayableFullAccess
                                                                    ? true
                                                                    : PayableView
                                                            }
                                                            onClick={() => {
                                                                setPayableView(
                                                                    !PayableView
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="PayableExport"
                                                            checked={
                                                                PayableFullAccess
                                                                    ? true
                                                                    : PayableExport
                                                            }
                                                            onClick={() => {
                                                                setPayableExport(
                                                                    !PayableExport
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="PayableShare"
                                                            checked={
                                                                PayableFullAccess
                                                                    ? true
                                                                    : PayableShare
                                                            }
                                                            onClick={() => {
                                                                setPayableShare(
                                                                    !PayableShare
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="PayableMail"
                                                            checked={
                                                                PayableFullAccess
                                                                    ? true
                                                                    : PayableMail
                                                            }
                                                            onClick={() => {
                                                                setPayableMail(
                                                                    !PayableMail
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                {/* <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="PayableCreate"
                                                            checked={
                                                                PayableFullAccess
                                                                    ? true
                                                                    : PayableCreate
                                                            }
                                                            onClick={() => {
                                                                setPayableCreate(
                                                                    !PayableCreate
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="PayableEdit"
                                                            checked={
                                                                PayableFullAccess
                                                                    ? true
                                                                    : PayableEdit
                                                            }
                                                            onClick={() => {
                                                                setPayableEdit(
                                                                    !PayableEdit
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="PayableDelete"
                                                            checked={
                                                                PayableFullAccess
                                                                    ? true
                                                                    : PayableDelete
                                                            }
                                                            onClick={() => {
                                                                setPayableDelete(
                                                                    !PayableDelete
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="PayableApprove"
                                                            checked={
                                                                PayableFullAccess
                                                                    ? true
                                                                    : PayableApprove
                                                            }
                                                            onClick={() => {
                                                                setPayableApprove(
                                                                    !PayableApprove
                                                                )
                                                            }}
                                                        />
                                                    </label>
                                                </td> */}
                                                {/* <td className="px-6 py-4 text-center">
                                                    <a
                                                        href="#"
                                                        className="font-normal text-[#4F3889]"
                                                    >
                                                        More Permissions
                                                    </a>
                                                </td> */}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}


                        <div className="flex items-center w-full mt-3 px-6 py-2 bg-blue-50 gap-2">
                            {settingMenu ? (
                                <IoIosArrowDown
                                    size="20px"
                                    className="font-bold cursor-pointer"
                                    fontWeight={600}
                                    onClick={() => {
                                        setSettingMenu(!settingMenu)
                                    }}
                                />
                            ) : (
                                <IoIosArrowUp
                                    size="20px"
                                    className="font-bold cursor-pointer"
                                    fontWeight={600}
                                    onClick={() => {
                                        setSettingMenu(!settingMenu)
                                    }}
                                />
                            )}
                            <p className="text-lg  font-normal text-[#1F2937]  w-full">
                                Settings
                            </p>
                        </div>
                        {settingMenu && (
                            <div className="w-full">
                                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                                <div className=" border ">
                        {/* <div className="flex items-center w-full px-6 py-2 gap-3">
                            <input
                                type="checkbox"
                                name="settings"
                                checked={setting}
                                onClick={() => {
                                    setSetting(!setting)
                                }}
                            />

                            <p className="text-lg  font-normal text-[#1F2937] ">
                                All Settings
                            </p>
                        </div> */}
                        <div className="flex items-center w-full px-6 py-2 gap-3">
                            <input
                                type="checkbox"
                                name="updateOrgProfile"
                                // checked={setting ? true : updateOrgProfile}
                                checked={updateOrgProfile}
                                onClick={() => {
                                    setUpdateOrgProfile(!updateOrgProfile)
                                }}
                            />

                            <p className="text-lg  font-normal text-[#1F2937]  w-full">
                                Update organization profile
                            </p>
                        </div>
                        <div className="flex items-center w-full px-6 py-2 gap-3">
                            <input
                                type="checkbox"
                                name="GeneralSettings"
                                // checked={setting ? true : generalSetting}
                                checked={generalSetting}
                                onClick={() => {
                                    setGeneralSettings(!generalSetting)
                                }}
                            />

                            <p className="text-lg  font-normal text-[#1F2937]  w-full">
                                General Settings
                            </p>
                        </div>

                        {/* <div className="flex items-center w-full px-6 py-2 gap-3">
                            <input
                                type="checkbox"
                                name="notification"
                                checked={setting ? true : notification}
                                onClick={() => {
                                    setNotification(!notification)
                                }}
                            />

                            <p className="text-lg  font-normal text-[#1F2937]  w-full">
                                Notifications
                            </p>
                        </div> */}
                        <div className="flex items-center w-full px-6 py-2 gap-3">
                            <input
                                type="checkbox"
                                name="supportAndFeedback"
                                checked={supportAndFeedback}
                                // checked={setting ? true : supportAndFeedback}
                                onClick={() => {
                                    setSupportAndFeedback(!supportAndFeedback)
                                }}
                            />

                            <p className="text-lg  font-normal text-[#1F2937]  w-full">
                                Supports & Feedback
                            </p>
                        </div>
                        <div className="flex items-center w-full px-6 py-2 gap-3">
                            <input
                                type="checkbox"
                                name="preferenceSetting"
                                // checked={setting ? true : preferenceSetting}
                                checked={preferenceSetting}
                                onClick={() => {
                                    setPreferenceSetting(!preferenceSetting)
                                }}
                            />

                            <p className="text-lg  font-normal text-[#1F2937]  w-full">
                                Preferences Settings
                            </p>
                        </div>
                                </div>
                                </div>
                            </div>
                        )}


                        {/* <Button className="ltr:mr-2 rtl:ml-2">Cancel</Button>
                        <Button className="ltr:mr-2 rtl:ml-2">Reset</Button>
                    <Button type="button" variant="solid">Create </Button> */}
                        <div className="mt-5 flex justify-center items-center">
                            <Button
                                type="button"
                                variant="solid"
                                onClick={(e) => HandleFormUpdate(e)}
                            >
                                Update Role
                            </Button>
                            {/*<button onClick={(e) => HandleFormSubmit(e)}>
                                Submit
                </button>*/}
                        </div>
                    </FormContainer>
                </Form>
            </Formik>
        </div>
    )
}

export default UsersRolePermissionUpdate
