import React, { useEffect, useState } from 'react'
import { Radio } from 'components/ui'
import { MdOutlineModeEditOutline } from 'react-icons/md'
import { Input, Button, Dialog, FormItem, FormContainer } from 'components/ui'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import { CiEdit } from 'react-icons/ci'

const MIN_UPLOAD = 1

const EditMailServerDetails = ({ id, getMailData }) => {
    var tokenData = JSON.parse(localStorage.getItem('setuFiToken'))
    const [dialogIsOpen, setIsOpen] = useState(false)
    const [radioValue, setRadioValue] = useState({
        REPLY: false,
        NOREPLY: false,
        TRANSACTION: false,
    })
    const [mailData, setMailData] = useState({
        CREATEDBY: '',
        ISSUPERADMIN: '',
        ACCOUNTNAME: '',
        _id: '',
        SMTPUSERNAME: '',
        SMTPPASSWORD: '',
        HOST: '',
        PORT: '',
    })
    const getSingleData = async () => {
        try {
            let resp = await fetch(
                `${process.env.REACT_APP_BASE_URL}/daybook/mail/${id}?db=superDatabase`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${tokenData}`,
                        'Content-Type': 'application/json',

                        'Access-Control-Allow-Origin': '*',
                        'access-control-allow-credentials': 'true',
                        'access-control-allow-origin': '*',
                    },
                }
            )
            resp = await resp.json()
            // let filteredData = resp.data?.find((item) => item?._id == id)
            // console.log("filteredData",filteredData)
            setMailData(resp.data)

            openDialog()
        } catch (err) {
            console.log(err)
        }
    }

    const openDialog = () => {
        setIsOpen(true)
    }

    const onDialogClose = (e) => {
        setIsOpen(false)
    }

    const onChange = (val) => {
        let data = {
            TRANSACTION: false,
            REPLY: false,
            NOREPLY: false,
        }
        for (let item in data) {
            if (val == item) {
                data[item] = true
            }
        }
        setRadioValue(data)
    }
    const submitMailData = async (val) => {
        let bodyData = {
            collection_name: 'mail servers',
            db: 'superDatabase',
            token: tokenData,
            // query: {
            //     _id: id,
            // },
            data: { ...mailData, ...radioValue },
        }
        try {
            let resp = await fetch(
                `${process.env.REACT_APP_BASE_URL}/daybook/mail/${id}`,
                {
                    method: 'PUT',
                    body: JSON.stringify(bodyData),
                    headers: {
                        Authorization: `Bearer ${tokenData}`,
                        'Content-Type': 'application/json',

                        'Access-Control-Allow-Origin': '*',
                        'access-control-allow-credentials': 'true',
                        'access-control-allow-origin': '*',
                    },
                }
            )
            resp = await resp.json()
            toast.success('Mail Updated successfully!')
            onDialogClose()
            getMailData()
        } catch (err) {
            console.log(err)
            toast.error('Error from Server side!.')
        }
    }

    return (
        <div>
            <ToastContainer />
            <Button
                variant="solid"
                className="bg-[#133386]"
                onClick={() => getSingleData()}
            >
                <CiEdit color="white" size={25} />
            </Button>
            <Dialog
                isOpen={dialogIsOpen}
                onClose={onDialogClose}
                onRequestClose={onDialogClose}
            >
                <div className="flex flex-col h-full justify-between">
                    <h5 className="mb-4">Edit Mail Server Details</h5>
                    <div className="max-h-96 overflow-y-auto">
                        <Formik
                            enableReinitialize
                            initialValues={mailData}
                            onSubmit={(
                                values,
                                { setSubmitting, setFieldValue }
                            ) => {
                                submitMailData(values)

                                setMailData((prevState) => ({
                                    ...prevState,
                                    ACCOUNTNAME: values.ACCOUNTNAME,
                                }))
                            }}
                        >
                            {({ values, touched, errors, resetForm }) => (
                                <Form>
                                    <FormContainer>
                                        <FormItem
                                            label="Account Name"
                                            asterisk
                                            invalid={
                                                errors.input && touched.input
                                            }
                                            errorMessage={errors.input}
                                        >
                                            <Field
                                                type="text"
                                                name="ACCOUNTNAME"
                                                placeholder="Account Name"
                                                component={Input}
                                                value={values.ACCOUNTNAME}
                                                onChange={(e) =>
                                                    setMailData({
                                                        ...mailData,
                                                        ACCOUNTNAME:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </FormItem>
                                        <FormItem
                                            label="Email"
                                            asterisk
                                            invalid={
                                                errors.input && touched.input
                                            }
                                            errorMessage={errors.input}
                                        >
                                            <Field
                                                type="text"
                                                name="_id"
                                                placeholder="Email"
                                                component={Input}
                                                value={values.EMAIL}
                                                onChange={(e) =>
                                                    setMailData({
                                                        ...mailData,
                                                        EMAIL: e.target.value,
                                                    })
                                                }
                                            />
                                        </FormItem>
                                        <FormItem
                                            label="SMTP Username"
                                            asterisk
                                            invalid={
                                                errors.input && touched.input
                                            }
                                            errorMessage={errors.input}
                                        >
                                            <Field
                                                type="text"
                                                name="SMTPUSERNAME"
                                                placeholder="SMTP Username"
                                                component={Input}
                                                value={values.SMTPUSERNAME}
                                                onChange={(e) =>
                                                    setMailData({
                                                        ...mailData,
                                                        SMTPUSERNAME:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </FormItem>
                                        <FormItem
                                            label="Password"
                                            asterisk
                                            invalid={
                                                errors.input && touched.input
                                            }
                                            errorMessage={errors.input}
                                        >
                                            <Field
                                                type="text"
                                                name="SMTPPASSWORD"
                                                placeholder="Password"
                                                component={Input}
                                                value={values.SMTPPASSWORD}
                                                onChange={(e) =>
                                                    setMailData({
                                                        ...mailData,
                                                        SMTPPASSWORD:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </FormItem>
                                        <FormItem
                                            label="Host"
                                            asterisk
                                            invalid={
                                                errors.input && touched.input
                                            }
                                            errorMessage={errors.input}
                                        >
                                            <Field
                                                type="text"
                                                name="HOST"
                                                placeholder="Host"
                                                component={Input}
                                                value={values.HOST}
                                                onChange={(e) =>
                                                    setMailData({
                                                        ...mailData,
                                                        HOST: e.target.value,
                                                    })
                                                }
                                            />
                                        </FormItem>
                                        <FormItem
                                            label="Port"
                                            asterisk
                                            invalid={
                                                errors.input && touched.input
                                            }
                                            errorMessage={errors.input}
                                        >
                                            <Field
                                                type="text"
                                                name="PORT"
                                                placeholder="Port"
                                                component={Input}
                                                value={values.PORT}
                                                onChange={(e) =>
                                                    setMailData({
                                                        ...mailData,
                                                        PORT: e.target.value,
                                                    })
                                                }
                                            />
                                        </FormItem>
                                        <FormItem
                                            label="Associated With"
                                            asterisk
                                            invalid={
                                                errors.input && touched.input
                                            }
                                            errorMessage={errors.input}
                                        >
                                            <Radio.Group
                                                value={radioValue}
                                                onChange={onChange}
                                            >
                                                <Radio value={'TRANSACTION'}>
                                                    Transactions
                                                </Radio>
                                                <Radio value={'REPLY'}>
                                                    Reply
                                                </Radio>
                                                <Radio value={'NOREPLY'}>
                                                    No Reply
                                                </Radio>
                                            </Radio.Group>
                                        </FormItem>
                                    </FormContainer>
                                    <div className="text-right mt-6 mr-4">
                                        <Button variant="solid" type="submit">
                                            Update
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default EditMailServerDetails
