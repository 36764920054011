import React, { useEffect, useState } from 'react'
import { Button, Card, Spinner } from 'components/ui'
import ReminderFormInvoice from './ReminderFormInvoice'
import ReminderFormBill from './ReminderFormBill'
import EditReminderBills from './EditReminderBills'
import { MdOutlineDelete } from 'react-icons/md'
import Swal from 'sweetalert2'
import { useSelector } from 'react-redux'
import EditReminderInvoice from './EditReminderInvoice'
import { HiOutlinePencilAlt } from 'react-icons/hi'
import { FaClock } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { Loading } from 'components/shared'

const Index = () => {
    var tokenData = JSON.parse(localStorage.getItem('setuFiToken'))
    const { user, companyData } = useSelector((state) => state.auth.user)
    const [invoices, setInvoices] = useState([])
    const [bills, setBills] = useState([])
    const [loading, setLoading] = useState(false)

    const getBills = async () => {
        let bodyData = {
            collection_name: 'users',
            database_name: 'superDatabase',
            token: tokenData,
        }
        try {
            let billResp = await fetch(
                `${process.env.REACT_APP_BASE_URL}/daybook/getallReminder`,
                {
                    method: 'POST',
                    body: JSON.stringify(bodyData),
                    headers: {
                        Authorization: `Bearer ${tokenData}`,
                        'Content-Type': 'application/json',

                        'Access-Control-Allow-Origin': '*',
                        'access-control-allow-credentials': 'true',
                        'access-control-allow-origin': '*',
                    },
                }
            )
            billResp = await billResp.json()
            let filterUser = billResp?.filter(
                (item) => item?.email == user?.email
            )
            // console.log('companyData', companyData?.Guid)
            // console.log('filterUser', filterUser)
            // let filterCompany = filterUser[0]?.companies?.find((item) => {
            //     item?.GUID == companyData?.GUID
            //     console.log("gxjfgf",item?.GUID == companyData?.GUID)
            // })
            let filterCompany = filterUser[0]?.companies?.find((item) => {
                if (item?.Guid && companyData?.Guid) {
                    return item.Guid === companyData.Guid
                }
                return false
            })

            // console.log('filterCompany:', filterCompany)
            let overDueData = []
            for (let overdue in filterCompany?.OVERDUEREMINDER) {
                let item = filterCompany?.OVERDUEREMINDER[overdue]
                if (item) {
                    overDueData.push(item)
                }
            }
            // console.log('overDueData:', overDueData)
            setBills(overDueData?.filter((item) => item?.type == 'bill'))
            setInvoices(overDueData?.filter((item) => item?.type == 'invoice'))
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getBills()
    }, [companyData])

    const deleteInvoice = async (item) => {
        let bodyData = {
            collection_name: 'users',
            database_name: 'superDatabase',
            token: tokenData,
            filter: {
                email: user?.email,
                'companies.Guid': companyData?.Guid,
            },
            fields: {
                OVERDUEREMINDER: {
                    [item?.dueDate === 'After (due date)'
                        ? parseInt(item?.dueDays) + 1
                        : item?.dueDays]: null,
                },
            },
        }
        try {
            const { value } = await Swal.fire({
                title: 'Are you sure?',
                text: 'You want to delete this file?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes!',
            })

            if (value) {
                let resp = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/daybook/deleteNestedField`,
                    {
                        method: 'DELETE',
                        body: JSON.stringify(bodyData),
                        headers: {
                            Authorization: `Bearer ${tokenData}`,
                            'Content-Type': 'application/json',

                            'Access-Control-Allow-Origin': '*',
                            'access-control-allow-credentials': 'true',
                            'access-control-allow-origin': '*',
                        },
                    }
                )
                resp = await resp.json()
                getBills()
            }
        } catch (err) {
            console.log(err)
        }
    }

    const sendManualRemider = async () => {
        const bodyData = {
            companyIds: companyData?.databaseName,
            email: user?.email,
        }

        try {
            setLoading(true)
            let resp = await fetch(
                `${process.env.REACT_APP_BASE_URL}/overDueReminder/run-cron-job`,
                {
                    method: 'POST',
                    body: JSON.stringify(bodyData),
                    headers: {
                        Authorization: `Bearer ${tokenData}`,
                        'Content-Type': 'application/json',
                    },
                }
            )

            resp = await resp.json()

            if (resp.status === true) {
                toast.success(resp.msg)
            } else {
                toast.error(resp.msg)
            }


        } catch (err) {
            console.log(err)
            setLoading(false)
        } finally {
            setLoading(false)
        }
    }
    console.log("user------->", user)
    return (
        <>
            {loading ? (
                <div>
                    <Loading loading={true} />
                    <p className='text-center font-semibold'>We are processing, Do not refresh the page</p>
                </div>
            ) : (
                <div className="mx-5 relative">
                    <Card className="mt-5">
                        <h1 className="text-base my-2">
                            Automated Reminders - Invoices
                        </h1>
                        <h2 className="text-base my-2">Based On Due Date</h2>
                        {invoices?.map((item, i) => {
                            return (
                                <div
                                    key={i}
                                    className="flex  my-2 items-center justify-between"
                                >
                                    <p className="text-lg">{item?.remainderName}</p>

                                    <div className="flex">
                                        <EditReminderInvoice
                                            id={item?.id}
                                            getBills={getBills}
                                        />
                                        <button
                                            className="border-[1px] rounded-lg px-5 py-2 mx-3 flex items-center"
                                            onClick={() => deleteInvoice(item)}
                                        >
                                            <MdOutlineDelete size={25} />
                                            <span className="text-6px font-bold">
                                                Delete
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            )
                        })}
                        <div className="max-h-[400px]  px-1">
                            <ReminderFormInvoice getReminder={getBills} />
                        </div>
                    </Card>

                    {/* <div>
                <Card className="mt-5">
                    <h1 className="text-base my-2">
                        Automated Reminders - Bills
                    </h1>
                    <h2 className="text-base my-2">Based On Due Date</h2>
                    {bills?.map((item, i) => {
                        console.log("bills",item)
                        return (
                            <div
                                key={i}
                                className="flex  my-2 items-center justify-between"
                            >
                                <p className="text-xl">{item?.remainderName}</p>
                                <div className="flex">
                                    <EditReminderBills
                                        email={item?.email}
                                        id={item?.id}
                                        getBills={getBills}
                                    />
                                    <button
                                        className="border-[1px] rounded-lg px-5 py-2 mx-3 flex items-center"
                                        onClick={() => deleteInvoice(item)}
                                    >
                                        <MdOutlineDelete size={25} />
                                        <span className="text-6px font-bold">
                                            Delete
                                        </span>
                                    </button>
                                </div>
                            </div>
                        )
                    })}

                    <div className="max-h-[400px] overflow-y-auto px-1">
                        <ReminderFormBill getBills={getBills} />
                    </div>
                </Card>
            </div> */}
                    <div className='absolute top-3 right-8 '>
                        <Button
                            style={{ background: '#133386' }}
                            icon={<FaClock />}
                            variant="solid"
                            onClick={sendManualRemider}
                        >
                            Send Manual Reminder
                        </Button>
                    </div>
                </div>
            )}
        </>
    )
}

export default Index
