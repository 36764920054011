import React from 'react'

const CategoryList = ({ categories, selectedCategoryId, onSelectCategory }) => {
    return (
        <div className="w-1/4 p-4 border-r">
            <h2 className="mb-4 font-semibold text-lg">Emails</h2>
            <ul className="space-y-2">
                {categories.map((category) => (
                    <li
                        key={category.id}
                        onClick={() => onSelectCategory(category.id)}
                        style={{
                            backgroundColor:
                                selectedCategoryId === category.id
                                    ? '#133386'
                                    : 'transparent',
                        }}
                        className={`cursor-pointer px-3 py-2 rounded-md ${
                            selectedCategoryId === category.id
                                ? 'text-white font-semibold'
                                : 'hover:bg-gray-100'
                        } text-center`}
                    >
                        {category.name}
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default CategoryList
