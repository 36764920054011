import React, { useState } from 'react';
import MultiSelect from './MultiSelect';


const UserRolesPage = () => {
    // State for managing the pop-up for inviting users and creating roles
    const [inviteUserPopup, setInviteUserPopup] = useState(false);
    const [createRolePopup, setCreateRolePopup] = useState(false);

    // State for storing form input values
    const [email, setEmail] = useState('');
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [newRole, setNewRole] = useState('');
    const [userData, setUserData] = useState([
        { id: 1, email: 'user1@example.com', roles: ['Option 1', 'Option 2'] },
        { id: 2, email: 'user2@example.com', roles: ['Option 2', 'Option 3'] },
        // Add more user data here
    ]);

    const options = ['Dashboard', 'Licence', 'Settings'];
    // Function to handle sending an invite
    const handleInviteUser = () => {
        // Implement the logic to send the invite
        // You can use the 'email' and 'selectedRoles' state values here
        // Close the invite user pop-up after sending the invite
        setInviteUserPopup(false);
    };

    // Function to handle creating a new role
    const handleCreateRole = () => {
        // Implement the logic to create a new role
        // You can use the 'newRole' state value here
        // Close the create role pop-up after creating the role
        setCreateRolePopup(false);
    };



    // ...
    const toggleUserRole = (user, option) => {
        // Create a copy of the user object to avoid modifying the original state directly
        const updatedUser = { ...user };

        if (updatedUser.roles.includes(option)) {
            // If the user already has the role, remove it
            updatedUser.roles = updatedUser.roles.filter((role) => role !== option);
        } else {
            // If the user doesn't have the role, add it
            updatedUser.roles = [...updatedUser.roles, option];
        }

        // Update the user data in your state with the modified user object
        const updatedUserData = userData.map((userDataItem) =>
            userDataItem.id === user.id ? updatedUser : userDataItem
        );

        // Update the state with the modified user data
        // This assumes you have a state variable for user data
        setUserData(updatedUserData); // Replace 'setUserData' with your actual state setter
    };
    // ...


    return (
        <div className="p-4">
            <div className="flex justify-between">
                <button
                    className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded mr-5"
                    onClick={() => setInviteUserPopup(true)}
                >
                    Invite User
                </button>
                {/* <button
                    className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded"
                    onClick={() => setCreateRolePopup(true)}
                >
                    Create Role
                </button> */}
            </div>

            {/* Invite User Pop-up */}
            {inviteUserPopup && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-700 bg-opacity-50">
                    <div className="bg-white p-8 rounded shadow-md">
                        <h2 className="text-lg font-semibold mb-4">Invite User</h2>
                        <input
                            type="text"
                            placeholder="Email"
                            className="border border-gray-400 rounded w-full p-2 mb-4 focus:outline-none focus:border-blue-400 transition duration-300"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <div className='mb-5'>
                            <MultiSelect options={options} />
                        </div>


                        <button
                            className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded mr-2"
                            onClick={handleInviteUser}
                        >
                            Invite
                        </button>
                        <button
                            className="bg-gray-300 hover:bg-gray-400 text-gray-800 py-2 px-4 rounded"
                            onClick={() => setInviteUserPopup(false)}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            )}

            {/* Create Role Pop-up */}
            {createRolePopup && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-700 bg-opacity-50">
                    <div className="bg-white p-8 rounded shadow-md">
                        <h2 className="text-lg font-semibold mb-4">Create Role</h2>
                        <input
                            type="text"
                            placeholder="Role Name"
                            className="border border-gray-400 rounded w-full p-2 mb-4"
                            value={newRole}
                            onChange={(e) => setNewRole(e.target.value)}
                        />
                        <button
                            className="bg-green-500 hover.bg-green-600 text-white py-2 px-4 rounded mr-2"
                            onClick={handleCreateRole}
                        >
                            Create
                        </button>
                        <button
                            className="bg-gray-300 hover.bg-gray-400 text-gray-800 py-2 px-4 rounded"
                            onClick={() => setCreateRolePopup(false)}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            )}


            {/* Table for User Emails and Roles */}
            <div className="overflow-x-auto">
                <div className="min-w-screen-xl bg-white overflow-hidden shadow-xl sm:rounded-lg">
                    <table className="w-full table-auto border-collapse border border-gray-300 mt-8">
                        <thead>
                            <tr>
                                <th className="py-2 px-4 border border-gray-300">Users</th>
                                {options.map((option) => (
                                    <th key={option} className="py-2 px-4 border border-gray-300">
                                        {option}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {/* You can map through your user data here and display each user's email */}
                            {userData.map((user) => (
                                <tr key={user.id}>
                                    <td className="py-2 px-4 border border-gray-300">{user.email}</td>
                                    {options.map((option) => (
                                        <td key={option} className="py-2 px-4 border border-gray-300 text-center">
                                            <input
                                                type="checkbox"
                                                checked={user.roles.includes(option)}
                                                onChange={() => toggleUserRole(user, option)}
                                            />
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>




        </div>
    );
};

export default UserRolesPage;
