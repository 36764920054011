

export default function cryptoFakeApi(server, apiPrefix) {
   

    server.get(`${apiPrefix}/tasks/followUp`, (schema) => {
        return schema.db.portfolioData[0]
    })

 

   

   
}
