import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { HiOutlineChartSquareBar } from 'react-icons/hi'
import { TbSettings } from 'react-icons/tb'
import { APP_PREFIX_PATH } from 'constants/route.constant'
import { ScrollBar } from 'components/ui'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Card } from 'components/ui'
import SettingTable from '../Sidebar/SettingTable'
import License from '../SuperAdminLicense/index'
import MailServer from '../MailServer/Index'
import Setting from '../../settings/index'

import Statistic from '../Sidebar/Statistic'
import {
    SIDE_NAV_WIDTH,
    SIDE_NAV_COLLAPSED_WIDTH,
    NAV_MODE_DARK,
    NAV_MODE_THEMED,
    NAV_MODE_TRANSPARENT,
    SIDE_NAV_CONTENT_GUTTER,
    LOGO_X_GUTTER,
} from 'constants/theme.constant'
import Logo from 'components/template/Logo'
import navigationConfig from 'configs/navigation.config'
import VerticalMenuContent from 'components/template/VerticalMenuContent'
import useResponsive from 'utils/hooks/useResponsive'
import { useSelector } from 'react-redux'

const SidebarStyle = {
    width: SIDE_NAV_WIDTH,
    minWidth: SIDE_NAV_WIDTH,
}

const SidebarCollapseStyle = {
    width: SIDE_NAV_COLLAPSED_WIDTH,
    minWidth: SIDE_NAV_COLLAPSED_WIDTH,
}

const Sidebar = () => {
    const [allUser, setallUser] = useState([])
    const [licenseData, setLicenseData] = useState([])
const [isLoading, setIsLoading] = useState(true)
    let tokenData = JSON.parse(localStorage.getItem('setuFiToken'))
    useEffect(() => {
        const callAPI = async () => {
            let data = await fetch(
                `${process.env.REACT_APP_BASE_URL}/getallusers/superDatabase/users/${tokenData}`
            )
            data = await data.json()
            setallUser(data)
            setIsLoading(false)
            setLicenseData(data?.filter((item) => item?.isAppAdmin == true))
        }
        callAPI()
    }, [])

    const themeColor = useSelector((state) => state.theme.themeColor)
    const primaryColorLevel = useSelector(
        (state) => state.theme.primaryColorLevel
    )
    const navMode = useSelector((state) => state.theme.navMode)
    const mode = useSelector((state) => state.theme.mode)
    const direction = useSelector((state) => state.theme.direction)
    const currentRouteKey = useSelector(
        (state) => state.base.common.currentRouteKey
    )
    const SidebarCollapse = useSelector(
        (state) => state.theme.layout.SidebarCollapse
    )
    const userAuthority = useSelector((state) => state.auth.user.authority)

    const { larger } = useResponsive()

    const SidebarColor = () => {
        if (navMode === NAV_MODE_THEMED) {
            return `bg-${themeColor}-${primaryColorLevel} side-nav-${navMode}`
        }
        return `side-nav-${navMode}`
    }

    const logoMode = () => {
        if (navMode === NAV_MODE_THEMED) {
            return NAV_MODE_DARK
        }

        if (navMode === NAV_MODE_TRANSPARENT) {
            return mode
        }

        return navMode
    }

    const menuContent = (
        <VerticalMenuContent
            navMode={navMode}
            collapsed={SidebarCollapse}
            navigationTree={navigationConfig}
            routeKey={currentRouteKey}
            userAuthority={userAuthority}
            direction={direction}
        />
    )

    return (
        <>
            {' '}
            {isLoading ? (
                <div class=" rounded-md p-4  w-full mx-auto">
                    <div class="animate-pulse flex space-x-4">
                        <div class="rounded-full bg-slate-200 h-10 w-10"></div>
                        <div class="flex-1 space-y-6 py-1">
                            <div class="h-2 bg-slate-200 rounded"></div>
                            <div class="space-y-3">
                                <div class="grid grid-cols-3 gap-4">
                                    <div class="h-2 bg-slate-200 rounded col-span-2"></div>
                                    <div class="h-2 bg-slate-200 rounded col-span-1"></div>
                                </div>
                                <div class="h-2 bg-slate-200 rounded"></div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="flex gap-5">
                    <div className="">
                        {larger.md && (
                            <div
                                style={
                                    SidebarCollapse
                                        ? SidebarCollapseStyle
                                        : SidebarStyle
                                }
                                className={classNames(
                                    'side-nav',
                                    SidebarColor(),
                                    !SidebarCollapse && 'side-nav-expand'
                                )}
                            >
                                <div className="side-nav-header"></div>
                                {SidebarCollapse ? (
                                    menuContent
                                ) : (
                                    <div className="side-nav-content ">
                                        <ScrollBar
                                            autoHide
                                            direction={direction}
                                        >
                                            <div className="flex flex-col gap-3 mt-6">
                                                <Card className="flex    items-center hover:bg-gray-300 mx-4 px-1 py-1 rounded-lg">
                                                    <div className="md:w-[210px]">
                                                        <div className="text-sm text-[#133386] font-semibold">
                                                            Total Number Of
                                                            License
                                                        </div>
                                                        <div className="mt-1">
                                                            <div className=" text-xs font-semibold flex justify-between gap-5">
                                                                <div>
                                                                    Active
                                                                </div>
                                                                <div>
                                                                    {licenseData
                                                                        ? licenseData.length
                                                                        : 0}
                                                                </div>
                                                            </div>
                                                            <div className=" text-xs font-semibold flex justify-between gap-5">
                                                                <div>
                                                                    Inactive
                                                                </div>
                                                                <div>0</div>
                                                            </div>
                                                            <div className=" text-xs font-semibold flex justify-between gap-5">
                                                                <div>
                                                                    Suspanded
                                                                </div>
                                                                <div>0</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card>
                                                <Card className="flex    items-center hover:bg-gray-300 mx-4 px-1 py-1 rounded-lg">
                                                    <div className="md:w-[210px]">
                                                        <div className="text-sm text-[#133386] font-semibold">
                                                            Total Company
                                                        </div>
                                                        <div className=" text-xs font-semibold mt-1 flex justify-center">
                                                            <div>9</div>
                                                        </div>
                                                    </div>
                                                </Card>
                                                <Card className="flex    items-center hover:bg-gray-300 mx-4 px-1 py-1 rounded-lg">
                                                    <div className="md:w-[210px]">
                                                        <div className="text-sm text-[#133386] font-semibold">
                                                            Total Transaction
                                                        </div>
                                                        <div className=" text-xs font-semibold flex justify-center mt-1">
                                                            <div>55</div>
                                                        </div>
                                                    </div>
                                                </Card>
                                                <Card className="flex    items-center hover:bg-gray-300 mx-4 px-1 py-1 rounded-lg">
                                                    <div className="md:w-[210px]">
                                                        <div className="text-sm text-[#133386] font-semibold">
                                                            Total Users
                                                        </div>
                                                        <div className=" text-xs font-semibold flex justify-center mt-1">
                                                            <div>69</div>
                                                        </div>
                                                    </div>
                                                </Card>
                                                <Card className="flex    items-center hover:bg-gray-300 mx-4 px-1 py-1 rounded-lg">
                                                    <div className="md:w-[210px]">
                                                        <div className="text-sm text-[#133386] font-semibold">
                                                            Sync Transaction
                                                            (Count)
                                                        </div>
                                                        <div className=" text-xs mt-1 font-semibold ">
                                                            <div className="flex justify-between gap-5">
                                                                <div>
                                                                    Daily Sync
                                                                </div>
                                                                <div>49</div>
                                                            </div>
                                                            <div className="flex  justify-between gap-5">
                                                                <div className="">
                                                                    Monthly Sync
                                                                </div>
                                                                <div>19</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card>
                                                <Card className="flex    items-center hover:bg-gray-300 mx-4 px-1 py-1 rounded-lg">
                                                    <div className="md:w-[210px]">
                                                        <div className="text-sm text-[#133386] font-semibold">
                                                            Sync Transaction
                                                            (Count)
                                                        </div>
                                                        <div className=" text-xs mt-1 font-semibold ">
                                                            <div className="flex justify-between gap-5">
                                                                <div>
                                                                    Daily Sync
                                                                </div>
                                                                <div>59</div>
                                                            </div>
                                                            <div className="flex justify-between gap-5">
                                                                <div>
                                                                    Monthly Sync
                                                                </div>
                                                                <div>69</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card>
                                                <Card className="flex    items-center hover:bg-gray-300 mx-4 px-1 py-1 rounded-lg">
                                                    <div className="md:w-[210px]">
                                                        <div className="text-sm text-[#133386] font-semibold">
                                                            current Active
                                                        </div>
                                                        <div className=" mx-2 text-xs mt-1 font-semibold ">
                                                            <div className="flex justify-between">
                                                                <div>
                                                                    License
                                                                </div>
                                                                <div className="">
                                                                    {licenseData
                                                                        ? licenseData.length
                                                                        : 0}
                                                                </div>
                                                            </div>
                                                            <div className="flex justify-between ">
                                                                <div>
                                                                    Company
                                                                </div>
                                                                <div className="">
                                                                    55,569
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card>
                                                <Card className="flex  items-center hover:bg-gray-300 mx-4 px-1 py-1 rounded-lg">
                                                    <div className="md:w-[210px] ">
                                                        <div className="text-sm text-[#133386] font-semibold">
                                                            current Active ( per
                                                            Company )
                                                        </div>
                                                        <div className="mx-2 text-xs mt-1 font-semibold ">
                                                            <div className="flex justify-between">
                                                                <div>
                                                                    License
                                                                </div>
                                                                <div>
                                                                    {licenseData
                                                                        ? licenseData.length
                                                                        : 0}
                                                                </div>
                                                            </div>
                                                            <div className="flex justify-between">
                                                                <div>
                                                                    Company
                                                                </div>
                                                                <div>39</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                        </ScrollBar>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                        <div className="mr-5 w-full overflow-x-auto">
                            <SettingTable allUser={licenseData} />
                        </div>
                </div>
            )}
        </>
    )
}

Sidebar.propTypes = {
    themed: PropTypes.bool,
    darkMode: PropTypes.bool,
    themeColor: PropTypes.string,
}

Sidebar.defaultProps = {
    themed: false,
    darkMode: false,
    themeColor: '',
}

export default Sidebar
