import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    selectedLicense: {},
    selectedManage: {},
    financialYear: 0,
    trialBalance: {},
    loading: false,
}

export const superAdminSlice = createSlice({
    name: 'auth/superAdmin',
    initialState,
    reducers: {
        selectLicense: (state, action) => {
            state.selectedLicense = action.payload
        },
        selectManage: (state, action) => {
            state.selectedManage = action.payload
        },
        selectFinancialYear: (state, action) => {
            state.financialYear = action.payload
        },
        setTrialBalance: (state, action) => {
            state.trialBalance = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
    },
})

export const {
    selectLicense,
    selectManage,
    selectFinancialYear,
    setTrialBalance,
    setLoading,
} = superAdminSlice.actions

export default superAdminSlice.reducer
