import React, { useState } from 'react'
import { Button, FormItem, Select, FormContainer, Dialog } from 'components/ui'
import { Form, Formik } from 'formik'
import { MdOutlineModeEditOutline } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

const EditUser = ({ EMAIL, getRoles }) => {
    const [dialogIsOpen, setIsOpen] = useState(false)
    let tokenData = JSON.parse(localStorage.getItem('setuFiToken'))
    const { user } = useSelector((state) => state.auth.user)

    const [role, setRole] = useState('')
    const openDialog = (e) => {
        e.preventDefault()
        setIsOpen(true)
    }

    const onDialogClose = (e) => {
        e.preventDefault()
        setIsOpen(false)
    }
    const closeBox = () => {
        setIsOpen(false)
    }
    const updateRole = async (e) => {
        e.preventDefault()
        let bodyData = {
            collection_name: 'users',
            database_name: 'superDatabase',
            filter: {
                email: user?.email,
                'TEAMS.email': EMAIL,
            },
            fields: {
                role: role,
            },
        }
        try {
            let resp = await fetch(
                `${process.env.REACT_APP_BASE_URL}/updatenestedfield/`,
                {
                    method: 'PUT',
                    body: JSON.stringify(bodyData),
                    headers: {
                        Authorization: `Bearer ${tokenData}`,
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'access-control-allow-credentials': 'true',
                        'access-control-allow-origin': '*',
                    },
                }
            )

            toast.success('Role Update successfully!')
            getRoles()
            closeBox()
        } catch (e) {
            console.log(e)
            toast.error('Error sending data.')
        }
    }

    return (
        <div>
            <Button onClick={(e) => openDialog(e)}>
                <MdOutlineModeEditOutline size={20} />
            </Button>
            <Dialog
                isOpen={dialogIsOpen}
                onClose={onDialogClose}
                onRequestClose={onDialogClose}
            >
                <h5 className="mb-4">Change User Role</h5>

                <Formik>
                    <Form>
                        <FormContainer>
                            <FormItem name="roles" label="Roles Name">
                                <select
                                    className="w-full px-3 py-3 border-2 rounded"
                                    onChange={(e) => setRole(e.target.value)}
                                >
                                    <option selected disabled>
                                        Select Role
                                    </option>
                                    <option value="AccountantAccess">
                                        Accountant Access
                                    </option>
                                    <option value="AdminRights">
                                        Admin RIghts
                                    </option>
                                    <option value="PurchaseManager">
                                        Purchase Manager
                                    </option>
                                    <option value="SalesManager">
                                        Sales Manager
                                    </option>
                                    <option value="WarehouseAccess">
                                        Warehouse Access
                                    </option>
                                </select>
                            </FormItem>
                            <FormItem>
                                <Button
                                    className="ltr:mr-2 rtl:ml-2"
                                    variant="plain"
                                    onClick={onDialogClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="ltr:mr-2 rtl:ml-2"
                                    onClick={onDialogClose}
                                >
                                    Reset
                                </Button>
                                <Button
                                    variant="solid"
                                    onClick={(e) => updateRole(e)}
                                >
                                    Update Role
                                </Button>
                            </FormItem>
                        </FormContainer>
                    </Form>
                </Formik>
            </Dialog>
        </div>
    )
}

export default EditUser
