import React, { useEffect } from 'react'
import classNames from 'classnames'
import { Menu, ScrollBar, Drawer } from 'components/ui'
import {
    HiOutlineInbox,
    HiOutlinePaperAirplane,
    HiOutlinePencil,
    HiOutlineStar,
    HiOutlineDocumentText,
} from 'react-icons/hi'
import { FaRegBuilding } from "react-icons/fa6";
import useResponsive from 'utils/hooks/useResponsive'
import { updateMailId } from '../store/dataSlice'
import {
    updateSelectedCategory,
    toggleMobileSidebar,
} from '../store/stateSlice'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

const { MenuItem } = Menu

export const groupList = [
    {
        value: 'UserInvite',
        label: 'User & Roles',
        icon: <HiOutlinePencil />,
    },
    // {
    //     value: 'inbox',
    //     label: ' Permissions',
    //     icon: <HiOutlineInbox />,
    // },
    {
        value: 'preference',
        label: 'Preference',
        icon: <HiOutlinePaperAirplane />,
    },
    { value: 'reminder', label: 'Reminder', icon: <HiOutlineStar /> },
    { value: 'companysetting', label: 'Delete Company Permanently', icon: <FaRegBuilding /> },
    // {
    //     value: 'reportscheduling',
    //     label: 'Report Scheduling',
    //     icon: <HiOutlineDocumentText />,
    // },
]

const MailSideBarContent = () => {
    const navigate = useNavigate()
    const location = useLocation()

    const dispatch = useDispatch()

    const selectedCategory = useSelector(
        (state) => state.crmMail.state.selectedCategory
    )

    const direction = useSelector((state) => state.theme.direction)

    const onMenuClick = (category) => {
        dispatch(updateMailId(''))
        dispatch(updateSelectedCategory(getCategory(category.value)))
        navigate(`/app/settings/`, { replace: true })
    }
    useEffect(() => {
        dispatch(updateMailId(''))
        dispatch(updateSelectedCategory(getCategory('UserInvite')))
        navigate(`/app/settings/`, { replace: true })
    }, [])

    const getCategory = (value) => {
        const categories = [...groupList]
        let category = value
        if (category === 'settings') {
            category = 'inbox'
        }
        return {
            value: category,
            label: categories.find((cat) => cat.value === category).label,
        }
    }

    return (
        <ScrollBar direction={direction}>
            <div className="flex flex-col justify-between mt-7 h-full">
                <div>
                    <div className=" mb-3  mx-6">
                        <h3>Settings</h3>
                    </div>

                    <Menu variant="transparent" className="mx-2 mb-10">
                        {groupList.map((menu) => (
                            <MenuItem
                                key={menu.value}
                                eventKey={menu.value}
                                className={`mb-2 ${selectedCategory.value === menu.value
                                    ? 'bg-gray-100 dark:bg-gray-700'
                                    : ''
                                    }`}
                                onSelect={() => onMenuClick(menu)}
                            >
                                <span className="text-2xl ltr:mr-2 rtl:ml-2">
                                    {menu.icon}
                                </span>
                                <span>{menu.label}</span>
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
            </div>
        </ScrollBar>
    )
}

const MailSidebar = () => {
    const sideBarExpand = useSelector(
        (state) => state.crmMail.state.sideBarExpand
    )

    const mobileSideBarExpand = useSelector(
        (state) => state.crmMail.state.mobileSideBarExpand
    )

    const dispatch = useDispatch()

    const { smaller } = useResponsive()

    const onMobileSideBarClose = () => {
        dispatch(toggleMobileSidebar(false))
    }

    return smaller.xl ? (
        <Drawer
            bodyClass="p-0"
            title="Mail"
            isOpen={mobileSideBarExpand}
            onClose={onMobileSideBarClose}
            onRequestClose={onMobileSideBarClose}
            placement="left"
            width={280}
        >
            <MailSideBarContent />
        </Drawer>
    ) : (
        <div
            className={classNames(
                'w-[280px] absolute top-0 bottom-0 ease-in-out duration-300 bg-white dark:bg-gray-800 ltr:border-r rtl:border-l border-gray-200 dark:border-gray-600 z-10',
                sideBarExpand
                    ? 'ltr:left-0 rtl:right-0'
                    : 'ltr:left-[-280px] rtl:right-[-280px]'
            )}
        >
            <MailSideBarContent />
        </div>
    )
}

export default MailSidebar
