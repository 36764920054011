import React from 'react'
import LicenseSidebar from './LicenseSidebar'
import TrialBalance from '../TrialBalance/TrialBalance'
const LicenseSidebarParent = () => {
    return (
        <div>
            <LicenseSidebar />
            <TrialBalance />
        </div>
    )
}

export default LicenseSidebarParent
