import React, { memo, useMemo, lazy, Suspense } from 'react'
import { Loading } from 'components/shared'
import { useSelector } from 'react-redux'
import useAuth from 'utils/hooks/useAuth'
import useDirection from 'utils/hooks/useDirection'
import useLocale from 'utils/hooks/useLocale'
import SuperAdmin from '../../views/SuperAdminDashBoard/SuperAdminSideHeaderBar'
import { useLocation } from 'react-router-dom'

const Layout = () => {
    const { user } = useSelector((state) => state.auth.user)
    const location = useLocation()

    const { authenticated } = useAuth()

    useDirection()

    useLocale()

    const AppLayout = useMemo(() => {
        if (authenticated) {
            return lazy(() => import('./ModernLayout'))
        }
        return lazy(() => import('./AuthLayout'))
    }, [authenticated])

    return (
        <Suspense
            fallback={
                <div className="flex flex-auto flex-col h-[100vh]">
                    <Loading loading={true} />
                </div>
            }
        >
            {user?.isSuperAdmin === true ? <SuperAdmin /> : <AppLayout />}
        </Suspense>
    )
}

export default memo(Layout)
