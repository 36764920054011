import { combineReducers } from '@reduxjs/toolkit'
import session from './sessionSlice'
import user from './userSlice'
import superAdmin from './superAdminLicense'

const reducer = combineReducers({
    session,
    user,
    superAdmin,
})

export default reducer
