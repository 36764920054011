import React from 'react'
import MailServer from './MailServer'
import {Card} from "components/ui"


const Index = () => {
  return (
      <Card className="m-5">
          <MailServer />
      </Card>
  )
}

export default Index