import React, { useEffect, useState } from 'react'
import { Card } from 'components/ui'
import { MdDoNotDisturbOnTotalSilence } from 'react-icons/md'
import { GiReceiveMoney } from 'react-icons/gi'
import axios from 'axios'
import DataTable from 'react-data-table-component'

const Report = () => {
    let tokenData = JSON.parse(localStorage.getItem('setuFiToken'))
    const [users, setUsers] = useState([])
    const [selectedValueis,setselectedValueis]= useState("license")
    const [companies, setCompanies] = useState([])
    const [dataFilter , setdataFilter] = useState();
    const [activeLicense, setActiveLicense] = useState([])
    const [suspendedLicense, setSuspendedLicense] = useState([])
const [AllUsersData,setAllUsersData]= useState()

    const getUsers = async () => {
        try {
            let data = await fetch(
                `${process.env.REACT_APP_BASE_URL}/getallusers/superDatabase/users/${tokenData}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${tokenData}`,
                        'Content-Type': 'application/json',

                        'Access-Control-Allow-Origin': '*',
                        'access-control-allow-credentials': 'true',
                        'access-control-allow-origin': '*',
                    },
                }
            )
            data = await data.json()
            setUsers(data?.filter((item) => item?.isSuperAdmin == false))
            const appAdmin = data?.filter(
                (item) => item?.isSuperAdmin == false && item?.isUser == false
            )
            let dummyCompany = []
            for (let user of appAdmin) {
                if (user?.companies) {
                    for (let company of user?.companies) {
                        dummyCompany.push(company)
                    }
                }
            }
            setCompanies(dummyCompany)
            setActiveLicense(
                appAdmin?.filter((item) => item?.isSuspended == false)
            )
            setSuspendedLicense(
                appAdmin?.filter((item) => item?.isSuspended == true)
            )
        } catch (err) {
            console.log(err)
        }
    }


const getAllUsers = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/superAdmin/getallusers/superDatabase/users`, {
      method: 'POST'
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
            setUsers(data?.filter((item) => item?.isSuperAdmin == false));
            const appAdmin = data?.filter(
                (item) => item?.isSuperAdmin == false && item?.isUser == false
            )
            let dummyCompany = []
            for (let user of appAdmin) {
                if (user?.companies) {
                    for (let company of user?.companies) {
                        dummyCompany.push(company)
                    }
                }
            }
            setCompanies(dummyCompany);
            setActiveLicense(
                appAdmin?.filter((item) => item?.isSuspended == false)
            );
            setSuspendedLicense(
                appAdmin?.filter((item) => item?.isSuspended == true)
            );
            

  } catch (error) {
    console.error('Error:', error.message);
  }
};

 const tableCustomStyles = {
        headRow: {
            style: {
                color: '#FFFFFF',
                backgroundColor: '#133386',
            },
        },
        rows: {
            style: {
                color: '#000000',
                backgroundColor: '#8E9DC420',
            },
            stripedStyle: {
                color: '#000000',
                backgroundColor: '#FFFFFF',
            },
        },
    }

useEffect(()=>{
 if(selectedValueis === "license"){
             setdataFilter(suspendedLicense?.filter((item) => item?.isSuspended == true))
            }
},[selectedValueis])

 const columns1 = [
        // Columns for Detailed Summary
        {
            name: 'name',
            selector: 'name',
            sortable: true,
            cell: (row) => row?.name,
        },
        {
            name: 'email',
            selector: 'email',
            sortable: true,
            cell: (row) => row?.email,
        },
        {
            name: 'phonenumber',
            selector: 'phonenumber',
            sortable: true,
            cell: (row) => row?.phonenumber,
        },
      
      
    ]
   
    useEffect(() => {
        // getUsers()
        getAllUsers()
    }, [])

    return (
        <div>
            <div className="mt-5">
                {' '}
                <Card>
                    <div className="">
                        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-4">
                            <div className="card card-border">
                                <div className="card-body">
                                    <div className="flex items-center gap-4">
                                        <span className="avatar avatar-rounded bg-cyan-100 text-cyan-600 dark:bg-cyan-500/20 dark:text-cyan-100">
                                            <span className="avatar-icon avatar-icon-55">
                                                <svg
                                                    stroke="currentColor"
                                                    fill="currentColor"
                                                    stroke-width="0"
                                                    viewBox="0 0 20 20"
                                                    height="30px"
                                                    width="30px"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                                                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                                                </svg>
                                            </span>
                                        </span>
                                        <div>
                                            <div className="flex gap-1.5 items-end mb-2">
                                                <h3 className="font-bold leading-none">
                                                    {companies?.length}
                                                </h3>
                                                <p className="font-semibold">
                                                    Company Sync
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card card-border">
                                <div className="card-body">
                                    <div className="flex items-center gap-4">
                                        <span className="avatar avatar-rounded bg-emerald-100 text-emerald-600 dark:bg-emerald-500/20 dark:text-emerald-100">
                                            <span className="avatar-icon avatar-icon-55">
                                                <svg
                                                    stroke="currentColor"
                                                    fill="currentColor"
                                                    stroke-width="0"
                                                    viewBox="0 0 20 20"
                                                    height="30px"
                                                    width="30px"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z"
                                                        clip-rule="evenodd"
                                                    ></path>
                                                </svg>
                                            </span>
                                        </span>
                                        <div>
                                            <div className="flex gap-1.5 items-end mb-2">
                                                <h3 className="font-bold leading-none">
                                                    {users?.length}
                                                </h3>
                                                <p className="font-semibold">
                                                    Users
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="card card-border">
                                <div className="card-body">
                                    <div className="flex items-center gap-4">
                                        <span className="avatar avatar-rounded bg-amber-100 text-amber-600 dark:bg-amber-500/20 dark:text-amber-100">
                                            <span className="avatar-icon avatar-icon-55">
                                                <svg
                                                    stroke="currentColor"
                                                    fill="currentColor"
                                                    stroke-width="0"
                                                    viewBox="0 0 20 20"
                                                    height="30px"
                                                    width="30px"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                                                        clip-rule="evenodd"
                                                    ></path>
                                                </svg>
                                            </span>
                                        </span>
                                        <div>
                                            <div className="flex gap-1.5 items-end mb-2">
                                                <h3 className="font-bold leading-none">
                                                    {companies?.length}
                                                </h3>
                                                <p className="font-semibold">
                                                    Database
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="card card-border">
                                <div className="card-body">
                                    <div className="flex items-center gap-4">
                                        <span className="avatar avatar-rounded bg-amber-100 text-amber-600 dark:bg-amber-500/20 dark:text-amber-100">
                                            <span className="avatar-icon avatar-icon-55">
                                                <svg
                                                    stroke="currentColor"
                                                    fill="currentColor"
                                                    stroke-width="0"
                                                    viewBox="0 0 20 20"
                                                    height="30px"
                                                    width="30px"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                                                        clip-rule="evenodd"
                                                    ></path>
                                                </svg>
                                            </span>
                                        </span>
                                        <div>
                                            <div className="flex gap-1.5 items-end mb-2">
                                                <h3 className="font-bold leading-none">
                                                    10
                                                </h3>
                                                <p className="font-semibold">
                                                    Transaction
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <Card className="mt-5">
                        {' '}
                        <h5> Licenses</h5>
                        <div className="w-full grid sm:grid-cols-1  md:grid-cols-3 lg:grid-cols-3  gap-8">
                            <div className="bg-white dark:bg-gray-800 rounded shadow px-5 py-5 flex items-center ">
                                <div style={{background : '#133386'}} className="p-4  rounded">
                                    <MdDoNotDisturbOnTotalSilence
                                        color="white"
                                        size={20}
                                    />
                                </div>
                                <div className="ml-3">
                                    <h3 className="mb-1 leading-5 text-gray-800 dark:text-gray-100 font-bold text-2xl">
                                        {suspendedLicense?.length +
                                            activeLicense?.length}
                                    </h3>
                                    <p className="text-gray-600 dark:text-gray-400 text-sm tracking-normal font-medium leading-5">
                                        Total License
                                    </p>
                                </div>
                            </div>
                            <div className="bg-white dark:bg-gray-800 rounded shadow px-5 py-5 flex items-center ">
                                <div style={{background : '#133386'}} className="p-4 rounded">
                                    <MdDoNotDisturbOnTotalSilence
                                        color="white"
                                        size={20}
                                    />
                                </div>
                                <div className="ml-3">
                                    <h3 className="mb-1 leading-5 text-gray-800 dark:text-gray-100 font-bold text-2xl">
                                        {suspendedLicense?.length}
                                    </h3>
                                    <p className="text-gray-600 dark:text-gray-400 text-sm tracking-normal font-medium leading-5">
                                        Suspended License
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white dark:bg-gray-800 rounded shadow px-5 py-5 flex items-center">
                                <div style={{background : '#133386'}} className="p-4 rounded">
                                    <GiReceiveMoney size={20} color="white" />
                                </div>
                                <div className="ml-3">
                                    <h3 className="mb-1 leading-5 text-gray-800 dark:text-gray-100 font-bold text-2xl">
                                        {activeLicense?.length}
                                    </h3>
                                    <p className="text-gray-600 dark:text-gray-400 text-sm tracking-normal font-medium leading-5">
                                        Active License
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Card>
                </Card>
            </div>
             <DataTable
                        columns={columns1}
                        data={dataFilter}
                        pagination={true}
                        paginationPerPage={10}
                        noHeader={true}
                        className="text-gray-800"
                        customStyles={tableCustomStyles}
                        // progressPending={loading}
                        progressComponent={
                            <div className="d-flex align-items-center p-5">
                                <div
                                    className="spinner-border text-primary"
                                    style={{ width: '3rem', height: '3rem' }}
                                    role="status"
                                >
                                    <span className="sr-only mt-5"></span>
                                </div>
                                <h5 className="">Loading Data...</h5>
                            </div>
                        }
                    />
        </div>
    )
}

export default Report
