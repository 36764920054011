import React, { useState } from 'react'
import { Card } from 'components/ui'
import { Select } from 'components/ui'
import { Input, Button, DatePicker } from 'components/ui'
import { HiOutlineSearch } from 'react-icons/hi'
import DataTable from 'react-data-table-component'

const colourOptions = [
    { value: 'ocean', label: 'In Stock', color: '#00B8D9' },
    { value: 'blue', label: 'Not In Stock ', color: '#0052CC' },
    { value: 'blue', label: 'Negative Stock ', color: '#0052CC' },
    { value: 'blue', label: 'Below  Reorder Level ', color: '#0052CC' },
]

const IndexMain = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [dateRange, setDateRange] = useState([
        new Date(2023, 5, 1),
        new Date(2023, 2, 5),
    ])
    const handleRangePickerChange = (date) => {
        setDateRange(date)
    }
    const columns = [
        {
            name: 'Items Name',
            selector: (row) => row?.itmsName,
            sortable: true,
        },
        {
            name: 'Qty Sold',
            selector: (row) => row?.itmsName,
            sortable: true,
        },
        {
            name: 'Qty In Stock',
            selector: (row) => row?.itmsName,
            sortable: true,
        },
        {
            name: 'Sales By Value',
            selector: (row) => row?.itmsName,
            sortable: true,
        },
        {
            name: 'Inventory Value',
            selector: (row) => row?.itmsName,
            sortable: true,
        },
        {
            name: ' Avg. Sales Value ',
            selector: (row) => row?.itmsName,
            sortable: true,
        },
        {
            name: 'Avg. Cost Price',
            selector: (row) => row?.itmsName,
            sortable: true,
        },
        {
            name: 'Last Sale Date',
            selector: (row) => row?.itmsName,
            sortable: true,
        },
    ]

    const customStyles = {
        rows: {
            style: {
                fontSize: '12px',
                fontWeight: 'bolder',
                cursor: 'pointer',
            },
        },
        headCells: {
            style: {
                fontSize: '14px',
            },
        },
    }

    return (
        <div className="mx-5">
            <div className="flex justify-end">
                <Button className="bg-[#133386]" variant="solid">
                    Export
                </Button>
            </div>
            <Card className="mt-5">
                <div className="grid grid-cols-3 mb-5 gap-4">
                    <div className="  text-base">
                        <p className="my-2 ">Filter Via Inventory</p>
                        <Select options={colourOptions} />
                    </div>
                    <div className="  text-base">
                        <p className="my-2">Filter Via Group</p>
                        <Input />
                    </div>
                    <div className=" text-base">
                        <p className="my-2 ">Filter Via category</p>
                        <Input />
                    </div>
                </div>
                <div className="grid grid-cols-2 gap-5 mb-5">
                    <Input
                        className=""
                        // size="sm"
                        placeholder="Search Items"
                        prefix={<HiOutlineSearch className="text-lg" />}
                    />

                    <DatePicker.DatePickerRange
                        placeholder="Select dates range"
                        value={dateRange}
                        onChange={handleRangePickerChange}
                        className=""
                    />
                </div>
                <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    fixedHeader
                    fixedHeaderScrollHeight="470px"
                    selectableRows
                    selectableRowsHighlight
                    highlightOnHover
                    subHeader
                    striped
                    customStyles={customStyles}
                    responsive
                    progressPending={loading}
                    progressComponent={
                        <div className="d-flex align-items-center p-5">
                            <div
                                className="spinner-border text-primary"
                                style={{ width: '3rem', height: '3rem' }}
                                role="status"
                            >
                                <span className="sr-only mt-5"></span>
                            </div>
                            <h5 className="">Loading Data...</h5>
                        </div>
                    }
                />
            </Card>
        </div>
    )
}

export default IndexMain
