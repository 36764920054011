import React, { useEffect, useState } from 'react'
import { Button, Dialog, FormItem, FormContainer } from 'components/ui'
import { Form, Formik } from 'formik'
import { useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const EditLIcense = ({ item, getUserData, getAllUserData ,handleClosePopup}) => {
    const [dialogIsOpen, setIsOpen] = useState(false)
    const { user } = useSelector((state) => state.auth.user)
    var tokenData = JSON.parse(localStorage.getItem('setuFiToken'))
    const [data, setData] = useState({})

    useEffect(() => {
        setData({
            ...item,
        })
    }, [item])

    const openDialog = () => {
        setIsOpen(true)
        handleClosePopup(true)
    }

    const onDialogClose = (e) => {
        setIsOpen(false)
    }

    // const submitData = async (e) => {
    //     e.preventDefault()
    //     const { _id, ...updatedData } = data
    //     onDialogClose()
    //     // alert(_id)
    //     let bodyData = {
    //         collection_name: 'users',
    //         database_name: 'superDatabase',
    //         query: {
    //             email: item?.email,
    //         },
    //         fields: updatedData,
    //     }
    //     try {
    //         let resp = await fetch(
    //             `${process.env.REACT_APP_BASE_URL}/api/v1/superAdmin/updateUser/${_id}`,
    //             {
    //                 method: 'PATCH',
    //                 body: JSON.stringify(bodyData),
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                 },
    //             }
    //         )

    //         resp = await resp.json()
    //         if (resp?.status == false) {
    //             toast.error('Error from server!')
    //             return
    //         }
    //         toast.success('Data saved successfully!')
    //         getAllUserData()
    //     } catch (err) {
    //         console.log(err)
    //     }
    // }

    const submitData = async (e) => {
        e.preventDefault()
        const { _id, ...updatedData } = data
        onDialogClose()

        let bodyData = {
            databaseName: 'superDatabase',
            collectionName: 'users',
            query: {
                email: item?.email,
            },

            fieldsToUpdate: updatedData,
        }

        try {
            let resp = await fetch(
                `${process.env.REACT_APP_BASE_URL}/superAdmin/updateUser/${_id}`,
                {
                    method: 'PATCH',
                    body: JSON.stringify(bodyData),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            resp = await resp.json()
            if (resp?.status == false) {
                toast.error('Error from server!')
                return
            }
            toast.success('Data saved successfully!')
            setIsOpen(false)
            getAllUserData()
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <div>
            <button
                onClick={() => openDialog()}
                className="px-4 py-2 text-gray-800 hover:bg-gray-100"
            >
                Edit
            </button>

            <Dialog
                isOpen={dialogIsOpen}
                onClose={onDialogClose}
                onRequestClose={onDialogClose}
            >
                <div className="flex flex-col h-full justify-between">
                    <div className="max-h-96 overflow-y-auto">
                        <Formik>
                            <FormContainer>
                                <h5 className="mb-4">App Admin</h5>
                                <FormItem label="Name">
                                    <input
                                        className="border-[1px] py-3 px-2 w-full rounded-lg"
                                        type="text"
                                        name="Name"
                                        placeholder="Name"
                                        value={data?.name}
                                        onChange={(e) =>
                                            setData({
                                                ...data,
                                                name: e.target.value,
                                            })
                                        }
                                    />
                                </FormItem>
                                <FormItem label="Phone Number">
                                    <input
                                        className="border-[1px] py-3 px-2 w-full rounded-lg"
                                        type="text"
                                        name="Phone Number"
                                        placeholder="Phone Number"
                                        value={data?.phonenumber}
                                        onChange={(e) =>
                                            setData({
                                                ...data,
                                                phonenumber: e.target.value,
                                            })
                                        }
                                    />
                                </FormItem>
                                <FormItem label="Email">
                                    <input
                                        className="border-[1px] py-3 px-2 w-full rounded-lg"
                                        type="text"
                                        name="Email"
                                        placeholder="Email"
                                        value={data?.email}
                                        onChange={(e) =>
                                            setData({
                                                ...data,
                                                email: e.target.value,
                                            })
                                        }
                                        disabled={true}
                                    />
                                </FormItem>

                                <h5 className="mb-4">Company Profile</h5>
                                <FormItem label="Company Type" asterisk>
                                    <input
                                        className="border-[1px] py-3 px-2 w-full rounded-lg"
                                        type="text"
                                        name="Company Type"
                                        placeholder="Company Type"
                                        value={data?.companytype}
                                        onChange={(e) =>
                                            setData({
                                                ...data,
                                                companytype: e.target.value,
                                            })
                                        }
                                    />
                                </FormItem>
                                <FormItem label="Company Size">
                                    <input
                                        className="border-[1px] py-3 px-2 w-full rounded-lg"
                                        type="text"
                                        name="Company Size"
                                        placeholder="Company Size"
                                        value={data?.companysize}
                                        onChange={(e) =>
                                            setData({
                                                ...data,
                                                companysize: e.target.value,
                                            })
                                        }
                                    />
                                </FormItem>
                                <FormItem label="Turn Over">
                                    <input
                                        className="border-[1px] py-3 px-2 w-full rounded-lg"
                                        type="text"
                                        name="Turn Over"
                                        placeholder="Turn Over"
                                        value={data?.turnOver}
                                        onChange={(e) =>
                                            setData({
                                                ...data,
                                                turnOver: e.target.value,
                                            })
                                        }
                                    />
                                </FormItem>
                                <FormItem label="Website">
                                    <input
                                        className="border-[1px] py-3 px-2 w-full rounded-lg"
                                        type="text"
                                        name="Website"
                                        placeholder="Website"
                                        value={data?.website}
                                        onChange={(e) =>
                                            setData({
                                                ...data,
                                                website: e.target.value,
                                            })
                                        }
                                    />
                                </FormItem>
                                <h5 className="mb-4">Tax Registration</h5>
                                <FormItem label="CA License Number">
                                    <input
                                        className="border-[1px] py-3 px-2 w-full rounded-lg"
                                        type="text"
                                        name="CA License Number"
                                        placeholder="CA License Number"
                                        value={data?.CALicenseNumber}
                                        onChange={(e) =>
                                            setData({
                                                ...data,
                                                CALicenseNumber: e.target.value,
                                            })
                                        }
                                    />
                                </FormItem>
                                <FormItem label="GST Number">
                                    <input
                                        className="border-[1px] py-3 px-2 w-full rounded-lg"
                                        type="text"
                                        name="GST Number"
                                        placeholder="GST Number"
                                        value={data?.GSTNumber}
                                        onChange={(e) =>
                                            setData({
                                                ...data,
                                                GSTNumber: e.target.value,
                                            })
                                        }
                                    />
                                </FormItem>
                                <FormItem label="PAN Number">
                                    <input
                                        className="border-[1px] py-3 px-2 w-full rounded-lg"
                                        type="text"
                                        name="PAN Number"
                                        placeholder="PAN Number"
                                        value={data?.panNumber}
                                        onChange={(e) =>
                                            setData({
                                                ...data,
                                                panNumber: e.target.value,
                                            })
                                        }
                                    />
                                </FormItem>
                                <h5 className="mb-4">License</h5>
                                <FormItem label="License Number">
                                    <input
                                        className="border-[1px] py-3 px-2 w-full rounded-lg"
                                        type="text"
                                        name="License Number"
                                        placeholder="License Number"
                                        value={data?.licenseNumber}
                                        onChange={(e) =>
                                            setData({
                                                ...data,
                                                licenseNumber: e.target.value,
                                            })
                                        }
                                        disabled={true}
                                    />
                                </FormItem>
                                <FormItem label="Active Date">
                                    <input
                                        className="border-[1px] py-3 px-2 w-full rounded-lg"
                                        type="text"
                                        name="Active Date"
                                        placeholder="Active Date"
                                        value={data?.activeDate}
                                        onChange={(e) =>
                                            setData({
                                                ...data,
                                                activeDate: e.target.value,
                                            })
                                        }
                                        disabled={true}
                                    />
                                </FormItem>
                            </FormContainer>
                            {/* </Form> */}
                        </Formik>
                    </div>
                    <div className="text-right mt-6">
                        <Button
                            className="ltr:mr-2 rtl:ml-2"
                            variant="plain"
                            onClick={onDialogClose}
                        >
                            Cancel
                        </Button>
                        <Button variant="solid" onClick={(e) => submitData(e)}>
                            Update
                        </Button>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default EditLIcense
