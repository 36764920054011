import React, { useEffect, useState } from 'react'
import { Card } from 'components/ui'
import { MdOutlineDelete } from 'react-icons/md'
import { useSelector } from 'react-redux'

const Index = () => {
    const { user } = useSelector((state) => state.auth.user)
    let tokenData = JSON.parse(localStorage.getItem('setuFiToken'))
    const [company, setCompany] = useState([])
    useEffect(() => {
        setCompany([...user?.companies])
    }, [])

    const handleCompanyDelete = async (databaseName) => {
        try {
            const bodyData = {
                dbName: databaseName
            }
            const permanentlyDeleteDatabaseFromMongoDB = await fetch(
                `${process.env.REACT_APP_BASE_URL}/company/deleteCompany`,
                {
                    method: 'DELETE',
                    headers: {
                        Authorization: `Bearer ${tokenData}`,
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'access-control-allow-credentials': 'true',
                        'access-control-allow-origin': '*',
                    },
                    body: JSON.stringify(bodyData),
                }
            )

            const deleteUploadedFilesFromAWS = await fetch(
                `${process.env.REACT_APP_BASE_URL}/daybook/deleteCompanyUploads?db=${databaseName}`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${tokenData}`,
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'access-control-allow-credentials': 'true',
                        'access-control-allow-origin': '*',
                    },
                    body: JSON.stringify(bodyData),
                }
            )

            // localStorage.clear();

        } catch (err) {
            console.log(err)
        } 
    }

    return (
        <>
            <div className="mx-5">
                <Card className="mt-">
                    <h1 className="text-lg my-2">
                        List of all companies
                    </h1>
                    {company?.map((value, index) => (
                        <div className='w-full flex items-center justify-between mb-2 border rounded-md py-1 px-2' key={index}>
                            <div className='w-1/ py-2'>
                                <div key={index}>
                                    <p className='text-base font-semibold '>{value?.Name}</p>
                                </div>
                            </div>
                            <div className='w-1/'>
                                <button
                                    className="border hover:bg-red-500 transition-all hover:text-white rounded-lg px-5 py-2 flex items-center"
                                    onClick={() => handleCompanyDelete(value.databaseName)}
                                >
                                    <MdOutlineDelete
                                        size={25} />
                                    <span className="text-6px font-bold">
                                        Delete
                                    </span>
                                </button>
                            </div>
                        </div>
                    ))}

                </Card>
            </div>
        </>
    )
}

export default Index