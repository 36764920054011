import React, { useEffect, useState } from 'react';
import './App.css';
import { CSVLink } from 'react-csv';

const formatter = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 2,
});

export function formatIndianCurrency(amount) {
    if (amount < 0) {
        return `${formatter.format(-amount)}`;
    } else if (amount > 0) {
        return `${formatter.format(amount)}`;
    } else {
        return `${formatter.format(amount)}`;
    }
}

const ParentTableV2 = ({ data }) => {
    const [currentData, setCurrentData] = useState();
    const [history, setHistory] = useState([]); // To maintain history of previous data
    const [showPopup, setShowPopup] = useState(false); // Popup visibility state
    const [showZeroData, setShowZeroData] = useState(false); // Toggle to show/hide zero balance data

    const filterData = (items) => {
        return items
            .map((item) => {
                const hasChildGroups =
                    item.childGroups &&
                    filterData(item.childGroups).length > 0;
                const hasLedgers =
                    item.ledgers &&
                    filterData(item.ledgers).length > 0;

                // Check if current item has a non-zero balance or has valid child data
                const shouldInclude =
                    showZeroData ||
                    item.closingBalance !== 0 ||
                    hasChildGroups ||
                    hasLedgers;

                if (!shouldInclude) return null;

                // Filter child groups and ledgers recursively
                return {
                    ...item,
                    childGroups: item.childGroups
                        ? filterData(item.childGroups)
                        : [],
                    ledgers: item.ledgers
                        ? filterData(item.ledgers)
                        : [],
                };
            })
            .filter(Boolean); // Remove nulls
    };

    useEffect(() => {
        const filteredData = data ? filterData(data) : [];
        setCurrentData(filteredData);
    }, [data, showZeroData]);

    const rowStyles = {
        cursor: 'pointer',
        transition: 'background-color 0.3s',
    };

    const thStyles = {
        padding: '15px',
        textAlign: 'left',
        color: 'white',
        border: '1px solid #fff',
    };

    const tdStyles = {
        padding: '15px',
        borderBottom: '2px solid #ccc',
        border: '1px solid #ccc',
    };

    const tableStyles = {
        width: '100%',
        borderCollapse: 'collapse',
        border: '2px solid #ccc',
        fontWeight: 'bold',
    };

    const handleRowClick = (group) => {
        if (group.childGroups && group.childGroups.length > 0) {
            setHistory((prevHistory) => [...prevHistory, currentData]); // Save current data in history
            setCurrentData(group.childGroups); // Update table with childGroups
        } else if (group.ledgers && group.ledgers.length > 0) {
            setHistory((prevHistory) => [...prevHistory, currentData]); // Save current data in history
            setCurrentData(group.ledgers); // Update table with ledgers
        } else {
            setShowPopup(true);
        }
    };

    const handleBack = () => {
        if (history.length > 0) {
            const previousData = history[history.length - 1]; // Get the last data from history
            setCurrentData(previousData); // Set the table to the previous data
            setHistory((prevHistory) => prevHistory.slice(0, -1)); // Remove the last entry from history
        }
    };

    const handleIncomeAndExpenseData = () => {
        // Filter the data based on the given names
        const filteredData = currentData.filter(
            (item) =>
                item.Name === 'Direct Incomes' ||
                item.Name === 'Direct Expenses' ||
                item.Name === 'Indirect Incomes' ||
                item.Name === 'Indirect Expenses'
        );

        // Update the state with the filtered data
        setHistory((prevHistory) => [...prevHistory, currentData]); // Save current data in history
        setCurrentData(filteredData);
    };

    const handleZeroShow = () => {
        setShowZeroData(!showZeroData);
    };

    const handleExport = () => {
        if (!currentData) return [];

        // Map currentData to a flat structure for CSV export
        return currentData.map((item) => ({
            Name: item.Name || item.ledgerName,
            ClosingDebit: item.closingBalance < 0 ? formatIndianCurrency(-item.closingBalance) : formatIndianCurrency(0),
            ClosingCredit: item.closingBalance > 0 ? formatIndianCurrency(item.closingBalance) : formatIndianCurrency(0),
        }));
    };

// adding comment
    return (
        <div style={{ width: '100%', height: '100%' }}>
            <div className='flex items-center gap-2'>
                <button
                    onClick={handleBack}
                    disabled={history.length === 0}
                    style={{
                        marginBottom: '10px',
                        padding: '10px 20px',
                        backgroundColor: history.length === 0 ? '#ccc' : '#133386',
                        color: 'white',
                        border: 'none',
                        cursor: history.length === 0 ? 'not-allowed' : 'pointer',
                    }}
                >
                    Back
                </button>
                <CSVLink
                    data={handleExport()}
                    headers={[
                        { label: 'Name', key: 'Name' },
                        { label: 'Closing Debit', key: 'ClosingDebit' },
                        { label: 'Closing Credit', key: 'ClosingCredit' },
                    ]}
                    filename="Trial Balance"
                >
                    <button
                        style={{
                            marginBottom: '10px',
                            padding: '10px 20px',
                            backgroundColor: '#133386',
                            color: 'white',
                            border: 'none',
                            cursor: 'pointer',
                        }}
                    >
                        Export
                    </button>
                </CSVLink>
                <button
                    onClick={handleIncomeAndExpenseData}
                    style={{
                        marginBottom: '10px',
                        padding: '10px 20px',
                        backgroundColor: '#133386',
                        color: 'white',
                        border: 'none',
                        cursor: 'pointer',
                    }}
                >
                    Income & Expense
                </button>
                <button
                    onClick={handleZeroShow}
                    style={{
                        marginBottom: '10px',
                        padding: '10px 20px',
                        backgroundColor: '#133386',
                        color: 'white',
                        border: 'none',
                        cursor: 'pointer',
                    }}
                >
                    {showZeroData ? 'Hide Zero Value' : 'Show Zero Value'}
                </button>
            </div>

            <table
                style={tableStyles}
                className='table table-hover table-sm table-bordered'
            >
                <thead>
                    <tr style={{ backgroundColor: '#133386' }}>
                        <th style={thStyles} scope='col'>
                            Name
                        </th>
                        <th style={thStyles} scope='col'>
                            Closing Debit
                        </th>
                        <th style={thStyles} scope='col'>
                            Closing Credit
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {currentData?.map((item, index) => (
                        <tr
                            key={index}
                            onClick={() => handleRowClick(item)}
                            style={rowStyles}
                        >
                            <td style={tdStyles}>{item.Name || item.ledgerName}</td>
                            <td style={tdStyles} className='text-right'>
                                {item.closingBalance < 0
                                    ? formatIndianCurrency(
                                        parseFloat(
                                            item.closingBalance?.toFixed(2) || 0
                                        )
                                    )
                                    : formatIndianCurrency(0)}
                            </td>
                            <td style={tdStyles} className='text-right'>
                                {item.closingBalance > 0
                                    ? formatIndianCurrency(
                                        parseFloat(
                                            item.closingBalance?.toFixed(2) || 0
                                        )
                                    )
                                    : formatIndianCurrency(0)}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {showPopup && (
                <div className='popup'>
                    <div className='popup-content'>
                        <h4>Trial Balance</h4>
                        <div className='message-box mt-0'>
                            <p className='text-sm'>No data available for this parent.</p>
                            <button
                                onClick={() => setShowPopup(false)}
                                className='ok-button mt-3 py-2 text-sm'
                            >
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ParentTableV2;
