import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    avatar: '',
    userName: '',
    email: '',
    authority: [],
    user: {},
    companyData: {},
    financialYear: 0,
    superAdmin: {},
}

export const userSlice = createSlice({
    name: 'auth/user',
    initialState,
    reducers: {
        setUser: (_, action) => action.payload,
        userLoggedOut: () => initialState,
        userData: (state, action) => {
            state.user = action.payload
        },
        selectCompany: (state, action) => {
            state.companyData = action.payload
        },
        selectFinancialYear: (state, action) => {
            state.financialYear = action.payload
        },
        AccessSuperAdmin: (state, action) => {
            state.superAdmin= action.payload
        },
    },
})

export const { setUser, userData, selectCompany, selectFinancialYear,AccessSuperAdmin } =
    userSlice.actions

export default userSlice.reducer
