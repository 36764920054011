import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import './App.css'
import LicenseSidebar from '../SuperAdminLicense/LicenseSidebar'
import { formatIndianCurrency } from './ParentTable'

const ChildTable = (props) => {
    const [data, setData] = useState([]) // State to store the fetched data
    const [isLoading, setIsLoading] = useState(true) // State to track loading
    const [noData, setNoData] = useState(false) // State to track if there's no data

    const { parentGUID } = useParams()

    var tokenData = JSON.parse(localStorage.getItem('setuFiToken'))
    const { selectedLicense, selectedManage, financialYear } = useSelector(
        (state) => state.auth.superAdmin
    )

    let databaseName = selectedManage?.databaseName
    let token = tokenData

    const navigate = useNavigate()

    const handleRowClick = (rowGUID) => {
        navigate(`/trialBalance/${rowGUID}`)
    }

    // Function to handle the OK button click
    const handleOKClick = () => {
        // console.log("selectedLicense", selectedLicense)
        // navigate(`/license/${selectedLicense?.name}`)
        navigate(`/license/${selectedLicense?._id}`)
    }

    useEffect(() => {
        getData()
    }, [databaseName])

    const getData = async () => {
        setIsLoading(true)
        try {
            let bodyData = {
                DATABASE: databaseName,
            }
            let resp = await fetch(
                `${process.env.REACT_APP_BASE_URL}/trialBalance/getTrialBalance`,
                {
                    method: 'POST',
                    body: JSON.stringify(bodyData),
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            )
            if (resp.ok) {
                const data = await resp.json()
                console.log("trialBalance", data)
                setData(data)
            } else {
                console.error('Request failed with status:', resp.status)
            }
        } catch (err) {
            console.error('Error:', err)
        } finally {
            // Set isLoading to false after data is fetched
            setIsLoading(false)
        }
    }

    function findParentWithChildrenByGUID(data, parentGUID) {
        if (!data) {
            return
        }
        for (const parent of data) {
            if (parent.GUID === parentGUID) {
                // Include all children in the result
                return { ...parent, children: parent.children }
            }

            // Check if the parent has children before iterating
            if (parent.children && Array.isArray(parent.children)) {
                const foundParent = findParentWithChildrenByGUID(
                    parent.children,
                    parentGUID
                )
                if (foundParent) {
                    return foundParent
                }
            }
        }
        return null // Return null if no match is found
    }

    const parent = findParentWithChildrenByGUID(data, parentGUID)
    const totalNegative = sumAmounts(parent)?.totalNegative
    const totalPositive = sumAmounts(parent)?.totalPositive

    const rowStyles = {
        cursor: 'pointer',
        transition: 'background-color 0.3s',
    }
    const hoverRowStyle = {
        backgroundColor: '#dcdcdc', // Change the background color on hover
    }

    // Define styles for <th> and <td>
    const thStyles = {
        padding: '15px',
        textAlign: 'left',
        backgroundColor: '#133386',
        color: 'white',
        border: '1px solid #fff', // Column borders
    }

    const tdStyles = {
        padding: '15px',
        borderBottom: '2px solid #ccc',
        border: '1px solid #ccc', // Column borders
    }

    const tableStyles = {
        width: '100%',
        borderCollapse: 'collapse',
        border: '2px solid #ccc',
    }

    function sumAmounts(parent) {
        console.log("parent", parent)
        if (!parent) {
            return
        }
        let totalPositive = 0
        let totalNegative = 0

        function recursiveSum(data) {
            for (const entry of data?.data) {
                for (const entry2 of entry.ledgerentries) {
                    const amountValue = parseFloat(
                        entry2?.amount?.str.replace(/[₹,]/g, '')
                    )
                    if (!isNaN(amountValue)) {
                        if (amountValue > 0) {
                            totalPositive += amountValue
                        } else {
                            totalNegative += amountValue
                        }
                    }
                }
            }

            if (data.children && Array.isArray(data.children)) {
                for (const child of data.children) {
                    recursiveSum(child)
                }
            }
        }
        recursiveSum(parent)
        // Call the recursiveSum function to calculate totals for the given Parent

        // Call the recursiveSum function to calculate totals for the given Parent

        return {
            totalPositive: totalPositive.toFixed(2), // Format to 2 decimal places
            totalNegative: Math.abs(totalNegative).toFixed(2), // Format to 2 decimal places, and make it positive
        }
    }
    // Render the loader if isLoading is true
    if (isLoading) {
        return (
            <div className="loader-container">
                <div
                    className="loader"
                    style={{ backgroundColor: '#133386' }}
                ></div>
            </div>
        )
    } else if (parent.children && parent.children.length > 0) {
        return (
            <div
                className="px-5 mb-5"
                style={{ width: '100%', height: '100%' }}
            >
                {/* <LicenseSidebar /> */}
                {/* <h2 className="text-2xl font-semibold mb-4">Trial Balance</h2> */}

                <div>
                    {/* Scrollable body table */}
                    <table
                        style={tableStyles}
                        className="table table-hover table-sm table-bordered "
                    >
                        <thead>
                            <tr
                                style={{
                                    backgroundColor: '#133386',
                                }}
                            >
                                <th style={thStyles} scope="col">
                                    LEDGER
                                </th>
                                <th style={thStyles} scope="col">
                                    Debit
                                </th>
                                <th style={thStyles} scope="col">
                                    Credit
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {parent.children?.map((row) => {
                                const { totalPositive, totalNegative } =
                                    sumAmounts(row)
                                return (
                                    <tr
                                        key={row.GUID}
                                        style={{
                                            ...rowStyles,
                                            ...hoverRowStyle,
                                            cursor: 'pointer',
                                            backgroundColor: '#ffffff',
                                        }}
                                        onClick={() => {
                                            if (!(+totalPositive === 0) && !(+totalNegative === 0)) {
                                                handleRowClick(row.GUID)
                                            }
                                        }}
                                    // handleRowClick(row.GUID) // Programmatically navigate
                                    >
                                        {console.log("totalPositive", +totalPositive, +totalPositive === 0)}
                                        {console.log("totalPositive", +totalNegative, +totalNegative === 0)}
                                        <td
                                            style={{
                                                ...tdStyles,
                                            }}
                                        >
                                            {row.NAME}
                                        </td>
                                        <td
                                            style={{
                                                ...tdStyles,
                                            }}
                                        >
                                            {formatIndianCurrency(totalNegative)}
                                        </td>
                                        <td
                                            style={{
                                                ...tdStyles,
                                            }}
                                        >
                                            {formatIndianCurrency(totalPositive)}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    } else if (noData) {
        return (
            <div className="popup">
                <div className="popup-content">
                    <h4>Trial Balance</h4>
                    <div className="message-box mt-0">
                        <p className='text-sm'>No data available for this parent.</p>
                        <button onClick={handleOKClick} className="ok-button mt-3 py-2 text-sm">
                            OK
                        </button>
                    </div>
                </div>
            </div>
        )
    } else {
        setNoData(true) // Set noData state to true when there's no data
        return null
    }
}

export default ChildTable
