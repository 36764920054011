import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ParentTable from './ParentTable'
import ChildTable from '../TrialBalance/ChildTable' // Make sure to provide the correct import path.
import './App.css'
import { setTrialBalance } from 'store/auth/superAdminLicense'

const Loader = () => (
    <div className="loader-container">
        <div className="loader" style={{ backgroundColor: '#133386' }}></div>
    </div>
)

const TrialBalance = () => {
    const dispatch = useDispatch()
    const [data, setData] = useState([]) // State to store the fetched data
    const [isLoading, setIsLoading] = useState(true) // State to track loading

    var tokenData = JSON.parse(localStorage.getItem('setuFiToken'))
    const { selectedLicense, selectedManage, financialYear } = useSelector(
        (state) => state.auth.superAdmin
    )

    let databaseName = selectedManage?.databaseName
    let token = tokenData

    useEffect(() => {
        getData()
    }, [selectedManage, selectedLicense, financialYear])

    const getData = async () => {
        setIsLoading(true)
        try {
            let bodyData = {
                DATABASE: databaseName,
            }
            let resp = await fetch(
                `${process.env.REACT_APP_BASE_URL}/trialBalance/getTrialBalance`,
                {
                    method: 'POST',
                    body: JSON.stringify(bodyData),
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            )

            if (resp.ok) {
                const data = await resp.json()
                setData(data)

                let currentAssets = data
                    ?.filter((i) => i?.Name === 'CURRENT ASSETS')
                    ?.map((i) => i?.children)[0]
                let sundryDebtors = currentAssets
                    ?.filter((i) => i?.Name === 'SUNDRY DEBTORS')
                    ?.map((i) => i?.children)[0]
                let currentLiability = data
                    ?.filter((i) => i?.Name === 'CURRENT LIABILITIES')
                    ?.map((i) => i?.children)[0]
                let sundryCreditors = currentLiability
                    ?.filter((i) => i?.Name === 'SUNDRY CREDITORS')
                    ?.map((i) => i?.children)[0]
                dispatch(
                    setTrialBalance({
                        sundryDebtors: sundryDebtors ? sundryDebtors : [],
                        sundryCreditors: sundryCreditors ? sundryCreditors : [],
                    })
                )
            } else {
                console.error('Request failed with status:', resp.status)
            }
        } catch (err) {
            console.error('Error:', err)
        } finally {
            // Set isLoading to false after data is fetched
            setIsLoading(false)
        }
    }

    // Function to calculate total positive and total negative values for a parent
    function calculateTotalAmountsForTopLevelParents(data) {
        const totals = [] // An array to store the calculated totals for each top-level parent

        function sumAmounts(Parent) {
            let totalPositive = 0 // Initialize the total positive amount
            let totalNegative = 0 // Initialize the total negative amount

            // A recursive function to traverse the data structure and calculate totals
            function recursiveSum(data) {

                for (const entry of data.data) {
                    for (const entry2 of entry.ledgerentries) {
                        const amountValue = parseFloat(
                            entry2?.amount?.str.replace(/[₹,]/g, '')
                        )
                        if (!isNaN(amountValue)) {
                            if (amountValue > 0) {
                                totalPositive += amountValue // Add positive amounts
                            } else {
                                totalNegative += amountValue // Add negative amounts
                            }
                        }
                    }
                }

                if (data.children && Array.isArray(data.children)) {
                    for (const child of data.children) {
                        recursiveSum(child)
                    }
                }
            }

            // Call the recursiveSum function to calculate totals for the given Parent
            recursiveSum(Parent)

            // Calculate the totalNet as the sum of totalPositive and totalNegative
            const totalNet = (totalPositive + totalNegative).toFixed(2)

            return {
                totalPositive: totalPositive.toFixed(2),
                totalNegative: Math.abs(totalNegative).toFixed(2),
                totalNet,
            }
        }

        // Loop through each top-level Parent in the data array
        data?.forEach((Parent) => {
            const { totalPositive, totalNegative, totalNet } =
                sumAmounts(Parent)

            // Add the calculated totals and the Parent's GUID to the 'totals' array
            totals.push({
                ParentGUID: Parent.GUID,
                totalPositive,
                totalNegative,
                totalNet,
            })
        })

        // Return the array containing the calculated totals for all top-level parents
        return totals
    }

    // Call the function with your 'data' to calculate the totals for top-level parents
    const topLevelParentTotals = calculateTotalAmountsForTopLevelParents(data)

    if (isLoading) {
        return <Loader />
    }

    return (
        <div className="px-5 mb-5">
            <h2 className="text-2xl font-semibold mb-4">Trial Balance</h2>
            <ParentTable
                data={data}
                topLevelParentTotals={topLevelParentTotals}
            />
            {/* <ChildTable databaseName={databaseName} token={token} /> */}
        </div>
    )
}

export default TrialBalance
