import React from 'react'
import Branding from './Branding'
import General from './General'
import UtilitySetting from './UtilitySetting'
import { Tabs } from 'components/ui'
const { TabNav, TabList, TabContent } = Tabs

const Index = () => {
  return (
      <div>
          <Tabs defaultValue="tab1">
              <TabList>
                  <TabNav value="tab1">General</TabNav>
                  <TabNav value="tab2">Utility Setting</TabNav>
                  {/* <TabNav value="tab2">Branding</TabNav> */}
              </TabList>
              <div className="p-4">
                  <TabContent value="tab1">
                      <General />
                  </TabContent>
                  <TabContent value="tab2">
                      <UtilitySetting />
                  </TabContent>
                  {/* <TabContent value="tab2">
                      <Branding />
                  </TabContent> */}
              </div>
          </Tabs>
      </div>
  )
}

export default Index