import React, { useEffect, useState } from 'react'
import { Avatar, Dropdown } from 'components/ui'
import withHeaderItem from 'utils/hoc/withHeaderItem'
import useAuth from 'utils/hooks/useAuth'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { HiOutlineUser, HiOutlineCog, HiOutlineLogout } from 'react-icons/hi'
import { FiActivity } from 'react-icons/fi'
import { VscSignOut } from 'react-icons/vsc'

const UserDropdownSuperAdmin = ({ className }) => {
    const { authority } = useSelector((state) => state.auth.user)
    const { user } = useSelector((state) => state.auth.user)
    const { signOut } = useAuth()
    const [showBackButton, setShowBackButton] = useState(false)
    const [backToCustomer, setBackToCustomer] = useState(false)
    const [backToVendor, setBackToVendor] = useState(false)
    const currPath = window.location.pathname.split('/');
    console.log("currPath", currPath[2])
    const customerId = currPath[2];


    useEffect(() => {
        const pathCheck = window.location.pathname.split('/');
        console.log("pathCheck1", pathCheck)
        console.log("pathCheck1", pathCheck.length)
        console.log("pathCheck2", window.location.pathname)
        if (pathCheck[1] === 'license' && pathCheck.length === 3) {
            setShowBackButton(true)
        } else if (pathCheck[3] === 'receivables' && pathCheck.length === 4) {
            setBackToCustomer(true)
        } else if (pathCheck[3] === 'payables' && pathCheck.length === 4) {
            setBackToCustomer(true)
        }
        else {
            setShowBackButton(false)
            setBackToCustomer(false)
        }
    }, [window.location.pathname])


    const UserAvatar = (
        <div className={classNames(className, 'flex items-center gap-2')}>
            <div
                style={{ background: '#133386' }}
                className="rounded-full w-12 h-12 flex items-center justify-center text-white text-2xl"
            >
                {user
                    ? user.userName[0].toUpperCase() +
                    user.userName.split(' ')[1][0].toUpperCase()
                    : ''}
            </div>
            <div className="hidden md:block">
                <div className="text-xs capitalize">
                    {user?.isAppAdmin == true
                        ? 'Admin'
                        : user?.isSuperAdmin == true
                            ? 'Super Admin'
                            : 'User'}
                </div>
                <div className="font-bold">
                    <span>{user?.userName}</span>
                </div>
            </div>
        </div>
    )

    return (
        <div>
            <Dropdown
                menuStyle={{ minWidth: 240 }}
                renderTitle={UserAvatar}
                placement="bottom-end"
            >
                <Dropdown.Item variant="header">
                    <div className="py-2 px-3 flex items-center gap-2">
                        <div
                            style={{ background: '#133386' }}
                            className="rounded-full w-12 h-12 flex items-center justify-center text-white  text-2xl"
                        >
                            {user
                                ? user.userName[0].toUpperCase() +
                                user.userName.split(' ')[1][0].toUpperCase()
                                : ''}
                        </div>
                        <div>
                            <div className="font-bold text-gray-900 dark:text-gray-100">
                                {user?.userName}
                            </div>
                            <div className="text-xs">{user?.email}</div>
                        </div>
                    </div>
                </Dropdown.Item>
                {showBackButton && (
                    <Dropdown.Item className="mb-1 ">
                        <Link className="flex gap-2 items-center" to="/license">
                            <span className="text-xl opacity-50">
                                <VscSignOut />
                            </span>
                            <span>Back to Licence</span>
                        </Link>
                    </Dropdown.Item>
                )}
                {backToCustomer && (
                    <>
                        <Dropdown.Item className="mb-1 ">
                            <Link className="flex gap-2 items-center" to={`/license/${customerId}`}>
                                <span className="text-xl opacity-50">
                                    <VscSignOut />
                                </span>
                                <span>Back</span>
                            </Link>
                        </Dropdown.Item>
                        <Dropdown.Item className="mb-1 ">
                            <Link className="flex gap-2 items-center" to="/license">
                                <span className="text-xl opacity-50">
                                    <VscSignOut />
                                </span>
                                <span>Back to Licence</span>
                            </Link>
                        </Dropdown.Item>
                    </>
                )}

                < Dropdown.Item variant="divider" />
                <Dropdown.Item
                    onClick={signOut}
                    eventKey="Sign Out"
                    className="gap-2"
                >
                    <span className="text-xl opacity-50">
                        <HiOutlineLogout />
                    </span>
                    <span>Sign Out</span>
                </Dropdown.Item>
            </Dropdown >
        </div >
    )
}
export default withHeaderItem(UserDropdownSuperAdmin)
