import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { APP_NAME } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'

const LOGO_SRC_PATH = '/img/logo/'

const Logo = (props) => {
    const { gutter, className, imgClass, style, logoWidth } = props
    const navigate = useNavigate()

    return (
        <div
            className={classNames('logo', className, gutter)}
            onClick={() => navigate('/app/dashboard')}
            style={{
                ...style,
                ...{ width: logoWidth, marginTop: '2%' },
            }}
        >
            <img
                className={imgClass}
                style={{width:"130px"}}
                src={`${LOGO_SRC_PATH}setufi.svg`}
                alt={`${APP_NAME} logo`}
            />
        </div>
    )
}

Logo.defaultProps = {
    // mode: 'setufi',
    type: 'full',
    logoWidth: '100%',
}

Logo.propTypes = {
    // mode: PropTypes.oneOf(['setufi', 'dark']),
    type: PropTypes.oneOf(['full', 'streamline']),
    gutter: PropTypes.string,
    imgClass: PropTypes.string,
    logoWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default Logo
