import React, { useEffect, useState } from 'react'
import { Button, FormItem, FormContainer } from 'components/ui'
import { Form, Formik } from 'formik'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

const colourOptions = [
    // { value: 'Sale', label: 'Sale', color: '#00B8D9' },
    // { value: 'Purchase', label: 'Purchase', color: '#00B8D9' },
    // { value: 'Credit Note', label: 'Credit Note', color: '#00B8D9' },
    // { value: 'Debit Note', label: 'Debit Note', color: '#00B8D9' },
    // { value: 'Receipt', label: 'Receipt', color: '#00B8D9' },
    // { value: 'Payment', label: 'Payment', color: '#00B8D9' },
    { value: 'Outstanding', label: 'Outstanding', color: '#00B8D9' },
]
const colourOptions1 = [
    { value: 'EVERYDAY', label: 'Everyday', color: '#00B8D9' },
    { value: 'BYWEEKLY', label: 'Byweekly', color: '#00B8D9' },
    { value: 'WEEKLY', label: 'Weekly', color: '#00B8D9' },
]

const FormTrigger = () => {
    const { user, companyData } = useSelector((state) => state.auth.user)
    let tokenData = JSON.parse(localStorage.getItem('setuFiToken'))
    const [teams, setTeams] = useState([])
    const [email, setEmail] = useState([])
    const [dropDown, setDropDown] = useState([])
    const [data, setData] = useState({
        REPORTINGNAME: 'Outstanding',
        SELECTEDEMAILS: [],
        FILEFORMAT: ['PDF'],
        SELECTEDPERIOD: 'WEEKLY',
        STARTTIME: {
            HOURS: '01',
            MINUTES: '00',
        },
        STARTDATE: '',
        COMPANYNAME: companyData?.NAME,
        COMPANYGUID: companyData?.GUID,
    })

    useEffect(() => {
        let filter = user?.TEAMS?.filter(
            (item) => item?.companyName == companyData?.NAME
        )
        setTeams([...filter])
    }, [])

    useEffect(() => {
        if (teams?.length > 0) {
            let filterTeam = []
            for (let item of teams) {
                let val = filterTeam?.find(
                    (i) =>
                        Object.getOwnPropertyNames(i?.role) ==
                        Object.getOwnPropertyNames(item?.role)
                )
                if (val) {
                    val.email = [item?.email, ...val.email]
                } else {
                    filterTeam.push({
                        role: Object.getOwnPropertyNames(item?.role),
                        email: [item?.email],
                    })
                }
            }
            setDropDown([
                {
                    role: 'Everyone',
                    email: [user?.email, ...teams?.map((item) => item?.email)],
                },
                ...filterTeam,
            ])
        } else {
            setDropDown([
                {
                    role: 'Everyone',
                    email: [user?.email],
                },
            ])
        }
    }, [teams])

    const minutes = []
    for (let i = 0; i <= 59; i++) {
        const formattedMinute = i.toString().padStart(2, '0')
        minutes.push(formattedMinute)
    }

    const hours = []
    for (let i = 1; i <= 24; i++) {
        const formattedHour = i.toString().padStart(2, '0')
        hours.push(formattedHour)
    }

    const submitData = async (val, e) => {
        let bodyData = {
            collection_name: 'users',
            token: tokenData,
            database_name: 'superDatabase',
            filter: {
                email: user?.email,
                'companies.GUID': companyData?.GUID,
            },
            fields: {
                REMAINDERSETTINGS: {
                    [data.REPORTINGNAME]: {
                        ...data,
                        SELECTEDEMAILS: [...data?.SELECTEDEMAILS, ...email],
                    },
                },
            },
        }

        try {
            let resp = await fetch(
                `${process.env.REACT_APP_BASE_URL}/patchnestedfield/`,
                {
                    method: 'PATCH',
                    body: JSON.stringify(bodyData),
                    headers: {
                        Authorization: `Bearer ${tokenData}`,
                        'Content-Type': 'application/json',

                        'Access-Control-Allow-Origin': '*',
                        'access-control-allow-credentials': 'true',
                        'access-control-allow-origin': '*',
                    },
                }
            )
            toast.success('Data saved Sucessfully!')
        } catch (e) {
            console.log(e)
            toast.error('Error from serverside!')
        } finally {
            setData({
                REPORTINGNAME: 'Outstanding',
                SELECTEDEMAILS: [],
                FILEFORMAT: ['PDF'],
                SELECTEDPERIOD: 'WEEKLY',
                STARTTIME: {
                    HOURS: '',
                    MINUTES: '',
                },
                STARTDATE: '',
                COMPANYNAME: companyData?.NAME,
                COMPANYGUID: companyData?.GUID,
            })
        }
    }

    return (
        <div className="flex flex-col h-full justify-between my-4">
            {/* <h5 className="mb-4">Report Wise Schedule Report</h5> */}
            <div className="px-8">
                <Formik>
                    <Form>
                        <FormContainer>
                            <FormItem label="Report Name">
                                <select
                                    placeholder="Please Select"
                                    className="border-[1px] rounded-lg py-3 w-full px-2"
                                    name="REPORTINGNAME"
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            REPORTINGNAME: e.target.value,
                                        })
                                    }
                                >
                                    <option className="px-2" disabled selected>
                                        Select Report
                                    </option>
                                    {colourOptions?.map((item) => {
                                        return (
                                            <option value={item?.value}>
                                                {item?.value}
                                            </option>
                                        )
                                    })}
                                </select>
                            </FormItem>
                            <FormItem label="Frequency">
                                <select
                                    className="border-[1px] rounded-lg py-3 px-2 w-full"
                                    placeholder="Please Select"
                                    name="SELECTEDPERIOD"
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            SELECTEDPERIOD: e.target.value,
                                        })
                                    }
                                >
                                    <option selected disabled>
                                        Select Frequency
                                    </option>
                                    {colourOptions1?.map((item) => (
                                        <option value={item?.value}>
                                            {item?.value}
                                        </option>
                                    ))}
                                </select>
                            </FormItem>
                            <div className="flex gap-3">
                                <FormItem label="Start Date & Time*">
                                    <input
                                        className="border-[1px] rounded-lg py-3 px-2 w-half"
                                        type="date"
                                        placeholder=" Date"
                                        value={data?.STARTDATE}
                                        onChange={(e) =>
                                            setData({
                                                ...data,
                                                STARTDATE: e.target.value,
                                            })
                                        }
                                    />
                                </FormItem>
                                <FormItem label="Hours">
                                    <select
                                        className="border-[1px]  px-4 py-3 rounded-lg focus:border-none "
                                        value={data?.STARTTIME?.HOURS}
                                        onChange={(e) =>
                                            setData({
                                                ...data,
                                                STARTTIME: {
                                                    ...data.STARTTIME,
                                                    HOURS: e.target.value,
                                                },
                                            })
                                        }
                                    >
                                        {hours.map((hour) => (
                                            <option
                                                className=""
                                                key={hour}
                                                value={hour}
                                            >
                                                {hour}
                                            </option>
                                        ))}
                                    </select>
                                </FormItem>

                                <FormItem label="Minutes">
                                    <select
                                        className="border-[1px]  px-4 py-3 rounded-lg focus:border-none "
                                        value={data?.STARTTIME?.MINUTES}
                                        onChange={(e) =>
                                            setData({
                                                ...data,
                                                STARTTIME: {
                                                    ...data.STARTTIME,
                                                    MINUTES: e.target.value,
                                                },
                                            })
                                        }
                                    >
                                        {minutes.map((minute) => (
                                            <option key={minute} value={minute}>
                                                {minute}
                                            </option>
                                        ))}
                                    </select>
                                </FormItem>
                            </div>
                            <h5 className="my-3">Recipient Details</h5>
                            <FormItem label="Email Recipients">
                                <select
                                    className="border-[1px] rounded-lg py-3 px-2 w-full"
                                    placeholder="Please Select"
                                    onChange={(e) =>
                                        setEmail(e.target.value.split(','))
                                    }
                                >
                                    <option disabled selected>
                                        Select
                                    </option>
                                    {dropDown?.map((item) => (
                                        <option value={item?.email}>
                                            {item?.role}
                                        </option>
                                    ))}
                                </select>
                            </FormItem>
                            <FormItem label="Additional Recipient">
                                <input
                                    className="border-[1px] rounded-lg py-3 px-2 w-full"
                                    type="text"
                                    name="Additional Recipient"
                                    placeholder="Additional Recipient"
                                    value={data?.SELECTEDEMAILS.slice(',')}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            SELECTEDEMAILS:
                                                e.target.value.split(','),
                                        })
                                    }
                                />
                                use comma(,) to separate more than one email
                                address
                            </FormItem>
                            <FormItem
                                label="Attach Report As*"
                                name="FILEFORMAT"
                            >
                                <div>
                                    <label>
                                        <input
                                            type="radio"
                                            value="PDF"
                                            checked={data.FILEFORMAT === 'PDF'}
                                            onChange={(e) =>
                                                setData({
                                                    ...data,
                                                    FILEFORMAT: e.target.value,
                                                })
                                            }
                                        />
                                        PDF
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <input
                                            type="radio"
                                            value="XLS"
                                            checked={data.FILEFORMAT === 'XLS'}
                                            onChange={(e) =>
                                                setData({
                                                    ...data,
                                                    FILEFORMAT: e.target.value,
                                                })
                                            }
                                        />
                                        XLS(Microsoft Excel 1997-2004
                                        Compatible)
                                    </label>
                                </div>
                            </FormItem>
                        </FormContainer>
                    </Form>
                </Formik>
            </div>
            <div className="text-right mt-6 mx-8">
                <Button variant="solid" onClick={() => submitData()}>
                    Submit
                </Button>
            </div>
        </div>
    )
}

export default FormTrigger
