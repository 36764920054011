import React, { useEffect, useRef, useState } from 'react'
import {
    Button,
    FormItem,
    FormContainer,
    Dialog,
    Spinner,
    Select,
} from 'components/ui'
import { Form, Formik } from 'formik'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const InviteUser = ({ roleData, getRoles }) => {
    const ref = useRef()
    var tokenData = JSON.parse(localStorage.getItem('setuFiToken'))
    const { user, companyData, financialYear } = useSelector(
        (state) => state.auth.user
    )
    const [disabledButton, setDisabledButton] = useState(false)
    const [customers, setCustomers] = useState([])
    const [allRolesDropdown, setAllRolesDropdown] = useState(null)
    const [checkUser, setCheckUser] = useState(false)

    const [detail, setDetail] = useState({
        email: '',
        roleID: '',
        ledgers: [],
    })

    // const [typingTimeout, setTypingTimeout] = useState(null);

    // const checkUserExists = async (email) => {
    //     let res = await fetch(
    //         `${process.env.REACT_APP_BASE_URL}/userInvitation/checkUserExists`,
    //         {
    //             method: 'POST',
    //             body: JSON.stringify({ email }),
    //             headers: {
    //                 Authorization: `Bearer ${tokenData}`,
    //                 'Content-Type': 'application/json',
    //             },
    //         }
    //     );
    //     return await res.json();
    // };

    const handleEmailChange = (e) => {
        const { value } = e.target;
        setDetail({ ...detail, email: value });

        // if (typingTimeout) {
        //     clearTimeout(typingTimeout);
        // }

        // setTypingTimeout(
        //     setTimeout(() => {
        //         checkUserExists(value.trim()).then((res) => {
        //             console.log(res);
        //             // Handle response, e.g., show message if user exists
        //         });
        //     }, 1000) // Adjust delay as needed
        // );
    };



    const [dialogIsOpen, setIsOpen] = useState(false)
    const openDialog = () => {
        setIsOpen(true)
    }

    const onDialogClose = (e) => {
        e.preventDefault()
        setIsOpen(false)
    }
    const closeBox = () => {
        setIsOpen(false)
    }

    // const submitData = async (e) => {
    //     e.preventDefault()
    //     if (detail?.email != '' && detail?.role != '') {
    //         setDisabledButton(true)
    //         try {
    //             let bodyData = {
    //                 dbName: companyData?.databaseName,
    //                 name: 'User',
    //                 companyName: companyData?.Name,
    //                 companyGuid: companyData?.Guid,
    //                 role: detail?.role,
    //                 email: (detail?.email).trim(),
    //                 inviteEmail: user?.email,
    //             }
    //             let res = await fetch(
    //                 `${process.env.REACT_APP_BASE_URL}/userInvitation/send-invitation/`,
    //                 {
    //                     method: 'POST',
    //                     body: JSON.stringify(bodyData),
    //                     headers: {
    //                         Authorization: `Bearer ${tokenData}`,
    //                         'Content-Type': 'application/json',

    //                         'Access-Control-Allow-Origin': '*',
    //                         'access-control-allow-credentials': 'true',
    //                         'access-control-allow-origin': '*',
    //                     },
    //                 }
    //             )
    //             res = await res.json()
    //             if (res?.success === true) {
    //                 toast.success(res?.message)
    //             } else {
    //                 toast.error(res?.message)
    //             }
    //         } catch (err) {
    //             console.log(err)
    //             toast.error('Error from server.')
    //         } finally {
    //             closeBox()
    //             setDisabledButton(false)
    //             getRoles()
    //             try {
    //                 let timeAndDate = new Date()
    //                 await fetch(`${process.env.REACT_APP_BASE_URL}/saveLogs/`, {
    //                     method: 'POST',
    //                     body: JSON.stringify({
    //                         company_name: companyData?.NAME,
    //                         userEmail: user?.email,
    //                         email: detail?.email,
    //                         eventName: 'User Invite',
    //                         sendCategory: 'EMAIL',
    //                         timeAndDate,
    //                     }),
    //                     body: JSON.stringify({
    //                         COMPANYNAME: companyData?.NAME,
    //                         PARTYLEDGERGUID: '',
    //                         PARTYLEDGERNAME: '',
    //                         TOCONTACT: '',
    //                         TOEMAIL: detail?.email,
    //                         EVENTNAME: 'USER INVITE',
    //                         SENDCATEGORY: 'EMAIL',
    //                         TIMEANDDATE: timeAndDate,
    //                         TRANSACTIONID: '',
    //                         USERNAME: user?.name,
    //                         USEREMAIL: user?.email,
    //                         MASTERNAME: '',
    //                     }),

    //                     headers: {
    //                         Authorization: `Bearer ${tokenData}`,
    //                         'Content-Type': 'application/json',
    //                         Accept: 'application/json',
    //                     },
    //                 })
    //             } catch (err) {
    //                 console.log(err)
    //             }
    //         }
    //     }
    // }

    // const getAllRolesDropdown = async () => {
    //     try {
    //         const dbId = companyData?.databaseName
    //         const bodyData = {
    //             dbName: dbId,
    //         }
    //         let resp = await fetch(
    //             `${process.env.REACT_APP_BASE_URL}/rolePermission/getAll`,
    //             {
    //                 method: 'POST',
    //                 body: JSON.stringify(bodyData),
    //                 headers: {
    //                     Authorization: `Bearer ${tokenData}`,
    //                     'Content-Type': 'application/json',

    //                     'Access-Control-Allow-Origin': '*',
    //                     'access-control-allow-credentials': 'true',
    //                     'access-control-allow-origin': '*',
    //                 },
    //             }
    //         )
    //         resp = await resp.json()
    //         setAllRolesDropdown(resp.rolePermissions)
    //     } catch (err) {
    //         console.log(err)
    //     } finally {
    //     }
    // }

    const submitData = async (e) => {
        e.preventDefault()
        if (detail?.email != '' && detail?.roleID != '') {
            setDisabledButton(true)
            const getSingleRole = await allRolesDropdown.filter((x) => x._id === detail.roleID && x.Action.IsDelete === false)
            try {
                let bodyData = {
                    company: companyData?.Name,
                    roleID: detail?.roleID,
                    senderEmail: (detail?.email).trim(),
                    adminEmail: user?.email,
                    role: getSingleRole[0].roleName,
                }
                let res = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/userInvitation/inviteUser/`,
                    {
                        method: 'POST',
                        body: JSON.stringify(bodyData),
                        headers: {
                            Authorization: `Bearer ${tokenData}`,
                            'Content-Type': 'application/json',

                            'Access-Control-Allow-Origin': '*',
                            'access-control-allow-credentials': 'true',
                            'access-control-allow-origin': '*',
                        },
                    }
                )
                res = await res.json()
                if (res?.status === true) {
                    toast.success(res?.msg)
                } else {
                    toast.error(res?.msg)
                }
            } catch (err) {
                console.log(err)
                toast.error('Error from server.')
            } finally {
                closeBox()
                setDisabledButton(false)
                getRoles()
                try {
                    let timeAndDate = new Date()
                    await fetch(`${process.env.REACT_APP_BASE_URL}/saveLogs/`, {
                        method: 'POST',
                        body: JSON.stringify({
                            company_name: companyData?.NAME,
                            userEmail: user?.email,
                            email: detail?.email,
                            eventName: 'User Invite',
                            sendCategory: 'EMAIL',
                            timeAndDate,
                        }),
                        body: JSON.stringify({
                            COMPANYNAME: companyData?.NAME,
                            PARTYLEDGERGUID: '',
                            PARTYLEDGERNAME: '',
                            TOCONTACT: '',
                            TOEMAIL: detail?.email,
                            EVENTNAME: 'USER INVITE',
                            SENDCATEGORY: 'EMAIL',
                            TIMEANDDATE: timeAndDate,
                            TRANSACTIONID: '',
                            USERNAME: user?.name,
                            USEREMAIL: user?.email,
                            MASTERNAME: '',
                        }),

                        headers: {
                            Authorization: `Bearer ${tokenData}`,
                            'Content-Type': 'application/json',
                            Accept: 'application/json',
                        },
                    })
                } catch (err) {
                    console.log(err)
                }
            }
        }
    }

    const getAllRolesDropdown = async () => {
        try {
            const dbId = companyData?.databaseName

            let resp = await fetch(
                `${process.env.REACT_APP_BASE_URL}/rolePermission/getAllroles?dbName=${dbId}`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${tokenData}`,
                        'Content-Type': 'application/json',

                        'Access-Control-Allow-Origin': '*',
                        'access-control-allow-credentials': 'true',
                        'access-control-allow-origin': '*',
                    },
                }
            )
            resp = await resp.json()
            const filteredResponse = resp?.roles?.filter(
                (x) => x.Action.IsDelete == false
            )
            setAllRolesDropdown(filteredResponse)
        } catch (err) {
            console.log(err)
        } finally {
        }
    }

    useEffect(() => {
        getAllRolesDropdown()
    }, [companyData])

    const resetData = () => {
        setDetail({
            email: '',
            roleID: '',
        })
    }
    return (
        <div>
            <Button
                style={{ background: '#133386' }}
                variant="solid"
                onClick={() => openDialog()}
            >
                Invite User
            </Button>
            <Dialog
                isOpen={dialogIsOpen}
                onClose={onDialogClose}
                onRequestClose={onDialogClose}
            >
                <h5 className="mb-4">Invite User</h5>

                <Formik>
                    <Form>
                        <FormContainer>
                            <FormItem label="Email">
                                <input
                                    type="email"
                                    autoComplete="off"
                                    name="email"
                                    placeholder="Email"
                                    className="w-full px-3 py-3 border-2 rounded"
                                    // onChange={(e) =>{
                                    //     setDetail({
                                    //         ...detail,
                                    //         email: e.target.value,
                                    //     })
                                    // }
                                    // }
                                    onChange={handleEmailChange}
                                />
                            </FormItem>
                            <FormItem name="roles" label="Roles Name">
                                <select
                                    className="w-full px-3 py-3 border-2 rounded"
                                    onChange={(e) =>
                                        setDetail({
                                            ...detail,
                                            roleID: e.target.value,
                                        })
                                    }
                                >
                                    <option selected disabled>
                                        Select Role
                                    </option>
                                    {allRolesDropdown?.map((value, index) => (
                                        <option
                                            value={value._id}
                                            key={index}
                                        >
                                            {value.roleName}
                                        </option>
                                    ))}
                                    {/* <option value="ACCOUNTANTACCESS">
                                        Accountant Access
                                    </option>
                                    <option value="ADMINRIGHTS">
                                        Admin RIghts
                                    </option>
                                    <option value="PURCHASEACCESS">
                                        Purchase Access
                                    </option>
                                    <option value="SALESACCESS">
                                        Sales Access
                                    </option>
                                    <option value="WAREHOUSEACCESS">
                                        Warehouse Access
                                    </option> */}

                                    {roleData?.map((item) => {
                                        return (
                                            <option value={item}>{item}</option>
                                        )
                                    })}
                                </select>
                            </FormItem>
                            {/* <FormItem name="roles" label="Ledger Customer">
                                <Select
                                    isMulti
                                    placeholder="Please Select"
                                    options={customers}
                                    onChange={(e) =>
                                        setDetail({
                                            ...detail,
                                            ledgers: [
                                                ...e?.map(
                                                    (item) => item?.value
                                                ),
                                            ],
                                        })
                                    }
                                />
                            </FormItem> */}

                            <div className="flex ">
                                <div>
                                    <Button
                                        className="ltr:mr-2 rtl:ml-2"
                                        variant="plain"
                                        onClick={(e) => onDialogClose(e)}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                                <div>
                                    <Button
                                        type="reset"
                                        className="ltr:mr-2 rtl:ml-2"
                                        onClick={() => resetData()}
                                    >
                                        Reset
                                    </Button>
                                </div>

                                <div className="flex items-center relative">
                                    {disabledButton ? (
                                        <Spinner
                                            size={30}
                                            className="relative left-16"
                                            color="white"
                                        />
                                    ) : null}
                                    <Button
                                        style={{
                                            backgroundColor: '#133386', // You can replace 'blue' with your desired color
                                            color: 'white', // Optional: Set text color
                                            // Add any other styles you need
                                        }}
                                        variant="solid"
                                        onClick={(e) => submitData(e)}
                                        disabled={disabledButton}
                                    >
                                        Invite
                                    </Button>
                                </div>
                            </div>

                            {/* </FormItem> */}
                        </FormContainer>
                    </Form>
                </Formik>
            </Dialog>
        </div>
    )
}

export default InviteUser
