import React, { useEffect, useRef, useState } from 'react'
import { Button, FormItem, FormContainer, Dialog } from 'components/ui'
import { Form, Formik } from 'formik'
import { HiOutlinePencil } from 'react-icons/hi'
import { useSelector } from 'react-redux'
import { v4 as uuid } from 'uuid'
import useAuth from 'utils/hooks/useAuth'
import { Switcher } from 'components/ui'
import JoditEditor from 'jodit-react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const EditReminderBills = ({ id, getBills }) => {
    const editor = useRef()
    const [dialogIsOpen, setIsOpen] = useState(false)
    var tokenData = JSON.parse(localStorage.getItem('setuFiToken'))
    const { user, companyData } = useSelector((state) => state.auth.user)
    const { signOut } = useAuth()
    const [reminderData, setReminderData] = useState({
        remainderName: '',
        dueDate: '',
        dueDays: '',
        subject: '',
        contentString: '',
        email: user?.email,
        type: 'bill',
        id: uuid(),
        to: [],
        content: [],
    })
    const [toogle, setToogle] = useState(false)

    const getData = async () => {
        openDialog()
        let bodyData = {
            collection_name: 'users',
            database_name: 'superDatabase',
            token: tokenData,
        }
        try {
            let resp = await fetch(
                `${process.env.REACT_APP_BASE_URL}/getalldata/`,
                {
                    method: 'POST',
                    body: JSON.stringify(bodyData),
                    headers: {
                        Authorization: `Bearer ${tokenData}`,
                        'Content-Type': 'application/json',

                        'Access-Control-Allow-Origin': '*',
                        'access-control-allow-credentials': 'true',
                        'access-control-allow-origin': '*',
                    },
                }
            )
            resp = await resp.json()
            let filterUser = resp?.filter((item) => item?.email == user?.email)
            let filterCompany = filterUser[0]?.companies?.find(
                (item) => item?.GUID == companyData?.GUID
            )
            let overDueData = []
            for (let overdue in filterCompany?.OVERDUEREMINDER) {
                let item = filterCompany?.OVERDUEREMINDER[overdue]
                if (item) {
                    overDueData.push(item)
                }
            }
            let finalData = overDueData?.find((item) => item?.id == id)
            setReminderData({
                remainderName: finalData?.remainderName
                    ? finalData?.remainderName
                    : '',
                dueDate: finalData?.dueDate ? finalData?.dueDate : '',
                dueDays: finalData?.dueDays ? finalData?.dueDays : '',
                subject: finalData?.subject ? finalData?.subject : '',
                contentString: finalData?.contentString
                    ? finalData?.contentString
                    : '',
                email: user?.email,
                type: finalData?.type ? finalData?.type : '',
                id: finalData?.id ? finalData?.id : '',
                to: finalData?.to ? [...finalData?.to] : [],
                content: finalData?.content ? [...finalData?.content] : [{}],
            })
            setToogle(finalData?.isReminderEnabled)
        } catch (err) {
            console.log(err)
        }
    }

    const openDialog = () => {
        setIsOpen(true)
    }

    const onDialogClose = (e) => {
        e.preventDefault()
        setIsOpen(false)
    }

    const submitData = async (e) => {
        e.preventDefault()
        try {
            let bodyData = {
                database_name: 'superDatabase',
                collection_name: 'users',
                token: tokenData,
                filter: {
                    email: user?.email,
                    'companies.GUID': companyData?.GUID,
                },
                fields: {
                    OVERDUEREMINDER: {
                        [reminderData?.dueDays]: {
                            ...reminderData,
                            isReminderEnabled: toogle,
                            content: [
                                {
                                    insert: reminderData?.contentString,
                                },
                            ],
                        },
                    },
                },
            }
            let resp = await fetch(
                `${process.env.REACT_APP_BASE_URL}/patchnestedfield/`,
                {
                    method: 'PATCH',
                    body: JSON.stringify(bodyData),
                    headers: {
                        Authorization: `Bearer ${tokenData}`,
                        'Content-Type': 'application/json',

                        'Access-Control-Allow-Origin': '*',
                        'access-control-allow-credentials': 'true',
                        'access-control-allow-origin': '*',
                    },
                }
            )
            if (resp.ok) {
                toast.success('Automated Reminders -Edited  Data sent !')
            }

            getBills()
        } catch (err) {
            console.log(err)
        } finally {
            setIsOpen(false)
        }
    }

    return (
        <div>
            <ToastContainer />
            <Button icon={<HiOutlinePencil />} onClick={() => getData()}>
                Edit
            </Button>
            <Dialog
                className="-mt-10 "
                isOpen={dialogIsOpen}
                onClose={onDialogClose}
                onRequestClose={onDialogClose}
            >
                <div className="flex flex-col h-[100vh] justify-between overflow-y-scroll ">
                    <h5 className="mb-4">Automated Reminders - Bill</h5>
                    <Formik>
                        <Form>
                            <FormContainer>
                                <FormItem label="Send Reminder">
                                    <select
                                        placeholder="Due Date"
                                        type="text"
                                        name="sendReminder"
                                        className="border-[1px] px-2 py-3 w-full rounded-lg"
                                        value={reminderData?.dueDate}
                                        onChange={(e) =>
                                            setReminderData({
                                                ...reminderData,
                                                dueDate: e.target.value,
                                            })
                                        }
                                    >
                                        <option value="After (due date)">
                                            After (due date)
                                        </option>
                                        <option value="Before (due date)">
                                            Before (due date)
                                        </option>
                                    </select>
                                    <input
                                        type="number"
                                        disabled
                                        placeholder="Day(s)"
                                        className="border-[1px] px-2 py-3 w-full rounded-lg mt-5"
                                        value={reminderData?.dueDays}
                                        onChange={(e) =>
                                            setReminderData({
                                                ...reminderData,
                                                dueDays: e.target.value,
                                            })
                                        }
                                    />
                                </FormItem>
                                <FormItem label=" Reminder Name">
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        placeholder="Reminder Name"
                                        className="border-[1px] px-2 py-3 w-full rounded-lg mt-1"
                                        value={reminderData?.remainderName}
                                        onChange={(e) =>
                                            setReminderData({
                                                ...reminderData,
                                                remainderName: e.target.value,
                                            })
                                        }
                                    />
                                </FormItem>

                                <FormItem label="Subject">
                                    <input
                                        name="subject"
                                        placeholder="Reminder for 30 days"
                                        className="border-[1px] px-2 py-3 w-full rounded-lg mt-1"
                                        value={reminderData?.subject}
                                        onChange={(e) =>
                                            setReminderData({
                                                ...reminderData,
                                                subject: e.target.value,
                                            })
                                        }
                                    />
                                </FormItem>
                                <FormItem label="Content">
                                    <JoditEditor
                                        ref={editor}
                                        value={reminderData?.contentString}
                                        onChange={(e) =>
                                            setReminderData({
                                                ...reminderData,
                                                contentString: e,
                                            })
                                        }
                                    />
                                </FormItem>
                                <FormItem className=" pb-6">
                                    <Switcher
                                        checked={toogle}
                                        onClick={() => setToogle(!toogle)}
                                    />
                                    <Button
                                        className="ltr:mr-2 rtl:ml-2"
                                        variant="plain"
                                        onClick={onDialogClose}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type="reset"
                                        className="ltr:mr-2 rtl:ml-2"
                                    >
                                        Reset
                                    </Button>
                                    <Button
                                        variant="solid"
                                        onClick={(e) => submitData(e)}
                                    >
                                        Update
                                    </Button>
                                </FormItem>
                            </FormContainer>
                        </Form>
                    </Formik>
                </div>
            </Dialog>
        </div>
    )
}

export default EditReminderBills
