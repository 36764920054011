import { APP_PREFIX_PATH } from 'constants/route.constant'
import {
    NAV_ITEM_TYPE_TITLE,
    NAV_ITEM_TYPE_COLLAPSE,
    NAV_ITEM_TYPE_ITEM,
} from 'constants/navigation.constant'
import { ADMIN, USER } from 'constants/roles.constant'

const appsNavigationConfig = [
    {
        key: 'apps',
        path: '',
        title: 'APPS',
        translateKey: 'nav.apps',
        icon: 'apps',
        type: NAV_ITEM_TYPE_TITLE,
        authority: [ADMIN, USER],
        subMenu: [
            // {
            //     key: 'apps.crm',
            //     path: '',
            //     title: 'Debtor Summary',
            //     translateKey: 'nav.appsCrm.crm',
            //     icon: 'reports',
            //     type: NAV_ITEM_TYPE_COLLAPSE,
            //     authority: [ADMIN, USER],
            //     subMenu: [
            //         {
            //             key: 'appsCrm.dashboard',
            //             path: `${APP_PREFIX_PATH}/reports/debtorsummary`,
            //             title: 'Dashboard',
            //             translateKey: 'nav.appsCrm.dashboard',
            //             icon: '',
            //             type: NAV_ITEM_TYPE_ITEM,
            //             authority: [ADMIN, USER],
            //             subMenu: [],
            //         },

            //         {
            //             key: 'appsCrm.customers',
            //             path: `${APP_PREFIX_PATH}/reports/salesmanagerreport`,
            //             title: 'Customers',
            //             translateKey: 'nav.appsCrm.customers',
            //             icon: '',
            //             type: NAV_ITEM_TYPE_ITEM,
            //             authority: [ADMIN, USER],
            //             subMenu: [],
            //         },
            //         {
            //             key: 'appsCrm.customerDetails',
            //             path: `${APP_PREFIX_PATH}/reports/projectedcollection`,
            //             title: 'Customer Details',
            //             translateKey: 'nav.appsCrm.customerDetails',
            //             icon: '',
            //             type: NAV_ITEM_TYPE_ITEM,
            //             authority: [ADMIN, USER],
            //             subMenu: [],
            //         },
            //     ],
            // },
            {
                key: 'apps.sales',
                path: '',
                title: 'Sales',
                translateKey: 'nav.appsSales.sales',
                icon: 'sales',
                type: NAV_ITEM_TYPE_COLLAPSE,
                authority: [ADMIN, USER],
                subMenu: [
                    {
                        key: 'appsSales.dashboard',
                        path: `${APP_PREFIX_PATH}/sales/Customers`,
                        title: 'Dashboard',
                        translateKey: 'nav.appsSales.dashboard',
                        icon: '',
                        type: NAV_ITEM_TYPE_ITEM,
                        authority: [ADMIN, USER],
                        subMenu: [],
                    },
                    // {
                    //     key: 'appsSales.productList',
                    //     path: `${APP_PREFIX_PATH}/sales/invoices`,
                    //     title: 'Product List',
                    //     translateKey: 'nav.appsSales.productList',
                    //     icon: '',
                    //     type: NAV_ITEM_TYPE_ITEM,
                    //     authority: [ADMIN, USER],
                    //     subMenu: [],
                    // },
                    // {
                    //     key: 'appsSales.productNew',
                    //     path: `${APP_PREFIX_PATH}/sales/creditnote`,
                    //     title: 'Credit Note',
                    //     translateKey: 'nav.appsSales.productNew',
                    //     icon: '',
                    //     type: NAV_ITEM_TYPE_ITEM,
                    //     authority: [ADMIN, USER],
                    //     subMenu: [],
                    // },
                    // {
                    //     key: 'appsSales.orderList',
                    //     path: `${APP_PREFIX_PATH}/sales/receipts`,
                    //     title: 'Receipts',
                    //     translateKey: 'nav.appsSales.orderList',
                    //     icon: '',
                    //     type: NAV_ITEM_TYPE_ITEM,
                    //     authority: [ADMIN, USER],
                    //     subMenu: [],
                    // },
                ],
            },
            // {
            //     key: 'apps.crypto',
            //     path: '',
            //     title: 'rate',
            //     translateKey: 'nav.appsCrypto.crypto',
            //     icon: 'tasks',
            //     type: NAV_ITEM_TYPE_COLLAPSE,
            //     authority: [ADMIN, USER],
            //     subMenu: [
            //         {
            //             key: 'appsCrypto.portfolio',
            //             path: `${APP_PREFIX_PATH}/tasks/followUp`,
            //             title: 'Tasks',
            //             translateKey: 'nav.appsCrypto.portfolio',
            //             icon: '',
            //             type: NAV_ITEM_TYPE_ITEM,
            //             authority: [ADMIN, USER],
            //             subMenu: [],
            //         },
            //     ],
            // },
            {
                key: 'apps.knowledgeBase',
                path: '',
                title: 'purchase',
                translateKey: 'nav.appsknowledgeBase.knowledgeBase',
                icon: 'purchase',
                type: NAV_ITEM_TYPE_COLLAPSE,
                authority: [ADMIN, USER],
                subMenu: [
                    // {
                    //     key: 'appsknowledgeBase.suppliers',
                    //     path: `${APP_PREFIX_PATH}/purchase/suppliers`,
                    //     title: 'Suppliers',
                    //     translateKey: 'nav.appsknowledgeBase.suppliers',
                    //     icon: '',
                    //     type: NAV_ITEM_TYPE_ITEM,
                    //     authority: [ADMIN, USER],
                    //     subMenu: [],
                    // },
                    // {
                    //     key: 'appsknowledgeBase.helpCenter',
                    //     path: `${APP_PREFIX_PATH}/purchase/bill`,
                    //     title: 'Help Center',
                    //     translateKey: 'nav.appsknowledgeBase.helpCenter',
                    //     icon: '',
                    //     type: NAV_ITEM_TYPE_ITEM,
                    //     authority: [ADMIN, USER],
                    //     subMenu: [],
                    // },
                    // {
                    //     key: 'appsknowledgeBase.article',
                    //     path: `${APP_PREFIX_PATH}/purchase/payment`,
                    //     title: 'Payment',
                    //     translateKey: 'nav.appsknowledgeBase.article',
                    //     icon: '',
                    //     type: NAV_ITEM_TYPE_ITEM,
                    //     authority: [ADMIN, USER],
                    //     subMenu: [],
                    // },

                    // {
                    //     key: 'appsknowledgeBase.editArticle',
                    //     path: `${APP_PREFIX_PATH}/purchase/debitcreditnotes`,
                    //     title: 'Debit Notes',
                    //     translateKey: 'nav.appsknowledgeBase.editArticle',
                    //     icon: '',
                    //     type: NAV_ITEM_TYPE_ITEM,
                    //     authority: [ADMIN, USER],
                    //     subMenu: [],
                    // },
                    {
                        key: 'appsknowledgeBase.suppliers',
                        path: `${APP_PREFIX_PATH}/purchase/suppliers`,
                        title: 'Supplier',
                        translateKey: 'nav.appsknowledgeBase.suppliers',
                        icon: '',
                        type: NAV_ITEM_TYPE_ITEM,
                        authority: [ADMIN, USER],
                        subMenu: [],
                    },
                ],
            },
        ],
    },
]

export default appsNavigationConfig
