import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Switcher } from 'components/ui'
import { Tooltip } from 'components/ui'
import { useNavigate, useParams } from 'react-router-dom'
import { selectLicense } from '../../../store/auth/superAdminLicense'
import { userData, AccessSuperAdmin } from 'store/auth/userSlice'
import EditLIcense from './EditLicense'
import { GiEyelashes } from 'react-icons/gi'
import { VscEye } from 'react-icons/vsc'
import DataTable from 'react-data-table-component'
import './App.css'
import {
    FaSearch,
    FaAngleUp,
    FaAngleDown,
    FaUserPlus,
    FaEllipsisV,
    FaChevronUp,
    FaChevronDown,
} from 'react-icons/fa'
import { toast } from 'react-toastify'
import { FaFileExport } from 'react-icons/fa'
import { CSVLink } from 'react-csv'

const MyCustomDropdown = ({
    getAllUserData,
    rowData,
    onAction,
    isOpen,
    toggleDropdown,
    rowIndex,
    filteredData,
}) => {
    const handleActionClick = (action) => {
        onAction(rowData, action)
        toggleDropdown(null)
        isOpen = false // Close the dropdown after action is clicked
    }

    const [closePopup, setClosePopup] = useState(false)

    const handleClosePopup = () => {
        setClosePopup(true)
    }
    // ${isLastThreeRows ? 'bottom-8' : 'mt-2' }

    const isLastThreeRows = rowIndex >= 7 // Assuming 'data' is your table data array


    return (
        <div className="relative">
            <button
                onClick={() => toggleDropdown(rowData._id)}
                className="text-gray-600"
            >
                {isOpen ? <FaChevronUp /> : <FaChevronDown />}
            </button>
            {isOpen && (
                <div className={`absolute mt-0 ${rowIndex > 2 ? '' : '-top-20 mr-4' }  right-0 py-2 w-40 bg-white border rounded shadow z-[999]`}>
                    <button
                        onClick={() => handleActionClick('visit')}
                        className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                    >
                        Visit
                    </button>
                    <EditLIcense
                        item={rowData}
                        getAllUserData={getAllUserData}
                        handleClosePopup={handleClosePopup}
                    />
                    <button
                        onClick={() => handleActionClick('manage')}
                        className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                    >
                        Manage
                    </button>
                </div>
            )}
        </div>
    )
}

const LicenseTable = () => {
    const params = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth.user)
    var tokenData = JSON.parse(localStorage.getItem('setuFiToken'))
    const [licenseData, setLicenseData] = useState([])
    const [filterLicenseData, setFilterLicenseData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [hovereye, setHoverEye] = useState({ status: true, id: null })
    const [searchQuery, setSearchQuery] = useState('')
    const dropdownRef = useRef(null)

    //show more functionality
    const [selectedColumns, setSelectedColumns] = useState([
        'Name',
        'License No',
        'Company Size',
        'Phone Number',
        'Email',
        'Suspend License',
        'Visit Account',
        'Actions',
        'Manage',
    ])

    const [showDropdown, setShowDropdown] = useState(false)
    const [showInviteDropdown, setShowInviteDropdown] = useState(false)

    const [openDropdownRowId, setOpenDropdownRowId] = useState(null)
    const [setOpenDropdownRow, setsetOpenDropdownRow] = useState(false)

    const [invitedEmails, setInvitedEmails] = useState([])
    const [submitting, setSubmitting] = useState(false)

    const handleRemoveEmail = (emailToRemove) => {
        setInvitedEmails(
            invitedEmails?.filter((email) => email !== emailToRemove)
        )
    }

    useEffect(() => {
        // Add event listener to close dropdown when clicking outside
        const handleOutsideClick = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setShowDropdown(false)
                setShowInviteDropdown(false)
            }
        }

        window.addEventListener('click', handleOutsideClick)

        return () => {
            window.removeEventListener('click', handleOutsideClick)
        }
    }, [])

    const getUserData = async () => {
        try {
            setIsLoading(true)
            let resp = await fetch(
                `${process.env.REACT_APP_BASE_URL}/getallusers/superDatabase/users/${tokenData}`,
                {
                    method: 'Patch',
                    headers: {
                        Authorization: `Bearer ${tokenData}`,
                        'Content-Type': 'application/json',

                        'Access-Control-Allow-Origin': '*',
                        'access-control-allow-credentials': 'true',
                    },
                }
            )
            resp = await resp.json()
            let filteredData = resp?.filter(
                (item) => item?.isSuperAdmin === false && item?.isUser == false
            )
            setLicenseData([...filteredData])
            setFilterLicenseData([...filteredData])
            dispatch(selectLicense(filteredData[0]))
            setIsLoading(false)
        } catch (err) {
            console.log(err)
        }
    }
    const getAllUserData = async () => {
        try {
            setIsLoading(true)
            const resp = await fetch(
                `${process.env.REACT_APP_BASE_URL}/superAdmin/getallusers/superDatabase/users`,
                {
                    method: 'POST',
                }
            )

            // resp = await resp.json()
            if (!resp.ok) {
                throw new Error('Network response was not ok')
            }
            const data = await resp.json()

            let filteredData = data?.filter(
                (item) =>
                    item?.isSuperAdmin === false &&
                    item?.isUser == false &&
                    item.isDeleted == false
            )
            setLicenseData([...filteredData])
            setFilterLicenseData([...filteredData])
            dispatch(selectLicense(filteredData[0]))
            setIsLoading(false)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        // getUserData()
        getAllUserData()
    }, [])

    const onSwitcherToggle = async (email, val) => {
        try {
            let bodyData = {
                collection_name: 'users',
                // token: tokenData,
                database_name: 'superDatabase',
                query: {
                    email: email,
                },
                fields: {
                    isSuspended: val,
                },
            }

            setIsLoading(true)

            let resp = await fetch(
                `${process.env.REACT_APP_BASE_URL}/superAdmin/updateuserIsSuspended`,
                {
                    method: 'PATCH',
                    body: JSON.stringify(bodyData),
                    headers: {
                        'Content-Type': 'application/json',
                        // Add other headers if needed
                    },
                }
            )

            if (!resp.ok) {
                throw new Error(
                    'Failed to update user. Server returned ' + resp.status
                )
            }

            const data = await resp.json()

            if (data.status === false) {
                toast.error('Error from Server side!')
            } else {
                toast.success('Action performed successfully!')
                getAllUserData()
            }

            setIsLoading(false)
        } catch (err) {
            console.log(err)
        }
    }

    //----Visit ACcount

    const visitAppAdmin = async (email) => {
        dispatch(AccessSuperAdmin(user))
        var detail = {
            email: email,
            super_admin_email: user?.email,
        }
        let resp = await fetch(
            `${process.env.REACT_APP_BASE_URL}/superAdmin/getRedirectUser`,
            {
                method: 'POST',
                body: JSON.stringify(detail),
                headers: {
                    Authorization: `Bearer ${tokenData}`,
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'access-control-allow-credentials': 'true',
                    'access-control-allow-origin': '*',
                },
            }
        )
        resp = await resp.json()
        if (resp.status == true) {
            dispatch(userData(resp?.data))
        }
    }

    //     const onSendMail = async () => {
    //         if (invitedEmails.length > 0) {
    //             setSubmitting(true)
    //             try {
    //                 const bodyHtml = `
    //                 <html>
    //                     <head>
    //                     <style>
    //                         /* Add any CSS styling here */
    //                     </style>
    //                     </head>
    //                     <body style="font-family: Arial, sans-serif; padding: 20px;">
    //                     <img src="https://capitalsetu.com/img/logo.webp" alt="Company Logo" style="max-width: 150px;">
    //                     <h2>Welcome to Our Platform!</h2>
    //                     <p>Dear user,</p>
    //                     <p>You have been invited to join our platform. Click the links below to get started:</p>
    //                     <ul>
    //                         <li><a href="https://play.google.com/store/apps/details?id=com.cs.setufi">Download our mobile app</a></li>
    //                         <li><a href="https://portal.setufi.com/">Explore setufi webportal.</a></li>
    //                     </ul>
    //                     <p>If you have any questions, feel free to contact us.</p>
    //                     <p>Best regards,</p>
    //                     <p>The Team at Capital Setu</p>
    //                     </body>
    //                 </html>
    // `
    //                 let mailBodyData = {
    //                     reciever_email: invitedEmails,
    //                     subject: 'Invitation to Join Our Platform',
    //                     html: bodyHtml,
    //                     token: tokenData,
    //                     mail_type: 'NOREPLY',
    //                 }
    //                 let resp = await fetch(
    //                     `${process.env.REACT_APP_BASE_URL}/send-mail/`,
    //                     {
    //                         method: 'POST',
    //                         body: JSON.stringify(mailBodyData),
    //                         headers: {
    //                             Authorization: `Bearer ${tokenData}`,
    //                             'Content-Type': 'application/json',

    //                             'Access-Control-Allow-Origin': '*',
    //                             'access-control-allow-credentials': 'true',
    //                             'access-control-allow-origin': '*',
    //                         },
    //                     }
    //                 )
    //                 resp = await resp.json()
    //                 if (resp.status == false) {
    //                     toast.error('Error from mail server!')
    //                     return
    //                 }
    //                 toast.success('User Invited Sucessfully!')

    //                 setSubmitting(false)
    //                 setShowInviteDropdown(false)
    //             } catch (errors) {
    //                 setSubmitting(false)
    //             } finally {
    //                 setInvitedEmails([])
    //             }
    //         } else {
    //             toast.success('Please add Emails!')
    //         }
    //     }

    const onSendMail = async () => {
        if (invitedEmails.length > 0) {
            setSubmitting(true)
            try {
                let mailBodyData = {
                    email: invitedEmails,
                }
                let resp = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/userInvitation/invite/`,
                    {
                        method: 'POST',
                        body: JSON.stringify(mailBodyData),
                        headers: {
                            Authorization: `Bearer ${tokenData}`,
                            'Content-Type': 'application/json',

                            'Access-Control-Allow-Origin': '*',
                            'access-control-allow-credentials': 'true',
                            'access-control-allow-origin': '*',
                        },
                    }
                )
                resp = await resp.json()
                if (resp.status == false) {
                    toast.error('Error from mail server!')
                    return
                }
                toast.success('User Invited Sucessfully!')

                setSubmitting(false)
                setShowInviteDropdown(false)
            } catch (errors) {
                setSubmitting(false)
            } finally {
                setInvitedEmails([])
                setSubmitting(false)
                setShowInviteDropdown(false)
            }
        } else {
            toast.warning('Please add Emails!')
        }
    }

    const targetLicense = (item) => {
        if (item?.companies?.length > 0) {
            dispatch(selectLicense(item))
            navigate(`/license/${item?._id}`)
        } else {
            alert('No Company Synced!')
        }
    }

    const handleColumnSelection = (columnName) => {
        setSelectedColumns((prevSelectedColumns) => {
            if (prevSelectedColumns.includes(columnName)) {
                return prevSelectedColumns.filter((col) => col !== columnName)
            } else {
                return [...prevSelectedColumns, columnName]
            }
        })
    }

    const tableCustomStyles = {
        headRow: {
            style: {
                color: '#FFFFFF',
                backgroundColor: '#133386',
            },
        },
        rows: {
            style: {
                color: 'STRIPEDCOLOR',
                backgroundColor: '#8E9DC420',
            },
            stripedStyle: {
                color: 'NORMALCOLOR',
                backgroundColor: '#FFFFFF',
            },
        },
    }

    const columns = [
        {
            name: 'Name',
            selector: 'name',
            sortable: true,
            cell: (row) => row?.userName,
        },
        {
            name: 'CA License No',
            selector: 'CALicenseNumber',
            sortable: true,
            cell: (row) => row?.CALicenseNumber,
        },
        {
            name: 'License No',
            selector: 'licenseNumber',
            sortable: true,
            cell: (row) => (
                <Tooltip
                    className="w-12"
                    title={
                        row?.licenseNumber
                            ? row?.licenseNumber
                            : 'No License Number'
                    }
                >
                    <span className="cursor-pointer ">
                        {hovereye?.status == true && hovereye?.id == row?.id ? (
                            <VscEye size={22} style={{ color: '#133386' }} />
                        ) : (
                            <GiEyelashes
                                size={22}
                                style={{ color: '#133386' }}
                            />
                        )}
                    </span>
                </Tooltip>
            ),
        },
        {
            name: 'Company Size',
            selector: 'companies',
            sortable: true,
            cell: (row) => row?.companies?.length,
        },
        {
            name: 'Active Date',
            selector: 'activeDate',
            sortable: true,
            cell: (row) => row?.activeDate,
        },
        {
            name: 'PAN Card No',
            selector: 'panNumber',
            sortable: true,
            cell: (row) => row?.panNumber,
        },
        {
            name: 'Phone Number',
            selector: 'phonenumber',
            sortable: true,
            cell: (row) => row?.phone,
        },
        {
            name: 'Email',
            selector: 'email',
            sortable: true,
            cell: (row) => row?.email,
        },
        {
            name: 'Company Type',
            selector: 'companytype',
            sortable: true,
            cell: (row) => row?.companytype,
        },
        {
            name: 'Turnover',
            selector: 'turnOver',
            sortable: true,
            cell: (row) => row?.turnOver,
        },
        {
            name: 'Website',
            selector: 'website',
            sortable: true,
            cell: (row) => row?.website,
        },
        {
            name: 'GST Number',
            selector: 'GSTNumber',
            sortable: true,
            cell: (row) => row?.GSTNumber,
        },
        {
            name: 'Suspend License',
            selector: 'isSuspended',
            sortable: true,
            cell: (row) => (
                <Switcher
                    checked={row?.isSuspended}
                    onClick={() =>
                        onSwitcherToggle(row?.email, !row?.isSuspended)
                    }
                />
            ),
        },

        {
            name: 'Actions',
            cell: (row, index) => (
                <MyCustomDropdown
                    getAllUserData={getAllUserData}
                    rowIndex={index}
                    filteredData={filteredData}
                    rowData={row}
                    onAction={handleAction}
                    isOpen={openDropdownRowId === row._id && setOpenDropdownRow}
                    toggleDropdown={toggleDropdown}
                />
            ),
        },
    ]

    const handleAction = (rowData, action) => {
        // Handle the action based on 'action' and 'rowId'
        if (action === 'visit') {
            visitAppAdmin(rowData?.email)
        } else if (action === 'edit') {
        } else if (action === 'manage') {
            targetLicense(rowData)
        }
    }

    const toggleDropdown = (rowId) => {
        setOpenDropdownRowId(rowId)
        setsetOpenDropdownRow(!setOpenDropdownRow)
    }

    const filteredData = licenseData.filter(
        (item) =>
            item.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.phonenumber?.includes(searchQuery)
    )

    const exportableData = [
        [
            'Name',
            'CA License No',
            'License No',
            'Company Size',
            'Active Date',
            'PAN Card No',
            'Phone Number',
            'Email',
            'Company Type',
            'Turnover',
            'Website',
            'GST Number',
        ],
        ...filteredData.map((item) => {
            const {
                name,
                CALicenseNumber,
                licenseNumber,
                companies,
                activeDate,
                panNumber,
                phonenumber,
                email,
                companytype,
                turnOver,
                website,
                GSTNumber,
            } = item

            return [
                name,
                CALicenseNumber,
                licenseNumber,
                companies?.length,
                activeDate,
                panNumber,
                phonenumber,
                email,
                companytype,
                turnOver,
                website,
                GSTNumber,
            ]
        }),
    ]

    const exportableDataFunc = () => {
        const valuesToExclude = ['Actions', 'Visit Account', 'Manage']
        let selectors = columns
            .filter(
                (item) =>
                    item?.name !== 'Actions' &&
                    selectedColumns.includes(item?.name)
            )
            .map((item) => item?.selector)

        console.log("bvc", selectors)
        console.log("bvc", filteredData)

        return [
            selectedColumns.filter((item) => !valuesToExclude.includes(item)), // Header row
            ...filteredData.map((item) => {
                return selectors.map((fieldName) =>
                    Array.isArray(item[fieldName])
                        ? item[fieldName].length
                        : item[fieldName]
                )
            }),
        ]
    }

    const filename = 'license.csv'

    return (
        <>
            {isLoading ? (
                <div class="  rounded-md p-4 max-w-sm w-full mx-auto">
                    <div class="animate-pulse flex space-x-4">
                        <div class="rounded-full bg-slate-200 h-10 w-10"></div>
                        <div class="flex-1 space-y-6 py-1">
                            <div class="h-2 bg-slate-200 rounded"></div>
                            <div class="space-y-3">
                                <div class="grid grid-cols-3 gap-4">
                                    <div class="h-2 bg-slate-200 rounded col-span-2"></div>
                                    <div class="h-2 bg-slate-200 rounded col-span-1"></div>
                                </div>
                                <div class="h-2 bg-slate-200 rounded"></div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="w-full overflow-x-scroll">
                    <div className="flex items-center justify-between mb-4">
                        <div className="flex items-center">
                            <div className="mr-2">
                                <FaSearch className="text-gray-500" />
                            </div>
                            <input
                                type="text"
                                placeholder="Search by name, email, or phone"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                className="border border-gray-300 rounded-lg py-2 px-4 focus:outline-none focus:border-blue-400 transition duration-300"
                            />
                        </div>
                        <div className="relative">
                            <div className="flex">
                                <CSVLink
                                    data={exportableDataFunc()}
                                    filename={filename}
                                >
                                    <button
                                        style={{
                                            color: '#133386',
                                            fontWeight: 'bold',
                                        }}
                                        className="text-blue-500 text-sm flex items-center mr-5"
                                    >
                                        <FaFileExport className="mr-1" />
                                        Export
                                    </button>
                                </CSVLink>
                                <div>
                                    <button
                                        style={{
                                            color: '#133386',
                                            fontWeight: 'bold',
                                        }}
                                        onClick={() => {
                                            setShowInviteDropdown(
                                                !showInviteDropdown
                                            )
                                            setShowDropdown(false)
                                        }}
                                        className="text-blue-500 text-sm flex items-center mr-3"
                                    >
                                        <FaUserPlus className="mr-1" />
                                        Invite User
                                    </button>
                                    {showInviteDropdown && (
                                        <div className="absolute right-0 mt-2 bg-white border border-gray-300 rounded shadow-lg p-4 z-20 w-[340px]">
                                            <div className="flex flex-col items-center w-[90%] mx-6">
                                                <input
                                                    type="text"
                                                    placeholder="Enter email(s)"
                                                    className="border border-gray-300 rounded-lg py-2 px-4 focus:outline-none focus:border-blue-400 transition duration-300 "
                                                    value={invitedEmails
                                                        ?.join(', ')
                                                        .replace(/\s/g, '')}
                                                    onChange={(e) =>
                                                        setInvitedEmails([
                                                            ...e.target.value.split(
                                                                ','
                                                            ),
                                                        ])
                                                    }
                                                />
                                                <span>
                                                    Enter multiple emails using
                                                    , separate
                                                </span>
                                                <button
                                                    style={{
                                                        background: '#133386',
                                                    }}
                                                    className=" text-white px-4 py-2 rounded"
                                                    onClick={onSendMail}
                                                >
                                                    {submitting
                                                        ? 'Sending...'
                                                        : 'Invite'}
                                                </button>
                                            </div>
                                            {invitedEmails.length > 0 && (
                                                <div className="border rounded py-1 px-2 flex flex-wrap mt-3">
                                                    {invitedEmails.map(
                                                        (email) => (
                                                            <div
                                                                key={email}
                                                                className="bg-gray-200 text-gray-600 rounded-full py-1 px-2 m-1"
                                                            >
                                                                {email}
                                                                <button
                                                                    className="ml-1 text-red-500 whitespace-nowrap"
                                                                    onClick={() =>
                                                                        handleRemoveEmail(
                                                                            email
                                                                        )
                                                                    }
                                                                >
                                                                    &times;
                                                                </button>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <button
                                        style={{
                                            color: '#133386',
                                            fontWeight: 'bold',
                                        }}
                                        onClick={() => {
                                            setShowDropdown(!showDropdown)
                                            setShowInviteDropdown(false)
                                        }}
                                        className="text-blue-500 text-sm flex items-center"
                                    >
                                        {showDropdown ? (
                                            <FaAngleUp className="mr-1" />
                                        ) : (
                                            <FaAngleDown className="mr-1" />
                                        )}
                                        expand columns
                                    </button>
                                    {showDropdown && (
                                        <div className="absolute right-0 mt-2 bg-white border border-gray-300 rounded shadow-lg p-2  z-20 w-80">
                                            <div className="grid grid-cols-2 gap-4">
                                                <div>
                                                    {columns
                                                        .slice(
                                                            0,
                                                            Math.ceil(
                                                                columns.length /
                                                                2
                                                            )
                                                        )
                                                        .map((col) => (
                                                            <div
                                                                className="mr-4 mt-2"
                                                                key={col.name}
                                                            >
                                                                {/* Use the custom checkbox within your component */}
                                                                <label>
                                                                    <input
                                                                        type="checkbox"
                                                                        className="custom-checkbox"
                                                                        checked={selectedColumns.includes(
                                                                            col.name
                                                                        )}
                                                                        onChange={() =>
                                                                            handleColumnSelection(
                                                                                col.name
                                                                            )
                                                                        }
                                                                    />
                                                                </label>

                                                                {col.name}
                                                            </div>
                                                        ))}
                                                </div>
                                                <div>
                                                    {columns
                                                        .slice(
                                                            Math.ceil(
                                                                columns.length /
                                                                2
                                                            )
                                                        )
                                                        .map((col) => (
                                                            <div
                                                                className="mr-4 mt-2"
                                                                key={col.name}
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-checkbox"
                                                                    checked={selectedColumns.includes(
                                                                        col.name
                                                                    )}
                                                                    onChange={() =>
                                                                        handleColumnSelection(
                                                                            col.name
                                                                        )
                                                                    }
                                                                />

                                                                {col.name}
                                                            </div>
                                                        ))}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white shadow-md rounded-lg overflow-visible">
                        <DataTable
                            columns={columns.filter((col) =>
                                selectedColumns.includes(col.name)
                            )}
                            data={filteredData}
                            highlightOnHover
                            striped
                            pagination
                            customStyles={tableCustomStyles}
                        />
                    </div>
                </div>
            )}
        </>
    )
}
export default LicenseTable
