import React, { useEffect, useState } from 'react'
import { Input } from 'components/ui'
import { HiOutlineSearch } from 'react-icons/hi'
import { useSelector } from 'react-redux'
import DataTable from 'react-data-table-component'

const Feedback = () => {
    let tokenData = JSON.parse(localStorage.getItem('setuFiToken'))
    const { companyData, financialYear } = useSelector(
        (state) => state.auth.user
    )
    const transformedData = []
    const [loading, setLoading] = useState(false)
    const [feedback, setFeedback] = useState([])
    const [filterFeedback, setFilterFeedback] = useState([])
    const [filterStatus, setFilterStatus] = useState(false)

    // Get All Feedback API on load

    const getFeedbackData = async () => {
        try {
            setFilterFeedback([])
            setLoading(true)
            let data = await fetch(
                `${process.env.REACT_APP_BASE_URL}/feedback/getAllFeedbacks`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${tokenData}`,
                        'Content-Type': 'application/json',

                        'Access-Control-Allow-Origin': '*',
                        'access-control-allow-credentials': 'true',
                        'access-control-allow-origin': '*',
                    },
                }
            )
            data = await data.json()
            setFeedback(data.feedbacks)
            setFilterFeedback(transformedData)
        } catch (err) {
            console.log(err)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        getFeedbackData()
    }, [companyData, financialYear])

    // Searching functionality 

    const SearchHandler = (e) => {
        setFilterStatus(true)
        setFilterFeedback(
            transformedData?.filter(
                (item) =>
                    String(item?.name)
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase()) ||
                    String(item?.feedbackType)
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
            )
        )
    }

    feedback?.forEach((user) => {
        user.feedbacks.forEach((feedback) => {
            transformedData.push({
                name: user.name,
                feedbackType: feedback.selectedFeedBack,
                feedback: feedback.feedback,
                time: feedback.createdTime,
                date: feedback.createdDate,
            })
        })
    })

    const columns = [
        {
            name: 'NAME',
            selector: 'name',
            sortable: true,
            width: '200px',
        },
        {
            name: 'FEEDBACK TYPE',
            selector: 'feedbackType',
            sortable: true,
            width: '160px',
        },
        {
            name: 'FEEDBACK',
            selector: 'feedback',
            sortable: true,
            cell: (row) => (
                <div className='flex items-center' style={{ whiteSpace: 'pre-wrap', height: 'auto', minHeight: '10px' }}>
                    {row.feedback}
                </div>
            ),
        },
        {
            name: 'TIME',
            selector: 'time',
            sortable: true,
            width: '90px',
        },
        {
            name: 'DATE',
            selector: 'date',
            sortable: true,
            width: '130px',
        },
    ]

    const customStyles = {
        headRow: {
            style: {
                color: '#FFFFFF',
                backgroundColor: '#133386',
            },
        },
        rows: {
            style: {
                color: 'STRIPEDCOLOR',
                backgroundColor: '#8E9DC420',
            },
            stripedStyle: {
                color: 'NORMALCOLOR',
                backgroundColor: '#FFFFFF',
            },
        },
    }
    return (
        <>
            <div className="px-5">
                <div>
                    <p className="font-bold text-xl">Feedbacks</p>
                </div>
                <div className="flex items-center justify-between  mb-6 mt-6">
                    <>
                        <Input
                            className=" md:mb-0 w-80 mb-4"
                            size="sm"
                            placeholder="Search Feedback"
                            suffix={<HiOutlineSearch className="text-lg" />}
                            onChange={(e) => SearchHandler(e)}
                        />
                    </>
                </div>
                <div className="bg-white shadow-md rounded-lg overflow-hidden">
                    <DataTable
                        columns={columns}
                        data={filterStatus ? filterFeedback : transformedData}
                        pagination
                        fixedHeader
                        selectableRowsHighlight
                        highlightOnHover
                        striped
                        customStyles={customStyles}
                        responsive
                        progressPending={loading}
                        progressComponent={
                            <div className="d-flex align-items-center p-5">
                                <div
                                    className="spinner-border text-primary"
                                    style={{ width: '3rem', height: '3rem' }}
                                    role="status"
                                >
                                    <span className="sr-only mt-5"></span>
                                </div>
                                <h5 className="">Loading Data...</h5>
                            </div>
                        }
                    />
                </div>
            </div>
        </>
    )
}

export default Feedback
