import { Response } from 'miragejs'
import ApiService from './ApiService'
import 'react-toastify/dist/ReactToastify.css'

export async function apiSignIn(data) {
    if (data?.token) {
        try {
            let resp = await fetch(
                `${process.env.REACT_APP_BASE_URL}/getredirectuser/`,
                {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                        'Content-Type': 'application/json',

                        'Access-Control-Allow-Origin': '*',
                        'access-control-allow-credentials': 'true',
                        'access-control-allow-origin': '*',
                    },
                }
            )
            resp = await resp.json()
            return resp
        } catch (err) {
            console.log(err)
        }
    } else {
        try {
            let resp = await fetch(
                `${process.env.REACT_APP_BASE_URL}/daybook/checkuser`,
                {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'access-control-allow-credentials': 'true',
                        'access-control-allow-origin': '*',
                    },
                }
            )
            resp = await resp.json()
            return resp
        } catch (err) {
            console.log(err)
        }
    }
}

export async function apiDeleteUser(data) {
    try {
        let resp = await fetch(
            `${process.env.REACT_APP_BASE_URL}/deleteuser/`,
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'access-control-allow-credentials': 'true',
                    'access-control-allow-origin': '*',
                },
            }
        )
        resp = await resp.json()
        return resp
    } catch (err) {
        console.log(err)
    }
}

export async function apiSignUp() {
    let data = {
        collection_name: 'users',
        database_name: 'superDatabase',
        query: {
            email: 'ishfaq@test.com',
            password: '12345678',
        },
    }
    return ApiService.fetchData({
        url: '/sign-up',
        method: 'post',
        data,
    })
}

export async function apiSignOut(data) {
    return ApiService.fetchData({
        url: '/sign-out',
        method: 'post',
        data,
    })
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: '/forgot-password',
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: '/reset-password',
        method: 'post',
        data,
    })
}
