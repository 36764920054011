import React, { useEffect } from 'react'
import classNames from 'classnames'
import { ScrollBar, Button } from 'components/ui'
import { Loading } from 'components/shared'
import Permissions from './RolesAndPermissions/Permissions'
import Roles from './RolesAndPermissions/Roles'
import Preferences from './Preferences/index'
import ReportScheduling from './ReportScheduling'
import Reminder from './Reminder/index'
import CompanySetting from './CompanySetting/index'
import Stock from './Stocks/IndexMain'
import { HiMenu, HiMenuAlt2 } from 'react-icons/hi'
import { useSelector, useDispatch } from 'react-redux'
import { getMails } from '../store/dataSlice'
import {
    toggleSidebar,
    toggleMobileSidebar,
    updateReply,
} from '../store/stateSlice'
import useResponsive from 'utils/hooks/useResponsive'
import { useLocation } from 'react-router-dom'

const htmlReg = /(<([^>]+)>)/gi

const ToggleButton = ({ sideBarExpand, mobileSidebarExpand }) => {
    const dispatch = useDispatch()

    const { smaller } = useResponsive()

    const onSideBarToggle = () => {
        dispatch(toggleSidebar(!sideBarExpand))
    }

    const onMobileSideBar = () => {
        dispatch(toggleMobileSidebar(!mobileSidebarExpand))
    }

    return (
        <Button
            icon={
                smaller.xl ? (
                    mobileSidebarExpand ? (
                        <HiMenu />
                    ) : (
                        <HiMenuAlt2 />
                    )
                ) : sideBarExpand ? (
                    <HiMenu />
                ) : (
                    <HiMenuAlt2 />
                )
            }
            onClick={smaller.xl ? onMobileSideBar : onSideBarToggle}
            size="sm"
            variant="plain"
            shape="circle"
        />
    )
}

const MailList = () => {
    const dispatch = useDispatch()
    const mails = useSelector((state) => state.crmMail.data.mailList)
    const mailId = useSelector((state) => state.crmMail.data.selectedMailId)
    const loading = useSelector((state) => state.crmMail.data.mailListLoading)
    const sideBarExpand = useSelector(
        (state) => state.crmMail.state.sideBarExpand
    )
    const mobileSidebarExpand = useSelector(
        (state) => state.crmMail.state.mobileSidebarExpand
    )
    const selectedCategory = useSelector(
        (state) => state.crmMail.state.selectedCategory
    )

    const direction = useSelector((state) => state.theme.direction)

    const location = useLocation()

    const fetchData = (data) => {
        dispatch(getMails(data))
    }

    useEffect(() => {
        const path = location.pathname.substring(
            location.pathname.lastIndexOf('/') + 1
        )
        const category = { category: path }

        if (path === 'mail') {
            category.category = 'inbox'
        }

        fetchData(category)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])

    return (
        <div
            className={classNames(
                'min-w-[360px] ease-in-out duration-300 relative flex flex-auto flex-col ltr:border-r rtl:border-l border-gray-200 dark:border-gray-600',
                sideBarExpand && 'ltr:xl:ml-[280px] rtl:xl:mr-[280px]',
                mailId ? 'hidden xl:flex' : 'xs:flex'
            )}
        >
            <div className="relative mt-5 flex flex-0 items-center justify-between min-h-[55px] border-gray-200 dark:border-gray-600">
                <div className="flex items-center gap-1">
                    <ToggleButton
                        sideBarExpand={sideBarExpand}
                        mobileSidebarExpand={mobileSidebarExpand}
                    />
                    <h6>{selectedCategory.label}</h6>
                </div>
            </div>
            <ScrollBar autoHide direction={direction}>
                <Loading loading={loading}>
                    {selectedCategory.value === 'inbox' ? <Permissions /> : ''}
                    {selectedCategory.value === 'preference' ? (
                        <Preferences />
                    ) : (
                        ''
                    )}

                    {selectedCategory.value === 'reminder' ? <Reminder /> : ''}
                    {selectedCategory.value === 'stocks' ? <Stock /> : ''}
                    {selectedCategory.value === 'reportscheduling' ? (
                        <ReportScheduling />
                    ) : (
                        ''
                    )}
                    {selectedCategory.value === 'UserInvite' ? <Roles /> : ''}
                    {selectedCategory.value === 'companysetting' ? <CompanySetting /> : ''}
                </Loading>
            </ScrollBar>
        </div>
    )
}

export default MailList
