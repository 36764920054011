import React, { useEffect, useState } from 'react'
import SettingsPermission from './SettingsPermission'
import { Button, Spinner } from 'components/ui'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const Permissions = () => {
    const { user, companyData } = useSelector((state) => state.auth.user)
    var tokenData = JSON.parse(localStorage.getItem('setuFiToken'))
    const [companyPermission, setCompanyPermission] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [sale, setSale] = useState({
        invoice: {},
        creditNotes: {},
        receivables: {},
        customerPayments: {},
        delivaryChallan: {},
        salesOrder: {},
    })
    const getPermission = async () => {
        setIsLoading(true)
        let data = await fetch(
            `${process.env.REACT_APP_BASE_URL}/getallusers/superDatabase/users/${tokenData}`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${tokenData}`,
                    'Content-Type': 'application/json',

                    'Access-Control-Allow-Origin': '*',
                    'access-control-allow-credentials': 'true',
                    'access-control-allow-origin': '*',
                },
            }
        )
        data = await data.json()
        let filteredData = data?.find((item) => item?.email === user?.email)
        let company = filteredData?.companies?.find(
            (item) => item?.GUID === companyData?.GUID
        )
        setCompanyPermission({ ...company?.PERMISSIONS })
        setIsLoading(false)
    }

    useEffect(() => {
        getPermission()
    }, [companyData])

    const [purchase, setPurchase] = useState({
        bills: {},
        debitNotes: {},
        payables: {},
        vendorPayments: {},
        expenses: {},
        venderCredit: {},
        purchaseOrder: {},
    })

    const [items, setItems] = useState({
        items: {},
        inventoryAdjustment: {},
        warehouse: {},
        pricelist: {},
    })

    const [banking, setBanking] = useState({
        banking: {},
    })
    const [accountPermission, setAccountPermission] = useState({
        chartsofAccounts: {},
        journals: {},
        budget: {},
    })
    const [settingPermission, setSettingPermission] = useState([])

    const updatePermission = async () => {
        setIsLoading(true)
        let permission = {
            sale,
            purchase,
            items,
            banking,
            accountPermission,
            settingPermission,
        }
        let bodyData = {
            collection_name: 'users',
            database_name: 'superDatabase',
            token: tokenData,
            filter: {
                email: user?.email,
                'companies.GUID': companyData?.GUID,
            },
            fields: {
                PERMISSIONS: {
                    // SalesManager: permission.sale,
                    // AccountantAccess: permission.accountPermission,
                    // PurchaseManager: permission.purchase,
                    // Items: permission.items,
                    // Banking: permission.banking,
                    // AccountPermission: permission.accountPermission,
                    SettingPermission: permission.settingPermission,
                },
            },
        }
        try {
            let data = await fetch(
                `${process.env.REACT_APP_BASE_URL}/updatenestedfield/`,
                {
                    method: 'PUT',
                    body: JSON.stringify(bodyData),
                    headers: {
                        Authorization: `Bearer ${tokenData}`,
                        'Content-Type': 'application/json',

                        'Access-Control-Allow-Origin': '*',
                        'access-control-allow-credentials': 'true',
                        'access-control-allow-origin': '*',
                    },
                }
            )
            if (data.ok) {
                toast.success('Permissions Save successfully!')
            } else {
                toast.error('Error Permissions Data.')
            }
            data = await data.json()
        } catch (err) {
            console.log(err)
        } finally {
            getPermission()
            setIsLoading(false)
        }
    }

    //    ----------is this belons to preference--------

    // const updatePermission = async () => {
    //     setIsLoading(true)
    //     let permission = {
    //         sale,
    //         purchase,
    //         items,
    //         banking,
    //         accountPermission,
    //         settingPermission,
    //     }
    //     let bodyData = {
    //         collection_name: 'users',
    //         database_name: 'superDatabase',
    //         token: tokenData,
    //         filter: {
    //             email: user?.email,
    //             'companies.GUID': companyData?.GUID,
    //         },
    //         fields: {
    //             PERMISSIONS: {
    //                 // SalesManager: permission.sale,
    //                 // AccountantAccess: permission.accountPermission,
    //                 // PurchaseManager: permission.purchase,
    //                 // Items: permission.items,
    //                 // Banking: permission.banking,
    //                 // AccountPermission: permission.accountPermission,
    //                 SettingPermission: permission.settingPermission,
    //             },
    //         },
    //     }

    //     const requestBody = {
    //         email: user?.email,
    //         companyGuid: 'd880547f-6513-4444-9dc9-6f322687fcdc',
    //         companyData: {
    //             GENERALSETTINGS: {
    //                 OUTSTANDING: {
    //                     isRoundedoff: false,
    //                     isEncrypted: false,
    //                     isAttached: false,
    //                     noOfCopies: '1',
    //                     PDFAttachment: 'isAttached',
    //                     outstandingAmount: '300',
    //                     overduePeriod: '30',
    //                     overdueDays: '30',
    //                     companyReminderSwitch: true,
    //                 },
    //             },
    //         },
    //     }

    //     // Now you can use this requestBody object when making your HTTP request

    //     try {
    //         let data = await fetch(
    //             `${process.env.REACT_APP_BASE_URL}/daybook/updateCompantStatus?db=superDatabase`,
    //             {
    //                 method: 'POST',
    //                 body: JSON.stringify(requestBody),
    //                 headers: {
    //                     Authorization: `Bearer ${tokenData}`,
    //                     'Content-Type': 'application/json',
    //                     'Access-Control-Allow-Origin': '*',
    //                     'access-control-allow-credentials': 'true',
    //                     'access-control-allow-origin': '*',
    //                 },
    //             }
    //         )
    //         console.log(data)
    //         if (data.ok) {
    //             toast.success('Permissions Save successfully!')
    //         } else {
    //             toast.error('Error Permissions Data.')
    //         }
    //         data = await data.json()
    //     } catch (err) {
    //         console.log(err)
    //     } finally {
    //         getPermission()
    //         setIsLoading(false)
    //     }
    // }

    return (
        <div>
            {isLoading ? (
                <div class=" absolute z-10 rounded-md p-4 w-full h-screen   ">
                    <div class=" absolute z-10  rounded-md p-4 w-full h-screen   ">
                        <Spinner className="mx-[50%] my-[25%]" size="40px" />
                    </div>
                </div>
            ) : (
                <div className="">
                    {/* <SalesPermissions
                        setSale={setSale}
                        sale={sale}
                        companyPermission={companyPermission}
                    />
                    <PurchesPermission
                        setPurchase={setPurchase}
                        purchase={purchase}
                        companyPermission={companyPermission}
                    />
                    <ItemsPermission
                        setItemsPermission={setItems}
                        itemsPermission={items}
                        companyPermission={companyPermission}
                    />
                    <BankingPermission
                        setBankingPermission={setBanking}
                        bankingPermission={banking}
                        companyPermission={companyPermission}
                    />
                    <AcountPermission
                        setAccountPermission={setAccountPermission}
                        accountPermission={accountPermission}
                        companyPermission={companyPermission}
                    /> */}
                    <SettingsPermission
                        setSettingPermission={setSettingPermission}
                        companyPermission={companyPermission}
                    />
                    <div className="mt-5">
                        <Button
                            onClick={() => updatePermission()}
                            variant="solid"
                            block
                        >
                            SAVE
                        </Button>
                    </div>
                </div>
            )}
        </div>
    )
}
export default Permissions
