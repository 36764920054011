import React, { useEffect, useState } from 'react'
import Header from 'components/template/Header'
import SideBar from './Sidebar/Index'
import SideNavToggle from 'components/template/SideNavToggle'
import MobileNav from 'components/template/MobileNav'
import RoutingViewArea from '../../configs/SuperAdminRouting'
import UserDropdownSuperAdmin from 'components/template/UserDropdownSuperAdmin'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
    selectFinancialYear,
    selectManage,
    setTrialBalance,
    setLoading,
} from '../../store/auth/superAdminLicense'
import Select from 'react-select'

const SuperAdminSideHeaderbar = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const { selectedLicense, selectedManage, financialYear } = useSelector(
        (state) => state.auth.superAdmin
    )
    var tokenData = JSON.parse(localStorage.getItem('setuFiToken'))
    let bookFromYear = '' // Initialize bookFromYear as an empty string

    // Get the current year
    const currentYear = new Date().getFullYear()
    // if (
    //     selectedManage &&
    //     selectedManage.BOOKSFROM &&
    //     selectedManage.BOOKSFROM.length >= 4
    // ) {
    //     // Check if selectedManage and BOOKSFROM are defined and ensure that BOOKSFROM has at least 4 characters
    //     bookFromYear = selectedManage.BOOKSFROM.slice(0, 4) // Extract the year from the BOOKSFROM date
    // }
    if (
        selectedManage &&
        selectedManage.BooksFrom
        // &&
        // selectedManage.BooksFrom.length >= 4
    ) {
        // Check if selectedManage and BooksFrom are defined and ensure that BooksFrom has at least 4 characters
        bookFromYear = selectedManage.BooksFrom.slice(0, 4) // Extract the year from the BOOKSFROM date
    }
    // Now, you can use bookFromYear with the appropriate year value, or it will be an empty string if there was an issue.
    const [company, setCompany] = useState([])
    const [selectedCompany, setSelectedCompany] = useState({
        value: '',
        label: '',
    })

    // useEffect(() => {
    //     setCompany(selectedLicense?.companies)
    //     // console.log(' selectedLicense?.companies--->', selectedLicense?.companies)
    //     if (selectedLicense?.companies) {
    //         dispatch(selectManage(selectedLicense?.companies[0]))
    //         setSelectedCompany({
    //             value: selectedLicense?.companies[0]?.NAME,
    //             label: selectedLicense?.companies[0]?.NAME,
    //         })
    //     }
    // }, [selectedLicense])

    useEffect(() => {
        setCompany(selectedLicense?.companies)
        // console.log(' selectedLicense?.companies--->', selectedLicense?.companies)
        if (selectedLicense?.companies) {
            dispatch(selectManage(selectedLicense?.companies[0]))
            setSelectedCompany({
                value: selectedLicense?.companies[0]?.Name,
                label: selectedLicense?.companies[0]?.Name,
            })
        }
    }, [selectedLicense])
    // const companyOptions = company?.map((item) => ({
    //     value: item.NAME,
    //     label: item.NAME,
    // }))
    const companyOptions = company?.map((item) => ({
        value: item.Name,
        label: item.Name,
    }))

    const startYear = parseInt(bookFromYear)
    const yearOptions = []
    let defaultYear
    let defaultYear1
    for (let year = startYear; year <= currentYear; year++) {
        yearOptions.push({
            value: year.toString(),
            label: `${year}-${year + 1}`,
        })
        defaultYear = yearOptions[2]
    }
    useEffect(() => {
        dispatch(
            selectFinancialYear({
                label:`${bookFromYear}-${parseInt(bookFromYear) + 1}`,
            })
        )
    }, [])

    const initialSelectedYear = yearOptions.find(
        (option) => option.value === currentYear.toString()
    )

    const [selectedYear, setSelectedYear] = useState(
        initialSelectedYear || null
    )

    const getData = async (databaseName) => {
        dispatch(setLoading(true))
        try {
            let bodyData = {
                DATABASE: databaseName,
            }
            let resp = await fetch(
                `${process.env.REACT_APP_BASE_URL}/getTrialBalance/`,
                {
                    method: 'POST',
                    body: JSON.stringify(bodyData),
                    headers: {
                        Authorization: `Bearer ${tokenData}`,
                        'Content-Type': 'application/json',
                    },
                }
            )

            if (resp.ok) {
                const data = await resp.json()
                // setData(data)

                let currentAssets = data
                    ?.filter((i) => i?.NAME === 'CURRENT ASSETS')
                    ?.map((i) => i?.children)[0]
                let sundryDebtors = currentAssets
                    ?.filter((i) => i?.NAME === 'SUNDRY DEBTORS')
                    ?.map((i) => i?.children)[0]
                let currentLiability = data
                    ?.filter((i) => i?.NAME === 'CURRENT LIABILITIES')
                    ?.map((i) => i?.children)[0]
                let sundryCreditors = currentLiability
                    ?.filter((i) => i?.NAME === 'SUNDRY CREDITORS')
                    ?.map((i) => i?.children)[0]
                dispatch(
                    setTrialBalance({
                        sundryDebtors: sundryDebtors ? sundryDebtors : [],
                        sundryCreditors: sundryCreditors ? sundryCreditors : [],
                    })
                )
            } else {
                console.error('Request failed with status:', resp.status)
            }
        } catch (err) {
            console.error('Error:', err)
        } finally {
            // Set isLoading to false after data is fetched
            dispatch(setLoading(false))
        }
    }

    const selectCompany = (selectedOption) => {
        setSelectedCompany(selectedOption)
        const selectedValue = selectedOption ? selectedOption.value : ''
        const companyData = company.find((item) => item.Name === selectedValue)
        dispatch(selectManage(companyData))
        getData(companyData?.databaseName)
    }

    const selectYear = (selectedOption) => {
        setSelectedYear(selectedOption)
        // dispatch(selectFinancialYear(Number(selectedOption?.value)))
        dispatch(selectFinancialYear(selectedOption))
    }

    // useEffect(() => {
    //     dispatch(selectFinancialYear(selectedYear?.value))
    // }, [])

    useEffect(() => {
        dispatch(selectFinancialYear(selectedYear))
    }, [])

    const HeaderActionsStart = () => {
        return (
            <>
                <MobileNav />
                <SideNavToggle />
            </>
        )
    }

    const HeaderActionsMiddle = () => {
        return (
            <>
                {location.pathname.split('/')[1] === 'license' &&
                location.pathname.split('/').length > 2 ? (
                    <div className="flex gap-5">
                        <Select
                            value={selectedCompany}
                            options={companyOptions}
                            className="w-60"
                            placeholder="Select Company"
                            onChange={selectCompany}
                        />
                        <Select
                            value={selectedYear}
                            options={yearOptions}
                            className="w-40"
                            placeholder="Select Year"
                            onChange={selectYear}
                        />
                    </div>
                ) : null}
            </>
        )
    }

    const HeaderActionsEnd = () => {
        return (
            <>
                <UserDropdownSuperAdmin hoverable={false} />
            </>
        )
    }

    return (
        <div className="app-layout-modern flex flex-auto flex-col">
            <div className="flex flex-auto min-w-0">
                <SideBar />
                <div className="flex flex-col flex-auto min-h-screen min-w-0 relative w-full bg-white dark:bg-gray-800 border-l border-gray-200 dark:border-gray-700">
                    <Header
                        className="border-b border-gray-200 dark:border-gray-700"
                        headerEnd={<HeaderActionsEnd />}
                        headerMiddle={<HeaderActionsMiddle />}
                        headerStart={<HeaderActionsStart />}
                    />
                    <RoutingViewArea />
                </div>
            </div>
        </div>
    )
}

export default SuperAdminSideHeaderbar
