import React from 'react'
import { HiCurrencyRupee } from 'react-icons/hi'
import { GrMoney } from 'react-icons/gr'
import { SlCreditCard } from 'react-icons/sl'
import { GiReceiveMoney } from 'react-icons/gi'
import { TfiSave } from 'react-icons/tfi'
import { VscSaveAs } from 'react-icons/vsc'
import {Card} from "components/ui"

const Statistic = () => {
    return (
        <>
            <Card className="mt-5 mx-2">
                <div className="w-full grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-3">
                    <div className="bg-white dark:bg-gray-800 rounded py-5 pl-6  shadow">
                        <div className="text-gray-700 flex  items-center dark:text-gray-400">
                            <GrMoney className="text-3xl text-gray-800" />
                            <h3 className="font-normal ml-2 leading-4 text-gray-800 dark:text-gray-100 text-base">
                                Bank
                            </h3>
                        </div>
                        <div className=" pr-10 ">
                            <div className="flex items-end mt-2">
                                <div className="flex items-center  text-[14px] font-bold">
                                    <span>
                                        <HiCurrencyRupee className="text-indigo-700" />
                                    </span>{' '}
                                    <span className="m-2 text-indigo-700 dark:text-indigo-600 text-base leading-normal font-bold">
                                        457,66,8.00
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white dark:bg-gray-800 rounded py-5 pl-6  shadow">
                        <div className="text-gray-700 flex  items-center dark:text-gray-400">
                            <SlCreditCard className="text-3xl text-gray-800" />
                            <h3 className="font-normal ml-2 leading-4 text-gray-800 dark:text-gray-100 text-base">
                                Revenue
                            </h3>
                        </div>
                        <div className=" pr-10 ">
                            <div className="flex items-end mt-2">
                                <div className="flex items-center  text-[14px] font-bold">
                                    <span>
                                        <HiCurrencyRupee className="text-indigo-700" />
                                    </span>{' '}
                                    <span className="m-2 text-indigo-700 dark:text-indigo-600 text-base leading-normal font-bold">
                                        1,76,048.00
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white dark:bg-gray-800 rounded py-5 pl-6  shadow">
                        <div className="text-gray-700 flex  items-center dark:text-gray-400">
                            <GiReceiveMoney className="text-3xl text-gray-800" />
                            <h3 className="font-normal ml-2 leading-4 text-gray-800 dark:text-gray-100 text-base">
                                Expense
                            </h3>
                        </div>
                        <div className=" pr-10 ">
                            <div className="flex items-end mt-2">
                                <div className="flex items-center  text-[14px] font-bold">
                                    <span>
                                        <HiCurrencyRupee className="text-indigo-700" />
                                    </span>{' '}
                                    <span className="m-2 text-indigo-700 dark:text-indigo-600 text-base leading-normal font-bold">
                                        2,18,09,041.71
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white dark:bg-gray-800 rounded py-5 pl-6  shadow">
                        <div className="text-gray-700 flex  items-center dark:text-gray-400">
                            <TfiSave className="text-3xl text-gray-800" />
                            <h3 className="font-normal ml-2 leading-4 text-gray-800 dark:text-gray-100 text-base">
                                Cash
                            </h3>
                        </div>
                        <div className=" pr-10 ">
                            <div className="flex items-end mt-2">
                                <div className="flex items-center  text-[14px] font-bold">
                                    <span>
                                        <HiCurrencyRupee className="text-indigo-700" />
                                    </span>{' '}
                                    <span className="m-2 text-indigo-700 dark:text-indigo-600 text-base leading-normal font-bold">
                                        24,98,890.25
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white dark:bg-gray-800 rounded py-5 pl-6  shadow">
                        <div className="text-gray-700 flex  items-center dark:text-gray-400">
                            <VscSaveAs className="text-3xl text-gray-800" />
                            <h3 className="font-normal ml-2 leading-4 text-gray-800 dark:text-gray-100 text-base">
                                Total Outstanding
                            </h3>
                        </div>
                        <div className=" pr-10 ">
                            <div className="flex items-end mt-2">
                                <div className="flex items-center  text-[14px] font-bold">
                                    <span>
                                        <HiCurrencyRupee className="text-indigo-700" />
                                    </span>{' '}
                                    <span className="m-2 text-indigo-700 dark:text-indigo-600 text-base leading-normal font-bold">
                                        1,93,10,151.25
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        </>
    )
}

export default Statistic
