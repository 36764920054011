import React, { useState } from 'react'
import { Button, FormItem, FormContainer, Dialog } from 'components/ui'
import { Form, Formik } from 'formik'
import { useSelector } from 'react-redux'
const CreateRole = ({ getRoleData }) => {
    const [dialogIsOpen, setIsOpen] = useState(false)
    const [role, setRole] = useState('')
    var tokenData = JSON.parse(localStorage.getItem('setuFiToken'))
    const { user, companyData } = useSelector((state) => state.auth.user)

    const [roleDetails, setRoleDetails] = useState({
        roleName: '',
        description: '',
    })

    const openDialog = () => {
        setIsOpen(true)
    }

    const onDialogClose = (e) => {
        e.preventDefault()
        setIsOpen(false)
    }

    const onDialogOk = (e) => {
        e.preventDefault()
        setIsOpen(false)
    }

    // const submitData = async () => {
    //     let t = {}
    //     t[role] = role
    //     let bodyData = {
    //         collection_name: 'users',
    //         database_name: 'superDatabase',
    //         token: tokenData,
    //         filter: {
    //             email: user?.email,
    //             'companies.GUID': companyData.GUID,
    //         },
    //         fields: {
    //             roles: t,
    //         },
    //     }
    //     try {
    //         let data = await fetch(
    //             `${process.env.REACT_APP_BASE_URL}/updatenestedfield/`,
    //             {
    //                 method: 'PUT',
    //                 body: JSON.stringify(bodyData),
    //                 headers: {
    //                     Authorization: `Bearer ${tokenData}`,
    //                     'Content-Type': 'application/json',

    //                     'Access-Control-Allow-Origin': '*',
    //                     'access-control-allow-credentials': 'true',
    //                     'access-control-allow-origin': '*',
    //                 },
    //             }
    //         )
    //         data = await data.json()
    //         getRoleData()
    //     } catch (err) {
    //         console.log(err)
    //     } finally {
    //         setIsOpen(false)
    //     }
    // }

    const submitData = async () => {
        let t = {}
        t[role] = role
        let bodyData = {
            collection_name: 'users',
            database_name: 'superDatabase',
            token: tokenData,
            filter: {
                email: user?.email,
                'companies.GUID': companyData.GUID,
            },
            fields: {
                roles: t,
            },
        }
        try {
            let data = await fetch(
                `${process.env.REACT_APP_BASE_URL}/updatenestedfield/`,
                {
                    method: 'PUT',
                    body: JSON.stringify(bodyData),
                    headers: {
                        Authorization: `Bearer ${tokenData}`,
                        'Content-Type': 'application/json',

                        'Access-Control-Allow-Origin': '*',
                        'access-control-allow-credentials': 'true',
                        'access-control-allow-origin': '*',
                    },
                }
            )
            data = await data.json()
            getRoleData()
        } catch (err) {
            console.log(err)
        } finally {
            setIsOpen(false)
        }
    }

    const resetData = (e) => {
        e.preventDefault()
        setRoleDetails({
            roleName: '',
            description: '',
        })
    }
    return (
        <div>
            <Button variant="solid" onClick={() => openDialog()}>
                Create Role
            </Button>
            <Dialog
                isOpen={dialogIsOpen}
                onClose={onDialogClose}
                onRequestClose={onDialogClose}
            >
                <h5 className="mb-4">Create Role</h5>

                <Formik>
                    <Form>
                        <FormContainer>
                            <FormItem label="Role Name">
                                <input
                                    className="w-full px-3 border-2 border-grey-600 rounded py-3"
                                    type="text"
                                    autoComplete="off"
                                    name="roleName"
                                    placeholder="Role Name"
                                    value={roleDetails.roleName}
                                    onChange={(e) =>
                                        setRoleDetails({
                                            ...roleDetails,
                                            roleName: e.target.value,
                                        })
                                    }
                                />
                            </FormItem>
                            <FormItem label="Role Description">
                                <input
                                    className="w-full px-3 border-2 border-grey-600 rounded py-3"
                                    type="text"
                                    autoComplete="off"
                                    name="description"
                                    value={roleDetails.description}
                                    placeholder="Role Description"
                                    onChange={(e) =>
                                        setRoleDetails({
                                            ...roleDetails,
                                            description: e.target.value,
                                        })
                                    }
                                />
                            </FormItem>

                            {/* <FormItem> */}
                            <Button
                                className="ltr:mr-2 rtl:ml-2"
                                onClick={(e) => onDialogOk(e)}
                            >
                                Cancel
                            </Button>
                            <Button
                                className="ltr:mr-2 rtl:ml-2"
                                onClick={(e) => resetData(e)}
                            >
                                Reset
                            </Button>
                            <Button
                                type="button"
                                variant="solid"
                                onClick={() => submitData()}
                            >
                                Create
                            </Button>
                            {/* </FormItem> */}
                        </FormContainer>
                    </Form>
                </Formik>
            </Dialog>
        </div>
    )
}
export default CreateRole
