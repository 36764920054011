import React from 'react'
import ReportsHeader from './ReportsHeader';
import Report from './Report';
const Index = () => {

    return (
        <>
            <div className="w-full h-full bg-gray-200">
                <Report/>
            </div>
        </>
    )
}

export default Index