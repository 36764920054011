import React, { useEffect, useMemo, useState } from 'react'
import { Table, Card, Spinner } from 'components/ui'
import { MdDeleteOutline } from 'react-icons/md'
import { MdOutlineEditOff } from "react-icons/md";
import InviteUser from '../InviteUser'
import EditUser from '../EditUser'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { MdEdit } from 'react-icons/md'
import { MdOutlineModeEdit } from 'react-icons/md'
import { MdDelete } from 'react-icons/md'
import { AiOutlineDelete } from 'react-icons/ai'
import { Button, FormItem, FormContainer, Dialog, Select } from 'components/ui'
import { Form, Formik } from 'formik'
import 'react-toastify/dist/ReactToastify.css'
import CreateRole from '../CreateRole'
import { redirect } from 'react-router-dom'
import UsersRolePermissionUpdate from './UsersRolePermissionUpdate'

const { Tr, Th, Td, THead, TBody } = Table

const RoleMaster = ({ Edit, getRedirect }) => {
    const { user, companyData } = useSelector((state) => state.auth.user)
    var tokenData = JSON.parse(localStorage.getItem('setuFiToken'))
    const [dialogIsOpen, setIsOpen] = useState(false)
    const [disabledButton, setDisabledButton] = useState(false)
    const [customers, setCustomers] = useState([])
    const [allRolesDropdown, setAllRolesDropdown] = useState(null)
    const [userEmail, setUserEmail] = useState('')
    const [userRole, setUserRole] = useState('')
    const [getSingleRoleData, setGetSingleRoleData] = useState(null)

    const [detail, setDetail] = useState({
        email: userEmail,
        role: '',
        ledgers: [],
    })
    const openDialog = (email, roleName) => {
        setUserEmail(email)
        setUserRole(roleName)
        setDetail({
            ...detail,
            email: email,
            role: roleName,
        })
        setIsOpen(true)
    }

    const onDialogClose = (e) => {
        e.preventDefault()
        setIsOpen(false)
    }
    const closeBox = () => {
        setIsOpen(false)
    }

    const resetData = () => {
        setDetail({
            // email: '',
            role: userRole,
        })
    }

    const [isLoading, setIsLoading] = useState(false)
    const [roleData, setRoleData] = useState([])
    const [roles, setRoles] = useState(null)
    const [roleId, setRoleId] = useState()

    const getRoles = async () => {
        try {
            const dbId = companyData?.databaseName
            setIsLoading(true)
            let resp = await fetch(
                `${process.env.REACT_APP_BASE_URL}/rolePermission/getAllroles?dbName=${dbId}`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${tokenData}`,
                        'Content-Type': 'application/json',

                        'Access-Control-Allow-Origin': '*',
                        'access-control-allow-credentials': 'true',
                        'access-control-allow-origin': '*',
                    },
                }
            )
            resp = await resp.json()
            const filteredData = resp.roles.filter(
                (x) => x.Action.IsDelete === false
            )
            setRoles(filteredData)
        } catch (err) {
            console.log(err)
        } finally {
            setIsLoading(false)
            getRedirect(false)
        }
    }

    const DeleteRole = async (id,roleName) => {
        try {
            const dbId = companyData?.databaseName
            const userEmail = user.email;
            const bodyData = {
                dbName: dbId,
                id: id,
                roleName:roleName,
                adminEmail : userEmail
            }
            setIsLoading(true)
            let resp = await fetch(
                `${process.env.REACT_APP_BASE_URL}/rolePermission/softdelete`,
                {
                    method: 'POST',
                    body: JSON.stringify(bodyData),
                    headers: {
                        Authorization: `Bearer ${tokenData}`,
                        'Content-Type': 'application/json',

                        'Access-Control-Allow-Origin': '*',
                        'access-control-allow-credentials': 'true',
                        'access-control-allow-origin': '*',
                    },
                }
            )
            resp = await resp.json()
            // toast.success(resp?.message)
            if(resp?.message === 'Role soft deleted successfully.'){
                toast.success(resp?.message)
            }else{
                toast.error(resp?.message)
            }
            getRoles()
            setIsLoading(true)
        } catch (err) {
            console.log(err)
            toast.error('Error from Server side.')
        } finally {
            getRoles()
        }
    }

    const getRoleData = async () => {
        let data = await fetch(
            `${process.env.REACT_APP_BASE_URL}/getallusers/superDatabase/users/${tokenData}`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${tokenData}`,
                    'Content-Type': 'application/json',

                    'Access-Control-Allow-Origin': '*',
                    'access-control-allow-credentials': 'true',
                    'access-control-allow-origin': '*',
                },
            }
        )
        data = await data.json()
        let filteredData = data?.find((item) => item?.email === user?.email)
        let company = filteredData?.companies?.find(
            (item) => item?.GUID === companyData?.GUID
        )
        let convertArr = []
        for (var key in company?.roles) {
            var value = company?.roles[key]
            convertArr.push(value)
        }
        setRoleData([...convertArr])
    }

    const getAllRolesDropdown = async () => {
        try {
            const dbId = companyData?.databaseName
            const bodyData = {
                dbName: dbId,
            }
            let resp = await fetch(
                `${process.env.REACT_APP_BASE_URL}/rolePermission/getAll`,
                {
                    method: 'POST',
                    body: JSON.stringify(bodyData),
                    headers: {
                        Authorization: `Bearer ${tokenData}`,
                        'Content-Type': 'application/json',

                        'Access-Control-Allow-Origin': '*',
                        'access-control-allow-credentials': 'true',
                        'access-control-allow-origin': '*',
                    },
                }
            )
            resp = await resp.json()
            setAllRolesDropdown(resp.rolePermissions)
        } catch (err) {
            console.log(err)
        } finally {
        }
    }

    const updateUserRole = async (e) => {
        e.preventDefault()
        try {
            const dbId = companyData?.databaseName
            const bodyData = {
                dbName: dbId,
                email: detail.email,
                roleName: detail.role,
            }
            setIsLoading(true)
            let resp = await fetch(
                `${process.env.REACT_APP_BASE_URL}/userInvitation/updateRoleByEmail`,
                {
                    method: 'PUT',
                    body: JSON.stringify(bodyData),
                    headers: {
                        Authorization: `Bearer ${tokenData}`,
                        'Content-Type': 'application/json',

                        'Access-Control-Allow-Origin': '*',
                        'access-control-allow-credentials': 'true',
                        'access-control-allow-origin': '*',
                    },
                }
            )
            resp = await resp.json()
            toast.success(resp?.message)
            setIsOpen(false)
            getRoles()
            setIsLoading(true)
            // setRoles(resp.invitations)
        } catch (err) {
            console.log(err)
            toast.error('Error from Server side.')
        } finally {
            getRoles()
            setIsLoading(false)
        }
    }

    // const deleteRoles = async (Email) => {
    //     try {
    //         const { value } = await Swal.fire({
    //             title: 'Are you sure?',
    //             text: 'You want to delete this file?',
    //             icon: 'warning',
    //             showCancelButton: true,
    //             confirmButtonColor: '#3085d6',
    //             cancelButtonColor: '#d33',
    //             confirmButtonText: 'Yes!',
    //         })
    //         if (value) {
    //             let resp = await fetch(
    //                 `${process.env.REACT_APP_BASE_URL}/deleteTeam?ADMINEMAIL=${user?.email}&TEAMEMAIL=${Email}`,
    //                 {
    //                     method: 'DELETE',
    //                     headers: {
    //                         Authorization: `Bearer ${tokenData}`,
    //                         'Content-Type': 'application/json',

    //                         'Access-Control-Allow-Origin': '*',
    //                         'access-control-allow-credentials': 'true',
    //                         'access-control-allow-origin': '*',
    //                     },
    //                 }
    //             )
    //             toast.success('Role Delete successfully!')
    //             getRoles()
    //         }
    //     } catch (e) {
    //         toast.error('Error from Server side.')
    //     } finally {
    //         getRoles()
    //     }
    // }

    useEffect(() => {
        Edit('', 'tab1', 'false')
        getRoles()
    }, [])

    const getSingleRoleId = async (id) => {
        Edit(id, 'tab3', 'true')
        // try {
        //     const dbId = companyData?.databaseName
        //     console.log(dbId)
        //     setIsLoading(true)
        //     const bodyData = {
        //         dbName: dbId,
        //         id: id,
        //     }
        //     let resp = await fetch(
        //         `${process.env.REACT_APP_BASE_URL}/rolePermission/getSingleRole`,
        //         {
        //             method: 'POST',
        //             body: JSON.stringify(bodyData),
        //             headers: {
        //                 Authorization: `Bearer ${tokenData}`,
        //                 'Content-Type': 'application/json',
        //                 'Access-Control-Allow-Origin': '*',
        //                 'access-control-allow-credentials': 'true',
        //                 'access-control-allow-origin': '*',
        //             },
        //         }
        //     )
        //     resp = await resp.json()
        //     setGetSingleRoleData(resp)
        // } catch (err) {
        //     console.log(err)
        // } finally {
        //     setIsLoading(false)
        // }
    }

    useEffect(() => {
        getRoles()
        // getAllRolesDropdown()
    }, [companyData])

    return (
        <div>
            <Card>
                {isLoading ? (
                    <div class="relative items-center block h-screen p-6 bg-slate-100 border border-gray-100 rounded-lg shadow-md bg-blur backdrop-filter backdrop-blur-sm backdrop-opacity-10">
                        <div
                            role="status"
                            class="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
                        >
                            <Spinner
                                className="mx-[50%] my-[25%]"
                                size="40px"
                            />
                        </div>
                    </div>
                ) : (
                    <Card>
                        <div className="flex justify-end gap-3">
                            {/* <CreateRole getRoleData={getRoleData} /> */}
                        </div>
                        <div className="mt-4">
                            <Table>
                                <THead>
                                    <Tr>
                                        <Th>ROLE NAME</Th>
                                        <Th>DESCRIPTION</Th>
                                        {!user?.superAdminEmail ? (
                                            <Th>EDIT USER ROLE</Th>
                                        ) : null}
                                        {!user?.superAdminEmail ? (
                                            <Th>DELETE</Th>
                                        ) : null}
                                    </Tr>
                                </THead>

                                <TBody>
                                    {roles?.map((value, index) => (
                                        <Tr key={index}>
                                            <Td>{value?.roleName}</Td>
                                            <Td>{value?.description}</Td>
                                            <Td>
                                                {value?.roleName !==
                                                    'Sales Access' &&
                                                value?.roleName !==
                                                    'Purchase Access' &&
                                                value?.roleName !==
                                                    'Admin Rights' ? (
                                                    <div className="flex relative left-[40px] w-[25px]">
                                                        <MdOutlineModeEdit
                                                            size="20px"
                                                            color="blue"
                                                            className="cursor-pointer"
                                                            // onClick={() => {
                                                            //     var email =
                                                            //         value?.email
                                                            //     var roleName =
                                                            //         value?.roleName
                                                            //     openDialog(
                                                            //         email,
                                                            //         roleName
                                                            //     )
                                                            // }}
                                                            onClick={() => {
                                                                var id =
                                                                    value._id
                                                                getSingleRoleId(
                                                                    id
                                                                )
                                                            }}
                                                        />
                                                        <div className="hidden">
                                                            <UsersRolePermissionUpdate
                                                                singleRoleData={
                                                                    getSingleRoleData
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                ) : (
                                                    // <p className="text-blue-600">
                                                    //     Can't Update
                                                    // </p>
                                                    <div className='flex relative left-[40px] w-[25px]'>
                                                        <MdOutlineModeEdit size="20px"
                                                            color="blue"
                                                            className="cursor-not-allowed"/>
                                                    </div>
                                                )}
                                            </Td>
                                            <Td>
                                                {value?.roleName !==
                                                    'Sales Access' &&
                                                value?.roleName !==
                                                    'Purchase Access' &&
                                                value?.roleName !==
                                                    'Admin Rights' ? (
                                                    <div className="flex relative left-[15px] w-[25px]">
                                                        <AiOutlineDelete
                                                            size="20px"
                                                            color="red"
                                                            className="cursor-pointer"
                                                            onClick={() => {
                                                                var id =
                                                                    value._id
                                                                var roleName = value.roleName
                                                                DeleteRole(id,roleName)
                                                            }}
                                                        />
                                                    </div>
                                                ) : (
                                                    // <p className="text-red-600">
                                                    //     Can't Delete
                                                    // </p>
                                                    <div className='flex relative left-[15px] w-[25px]'>
                                                        <AiOutlineDelete size="20px"
                                                            color="red"
                                                            className="cursor-not-allowed"/>
                                                    </div>
                                                )}
                                            </Td>
                                        </Tr>
                                    ))}
                                </TBody>
                            </Table>
                        </div>
                    </Card>
                )}
            </Card>
        </div>
    )
}

export default RoleMaster
