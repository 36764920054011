import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component'
import { useSelector } from 'react-redux'
import { FaAngleDown, FaAngleUp, FaFileExport } from 'react-icons/fa'
import { CSVLink } from 'react-csv'

const Payables = () => {
    let tokenData = JSON.parse(localStorage.getItem('setuFiToken'))
    const [startYear, setStartYear] = useState(0)
    const [endYear, setEndYear] = useState(0)
    const { selectedManage, financialYear, trialBalance, loading } =
        useSelector((state) => state.auth.superAdmin)
    const { sundryCreditors } = trialBalance
    const [currentSummary, setCurrentSummary] = useState('detailed') // Initialize with Detailed Summary
    const [payables, setPayables] = useState([])
    const [receivablesData, setReceivableData] = useState([])
    const [showDropdown, setShowDropdown] = useState(false)
    const [selectedColumns, setSelectedColumns] = useState([
        'Supplier Name',
        'Voucher No.',
        'Total Amount',
        'Received Amount',
        'Outstanding',
        'Credit',
        'Debit',
        'Closing Balance',
        'Opening Balance',
    ])
    // useEffect(() => {
    //     // getChartsData()
    //     // getTrialBalance()
    // }, [selectedManage, financialYear])

    useEffect(() => {
        // getChartsData()
        // getTrialBalance()
        const [startYearStr, endYearStr] = financialYear.label.split('-')
        setStartYear(parseInt(startYearStr))
        setEndYear(parseInt(endYearStr))
    }, [financialYear])
    const getAllPayables = async () => {
        try {
            const reqBody = {
                from: startYear,
                till: endYear,
                isReceivables: false,

                db: selectedManage?.databaseName,
                // '9d247014c38370277916e48dcaacc667',
            }
            const response = await fetch(
                // `${process.env.REACT_APP_BASE_URL}/transcation/getAllReceivablesPayables`,
                `${process.env.REACT_APP_BASE_URL}/transcation/getAlltotalReceivablesPayables`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${tokenData}`,
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'access-control-allow-credentials': 'true',
                        'access-control-allow-origin': '*',
                    },
                    body: JSON.stringify(reqBody),
                }
            )

            if (!response.ok) {
                throw new Error('Failed to fetch receivables')
            }

            const data = await response.json()
            // const filteredData = data?.result?.slice(0, data?.result?.length - 2);
            const onlyVendorData = data?.result?.filter((x) => x.isCustomer === false)

            const slicedData = onlyVendorData.slice(0, data?.result?.length - 2);

            setPayables(slicedData)
        } catch (error) {
            console.error('Error fetching receivables:', error.message)
        }
    }

    useEffect(() => {
        getAllPayables()
    }, [selectedManage, endYear])


    function extractData(row, type) {
        let remaindersettingsObj = row?.INFO?.REMAINDERSETTINGS

        if (Object.keys(row?.INFO?.REMAINDERSETTINGS).length) {
            if (type === 'email') {
                return remaindersettingsObj?.contacts[0]?.EMAIL
                    ? remaindersettingsObj?.contacts[0]?.EMAIL
                    : '-'
            } else if (type === 'phone') {
                return remaindersettingsObj?.contacts[0]?.PRIMARYNUMBER
                    ? remaindersettingsObj?.contacts[0]?.PRIMARYNUMBER
                    : '-'
            }
        } else {
            return '-'
        }
    }

    function getDebitCreditNet(data) {
        const totals = [] // An array to store the calculated totals for each top-level parent

        // A helper function that calculates the totals for a parent node
        function sumAmounts(parent) {
            let totalPositive = 0 // Initialize the total positive amount
            let totalNegative = 0 // Initialize the total negative amount

            // A recursive function to traverse the data structure and calculate totals
            function recursiveSum(data) {
                if (data.data && Array.isArray(data.data)) {
                    // If there is a 'data' property and it's an array, process its items
                    for (const dataItem of data.data) {
                        if (dataItem.AMOUNT) {
                            const amount = parseFloat(dataItem.AMOUNT)
                            if (!isNaN(amount)) {
                                if (amount > 0) {
                                    totalPositive += amount // Add positive amounts
                                } else {
                                    totalNegative += amount // Add negative amounts
                                }
                            }
                        }
                    }
                }

                if (data.children && Array.isArray(data.children)) {
                    // If there are 'children' and they're in an array, recursively process them
                    for (const child of data.children) {
                        recursiveSum(child)
                    }
                }
            }

            // Call the recursiveSum function to calculate totals for the given parent
            recursiveSum(parent)

            // Calculate the totalNet as the sum of totalPositive and totalNegative
            const totalNet = (totalPositive + totalNegative).toFixed(2)

            return {
                totalPositive: totalPositive.toFixed(2), // Format to 2 decimal places
                totalNegative: Math.abs(totalNegative).toFixed(2), // Format to 2 decimal places, and make it positive
                totalNet,
            }
        }

        // Loop through each top-level parent in the data array
        data?.forEach((parent) => {
            const { totalPositive, totalNegative, totalNet } =
                sumAmounts(parent)

            // Add the calculated totals and the parent's GUID to the 'totals' array
            totals.push({
                totalPositive,
                totalNegative,
                totalNet,
            })
        })

        // Return the array containing the calculated totals for all top-level parents
        return totals
    }

    function formatIndianCurrency(amount) {
        const formatter = new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            minimumFractionDigits: 2,
        })

        return formatter.format(amount)
    }

    const columns1 = [
        // Columns for Detailed Summary
        {
            name: 'Supplier Name',
            selector: 'PartyLedgerName',
            width: "200px",
            sortable: true,
            cell: (row) => row?.PartyLedgerName,
        },
        {
            name: 'Voucher No.',
            selector: 'VoucherNo',
            sortable: true,
            cell: (row) => row?.VoucherNo,
        },
        {
            name: 'Total Amount',
            selector: 'TotalAmount',
            width: "150px",
            sortable: true,
            cell: (row) =>
                row?.TotalAmount < 0
                    ? `${formatIndianCurrency(-1 * row?.TotalAmount)} Dr.`
                    : `${formatIndianCurrency(row?.TotalAmount)} Cr.`,
        },
        // {
        //     name: 'Received Amount',
        //     selector: 'ReceivedAmount',
        //     width: "150px",
        //     sortable: true,
        //     // cell: (row) => row?.ReceivedAmount,
        //     cell: (row) =>
        //         row?.ReceivedAmount < 0
        //             ? `${formatIndianCurrency(-1 * row?.ReceivedAmount)} Dr.`
        //             : `${formatIndianCurrency(row?.ReceivedAmount)} Cr.`,
        // },
        {
            name: 'Outstanding',
            selector: 'Outstanding',
            width: "150px",
            sortable: true,
            // cell: (row) => row?.Outstanding,
            cell: (row) =>
                row?.Outstanding < 0
                    ? `${formatIndianCurrency(-1 * row?.Outstanding)} Dr.`
                    : `${formatIndianCurrency(row?.Outstanding)} Cr.`,
        },
        {
            name: 'Credit',
            selector: 'totalCredit',
            width: "150px",
            sortable: true,
            cell: (row) =>
                `${formatIndianCurrency(row?.totalCredit)}`
        },
        {
            name: 'Debit',
            selector: 'totalDebit',
            width: "150px",
            sortable: true,
            cell: (row) =>
                `${formatIndianCurrency(row?.totalDebit)}`,
        },
        {
            name: 'Closing Balance',
            selector: 'closingBalance',
            width: "150px",
            sortable: true,
            cell: (row) =>
                row?.closingBalance < 0
                    ? `${formatIndianCurrency(-1 * row?.closingBalance)} Dr.`
                    : `${formatIndianCurrency(row?.closingBalance)} Cr.`,
        },
        {
            name: 'Opening Balance',
            selector: 'openingBalance',
            width: "150px",
            sortable: true,
            cell: (row) =>
                row?.openingBalance < 0
                    ? `${formatIndianCurrency(-1 * row?.openingBalance)} Dr.`
                    : `${formatIndianCurrency(row?.openingBalance)} Cr.`,
        },

        // {
        //     name: 'NAME',
        //     selector: 'NAME',
        //     sortable: true,
        //     cell: (row) => row?.NAME,
        // },
        // {
        //     name: 'ADDRESS',
        //     selector: (row) => row['INFO'],
        //     sortable: true,
        //     cell: (row) =>
        //         row?.INFO?.['LEDMAILINGDETAILS.LIST']?.['ADDRESS.LIST']?.ADDRESS
        //             ? row?.INFO?.['LEDMAILINGDETAILS.LIST']?.['ADDRESS.LIST']
        //                   ?.ADDRESS
        //             : '-',
        // },
        // {
        //     name: 'PHONE',
        //     selector: (row) => row['PHONE'],
        //     sortable: true,
        //     cell: (row) => extractData(row, 'phone'),
        // },
        // {
        //     name: 'EMAIL',
        //     selector: (row) => row['EMAIL'],
        //     sortable: true,
        //     cell: (row) => extractData(row, 'email'),
        // },
        // {
        //     name: 'GST',
        //     selector: (row) => row['PARTYGSTIN'],
        //     sortable: true,
        //     cell: (row) =>
        //         row?.INFO?.PARTYGSTIN ? row?.INFO?.PARTYGSTIN : '-',
        // },

        // {
        //     name: 'DEBIT',
        //     // selector: (row) => row['AVGBILLCREDITPERIOD'],
        //     sortable: true,
        //     cell: (row) => `${getDebitCreditNet([row])[0]?.totalNegative}`,
        // },
        // {
        //     name: 'CREDIT',
        //     // selector: (row) => row['AVGBILLCREDITPERIOD'],
        //     sortable: true,
        //     cell: (row) => `${getDebitCreditNet([row])[0]?.totalPositive}`,
        // },

        // {
        //     name: 'CLOSING BALANCE',
        //     // selector: (row) => row['AVGBILLCREDITPERIOD'],
        //     sortable: true,
        //     cell: (row) =>
        //         getDebitCreditNet([row])[0]?.totalNet < 0
        //             ? `${Math.abs(getDebitCreditNet([row])[0]?.totalNet)} Dr`
        //             : `${getDebitCreditNet([row])[0]?.totalNet} Cr`,
        // },
    ]

    const columns2 = [
        // Columns for Ageing Summary
        {
            name: 'COMPANY NAME',
            selector: (row) => row['PARTYLEDGERNAME'],
            sortable: true,
            cell: (row) => row?.PARTYLEDGERNAME,
        },
        {
            name: 'PAYABLE',
            selector: (row) => row['OUTSTANDING'],
            sortable: true,
            cell: (row) => `+${Math.abs(row?.OUTSTANDING)?.toFixed(2)}`,
        },
        {
            name: '1-15 DAYS',
            selector: (row) => row['15'],
            sortable: true,
            cell: (row) => `+${Math.abs(row?.DAYS[15])?.toFixed(2)}`,
        },
        {
            name: '16-30 DAYS',
            selector: (row) => row['30'],
            sortable: true,
            cell: (row) => `+${Math.abs(row?.DAYS[30])?.toFixed(2)}`,
        },
        {
            name: '31-45 DAYS',

            selector: (row) => row['45'],
            sortable: true,
            cell: (row) => `+${Math.abs(row?.DAYS[45])?.toFixed(2)}`,
        },
        {
            name: '>45 DAYS',
            selector: (row) => row['60'],
            sortable: true,
            cell: (row) => `+${Math.abs(row?.DAYS[60])?.toFixed(2)}`,
        },
        // {
        //   name: 'TOTAL DAYS',
        //   selector: 'DAYS',
        //   sortable: true,
        //   cell: (row) => row?.DAYS,
        // },
    ]

    const tableCustomStyles = {
        headRow: {
            style: {
                color: '#FFFFFF',
                backgroundColor: '#133386',
            },
        },
        rows: {
            style: {
                color: '#000000',
                backgroundColor: '#8E9DC420',
            },
            stripedStyle: {
                color: '#000000',
                backgroundColor: '#FFFFFF',
            },
        },
    }

    const toggleSummary = () => {
        // Toggle between Detailed and Ageing Summary
        setCurrentSummary(currentSummary === 'detailed' ? 'ageing' : 'detailed')
    }

    const summaryColumns = currentSummary === 'detailed' ? columns1 : columns2

    // const exportableData = [
    //     [
    //         'Supplier Name',
    //         'Address',
    //         'Phone',
    //         'Email',
    //         'Gst',
    //         'Debit',
    //         'Credit',
    //         'Closing Balance',
    //     ],
    //     ...sundryCreditors.map((item) => {
    //         const {
    //             NAME,
    //             INFO: {
    //                 PARTYGSTIN,
    //                 'LEDMAILINGDETAILS.LIST': {
    //                     'ADDRESS.LIST': { ADDRESS } = {},
    //                 } = {},
    //                 REMAINDERSETTINGS: { contacts = [] } = {},
    //             } = {},
    //         } = item

    //         const primaryContact = contacts.find(
    //             (contact) => contact?.PRIMARYNUMBER
    //         )
    //         const emailContact = contacts.find((contact) => contact?.EMAIL)

    //         let DEBIT = getDebitCreditNet([item])[0]?.totalNegative || 0
    //         let CREDIT = getDebitCreditNet([item])[0]?.totalPositive || 0
    //         let NET = getDebitCreditNet([item])[0]?.totalNet || 0

    //         return [
    //             NAME || '',
    //             ADDRESS || '',
    //             primaryContact ? primaryContact.PRIMARYNUMBER || '' : '',
    //             emailContact ? emailContact.EMAIL || '' : '',
    //             PARTYGSTIN,
    //             `${DEBIT} `,
    //             `${CREDIT} `,
    //             NET < 0 ? `${Math.abs(NET)} Dr` : `${NET} Cr`,
    //         ]
    //     }),
    // ]

    const exportableData = () => {
        // Extract headers from the selected column names
        const headers = columns1
            .filter((column) => selectedColumns.includes(column.name))
            .map((column) => column.name);

        // Map over receivables to create the exportable data
        const data = payables?.map((item) => {
            return columns1
                .filter((column) => selectedColumns.includes(column.name)) // Filter selected columns
                .map((column) => {
                    // Extract the correct selector based on the column definition
                    let fieldValue;
                    if (typeof column.selector === 'function') {
                        // If the selector is a function, call it with the item (row) as an argument
                        fieldValue = column.selector(item);
                    } else {
                        // If the selector is a string, directly access the field in the item
                        fieldValue = item[column.selector];
                    }

                    // Handle array fields by returning the length, otherwise return the value
                    if (Array.isArray(fieldValue)) {
                        return fieldValue.length;
                    } else {
                        // Return the field value or an empty string if undefined or null
                        return fieldValue !== undefined && fieldValue !== null ? fieldValue : '';
                    }
                });
        });

        // Return headers as the first row followed by the data
        return [headers, ...data];
    };

    const filename = 'payables.csv'

    const handleColumnSelection = (columnName) => {
        setSelectedColumns((prevSelectedColumns) => {
            if (prevSelectedColumns.includes(columnName)) {
                return prevSelectedColumns.filter((col) => col !== columnName)
            } else {
                return [...prevSelectedColumns, columnName]
            }
        })
    }

    return (
        <div className="p-4">
            <div className="flex justify-between">
                <h2 className="text-xl font-semibold mb-4">
                    Supplier Information
                </h2>
                {/* <CSVLink data={exportableData} filename={filename}>
                    <button className="flex items-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                        <FaFileExport />
                        <p className="ml-1">Export</p>
                    </button>
                </CSVLink> */}
                <div>
                    <div>
                        <div className='flex items-center gap-2'>
                            <CSVLink data={exportableData()} filename={filename}>
                                <button
                                    style={{
                                        color: '#133386',
                                        fontWeight: 'bold',
                                    }}
                                    className="text-blue-500 text-sm flex items-center">
                                    <FaFileExport />
                                    <p className="ml-1">Export</p>
                                </button>
                            </CSVLink>
                            <button
                                style={{
                                    color: '#133386',
                                    fontWeight: 'bold',
                                }}
                                onClick={() => {
                                    setShowDropdown(!showDropdown)
                                }}
                                className="text-blue-500 text-sm flex items-center"
                            >
                                {showDropdown ? (
                                    <FaAngleUp className="mr-1" />
                                ) : (
                                    <FaAngleDown className="mr-1" />
                                )}
                                Expand columns
                            </button>
                        </div>
                        {showDropdown && (
                            <div className="absolute right-0 mt-2 bg-white border border-gray-300 rounded shadow-lg p-2  z-20 w-80">
                                <div className="grid grid-cols-2 gap-4">
                                    <div>
                                        {columns1
                                            .slice(
                                                0,
                                                Math.ceil(
                                                    columns1.length /
                                                    2
                                                )
                                            )
                                            .map((col) => (
                                                <div
                                                    className="mr-4 mt-2"
                                                    key={col.name}
                                                >
                                                    {/* Use the custom checkbox within your component */}
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            className="custom-checkbox"
                                                            checked={selectedColumns.includes(
                                                                col.name
                                                            )}
                                                            onChange={() =>
                                                                handleColumnSelection(
                                                                    col.name
                                                                )
                                                            }
                                                        />
                                                    </label>

                                                    {col.name}
                                                </div>
                                            ))}
                                    </div>
                                    <div>
                                        {columns1
                                            .slice(
                                                Math.ceil(
                                                    columns1.length /
                                                    2
                                                )
                                            )
                                            .map((col) => (
                                                <div
                                                    className="mr-4 mt-2"
                                                    key={col.name}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        className="custom-checkbox"
                                                        checked={selectedColumns.includes(
                                                            col.name
                                                        )}
                                                        onChange={() =>
                                                            handleColumnSelection(
                                                                col.name
                                                            )
                                                        }
                                                    />

                                                    {col.name}
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="bg-white border rounded-lg overflow-hidden mt-5">
                {payables && (
                    <DataTable
                        columns={columns1.filter((col) =>
                            selectedColumns.includes(col.name)
                        )}
                        // columns={columns1}
                        data={payables}
                        pagination={true}
                        paginationPerPage={10}
                        noHeader={true}
                        className="text-gray-800"
                        customStyles={tableCustomStyles}
                        progressPending={loading}
                        progressComponent={
                            <div className="d-flex align-items-center p-5">
                                <div
                                    className="spinner-border text-primary"
                                    style={{ width: '3rem', height: '3rem' }}
                                    role="status"
                                >
                                    <span className="sr-only mt-5"></span>
                                </div>
                                <h5 className="">Loading Data...</h5>
                            </div>
                        }
                    />
                )}
            </div>
            {/* <div className="mt-4">
                <button
                    onClick={toggleSummary}
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                >
                    {currentSummary === 'detailed'
                        ? 'Ageing Summary'
                        : 'Detailed Summary'}
                </button>
            </div> */}
        </div>
    )
}

export default Payables
