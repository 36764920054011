import React, { useEffect, useState } from 'react'
import { Spinner, Switcher, Table } from 'components/ui'
import { Card } from 'components/ui'
import { Button } from 'components/ui'
import { useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const { Tr, Td, TBody } = Table
const UtilitySetting = () => {
    const { user, companyData } = useSelector((state) => state.auth.user)
    let tokenData = JSON.parse(localStorage.getItem('setuFiToken'))
    const [generalSetting, setGeneralSetting] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [utilitySetting, setUtilitySetting] = useState({
        ip: '',
        port: "",
        rangevalue: ""
    })
    const [utilitySettingData, setUtilitySettingData] = useState()

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUtilitySetting({
            ...utilitySetting,
            [name]: value
        })
    }

    const submitData = async (e) => {
        e.preventDefault()
        try {
            if (utilitySetting.rangevalue <= 0 || utilitySetting.rangevalue > 500) {
                toast.error("Range Value will be > 0 and <= 500")
                return
            }

            if (utilitySetting.port !== "" && utilitySetting.port <= 0) {
                toast.error("Port can be negative")
                return
            }

            const user_email = user?.email;
            const bodyData = {
                "email": user_email,
                "settings": {
                    // "ip": `http://${utilitySetting.ip}`,
                    "ip": utilitySetting.ip,
                    "port": utilitySetting.port,
                    "rangevalue": +utilitySetting.rangevalue
                }
            }
            let resp = await fetch(
                `${process.env.REACT_APP_BASE_URL}/daybook/updateUtilitySettings?db=superDatabase`,
                {
                    method: 'POST',
                    body: JSON.stringify(bodyData),
                    headers: {
                        Authorization: `Bearer ${tokenData}`,
                        'Content-Type': 'application/json',

                        'Access-Control-Allow-Origin': '*',
                        'access-control-allow-credentials': 'true',
                        'access-control-allow-origin': '*',
                    },
                }
            )
            if (resp.ok) {
                toast.success("Utility Setting Updated Successfully")
                setRefresh(!refresh)
                setUtilitySetting({
                    ip: '',
                    port: '',
                    rangevalue: ''
                })
            } else {
                toast.error("Try Again Later")
            }

        } catch (e) {
            console.log(e)
            toast.error('Error sending data.')
        }
    }

    const getUtilitySetting = async () => {
        try {
            const user_email = user?.email;
            let resp = await fetch(
                `${process.env.REACT_APP_BASE_URL}/daybook/getUtilitySettings/${user_email}?db=superDatabase`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${tokenData}`,
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'access-control-allow-credentials': 'true',
                        'access-control-allow-origin': '*',
                    },
                }
            )
            resp = await resp.json()
            setUtilitySettingData(resp)
            setUtilitySetting((prev) => ({
                ...prev,
                ...resp
            }))

        } catch (err) {
            console.log(err)
        } finally {

        }
    }

    useEffect(() => {
        getUtilitySetting()
    }, [refresh])


    useEffect(() => {
        if (utilitySettingData) {
            setUtilitySetting({
                ip: utilitySettingData.ip,
                port: utilitySettingData.port,
                rangevalue: utilitySettingData.rangevalue
            })
        }
    }, [])

    // {
    //     "ip": "http://",
    //     "port": "9000",
    //     "rangevalue": "500"
    // }

    return (
        <>
            {isLoading ? (
                <div className=" absolute flex  justify-center items-center mx-[50%] my-[25%] z-10  ">
                    {' '}
                    <Spinner className="" size="40px" />
                </div>
            ) : (
                <form onSubmit={submitData}>
                    <>
                        <Card>
                            <h1 className="text-[14px] w-full ">
                                Utility Setting
                            </h1>
                            <Table compact>
                                <TBody>
                                    <Tr>
                                        <div className="flex gap-3">
                                            <Td className="!px-0">
                                                <div className="text-base">
                                                    <p className="pb-1">
                                                        IP Address
                                                    </p>
                                                    <input
                                                        className="border-[1px] rounded-lg py-3 px-2"
                                                        type="text"
                                                        name="ip"
                                                        value={utilitySetting.ip}
                                                        onChange={handleChange}
                                                        // placeholder="http://"
                                                    />
                                                </div>
                                            </Td>
                                            <Td className="">
                                                <div className="text-base">
                                                    <p className="pb-1">
                                                        PORT
                                                    </p>
                                                    <input
                                                        className="border-[1px] rounded-lg py-3 px-2"
                                                        type="number"
                                                        name="port"
                                                        value={utilitySetting.port}
                                                        onChange={handleChange}
                                                        // placeholder="855"
                                                    />
                                                </div>
                                            </Td>
                                        </div>
                                        <div className='mt-2'>
                                            <Td className="">
                                                <div className="text-base">
                                                    <p className="pb-1">
                                                        Range Value
                                                    </p>
                                                    <input
                                                        className="border-[1px] rounded-lg py-3 px-2"
                                                        type="number"
                                                        name="rangevalue"
                                                        value={utilitySetting.rangevalue}
                                                        onChange={handleChange}
                                                        // placeholder="120"
                                                    />
                                                </div>
                                            </Td>
                                        </div>
                                    </Tr>
                                </TBody>
                            </Table>
                        </Card>
                        <div className="mt-4">
                            <Button
                                className="w-fit"
                                style={{ background: '#133386' }}
                                type="submit"
                                variant="solid"
                                block
                            >
                                Save
                            </Button>
                        </div>
                    </>
                </form>
            )}
        </>
    )
}

export default UtilitySetting
