import React from 'react'
import { Link } from 'react-router-dom'
import ChildTable from './ChildTable'
import './App.css'
import { useParams, useNavigate } from 'react-router'
const ParentTable = ({ data, topLevelParentTotals }) => {
    // Define CSS styles for the rows
    const rowStyles = {
        cursor: 'pointer',
        transition: 'background-color 0.3s',
    };
    const hoverRowStyle = {
        backgroundColor: '#dcdcdc', // Change the background color on hover
    };

    // Define styles for <th> and <td>
    const thStyles = {
        padding: '15px',
        textAlign: 'left',
        color: 'white',
        border: '1px solid #fff', // Column borders
    };

    const tdStyles = {
        padding: '15px',
        borderBottom: '2px solid #ccc',
        border: '1px solid #ccc', // Column borders
    };

    const tableStyles = {
        width: '100%',
        borderCollapse: 'collapse',
        border: '2px solid #ccc',
        fontWeight:"bold"
    };
    const navigate = useNavigate(); // Get the navigate function from react-router-dom

    const handleRowClick = (parentGUID) => {
        // Handle the row click here
        // For example, navigate to a new page with the parentGUID in the URL
        navigate(`/trialBalance/${parentGUID}`);
    };

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <table
                style={tableStyles}
                className="table table-hover table-sm table-bordered  "
            >
                <thead>
                    <tr style={{ backgroundColor: '#133386' }}>
                        <th style={thStyles} scope="col">
                            LEDGER
                        </th>
                        <th style={thStyles} scope="col">
                            Debit
                        </th>
                        <th style={thStyles} scope="col">
                            Credit
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map((parent, index) => (
                        <tr
                            key={index}
                            onClick={() => handleRowClick(parent.GUID)}
                            style={{
                                ...rowStyles,
                                ...hoverRowStyle,
                                cursor: 'pointer',
                                backgroundColor: '#ffffff',
                            }}
                        >
                            <td style={{ ...tdStyles }}>{parent.NAME}</td>
                            <td style={tdStyles}>
                                {parseFloat(
                                    topLevelParentTotals[index].totalNegative
                                ).toFixed(2)}
                            </td>
                            <td style={tdStyles}>
                                {parseFloat(
                                    topLevelParentTotals[index].totalPositive
                                ).toFixed(2)}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Pass the parent.GUID to ChildTable */}
        </div>
    );
};

export default ParentTable;

