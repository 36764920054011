import React, { useEffect, useState } from 'react'
import { Table, Card } from 'components/ui'
import {
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'

const { Tr, Th, Td, THead, TBody, Sorter } = Table

const SettingTable = ({ allUser }) => {
    const [sorting, setSorting] = React.useState([])
    const [isLoading, setIsLoading] = useState(true)
    const columns = [
        { header: 'Name', accessorKey: 'name' },
        { header: 'Email', accessorKey: 'email' },
        { header: 'Phone No.', accessorKey: 'phonenumber' },
        { header: 'City', accessorKey: 'city' },
        { header: 'Zip', accessorKey: 'zipcode' },
        { header: 'State', accessorKey: 'state' },
        { header: 'Country', accessorKey: 'country' },
    ]
    const data = [...allUser]

    const table = useReactTable({
        data,
        columns,
        state: { sorting },

        onSortingChange: setSorting,

        getCoreRowModel: getCoreRowModel(),

        getSortedRowModel: getSortedRowModel(),
    })
    useEffect(() => {
        setTimeout(() => setIsLoading(false), 3000)
    }, [])

    return (
        <>
            <Card className="mt-6">
                <Table className="overflow-x-auto">
                    <THead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <Tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <Th
                                            key={header.id}
                                            colSpan={header.colSpan}
                                        >
                                            {header.isPlaceholder ? null : (
                                                <div
                                                    {...{
                                                        className:
                                                            header.column.getCanSort()
                                                                ? 'cursor-pointer select-none'
                                                                : '',

                                                        onClick:
                                                            header.column.getToggleSortingHandler(),
                                                    }}
                                                >
                                                    {flexRender(
                                                        header.column.columnDef
                                                            .header,

                                                        header.getContext()
                                                    )}
                                                </div>
                                            )}
                                        </Th>
                                    )
                                })}
                            </Tr>
                        ))}
                    </THead>

                    <TBody>
                        {table
                            .getRowModel()
                            .rows.slice(0, 10)
                            .map((row) => {
                                return (
                                    <Tr key={row.id}>
                                        {row.getVisibleCells().map((cell) => {
                                            return (
                                                <Td key={cell.id}>
                                                    {flexRender(
                                                        cell.column.columnDef
                                                            .cell,

                                                        cell.getContext()
                                                    )}
                                                </Td>
                                            )
                                        })}
                                    </Tr>
                                )
                            })}
                    </TBody>
                </Table>
            </Card>
        </>
    )
}

export default SettingTable
