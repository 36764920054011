import React from 'react'
import { Card } from 'components/ui'

const Branding = () => {
  return (
      <Card>
          <h1 className="text-lg">Organization Logo</h1>
          <div className="flex mt-10">
              <img src="/img/logo/appbar_logo.png" alt="" className="w-20" />
              <p className="ml-4 font-bold">
                  SetuFi one of the leading aggregators for small & medium
                  manufacturing businesses in supply chain financing.
              </p>
          </div>
          <h1 className="text-lg mt-10">Appearance</h1>
          <div className="flex gap-10 mt-4">
              <div>
                  {' '}
                  <img src="/img/logo/branding2.png" alt="" className="w-52" />
                  <div className="text-center mt-2">DARK LANE</div>
              </div>
              <div>
                  <img src="/img/logo/branding3.png" alt="" className="w-52" />
                  <div className="text-center mt-2">LIGHT LANE</div>
              </div>
          </div>

          <div className="mt-10">
              <p>
                  <strong>NOTE:</strong>  SetuFiFI  More cash inflow, more production, more sale ·
              </p>
              <p className=" mt-4 text-base">
                  SetuFi Private Limited was formed by Pankaj Goel and
                  Ayushi Gupta; they have been actively engaged in the same
                  field since 2011. We are one of the leading aggregators for
                  small & medium manufacturing businesses in supply chain
                  financing. At present, we have services across India.
              </p>
          </div>
      </Card>
  )
}

export default Branding