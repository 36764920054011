import React, { useEffect, useMemo, useState } from 'react'
import { Table, Card, Spinner } from 'components/ui'
import { MdDeleteOutline } from 'react-icons/md'
import InviteUser from '../InviteUser'
import EditUser from '../EditUser'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { MdEdit } from 'react-icons/md'
import { MdDelete } from 'react-icons/md'
import { AiOutlineDelete } from 'react-icons/ai'
import { MdOutlineModeEdit } from 'react-icons/md'
import { Button, FormItem, FormContainer, Dialog, Select } from 'components/ui'
import { Form, Formik } from 'formik'
import 'react-toastify/dist/ReactToastify.css'

const { Tr, Th, Td, THead, TBody } = Table

const Roles = () => {
    const { user, companyData } = useSelector((state) => state.auth.user)
    var tokenData = JSON.parse(localStorage.getItem('setuFiToken'))
    const [dialogIsOpen, setIsOpen] = useState(false)
    const [disabledButton, setDisabledButton] = useState(false)
    const [customers, setCustomers] = useState([])
    const [allRolesDropdown, setAllRolesDropdown] = useState(null)
    const [userEmail, setUserEmail] = useState('')
    const [userRole, setUserRole] = useState('')

    const [detail, setDetail] = useState({
        email: userEmail,
        role: '',
    })
    const openDialog = (email, roleName) => {
        setUserEmail(email)
        setUserRole(roleName)
        setDetail({
            ...detail,
            email: email,
            role: roleName,
        })
        setIsOpen(true)
    }

    const onDialogClose = (e) => {
        e.preventDefault()
        setIsOpen(false)
    }
    const closeBox = () => {
        setIsOpen(false)
    }

    const resetData = () => {
        setDetail({
            // email: '',
            role: userRole,
        })
    }

    // const [roles, setRoles] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [roleData, setRoleData] = useState([])
    const [roles, setRoles] = useState(null)

    // const getRoles = async () => {
    //     try {
    //         setIsLoading(true)
    //         let resp = await fetch(
    //             `${process.env.REACT_APP_BASE_URL}/getallusers/superDatabase/users/${user?.token}`,
    //             {
    //                 method: 'GET',
    //                 headers: {
    //                     Authorization: `Bearer ${tokenData}`,
    //                     'Content-Type': 'application/json',

    //                     'Access-Control-Allow-Origin': '*',
    //                     'access-control-allow-credentials': 'true',
    //                     'access-control-allow-origin': '*',
    //                 },
    //             }
    //         )
    //         resp = await resp.json()
    //         let filteredData = resp?.find((item) => item?.email === user?.email)
    //         let filteredData2 = filteredData?.TEAMS?.filter(
    //             (item) => item?.companyName === companyData?.NAME
    //         )
    //         setRoles([...filteredData2])
    //     } catch (err) {
    //         console.log(err)
    //     } finally {
    //         setIsLoading(false)
    //     }
    // }

    // const getRoles = async () => {
    //     try {
    //         const dbId = companyData?.databaseName
    //         setIsLoading(true)
    //         let resp = await fetch(
    //             `${process.env.REACT_APP_BASE_URL}/userInvitation/${dbId}/invitations`,
    //             {
    //                 method: 'GET',
    //                 headers: {
    //                     Authorization: `Bearer ${tokenData}`,
    //                     'Content-Type': 'application/json',

    //                     'Access-Control-Allow-Origin': '*',
    //                     'access-control-allow-credentials': 'true',
    //                     'access-control-allow-origin': '*',
    //                 },
    //             }
    //         )
    //         resp = await resp.json()
    //         setRoles(resp.invitations)
    //     } catch (err) {
    //         console.log(err)
    //     } finally {
    //         setIsLoading(false)
    //     }
    // }

    const getRoles = async () => {
        try {
            const GuId = companyData?.Guid
            const Email = user?.email
            setIsLoading(true)
            const bodyData = {
                email: Email,
                companyGUID: GuId,
            }
            let resp = await fetch(
                `${process.env.REACT_APP_BASE_URL}/userInvitation/getInvitationuser`,
                {
                    method: 'POST',
                    body: JSON.stringify(bodyData),
                    headers: {
                        Authorization: `Bearer ${tokenData}`,
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'access-control-allow-credentials': 'true',
                        'access-control-allow-origin': '*',
                    },
                }
            )
            resp = await resp.json()
            setRoles(resp.matchingTeams)
        } catch (err) {
            console.log(err)
        } finally {
            setIsLoading(false)
        }
    }

    const DeleteInvite = async (emailHaveToBeDeleted) => {
        try {
            const Email = user?.email
            setIsLoading(true)
            let resp = await fetch(
                `${process.env.REACT_APP_BASE_URL}/userInvitation/deleteTeam?ADMINEMAIL=${Email}&TEAMEMAIL=${emailHaveToBeDeleted}`,
                {
                    method: 'DELETE',
                    headers: {
                        Authorization: `Bearer ${tokenData}`,
                        'Content-Type': 'application/json',

                        'Access-Control-Allow-Origin': '*',
                        'access-control-allow-credentials': 'true',
                        'access-control-allow-origin': '*',
                    },
                }
            )
            resp = await resp.json()
            toast.success(resp?.msg)
            getRoles()
            setIsLoading(true)
        } catch (err) {
            console.log(err)
            toast.error('Error from Server side.')
        } finally {
            getRoles()
        }
    }


    const getRoleData = async () => {
        let data = await fetch(
            `${process.env.REACT_APP_BASE_URL}/getallusers/superDatabase/users/${tokenData}`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${tokenData}`,
                    'Content-Type': 'application/json',

                    'Access-Control-Allow-Origin': '*',
                    'access-control-allow-credentials': 'true',
                    'access-control-allow-origin': '*',
                },
            }
        )
        data = await data.json()
        let filteredData = data?.find((item) => item?.email === user?.email)
        let company = filteredData?.companies?.find(
            (item) => item?.GUID === companyData?.GUID
        )
        let convertArr = []
        for (var key in company?.roles) {
            var value = company?.roles[key]
            convertArr.push(value)
        }
        setRoleData([...convertArr])
    }

    // const getAllRolesDropdown = async () => {
    //     try {
    //         const dbId = companyData?.databaseName
    //         const bodyData = {
    //             dbName: dbId,
    //         }
    //         let resp = await fetch(
    //             `${process.env.REACT_APP_BASE_URL}/rolePermission/getAll`,
    //             {
    //                 method: 'POST',
    //                 body: JSON.stringify(bodyData),
    //                 headers: {
    //                     Authorization: `Bearer ${tokenData}`,
    //                     'Content-Type': 'application/json',

    //                     'Access-Control-Allow-Origin': '*',
    //                     'access-control-allow-credentials': 'true',
    //                     'access-control-allow-origin': '*',
    //                 },
    //             }
    //         )
    //         resp = await resp.json()
    //         setAllRolesDropdown(resp.rolePermissions)
    //     } catch (err) {
    //         console.log(err)
    //     } finally {
    //     }
    // }

    const getAllRolesDropdown = async () => {
        try {
            const dbId = companyData?.databaseName

            let resp = await fetch(
                `${process.env.REACT_APP_BASE_URL}/rolePermission/getAllroles?dbName=${dbId}`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${tokenData}`,
                        'Content-Type': 'application/json',

                        'Access-Control-Allow-Origin': '*',
                        'access-control-allow-credentials': 'true',
                        'access-control-allow-origin': '*',
                    },
                }
            )
            resp = await resp.json()
            const filteredResponse = resp?.roles?.filter(
                (x) => x.Action.IsDelete == false
            )
            setAllRolesDropdown(filteredResponse)
        } catch (err) {
            console.log(err)
        } finally {
        }
    }

    const updateUserRole = async (e) => {
        e.preventDefault()
        try {
            const adminEmail = user.email
            const bodyData = {
                adminEmail: adminEmail,
                SenderEmail: detail?.email,
                role: detail?.role,
            }
            setIsLoading(true)
            let resp = await fetch(
                `${process.env.REACT_APP_BASE_URL}/userInvitation/updateRole`,
                {
                    method: 'PATCH',
                    body: JSON.stringify(bodyData),
                    headers: {
                        Authorization: `Bearer ${tokenData}`,
                        'Content-Type': 'application/json',

                        'Access-Control-Allow-Origin': '*',
                        'access-control-allow-credentials': 'true',
                        'access-control-allow-origin': '*',
                    },
                }
            )
            resp = await resp.json()
            toast.success(resp?.message)
            setIsOpen(false)
            getRoles()
            setIsLoading(true)
            // setRoles(resp.invitations)
        } catch (err) {
            console.log(err)
            toast.error('Error from Server side.')
        } finally {
            getRoles()
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getRoles()
        getAllRolesDropdown()
        // getRoleData()
    }, [companyData])


    // const deleteRoles = async (Email) => {
    //     try {
    //         const { value } = await Swal.fire({
    //             title: 'Are you sure?',
    //             text: 'You want to delete this file?',
    //             icon: 'warning',
    //             showCancelButton: true,
    //             confirmButtonColor: '#3085d6',
    //             cancelButtonColor: '#d33',
    //             confirmButtonText: 'Yes!',
    //         })
    //         if (value) {
    //             let resp = await fetch(
    //                 `${process.env.REACT_APP_BASE_URL}/deleteTeam?ADMINEMAIL=${user?.email}&TEAMEMAIL=${Email}`,
    //                 {
    //                     method: 'DELETE',
    //                     headers: {
    //                         Authorization: `Bearer ${tokenData}`,
    //                         'Content-Type': 'application/json',

    //                         'Access-Control-Allow-Origin': '*',
    //                         'access-control-allow-credentials': 'true',
    //                         'access-control-allow-origin': '*',
    //                     },
    //                 }
    //             )
    //             toast.success('Role Delete successfully!')
    //             getRoles()
    //         }
    //     } catch (e) {
    //         toast.error('Error from Server side.')
    //     } finally {
    //         getRoles()
    //     }
    // }

    return (
        <div>
            <Card>
                {isLoading ? (
                    <div class="relative items-center block h-screen p-6 bg-slate-100 border border-gray-100 rounded-lg shadow-md bg-blur backdrop-filter backdrop-blur-sm backdrop-opacity-10">
                        <div
                            role="status"
                            class="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
                        >
                            <Spinner
                                className="mx-[50%] my-[25%]"
                                size="40px"
                            />
                        </div>
                    </div>
                ) : (
                    <Card>
                        <div className="flex justify-end gap-3">
                            <InviteUser
                                style={{ background: '#133386' }}
                                roleData={roleData}
                                getRoles={getRoles}
                            />
                            {/* <CreateRole getRoleData={getRoleData} /> */}
                        </div>
                        <div className="mt-4">
                            {' '}
                            <Table>
                                <THead>
                                    <Tr>
                                        <Th>EMAIL</Th>
                                        <Th>ROLE NAME</Th>
                                        <Th>STATUS</Th>

                                        {!user?.superAdminEmail ? (
                                            <Th>EDIT USER ROLE</Th>
                                        ) : null}
                                        {!user?.superAdminEmail ? (
                                            <Th>DELETE</Th>
                                        ) : null}
                                    </Tr>
                                </THead>

                                <TBody>
                                    {roles?.map((value, index) => (
                                        // const keysArray = item?.role && typeof item?.role === 'object' ? Object.keys(item?.role)[0] : item?.role
                                        <Tr key={index}>
                                            <Td>{value?.email}</Td>
                                            <Td>{value?.role}</Td>
                                            <Td>
                                                {value?.status === 'Pending' ? (
                                                    <p className="text-yellow-600 font-bold">
                                                        Pending
                                                    </p>
                                                ) : (
                                                    <p className="text-green-600 font-bold">
                                                        Success
                                                    </p>
                                                )}
                                            </Td>
                                            <Td>
                                                <div className="flex relative left-[40px] w-[25px]">
                                                    <MdOutlineModeEdit
                                                        size="20px"
                                                        color="blue"
                                                        className="cursor-pointer"
                                                        onClick={() => {
                                                            var email =
                                                                value?.email
                                                            var roleName =
                                                                value?.role
                                                            openDialog(
                                                                email,
                                                                roleName
                                                            )
                                                        }}
                                                    />
                                                </div>
                                            </Td>
                                            <Td>
                                                <div className="flex relative left-[15px] w-[25px]">
                                                    <AiOutlineDelete
                                                        size="20px"
                                                        color="red"
                                                        className="cursor-pointer"
                                                        onClick={() => {
                                                            var email =
                                                                value?.email
                                                            // var id = value._id
                                                            DeleteInvite(email)
                                                        }}
                                                    />
                                                </div>
                                            </Td>
                                            {/* <Td>{value?.status}</Td> */}

                                            {/* {!user?.superAdminEmail ? (
                                            <Td>
                                                <EditUser
                                                    EMAIL={item?.email}
                                                    getRoles={getRoles}
                                                />
                                            </Td>
                                        ) : null}
                                        {!user?.superAdminEmail ? (
                                            <Td>
                                                <MdDeleteOutline
                                                    size={20}
                                                    onClick={() =>
                                                        deleteRoles(item?.email)
                                                    }
                                                />
                                            </Td>
                                        ) : null} */}
                                        </Tr>
                                    ))}
                                </TBody>
                            </Table>
                        </div>
                    </Card>
                )}
            </Card>

            {/* ------------------Role Update Popup------------------- */}

            <div>
                <Dialog
                    isOpen={dialogIsOpen}
                    onClose={onDialogClose}
                    onRequestClose={onDialogClose}
                >
                    <h5 className="mb-4">Update User Role</h5>

                    <Formik>
                        <Form>
                            <FormContainer>
                                <FormItem label="Email">
                                    <input
                                        type="email"
                                        autoComplete="off"
                                        name="email"
                                        placeholder="Email"
                                        className="w-full px-3 py-3 border-2 rounded cursor-not-allowed"
                                        value={detail.email}
                                        readOnly
                                        onChange={(e) =>
                                            setDetail({
                                                ...detail,
                                                email: e.target.value,
                                            })
                                        }
                                    />
                                </FormItem>
                                <FormItem name="roles" label="Roles Name">
                                    <select
                                        className="w-full px-3 py-3 border-2 rounded"
                                        onChange={(e) =>
                                            setDetail({
                                                ...detail,
                                                role: e.target.value,
                                            })
                                        }
                                        >
                                        <option disabled>Select Role</option>
                                        {allRolesDropdown?.map(
                                            (value, index) => (
                                                <option value={value.roleName} key={index} selected={value?.roleName === detail?.role}>
                                                    {value?.roleName}
                                                </option>
                                            )
                                            )}
                                    </select>
                                </FormItem>

                                <div className="flex ">
                                    <div>
                                        <Button
                                            className="ltr:mr-2 rtl:ml-2"
                                            variant="plain"
                                            onClick={(e) => onDialogClose(e)}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                    <div>
                                        <Button
                                            type="reset"
                                            className="ltr:mr-2 rtl:ml-2"
                                            onClick={() => resetData()}
                                        >
                                            Reset
                                        </Button>
                                    </div>

                                    <div className="flex items-center relative">
                                        {disabledButton ? (
                                            <Spinner
                                                size={30}
                                                className="relative left-16"
                                                color="white"
                                            />
                                        ) : null}
                                        <Button
                                            style={{
                                                backgroundColor: '#133386', // You can replace 'blue' with your desired color
                                                color: 'white', // Optional: Set text color
                                                // Add any other styles you need
                                            }}
                                            variant="solid"
                                            onClick={(e) => updateUserRole(e)}
                                            disabled={disabledButton}
                                        >
                                            Update
                                        </Button>
                                    </div>
                                </div>

                                {/* </FormItem> */}
                            </FormContainer>
                        </Form>
                    </Formik>
                </Dialog>
            </div>
        </div>
    )
}

export default Roles
