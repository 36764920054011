import React, { useEffect, useState } from 'react'
import { DatePicker, Card } from 'components/ui'
import { FiUsers } from 'react-icons/fi'
import { SiMonkeytie } from 'react-icons/si'
import { MdOutlineCancel } from 'react-icons/md'
import { VscLayersActive } from 'react-icons/vsc'

const ReportsHeader = () => {
    const date = new Date()
    const [allUser, setAllUser] = useState([])
    const [licenseData, setLicenseData] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    let tokenData = JSON.parse(localStorage.getItem('setuFiToken'))
    useEffect(() => {
        const callAPI = async () => {
            let data = await fetch(
                `${process.env.REACT_APP_BASE_URL}/getallusers/superDatabase/users/${tokenData}`
            )
            data = await data.json()
            setAllUser(data)
            setLicenseData(data?.filter((item) => item?.isAppAdmin == true))
            setIsLoading(false)
        }
        callAPI()
    }, [])

    return (
        <Card>
            <Card className="flex flex-col gap-4 rounded-none">
                <h5>Total Number Of License</h5>
                <div className="w-full grid sm:grid-cols-1 m md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
                    <div
                        className={`bg-white dark:bg-gray-800 rounded shadow px-5 py-5 flex items-center ${
                            isLoading ? 'animate-pulse  space-x-4' : ''
                        }`}
                    >
                        {' '}
                        {isLoading ? (
                            <div class=" rounded-md p-4 max-w-sm w-full ">
                                <div class="animate-pulse flex space-x-4">
                                    <div class="rounded-full bg-slate-200 h-10 w-10"></div>
                                    <div class="flex-1 space-y-6 py-1">
                                        <div class="h-2 bg-slate-200 rounded"></div>
                                        <div class="space-y-3">
                                            <div class="grid grid-cols-3 gap-4">
                                                <div class="h-2 bg-slate-200 rounded col-span-2"></div>
                                                <div class="h-2 bg-slate-200 rounded col-span-1"></div>
                                            </div>
                                            <div class="h-2 bg-slate-200 rounded"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="p-4 bg-indigo-700 rounded">
                                <FiUsers color="white" size={30} />
                            </div>
                        )}
                        <div className="ml-3">
                            <h3 className="mb-1 leading-5 text-gray-800 dark:text-gray-100 font-bold text-2xl">
                                {isLoading ? '' : allUser?.length}
                            </h3>
                            <p className="text-gray-600 dark:text-gray-400 text-sm tracking-normal font-medium leading-5">
                                Active
                            </p>
                        </div>
                    </div>
                    <div
                        className={`bg-white dark:bg-gray-800 rounded shadow px-5 py-5 flex items-center ${
                            isLoading ? 'animate-pulse' : ''
                        }`}
                    >
                        {isLoading ? (
                            <div class=" rounded-md p-4 max-w-sm w-full ">
                                <div class="animate-pulse flex space-x-4">
                                    <div class="rounded-full bg-slate-200 h-10 w-10"></div>
                                    <div class="flex-1 space-y-6 py-1">
                                        <div class="h-2 bg-slate-200 rounded"></div>
                                        <div class="space-y-3">
                                            <div class="grid grid-cols-3 gap-4">
                                                <div class="h-2 bg-slate-200 rounded col-span-2"></div>
                                                <div class="h-2 bg-slate-200 rounded col-span-1"></div>
                                            </div>
                                            <div class="h-2 bg-slate-200 rounded"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="p-4 bg-indigo-700 rounded">
                                <SiMonkeytie color="white" size={30} />
                            </div>
                        )}
                        <div className="ml-3">
                            <h3 className="mb-1 leading-5 text-gray-800 dark:text-gray-100 font-bold text-2xl">
                                {isLoading ? '' : allUser?.length}
                            </h3>
                            <p className="text-gray-600 dark:text-gray-400 text-sm tracking-normal font-medium leading-5">
                                Inactive
                            </p>
                        </div>
                    </div>
                    <div
                        className={`bg-white dark:bg-gray-800 rounded shadow px-5 py-5 flex items-center ${
                            isLoading ? `animate-pulse ` : ''
                        }`}
                    >
                        {isLoading ? (
                            <div class=" rounded-md p-4 max-w-sm w-full ">
                                <div class="animate-pulse flex space-x-4">
                                    <div class="rounded-full bg-slate-200 h-10 w-10"></div>
                                    <div class="flex-1 space-y-6 py-1">
                                        <div class="h-2 bg-slate-200 rounded"></div>
                                        <div class="space-y-3">
                                            <div class="grid grid-cols-3 gap-4">
                                                <div class="h-2 bg-slate-200 rounded col-span-2"></div>
                                                <div class="h-2 bg-slate-200 rounded col-span-1"></div>
                                            </div>
                                            <div class="h-2 bg-slate-200 rounded"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="p-4 bg-indigo-700 rounded">
                                <VscLayersActive size={30} color="white" />
                            </div>
                        )}
                        <div className="ml-3">
                            <h3 className="mb-1 leading-5 text-gray-800 dark:text-gray-100 font-bold text-2xl">
                                {isLoading ? '' : licenseData.length}
                            </h3>
                            <p className="text-gray-600 dark:text-gray-400 text-sm tracking-normal font-medium leading-5">
                                Suspanded
                            </p>
                        </div>
                    </div>
                    \
                </div>
            </Card>
            <Card className="flex flex-col gap-4 rounded-none">
                <div>
                    <h5>Sync Transaction (Count)</h5>
                    <div className="w-full grid sm:grid-cols-1 m md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
                        <div
                            className={`bg-white dark:bg-gray-800 rounded shadow px-5 py-5 flex items-center ${
                                isLoading ? 'animate-pulse  space-x-4' : ''
                            }`}
                        >
                            {' '}
                            {isLoading ? (
                                <div class=" rounded-md p-4 max-w-sm w-full ">
                                    <div class="animate-pulse flex space-x-4">
                                        <div class="rounded-full bg-slate-200 h-10 w-10"></div>
                                        <div class="flex-1 space-y-6 py-1">
                                            <div class="h-2 bg-slate-200 rounded"></div>
                                            <div class="space-y-3">
                                                <div class="grid grid-cols-3 gap-4">
                                                    <div class="h-2 bg-slate-200 rounded col-span-2"></div>
                                                    <div class="h-2 bg-slate-200 rounded col-span-1"></div>
                                                </div>
                                                <div class="h-2 bg-slate-200 rounded"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="p-4 bg-indigo-700 rounded">
                                    <FiUsers color="white" size={30} />
                                </div>
                            )}
                            <div className="ml-3">
                                <h3 className="mb-1 leading-5 text-gray-800 dark:text-gray-100 font-bold text-2xl">
                                    {isLoading ? '' : allUser?.length}
                                </h3>
                                <p className="text-gray-600 dark:text-gray-400 text-sm tracking-normal font-medium leading-5">
                                    Daily Sync
                                </p>
                            </div>
                        </div>
                        <div
                            className={`bg-white dark:bg-gray-800 rounded shadow px-5 py-5 flex items-center ${
                                isLoading ? 'animate-pulse' : ''
                            }`}
                        >
                            {isLoading ? (
                                <div class=" rounded-md p-4 max-w-sm w-full ">
                                    <div class="animate-pulse flex space-x-4">
                                        <div class="rounded-full bg-slate-200 h-10 w-10"></div>
                                        <div class="flex-1 space-y-6 py-1">
                                            <div class="h-2 bg-slate-200 rounded"></div>
                                            <div class="space-y-3">
                                                <div class="grid grid-cols-3 gap-4">
                                                    <div class="h-2 bg-slate-200 rounded col-span-2"></div>
                                                    <div class="h-2 bg-slate-200 rounded col-span-1"></div>
                                                </div>
                                                <div class="h-2 bg-slate-200 rounded"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="p-4 bg-indigo-700 rounded">
                                    <SiMonkeytie color="white" size={30} />
                                </div>
                            )}
                            <div className="ml-3">
                                <h3 className="mb-1 leading-5 text-gray-800 dark:text-gray-100 font-bold text-2xl">
                                    {isLoading ? '' : allUser?.length}
                                </h3>
                                <p className="text-gray-600 dark:text-gray-400 text-sm tracking-normal font-medium leading-5">
                                    Monthly Sync
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                    <h5>Sync Transaction (Count)</h5>
                    <div className="w-full grid sm:grid-cols-1 m md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
                        <div
                            className={`bg-white dark:bg-gray-800 rounded shadow px-5 py-5 flex items-center ${
                                isLoading ? 'animate-pulse  space-x-4' : ''
                            }`}
                        >
                            {' '}
                            {isLoading ? (
                                <div class=" rounded-md p-4 max-w-sm w-full ">
                                    <div class="animate-pulse flex space-x-4">
                                        <div class="rounded-full bg-slate-200 h-10 w-10"></div>
                                        <div class="flex-1 space-y-6 py-1">
                                            <div class="h-2 bg-slate-200 rounded"></div>
                                            <div class="space-y-3">
                                                <div class="grid grid-cols-3 gap-4">
                                                    <div class="h-2 bg-slate-200 rounded col-span-2"></div>
                                                    <div class="h-2 bg-slate-200 rounded col-span-1"></div>
                                                </div>
                                                <div class="h-2 bg-slate-200 rounded"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="p-4 bg-indigo-700 rounded">
                                    <FiUsers color="white" size={30} />
                                </div>
                            )}
                            <div className="ml-3">
                                <h3 className="mb-1 leading-5 text-gray-800 dark:text-gray-100 font-bold text-2xl">
                                    {isLoading ? '' : allUser?.length}
                                </h3>
                                <p className="text-gray-600 dark:text-gray-400 text-sm tracking-normal font-medium leading-5">
                                    Daily Sync
                                </p>
                            </div>
                        </div>
                        <div
                            className={`bg-white dark:bg-gray-800 rounded shadow px-5 py-5 flex items-center ${
                                isLoading ? 'animate-pulse' : ''
                            }`}
                        >
                            {isLoading ? (
                                <div class=" rounded-md p-4 max-w-sm w-full ">
                                    <div class="animate-pulse flex space-x-4">
                                        <div class="rounded-full bg-slate-200 h-10 w-10"></div>
                                        <div class="flex-1 space-y-6 py-1">
                                            <div class="h-2 bg-slate-200 rounded"></div>
                                            <div class="space-y-3">
                                                <div class="grid grid-cols-3 gap-4">
                                                    <div class="h-2 bg-slate-200 rounded col-span-2"></div>
                                                    <div class="h-2 bg-slate-200 rounded col-span-1"></div>
                                                </div>
                                                <div class="h-2 bg-slate-200 rounded"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="p-4 bg-indigo-700 rounded">
                                    <SiMonkeytie color="white" size={30} />
                                </div>
                            )}
                            <div className="ml-3">
                                <h3 className="mb-1 leading-5 text-gray-800 dark:text-gray-100 font-bold text-2xl">
                                    {isLoading ? '' : allUser?.length}
                                </h3>
                                <p className="text-gray-600 dark:text-gray-400 text-sm tracking-normal font-medium leading-5">
                                    Monthly Sync
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </Card>
            <div className="flex flex-col">
                {' '}
                <div className="grid grid-cols-4">
                    {' '}
                    <div className=" container flex items-center justify-center h-full w-full py-8 px-4">
                        <div className="bg-gray-200 rounded-lg">
                            <div className="inline-flex flex-col items-start justify-end px-8 py-6 w-full ">
                                <div className="text-base font-semibold  ">
                                    <p>Total Number Of License</p>
                                </div>
                                <div className="flex mt-2 justify-between w-full items-center">
                                    <p className="text-xs  font-bold leading-normal">
                                        Active
                                    </p>
                                    <p className="text-sm  font-black leading-normal text-right ">
                                        6.3 K
                                    </p>
                                </div>
                                <div className="flex mt-2 justify-between w-full items-center">
                                    <p className="text-xs  font-bold leading-normal ">
                                        Inactive
                                    </p>
                                    <p className="  font-black leading-normal text-right">
                                        4.3 K
                                    </p>
                                </div>
                                <div className="flex mt-2 justify-between w-full items-center">
                                    <p className="text-xs font-bold leading-normal ">
                                        Suspanded
                                    </p>
                                    <p className=" font-black leading-normal text-right ">
                                        4.3 K
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" container flex items-center justify-center h-full w-full py-8 px-4">
                        <div className="bg-gray-200 py-4 rounded-lg">
                            <div className="inline-flex flex-col items-start justify-end px-8 py-6 w-full ">
                                <div className="text-base font-semibold  ">
                                    <p>Sync Transaction (Count)</p>
                                </div>
                                <div className="flex mt-2 justify-between w-full items-center">
                                    <p className="text-xs  font-bold leading-normal">
                                        Daily Sync
                                    </p>
                                    <p className="text-sm  font-black leading-normal text-right ">
                                        6.3 K
                                    </p>
                                </div>
                                <div className="flex mt-2 justify-between w-full items-center">
                                    <p className="text-xs  font-bold leading-normal ">
                                        Monthly Sync
                                    </p>
                                    <p className="  font-black leading-normal text-right">
                                        4.3 K
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="  container flex items-center justify-center h-full w-full py-8 px-4">
                        <div className="bg-gray-200 py-1 rounded-lg">
                            <div className="inline-flex flex-col items-start justify-end px-8 py-6 w-full ">
                                <div className="text-base font-semibold  ">
                                    <p>current Active ( per Company )</p>
                                </div>
                                <div className="flex mt-2 justify-between w-full items-center">
                                    <p className="text-xs  font-bold leading-normal">
                                        License
                                    </p>
                                    <p className="text-sm  font-black leading-normal text-right ">
                                        6.3 K
                                    </p>
                                </div>
                                <div className="flex mt-2 justify-between w-full items-center">
                                    <p className="text-xs  font-bold leading-normal ">
                                        Company
                                    </p>
                                    <p className="  font-black leading-normal text-right">
                                        4.3 K
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" container flex items-center justify-center h-full w-full py-8 px-4">
                        <div className="bg-gray-200 py-3 rounded-lg">
                            <div className="inline-flex flex-col items-start justify-end px-8 py-6 w-full ">
                                <div className="text-base font-semibold  ">
                                    <p>Sync Transaction (Size)</p>
                                </div>
                                <div className="flex mt-2 justify-between w-full items-center">
                                    <p className="text-xs  font-bold leading-normal">
                                        Daily Sync
                                    </p>
                                    <p className="text-sm  font-black leading-normal text-right ">
                                        6.3 K
                                    </p>
                                </div>
                                <div className="flex mt-2 justify-between w-full items-center">
                                    <p className="text-xs  font-bold leading-normal ">
                                        Monthly Sync
                                    </p>
                                    <p className="  font-black leading-normal text-right">
                                        4.3 K
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-4 -mt-12">
                    {' '}
                    <div className=" container flex items-center justify-center h-full w-full py-8 px-4">
                        <div className="bg-gray-200 px-9 rounded-lg">
                            <div className="inline-flex flex-col items-start justify-end px-8 py-6 w-full ">
                                <div className="text-base font-semibold  ">
                                    <p>Total Company</p>
                                    <p>55k</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" container flex items-center justify-center h-full w-full py-8 px-4">
                        <div className="bg-gray-200 px-7 rounded-lg">
                            <div className="inline-flex flex-col items-start justify-end px-8 py-6 w-full ">
                                <div className="text-base font-semibold  ">
                                    <p>Total Transaction</p>
                                    <p>55k</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" container flex items-center justify-center h-full w-full py-4 px-4">
                        <div className="bg-gray-200 px-14 rounded-lg">
                            <div className="inline-flex flex-col items-start justify-end px-8 py-6 w-full ">
                                <div className="text-base font-semibold  ">
                                    <p>Total Users</p>
                                    <p>55k</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="  container flex items-center justify-center h-full w-full py-4 px-4">
                        <div className="bg-gray-200 px-5 rounded-lg">
                            <div className="inline-flex flex-col items-start justify-end px-8 py-6 w-full ">
                                <div className="text-base font-semibold  ">
                                    <p>current Active</p>
                                </div>
                                <div className="flex mt-1 justify-between w-full items-center">
                                    <p className="text-xs  font-bold leading-normal">
                                        License
                                    </p>
                                    <p className="text-sm  font-black leading-normal text-right ">
                                        6.3 K
                                    </p>
                                </div>
                                <div className="flex mt-1 justify-between w-full items-center">
                                    <p className="text-xs  font-bold leading-normal ">
                                        Company
                                    </p>
                                    <p className="  font-black leading-normal text-right">
                                        4.3 K
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    )
}

export default ReportsHeader
