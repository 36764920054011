import React, { useEffect } from 'react' //{ useEffect, useState }
import Theme from 'components/template/Theme'
import Layout from 'components/layout'
import mockServer from './mock'
import appConfig from 'configs/app.config'
import './locales'
import { ToastContainer } from 'react-toastify'
import { userData } from 'store/auth/userSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const environment = process.env.NODE_ENV

if (appConfig.enableMock) {
    mockServer({ environment })
}

function App() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.auth.user)
    let currentLocation = window.location

    useEffect(() => {
        const getCompany = async () => {
            let resp = await fetch(
                `${process.env.REACT_APP_BASE_URL}/getAllCompany`,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        email: user?.email,
                    }),
                    headers: {
                        Authorization: `Bearer ${user?.token}`,
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'access-control-allow-credentials': 'true',
                    },
                }
            )
            resp = await resp.json()
            console.log("resp------>getCompany", resp)
            if (resp?.status == true) {
                let recentUser = { ...user, companies: resp?.data }
                dispatch(userData(recentUser))
            }
        }
        if (user?.email) {
            getCompany()
            console.log("resp------>getCompany")
            console.log("resp------>getCompany", currentLocation?.pathname)
        }
    }, [currentLocation?.pathname])

    // useEffect(() => {
    //     if (user?.TEAMS && user?.isUser == true) {
    //         if (
    //             Object.getOwnPropertyNames(user?.TEAMS[0]?.role)[0] ==
    //                 'PURCHASEACCESS' &&
    //             user?.isUser === true
    //         ) {
    //             if (
    //                 currentLocation?.pathname == '/app/sales/Customers' ||
    //                 currentLocation?.pathname == '/app/sales/creditnote' ||
    //                 currentLocation?.pathname == '/app/sales/invoices' ||
    //                 currentLocation?.pathname == '/app/reports/debtorsummary' ||
    //                 currentLocation?.pathname ==
    //                     '/app/reports/salesmanagerreport'
    //             ) {
    //                 navigate('app/dashboard')
    //             }
    //         } else if (
    //             Object.getOwnPropertyNames(user?.TEAMS[0]?.role)[0] ==
    //                 'SALESACCESS' &&
    //             user?.isUser === true
    //         ) {
    //             if (
    //                 currentLocation?.pathname == '/app/purchase/bill' ||
    //                 currentLocation?.pathname == '/app/purchase/payment' ||
    //                 currentLocation?.pathname ==
    //                     '/app/purchase/debitcreditnotes'
    //             ) {
    //                 navigate('app/dashboard')
    //             }
    //         }
    //     }
    // }, [currentLocation?.pathname])

    return (
        <Theme>
            <ToastContainer />
            <Layout />
        </Theme>
    )
}

export default App
