import React from 'react'
import FormTrigger from './FormTrigger'

const index = () => {
  return (
    <div>
    <FormTrigger/>
    </div>
  )
}

export default index
