// Index.js
import React, { useState } from 'react'
import Roles from './Roles'
import RoleMaster from './RoleMaster'
import UsersRolePermissionMaster from './UsersRolePermissionMaster'
import UsersRolePermissionUpdate from './UsersRolePermissionUpdate'
import { Tabs } from 'components/ui'

const { TabNav, TabList, TabContent } = Tabs

const Index = () => {
    const [isEdit, setIsEdit] = useState('')
    const [roleId, setRoleId] = useState(null)
    const [selectedTab, setselectedTab] = useState('tab1')
    const [isRedirect, setIsRedirect] = useState(false)

    const checkEdit = (id, tab, status) => {
        setselectedTab(tab)
        setIsEdit(status)
        setRoleId(id)
    }

    const handleRedirect = (redirect) => {
        setIsRedirect(redirect)
    }

    return (
        <div>
            {isRedirect ? (
                <Tabs defaultValue="tab1">
                    <TabList>
                        <TabNav value="tab1">Role Details</TabNav>
                        <TabNav value="tab2">Invite Users</TabNav>
                        <TabNav value="tab3">Roles & Permissions</TabNav>
                    </TabList>
                    <div className="p-4">
                        <TabContent value="tab1">
                            <RoleMaster
                                Edit={checkEdit}
                                getRedirect={handleRedirect}
                            />
                        </TabContent>
                        <TabContent value="tab2">
                            <Roles />
                        </TabContent>
                        <TabContent value="tab3">
                            {isEdit == 'true' ? (
                                <UsersRolePermissionUpdate
                                    RoleId={roleId}
                                    currTab={setselectedTab}
                                    getRedirect={handleRedirect}
                                />
                            ) : (
                                <UsersRolePermissionMaster
                                    getRedirect={handleRedirect}
                                />
                            )}
                        </TabContent>
                    </div>
                </Tabs>
            ) : (
                <Tabs key={selectedTab} defaultValue={`${selectedTab}`}>
                    <TabList>
                        <TabNav value="tab1">Role Details</TabNav>
                        <TabNav value="tab2">Invite Users</TabNav>
                        <TabNav value="tab3">Roles & Permissions</TabNav>
                    </TabList>
                    <div className="p-4">
                        <TabContent value="tab1">
                            <RoleMaster
                                Edit={checkEdit}
                                getRedirect={handleRedirect}
                            />
                        </TabContent>
                        <TabContent value="tab2">
                            <Roles />
                        </TabContent>
                        <TabContent value="tab3">
                            {isEdit == 'true' ? (
                                <UsersRolePermissionUpdate
                                    RoleId={roleId}
                                    currTab={setselectedTab}
                                    getRedirect={handleRedirect}
                                />
                            ) : (
                                <UsersRolePermissionMaster
                                    getRedirect={handleRedirect}
                                />
                            )}
                        </TabContent>
                    </div>
                </Tabs>
            )}
        </div>
    )
}

export default Index
