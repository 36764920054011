import React, { useEffect, useState } from 'react'
import { Spinner, Switcher, Table } from 'components/ui'
import { Card } from 'components/ui'
import { Button } from 'components/ui'
import { Radio } from 'components/ui'
import { useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const { Tr, Th, Td, TBody } = Table

const General = () => {
    const { user, companyData } = useSelector((state) => state.auth.user)
    let tokenData = JSON.parse(localStorage.getItem('setuFiToken'))
    const [generalSetting, setGeneralSetting] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const [checkboxValue, setCheckBoxValue] = useState({
        isRoundedoff: false,
        noOfCopies: '1',
        PDFAttachment: 'isAttached',
        outstandingAmount: '',
        overduePeriod: '',
        overdueDays: '',
        companyReminderSwitch: false,
        outStandingCheckbox: false
    })
    const [generalOption, setGeneralOption] = useState({})

    const colourOptions = [
        { value: 2, label: 'Two Copy', color: '#00B8D9' },
        { value: 3, label: 'Three Copy', color: '#00B8D9' },
    ]

    const SelectModules = (e) => {
        setGeneralOption({
            ...generalOption,
            [e.target.name]: e.currentTarget.checked,
        })
    }

    useEffect(() => {
        setGeneralOption({})
        setCheckBoxValue({
            isRoundedoff: false,
            noOfCopies: '1',
            PDFAttachment: 'isAttached',
            outstandingAmount: '',
            overduePeriod: '',
            overdueDays: '',
            companyReminderSwitch: false,
        })
        const getGeneralSetting = async () => {
            setIsLoading(true)
            // let bodyData = {
            //     collection_name: 'users',
            //     token: tokenData,
            //     database_name: 'superDatabase',
            //     query: {
            //         email: user?.email,
            //     },
            // }
            let bodyData = {
                email: user?.email,
                guid: companyData.Guid
            }

            try {
                let resp = await fetch(
                    // `${process.env.REACT_APP_BASE_URL}/daybook/getalldata/`,
                    `${process.env.REACT_APP_BASE_URL}/daybook/getCompanyGeneralSettings`,
                    {
                        method: 'POST',
                        body: JSON.stringify(bodyData),
                        headers: {
                            Authorization: `Bearer ${tokenData}`,
                            'Content-Type': 'application/json',

                            'Access-Control-Allow-Origin': '*',
                            'access-control-allow-credentials': 'true',
                            'access-control-allow-origin': '*',
                        },
                    }
                )
                resp = await resp.json()
                // console.log("generalSetting", resp.GENERALSETTINGS.OUTSTANDING)
                // let company = resp[0]?.companies?.find(
                //     (item) => item?.Name == companyData?.Name
                // )
                // setGeneralSetting(company?.GENERALSETTINGS)
                setGeneralSetting(resp.GENERALSETTINGS.OUTSTANDING)

                setIsLoading(false)
            } catch (err) {
                setIsLoading(false)
                console.log(err)
            }
        }
        getGeneralSetting()
    }, [companyData])

    useEffect(() => {
        if (generalSetting) {
            setCheckBoxValue((prev) => ({
                ...prev,
                outstandingAmount: generalSetting.outstandingAmount,
                overduePeriod: generalSetting.overduePeriod,
                overdueDays: generalSetting.overdueDays,
                companyReminderSwitch: generalSetting.companyReminderSwitch,
                outStandingCheckbox: generalSetting.outStandingCheckbox
            }))
        }
        // setGeneralOption({})
        // let generalData = {}
        // for (let key in generalSetting) {
        //     let k = key
        //     generalData = { ...generalData, [k]: true }
        //     setCheckBoxValue({
        //         isRoundedoff: generalSetting[key]?.isRoundedoff
        //             ? generalSetting[key]?.isRoundedoff
        //             : false,
        //         isEncrypted: generalSetting[key]?.isEncrypted
        //             ? generalSetting[key]?.isEncrypted
        //             : false,
        //         isAttached: generalSetting[key]?.isAttached
        //             ? generalSetting[key]?.isAttached
        //             : false,
        //         noOfCopies: generalSetting[key]?.noOfCopies
        //             ? generalSetting[key]?.noOfCopies
        //             : '',
        //         PDFAttachment: generalSetting[key]?.PDFAttachment
        //             ? generalSetting[key]?.PDFAttachment
        //             : 'isAttached',
        //         outstandingAmount: generalSetting[key]?.outstandingAmount
        //             ? generalSetting[key]?.outstandingAmount
        //             : '',
        //         overduePeriod: generalSetting[key]?.overduePeriod
        //             ? generalSetting[key]?.overduePeriod
        //             : '',
        //         overdueDays: generalSetting[key]?.overdueDays
        //             ? generalSetting[key]?.overdueDays
        //             : '',
        //         companyReminderSwitch: generalSetting[key]
        //             ?.companyReminderSwitch
        //             ? generalSetting[key]?.companyReminderSwitch
        //             : false,
        //     })
        // }
        // setGeneralOption(generalData)
    }, [generalSetting])

    // const submitData = async (e) => {
    //     e.preventDefault()
    //     let GENERALSETTINGS = {}
    //     for (var key in generalOption) {
    //         let k = key
    //         if (generalOption[key] == true) {
    //             GENERALSETTINGS = { ...GENERALSETTINGS, [k]: checkboxValue }
    //         }
    //     }
    //     let bodyData = {
    //         collection_name: 'users',
    //         token: tokenData,
    //         database_name: 'superDatabase',
    //         filter: {
    //             email: user?.email,
    //             'companies.GUID': companyData?.GUID,
    //         },
    //         fields: {
    //             GENERALSETTINGS: GENERALSETTINGS,
    //         },
    //     }
    //     try {
    //         let resp = await fetch(
    //             `${process.env.REACT_APP_BASE_URL}/updatenestedfield/`,
    //             {
    //                 method: 'PUT',
    //                 body: JSON.stringify(bodyData),
    //                 headers: {
    //                     Authorization: `Bearer ${tokenData}`,
    //                     'Content-Type': 'application/json',

    //                     'Access-Control-Allow-Origin': '*',
    //                     'access-control-allow-credentials': 'true',
    //                     'access-control-allow-origin': '*',
    //                 },
    //             }
    //         )

    //         if (resp.ok) {
    //             toast.success('Preference Saved successfully!')
    //         } else {
    //             toast.error('Error Preference data.')
    //         }
    //     } catch (e) {
    //         console.log(e)
    //         toast.error('Error sending data.')
    //     }
    // }

    const submitData = async (e) => {
        e.preventDefault()
        let GENERALSETTINGS = {}
        for (var key in generalOption) {
            let k = key
            if (generalOption[key] == true) {
                GENERALSETTINGS = { ...GENERALSETTINGS, [k]: checkboxValue }
            }
        }
        const containsNonNumbers = Object.values(checkboxValue).some(
            (value) => typeof value !== 'number' || isNaN(value)
        );

        if (!containsNonNumbers) {
            toast.error('Please enter a valid number.'); // Show a popup indicating invalid input
            return;
        }

        const requestBody = {
            email: user?.email,
            companyGuid: companyData?.Guid,
            companyData: {
                GENERALSETTINGS: {
                    OUTSTANDING: checkboxValue,
                },
            },
        }
        try {
            let resp = await fetch(
                `${process.env.REACT_APP_BASE_URL}/daybook/updateCompantStatus?db=superDatabase`,
                {
                    method: 'POST',
                    body: JSON.stringify(requestBody),

                    headers: {
                        Authorization: `Bearer ${tokenData}`,
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'access-control-allow-credentials': 'true',
                        'access-control-allow-origin': '*',
                    },
                }
            )

            if (resp.ok) {
                toast.success('Preference Saved successfully!')
            } else {
                toast.error('Error Preference data.')
            }
        } catch (e) {
            console.log(e)
            toast.error('Error sending data.')
        }
    }

    return (
        <>
            {isLoading ? (
                <div className=" absolute flex  justify-center items-center mx-[50%] my-[25%] z-10  ">
                    {' '}
                    <Spinner className="" size="40px" />
                </div>
            ) : (
                <form onSubmit={submitData}>
                    <>
                        <Card>
                            <h1 className="text-[14px] w-full ">
                                Select the modules you would like to enable
                                setGeneralOption
                                <span className=" font-bold">*</span>
                            </h1>
                            <Table compact className="flex">
                                <TBody>
                                    {/* <Tr>
                                    <Td>
                                        <input
                                            type="checkbox"
                                            id=""
                                            name="SALE"
                                            checked={generalOption?.SALE}
                                            onChange={(e) => SelectModules(e)}
                                            className="w-5 h-5 "
                                        />
                                    </Td>
                                    <Th className="text-start">Sale</Th>
                                </Tr>
                                <Tr>
                                    <Td>
                                        <input
                                            type="checkbox"
                                            id=""
                                            name="PURCHASES"
                                            onChange={(e) => SelectModules(e)}
                                            checked={generalOption?.PURCHASES}
                                            className="w-5 h-5 "
                                        />
                                    </Td>
                                    <Th className="text-start">Purchases</Th>
                                </Tr> */}
                                    <Tr>
                                        <Td>
                                            {/* <input
                                                type="checkbox"
                                                required
                                                id=""
                                                onChange={(e) =>
                                                    SelectModules(e)
                                                }
                                                name="OUTSTANDING"
                                                checked={
                                                    generalOption?.OUTSTANDING
                                                }
                                                className="w-5 h-5"
                                                style={{
                                                    // Check if the checkbox is checked and set the background color accordingly
                                                    backgroundColor:
                                                        generalOption?.OUTSTANDING
                                                            ? '#133386'
                                                            : 'initial',
                                                }}
                                            /> */}
                                            <input
                                                type="checkbox"
                                                required
                                                id=""
                                                checked={
                                                    checkboxValue?.outStandingCheckbox
                                                }
                                                value={
                                                    checkboxValue?.companyReminderSwitch
                                                }
                                                onClick={(e) =>
                                                    setCheckBoxValue({
                                                        ...checkboxValue,
                                                        outStandingCheckbox: !checkboxValue?.outStandingCheckbox,
                                                    })
                                                }
                                                name="OUTSTANDING"
                                                className="w-5 h-5"
                                                style={{
                                                    // Check if the checkbox is checked and set the background color accordingly
                                                    backgroundColor:
                                                        checkboxValue?.outStandingCheckbox
                                                            ? '#133386'
                                                            : 'initial',
                                                }}
                                            />
                                        </Td>

                                        <Th className="text-start">
                                            Outstanding
                                        </Th>
                                    </Tr>

                                    {/* <Tr>
                                    <Td>
                                        <input
                                            type="checkbox"
                                            onChange={(e) => SelectModules(e)}
                                            id=""
                                            name="CREDITNOTE"
                                            checked={generalOption?.CREDITNOTE}
                                            className="w-5 h-5 "
                                        />
                                    </Td>
                                    <Th className="text-start">Credit Note</Th>
                                </Tr>
                                <Tr>
                                    <Td>
                                        <input
                                            type="checkbox"
                                            id=""
                                            onChange={(e) => SelectModules(e)}
                                            name="DEBITNOTE"
                                            checked={generalOption?.DEBITNOTE}
                                            className="w-5 h-5 "
                                        />
                                    </Td>
                                    <Th className="text-start">Debit Note</Th>
                                </Tr>
                                <Tr>
                                    <Td>
                                        <input
                                            type="checkbox"
                                            id=""
                                            name="RECIEVABLE"
                                            onChange={(e) => SelectModules(e)}
                                            checked={generalOption?.RECIEVABLE}
                                            className="w-5 h-5 "
                                        />
                                    </Td>
                                    <Th className="text-start">Receivable</Th>
                                </Tr> */}
                                </TBody>
                            </Table>
                        </Card>
                        <div className="mt-4">
                            {/* <Card>
                            <h5>PDF Attachment</h5>
                            <div className="flex my-2 text-base">
                                <div>
                                    <label>
                                        <input
                                            type="radio"
                                            className="w-4 h-4 m-2 text-red-500 bg-black"
                                            value="isAttached"
                                            checked={
                                                checkboxValue?.PDFAttachment ==
                                                'isAttached'
                                                    ? true
                                                    : false
                                            }
                                            onClick={(e) =>
                                                setCheckBoxValue({
                                                    ...checkboxValue,
                                                    PDFAttachment:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                        <span>
                                            Attach PDF File With The Link While
                                            Emailing The Invoice & Estimate ?
                                        </span>
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <input
                                            type="radio"
                                            className="w-4 h-4 m-2"
                                            value="isEncrypted"
                                            onClick={(e) =>
                                                setCheckBoxValue({
                                                    ...checkboxValue,
                                                    PDFAttachment:
                                                        e.target.value,
                                                })
                                            }
                                            checked={
                                                checkboxValue?.PDFAttachment ==
                                                'isEncrypted'
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <span>
                                            I would Like To Encrypt The PDF
                                            Files That I Send.
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </Card> */}
                        </div>
                        <div className="mt-4">
                            {/* <Card>
                            <div className="flex my-2 text-base">
                                <Radio.Group
                                    vertical
                                    value="isRoundedoff"
                                    onChange={(e) => SelectModules(e)}
                                >
                                    <div>
                                        <label>
                                            <input
                                                type="checkbox"
                                                className="w-4 h-4 m-2"
                                                value="isRoundedoff"
                                                onChange={(e) =>
                                                    setCheckBoxValue({
                                                        ...checkboxValue,
                                                        isRoundedoff:
                                                            !checkboxValue?.isRoundedoff,
                                                    })
                                                }
                                                checked={
                                                    checkboxValue.isRoundedoff
                                                }
                                            />
                                            <span>
                                                Round Off Total To The Nearest
                                                Whole Number For SALE
                                                Transactions.
                                            </span>
                                        </label>
                                    </div>
                                </Radio.Group>
                            </div>
                        </Card> */}
                        </div>
                        {/* <div className="mt-4">
                            <Card>
                                <h1 className="text-base mb-3">
                                    Document Copy Level
                                </h1>
                                <div className="">
                                    <div className="grid grid-cols-4 text-base gap-2">
                                        <p className="text-[#0d0b2c]">
                                            Number Of Copy
                                        </p>
                                        <p>Original</p>
                                        <p>Duplicate</p>
                                        <p>Triplicate</p>
                                    </div>
                                    <div className="grid grid-cols-4 text-base gap-5 mt-3">
                                        <p className="text-[#151428]">
                                            Two Copy
                                        </p>
                                        <div className=" w-24 h-10 bg-gray-300 rounded-lg disabled" />
                                        <div className=" w-24 h-10 bg-gray-300 rounded-lg disabled" />
                                    </div>
                                    <div className="grid grid-cols-4 text-base gap-5 mt-3">
                                        <p className="text-[#0d0b2c]">
                                            Three Copy
                                        </p>
                                        <div className=" w-24 h-10 bg-gray-300 rounded-lg disabled" />
                                        <div className=" w-24 h-10 bg-gray-300 rounded-lg disabled" />
                                        <div className=" w-24 h-10 bg-gray-300 rounded-lg disabled" />
                                    </div>
                                </div>
                                <div className="flex justify-between items-center mt-6 px-2 text-base bg-gray-200 rounded-lg">
                                    <div>Default Print Preference</div>

                                    <div>
                                        <select
                                            className="border-none active:border-none focus:border-none hover:border-none bg-inherit py-3 px-2 w-full"
                                            placeholder="i will chooes while printing"
                                            name="noOfCopies"
                                            value={checkboxValue?.noOfCopies}
                                            onChange={(e) =>
                                                setCheckBoxValue({
                                                    ...checkboxValue,
                                                    noOfCopies: e.target.value,
                                                })
                                            }
                                        >
                                            {colourOptions?.map((item) => (
                                                <option value={item?.value}>
                                                    {item?.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </Card>
                        </div> */}
                        <div className="mt-4">
                            <Card>
                                <h1 className="text-base">
                                    Credit Control Settings
                                </h1>
                                <div className="w-[30%] flex justify-between items-center ">
                                    <div className="font-bold">
                                        Overdue Reminder
                                    </div>
                                    <div>
                                        <Switcher
                                            checked={
                                                checkboxValue?.companyReminderSwitch
                                            }
                                            value={
                                                checkboxValue?.companyReminderSwitch
                                            }
                                            onClick={(e) =>
                                                setCheckBoxValue({
                                                    ...checkboxValue,
                                                    companyReminderSwitch:
                                                        !checkboxValue?.companyReminderSwitch,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="grid grid-cols-2 gap-5">
                                    <div className="  text-base">
                                        <p className="my-2 ">
                                            Credit Control Number Overdue Of
                                            Days
                                            <span className=" font-bold">
                                                *
                                            </span>
                                        </p>
                                        <input
                                            className="border-[1px] rounded-lg py-3 px-2 w-full"
                                            type="number"
                                            required
                                            name="overdueDays"
                                            placeholder="45"
                                            value={checkboxValue.overdueDays}
                                            onChange={(e) =>
                                                setCheckBoxValue({
                                                    ...checkboxValue,
                                                    overdueDays: e.target.value.trim(),
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="  text-base">
                                        <p className="my-2">
                                            Bad Debt Overdue Period Threshold
                                            <span className=" font-bold">
                                                *
                                            </span>
                                        </p>
                                        <input
                                            className="border-[1px] rounded-lg py-3 px-2 w-full"
                                            type="number"
                                            required
                                            name="overduePeriod"
                                            placeholder="5"
                                            value={checkboxValue.overduePeriod}
                                            onChange={(e) =>
                                                setCheckBoxValue({
                                                    ...checkboxValue,
                                                    overduePeriod:
                                                        e.target.value.trim(),
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="  text-base">
                                        <p className="my-2 ">
                                            Credit Control Outstanding Amount
                                            <span className=" font-bold">
                                                *
                                            </span>
                                        </p>
                                        <input
                                            className="border-[1px] rounded-lg py-3 px-2 w-full"
                                            type="number"
                                            required
                                            name="outstandingAmount"
                                            placeholder="20000"
                                            value={
                                                checkboxValue.outstandingAmount
                                            }
                                            onChange={(e) =>
                                                setCheckBoxValue({
                                                    ...checkboxValue,
                                                    outstandingAmount:
                                                        e.target.value.trim(),
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <div className="mt-4">
                            <Button
                                className="w-fit"
                                style={{ background: '#133386' }}
                                type="submit"
                                variant="solid"
                                block
                            >
                                SAVE
                            </Button>
                        </div>
                    </>
                </form>
            )}
        </>
    )
}

export default General
