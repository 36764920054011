import React, { useEffect, useState } from 'react'
import { Card, Button } from 'components/ui'
import { HiCheckCircle } from 'react-icons/hi'
import CreateMailServer from './CreateMailServer'
import { MdOutlineDeleteOutline } from 'react-icons/md'
import { Table } from 'components/ui'
import EditMailServerDetails from './EditMailServerDetails'
import { ToastContainer, toast } from 'react-toastify'
import Swal from 'sweetalert2'
import CategoryList from '../EmailTemplate/CategoryList'
import TemplateEditor from '../EmailTemplate/TemplateEditor'
import UserRolesPage from '../User&Roles/UserRolesPage'
import EditMailServer from './EditMailServers'

const { Tr, Th, Td, THead, TBody } = Table

const MailServer = () => {
    var tokenData = JSON.parse(localStorage.getItem('setuFiToken'))

    const [mailData, setMailData] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const categories = [
        { id: 1, name: 'FORGOT PASSWORD' },
        { id: 2, name: 'INVITE USER' },
        // Add more categories as needed
    ]
    const [selectedCategoryName, setselectedCategoryName] =
        useState('FORGOT PASSWORD')
    const [selectedCategoryId, setSelectedCategoryId] = useState(1)
    const [templateName, setTemplateName] = useState('')
    const [subject, setSubject] = useState('')
    const [templateContent, setTemplateContent] = useState('')

    useEffect(() => {
        // Load template data when selectedCategoryId changes
        loadTemplateData()
    }, [selectedCategoryId])

    const handleCategorySelect = (categoryId) => {
        setSelectedCategoryId(categoryId)
        setselectedCategoryName(
            categories.find((category) => category.id === categoryId)?.name
        )
    }

    const loadTemplateData = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/getTemplate?TEMPLATE=${selectedCategoryName}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${tokenData}`,
                        'Content-Type': 'application/json',

                        'Access-Control-Allow-Origin': '*',
                        'access-control-allow-credentials': 'true',
                        'access-control-allow-origin': '*',
                    },
                }
            )
            const data = await response.json()

            if (data.status && data.msg) {
                setTemplateName(data.msg.TEMPLATE)
                setSubject(data.msg.Subject)
                setTemplateContent(data.msg.Body)
            } else {
                setTemplateName('')
                setSubject('')
                setTemplateContent('')
            }
        } catch (error) {
            console.error('Error fetching template data:', error)
        }
    }

    const handleTemplateNameChange = (value) => {
        setTemplateName(value)
    }

    const handleSubjectChange = (value) => {
        setSubject(value)
    }

    const handleTemplateContentChange = (value) => {
        setTemplateContent(value)
    }

    const handleSaveTemplate = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/updateTemplate?TEMPLATE=${selectedCategoryName}`,
                {
                    method: 'PATCH',
                    headers: {
                        Authorization: `Bearer ${tokenData}`,
                        'Content-Type': 'application/json',

                        'Access-Control-Allow-Origin': '*',
                        'access-control-allow-credentials': 'true',
                        'access-control-allow-origin': '*',
                    },
                    body: JSON.stringify({
                        Subject: subject,
                        Body: templateContent,
                    }),
                }
            )

            const data = await response.json()

            if (data.status) {
            } else {
            }
        } catch (error) {
            console.error('Error saving template:', error)
        }
    }

    ////////////////////////////

    // const getMailData = async () => {
    //     let resp = await fetch(
    //         `${process.env.REACT_APP_BASE_URL}/getallusers/superDatabase/mail servers/${tokenData}`,
    //         {
    //             method: 'GET',
    //             headers: {
    //                 Authorization: `Bearer ${tokenData}`,
    //                 'Content-Type': 'application/json',

    //                 'Access-Control-Allow-Origin': '*',
    //                 'access-control-allow-credentials': 'true',
    //                 'access-control-allow-origin': '*',
    //             },
    //         }
    //     )
    //     resp = await resp.json()
    //     setMailData([...resp])
    //     setIsLoading(false)
    // }

    const getMailData = async () => {
        let resp = await fetch(
            `${process.env.REACT_APP_BASE_URL}/daybook/mail?db=superDatabase`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${tokenData}`,
                    'Content-Type': 'application/json',

                    'Access-Control-Allow-Origin': '*',
                    'access-control-allow-credentials': 'true',
                    'access-control-allow-origin': '*',
                },
                // body: JSON.stringify({ db: 'superDatabase' }),
            }
        )
        resp = await resp.json()
        // setMailData([...resp])
        setMailData(resp)
        setIsLoading(false)
    }

    useEffect(() => {
        getMailData()
    }, [])

    const headerExtraContent = (
        <span className="flex items-center">
            <span className="text-[#133386] text-xl">
                <HiCheckCircle />
            </span>
        </span>
    )

    const cardFooter = (
        <div className="flex justify-end">
            <CreateMailServer getMailData={getMailData} />
        </div>
    )

    const editMailServer = (
        <div className="flex justify-end">
            <EditMailServer
                updatetext={'update'}
                getAllMailData={getMailData}
            />
        </div>
    )

    const deleteServer = async (id) => {
        // let bodyData = {
        //     collection_name: 'mail servers',
        //     db: 'superDatabase',
        //     token: tokenData,
        //     ,
        // }
        try {
            const { value } = await Swal.fire({
                title: 'Are you sure?',
                text: 'You want to delete this file?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes!',
            })
            if (value) {
                let resp = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/daybook/mail/${id}?db=superDatabase`,
                    {
                        method: 'DELETE',
                        // body: JSON.stringify(bodyData),
                        headers: {
                            Authorization: `Bearer ${tokenData}`,
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            'access-control-allow-credentials': 'true',
                            'access-control-allow-origin': '*',
                        },
                    }
                )
                resp = await resp.json()
                toast.success('Mail Deleted successfully!')
                getMailData()
                setIsLoading(false)
            }
        } catch (err) {
            console.log(err)
            toast.error('Error from Server side!.')
        }
    }

    return (
        <div>
            <Card
                header="Mail Server"
                headerExtra={headerExtraContent}
                footer={cardFooter}
            >
                <p className="text-base font-semibold">List Mail Accounts</p>
                <p className="text-sm font-semibold">
                    This Features let's you Create And Manage Email Accounts
                </p>
            </Card>
            {isLoading ? (
                <div class="  rounded-md p-4 max-w-sm w-full mx-auto">
                    <div class="animate-pulse flex space-x-4">
                        <div class="rounded-full bg-slate-200 h-10 w-10"></div>
                        <div class="flex-1 space-y-6 py-1">
                            <div class="h-2 bg-slate-200 rounded"></div>
                            <div class="space-y-3">
                                <div class="grid grid-cols-3 gap-4">
                                    <div class="h-2 bg-slate-200 rounded col-span-2"></div>
                                    <div class="h-2 bg-slate-200 rounded col-span-1"></div>
                                </div>
                                <div class="h-2 bg-slate-200 rounded"></div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <Card className="mt-5">
                    <Table>
                        <THead>
                            <Tr>
                                <Th className="whitespace-nowrap">
                                    Account Name
                                </Th>
                                <Th className="whitespace-nowrap">Email</Th>
                                <Th className="whitespace-nowrap">
                                    Associated With
                                </Th>
                                <Th className="text-center whitespace-nowrap">
                                    Action
                                </Th>
                            </Tr>
                        </THead>
                        <TBody>
                            {mailData.data?.map((item) => {
                                return (
                                    <Tr>
                                        <Td>{item?.ACCOUNTNAME}</Td>
                                        <Td>{item?.EMAIL}</Td>
                                        <Td>
                                            {item?.TRANSACTION == true
                                                ? 'TRANSACTION'
                                                : item?.REPLY == true
                                                ? 'REPLY'
                                                : item?.NOREPLY == true
                                                ? 'NOREPLY'
                                                : null}
                                        </Td>
                                        <Td>
                                            <div className="flex gap-5">
                                                <EditMailServerDetails
                                                    id={item?._id}
                                                    getMailData={getMailData}
                                                />
                                                <div className="bg-[#133386] text-center py-1 text-white rounded-md px-6">
                                                    <MdOutlineDeleteOutline
                                                        size={30}
                                                        onClick={() =>
                                                            deleteServer(
                                                                item?._id
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </Td>
                                    </Tr>
                                )
                            })}
                        </TBody>
                    </Table>
                </Card>
            )}

            {/* <div className="flex border mt-5 rounded-md">
                <CategoryList
                    categories={categories}
                    onSelectCategory={handleCategorySelect}
                    selectedCategoryId={selectedCategoryId}
                />
                <TemplateEditor
                    selectedCategoryName={
                        categories.find(
                            (category) => category.id === selectedCategoryId
                        )?.name
                    }
                    templateName={templateName}
                    subject={subject}
                    templateContent={templateContent}
                    onTemplateNameChange={handleTemplateNameChange}
                    onSubjectChange={handleSubjectChange}
                    onTemplateContentChange={handleTemplateContentChange}
                    onSaveTemplate={handleSaveTemplate}
                />
            </div> */}

            {/* <div className="flex border mt-5 rounded-md">
                <UserRolesPage />

            </div> */}
        </div>
    )
}

export default MailServer
