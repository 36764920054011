import React from 'react'
import LicenseSidebar from './LicenseSidebar'
import TrialBalance from '../TrialBalance/TrialBalance'
import TrialBalanceV2 from '../TrialBalance/TrialBalanceV2'
const LicenseSidebarParent = () => {
    return (
        <div>
            <LicenseSidebar />
            {/* <TrialBalance /> */}
            <TrialBalanceV2 />
        </div>
    )
}

export default LicenseSidebarParent
