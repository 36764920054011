import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ParentTableV2 from './ParentTableV2'
import './App.css'

const Loader = () => (
    <div className="loader-container">
        <div className="loader" style={{ backgroundColor: '#133386' }}></div>
    </div>
)

const TrialBalanceV2 = () => {
    const dispatch = useDispatch()
    const [data, setData] = useState([]) // State to store the fetched data
    const [isLoading, setIsLoading] = useState(true) // State to track loading

    var tokenData = JSON.parse(localStorage.getItem('setuFiToken'))
    const { selectedLicense, selectedManage, financialYear } = useSelector(
        (state) => state.auth.superAdmin
    )

    let databaseName = selectedManage?.databaseName
    let token = tokenData
    var selectedFinancialYear = financialYear?.value;

    useEffect(() => {
        getData()
    }, [selectedManage, selectedLicense, financialYear])

    const getData = async () => {
        setIsLoading(true)
        try {

            let resp = await fetch(
                `${process.env.REACT_APP_BASE_URL}/trialBalance/getTrialBalance/${databaseName}/${selectedFinancialYear}/${(+selectedFinancialYear) + 1}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            )

            if (resp.ok) {
                const data = await resp.json()
                setData(data?.data)
            } else {
                console.error('Request failed with status:', resp.status)
            }
        } catch (err) {
            console.error('Error:', err)
        } finally {
            // Set isLoading to false after data is fetched
            setIsLoading(false)
        }
    }

    if (isLoading) {
        return <Loader />
    }

    return (
        <div className="px-5 mb-5">
            <h2 className="text-2xl font-semibold mb-4">Trial Balance</h2>
            <ParentTableV2 data={data} />
        </div>
    )
}

export default TrialBalanceV2
