import React, { useEffect, useState } from 'react'
import { Button, FormItem, FormContainer, Dialog } from 'components/ui'
import { Form, Formik } from 'formik'
import { HiOutlinePencil } from 'react-icons/hi'
import { useSelector } from 'react-redux'
import { v4 as uuid } from 'uuid'
import useAuth from 'utils/hooks/useAuth'
import { Switcher } from 'components/ui'
import { ToastContainer, toast } from 'react-toastify'
import JoditEditor from 'jodit-react'
import 'react-toastify/dist/ReactToastify.css'
import { useRef } from 'react'

const colourOptions1 = [
    { value: 'EVERYDAY', label: 'Everyday', color: '#00B8D9' },
    { value: 'BYWEEKLY', label: 'Byweekly', color: '#00B8D9' },
    { value: 'WEEKLY', label: 'Weekly', color: '#00B8D9' },
]

const EditReminderInvoice = ({ id, getBills }) => {
    const editor = useRef()
    const [dialogIsOpen, setIsOpen] = useState(false)
    var tokenData = JSON.parse(localStorage.getItem('setuFiToken'))
    const { user, companyData } = useSelector((state) => state.auth.user)
    const { signOut } = useAuth()
    const Template1 =
        'Dear __party name__ , You might have missed the payment date and the invoice is now overdue by __days__ days of amount __amount__. Not to worry at all! View your invoice and take the easy way out by making an payment. If you have already paid,please accept our apologies and kindly ignore this payment reminder. Your Opening Balance is : __opening_balance__.'
    const Template2 =
        'Dear __party name__, We wanted to remind you that the payment date for your invoice has passed, and it is now overdue by __days__ days, with an outstanding amount of __amount__. There’s no need for concern! Simply view your invoice for a straightforward solution to make your payment. Should you have already completed this payment, we wholeheartedly apologize for the oversight. In such a case, please disregard this reminder. Your Opening Balance was: __opening_balance__ .'
    const Template3 =
        'Dear __party name__, It has come to our attention that the payment for your invoice is now significantly overdue by __days__ days, involving an amount of __amount__. We understand how easy it is to overlook such matters, and we’re here to provide a simple solution for settling your invoice. If your payment has already been processed, please accept our sincere apologies for this reminder and kindly ignore it. Your Opening Balance remains: __opening_balance__.'

    const [template, setTemplate] = useState('')
    const [reminderData, setReminderData] = useState({
        remainderName: '',
        dueDate: '',
        dueDays: '',
        subject: '',
        contentString: '',
        email: user?.email,
        type: 'invoices',
        id: uuid(),
        to: [],
        content: [],
        SELECTEDPERIOD: '',

        mailPermission: '',
    })
    const [toogle, setToogle] = useState(false)

    //DATE AND TIME
    const minutes = []
    for (let i = 0; i <= 59; i++) {
        const formattedMinute = i.toString().padStart(2, '0')
        minutes.push(formattedMinute)
    }

    const hours = []
    for (let i = 1; i <= 24; i++) {
        const formattedHour = i.toString().padStart(2, '0')
        hours.push(formattedHour)
    }

    useEffect(() => {
        if (template === 'Template1') {
            setReminderData({
                ...reminderData,
                contentString: Template1,
            })
        }
        if (template === 'Template2') {
            setReminderData({
                ...reminderData,
                contentString: Template2,
            })
        }
        if (template === 'Template3') {
            setReminderData({
                ...reminderData,
                contentString: Template3,
            })
        }
    }, [template])

    const getData = async () => {
        openDialog()
        let bodyData = {
            collection_name: 'users',
            database_name: 'superDatabase',
            token: tokenData,
        }
        try {
            let resp = await fetch(
                `${process.env.REACT_APP_BASE_URL}/daybook/getallReminder`,
                {
                    method: 'POST',
                    body: JSON.stringify(bodyData),
                    headers: {
                        Authorization: `Bearer ${tokenData}`,
                        'Content-Type': 'application/json',

                        'Access-Control-Allow-Origin': '*',
                        'access-control-allow-credentials': 'true',
                        'access-control-allow-origin': '*',
                    },
                }
            )
            resp = await resp.json()
            let filterUser = resp?.filter((item) => item?.email == user?.email)
            let companiesArr = filterUser[0]?.companies
            let filterCompany = []
            for (let i = 0; i < companiesArr.length; i++) {
                // console.log('item', companiesArr[i]?.Guid, companyData?.Guid)
                if (companiesArr[i]?.Guid == companyData?.Guid) {
                    filterCompany.push(companiesArr[i])
                }
            }
            // let filterCompany = filterUser?.companies?.filter((item) => {
            //     // console.log('item', item, companyData?.Guid)
            //     // item?.Guid == companyData?.Guid
            // })

            let overDueData = []
            for (let overdue in filterCompany[0]?.OVERDUEREMINDER) {
                let item = filterCompany[0]?.OVERDUEREMINDER[overdue]
                if (item) {
                    overDueData.push(item)
                }
            }
            let finalData = overDueData?.find((item) => item?.id == id)
            console.log("finalData--->", finalData)
            setReminderData({
                remainderName: finalData?.remainderName
                    ? finalData?.remainderName
                    : '',
                dueDate: finalData?.dueDate ? finalData?.dueDate : '',
                dueDays: finalData?.dueDays ? finalData?.dueDays : '',
                subject: finalData?.subject ? finalData?.subject : '',
                contentString: finalData?.contentString
                    ? finalData?.contentString
                    : '',
                email: user?.email,
                type: finalData?.type ? finalData?.type : '',
                id: finalData?.id ? finalData?.id : '',
                to: finalData?.to ? [...finalData?.to] : [],
                content: finalData?.content ? [...finalData?.content] : [{}],
                SELECTEDPERIOD: finalData?.SELECTEDPERIOD
                    ? finalData?.SELECTEDPERIOD
                    : '',
                mailPermission: finalData?.mailPermission
                    ? finalData?.mailPermission
                    : '',
            })
            setToogle(finalData?.isReminderEnabled)
        } catch (err) {
            console.log(err)
        }
    }
    // ------shekharcode---------------
    //   const getData = async () => {
    //     openDialog()
    //     let bodyData = {
    //         collection_name: 'users',
    //         database_name: 'superDatabase',
    //         token: tokenData,
    //     }
    //     try {
    //         let resp = await fetch(
    //             `${process.env.REACT_APP_BASE_URL}/daybook/getalldata`,
    //             {
    //                 method: 'POST',
    //                 body: JSON.stringify(bodyData),
    //                 headers: {
    //                     Authorization: `Bearer ${tokenData}`,
    //                     'Content-Type': 'application/json',

    //                     'Access-Control-Allow-Origin': '*',
    //                     'access-control-allow-credentials': 'true',
    //                     'access-control-allow-origin': '*',
    //                 },
    //             }
    //         )
    //         resp = await resp.json()
    //         console.log(resp)
    //         let filterUser = resp?.filter((item) => item?.email == user?.email)
    //         // let filterCompany = filterUser?.companies?.filter((item) => {
    //         //     console.log(item)
    //         //     item?.Guid == companyData?.Guid
    //         // })
    //         // let filterCompany = filterUser?.companies?.filter((item) => {
    //         //     console.log(item)
    //         //     return item?.Guid == companyData?.Guid
    //         // })
    //         let filterCompany

    //         // Iterate over each item in filterUser
    //         filterUser?.forEach((userItem) => {
    //             // Iterate over the companies array of each user
    //             userItem?.companies?.forEach((company) => {
    //                 // Check if the GUID of the current company matches companyData.Guid
    //                 if (company?.Guid === companyData?.Guid) {
    //                     // If a match is found, assign the matching company to filterCompany
    //                     filterCompany = company
    //                     // Exit the loop early since we found a match
    //                     return
    //                 }
    //             })
    //             // If a matching company is found, exit the outer loop as well
    //             if (filterCompany) {
    //                 return
    //             }
    //         })

    //         console.log('filterCompany:', filterCompany)

    //         console.log('filterUser', filterUser)

    //         let overDueData = []
    //         // for (let overdue in filterCompany?.OVERDUEREMINDER) {
    //         //     let item = filterCompany?.OVERDUEREMINDER[overdue]
    //         //     if (item) {
    //         //         overDueData.push(item)
    //         //     }
    //         // }
    //         // for (let overdue in filterCompany?.GENERALSETTINGS?.OUTSTANDING) {
    //         //     let item = filterCompany?.OVERDUEREMINDER[overdue]
    //         //     if (item) {
    //         //         overDueData.push(item)
    //         //     }
    //         // }
    //            for (let overdue in filterCompany?.OVERDUEREMINDER
    //                ?.31) {
    //                let item = filterCompany?.OVERDUEREMINDER[overdue]
    //                if (item) {
    //                    overDueData.push(item)
    //                }
    //            }
    //         console.log('overDueData', overDueData)

    //         let finalData = overDueData?.find((item) => item?.id == id)
    //         console.log('finalData', finalData)

    //         setReminderData({
    //             remainderName: finalData?.remainderName
    //                 ? finalData?.remainderName
    //                 : '',
    //             dueDate: finalData?.dueDate ? finalData?.dueDate : '',
    //             dueDays: finalData?.dueDays ? finalData?.dueDays : '',
    //             subject: finalData?.subject ? finalData?.subject : '',
    //             contentString: finalData?.contentString
    //                 ? finalData?.contentString
    //                 : '',
    //             email: user?.email,
    //             type: finalData?.type ? finalData?.type : '',
    //             id: finalData?.id ? finalData?.id : '',
    //             to: finalData?.to ? [...finalData?.to] : [],
    //             content: finalData?.content ? [...finalData?.content] : [{}],
    //             SELECTEDPERIOD: finalData?.SELECTEDPERIOD
    //                 ? finalData?.SELECTEDPERIOD
    //                 : '',
    //             mailPermission: finalData?.mailPermission
    //                 ? finalData?.mailPermission
    //                 : '',
    //         })
    //         setToogle(finalData?.isReminderEnabled)
    //     } catch (err) {
    //         console.log(err)
    //     }
    // }
    const openDialog = () => {
        setIsOpen(true)
    }

    const onDialogClose = (e) => {
        e.preventDefault()
        setIsOpen(false)
    }

    const submitData = async (e) => {
        e.preventDefault()
        try {
            let bodyData = {
                database_name: 'superDatabase',
                collection_name: 'users',
                token: tokenData,
                filter: {
                    email: user?.email,
                    'companies.Guid': companyData?.Guid,
                },
                fields: {
                    OVERDUEREMINDER: {
                        [reminderData?.dueDate === 'Before (due date)'
                            ? reminderData?.dueDays
                            : parseInt(reminderData?.dueDays) + 1]: {
                            ...reminderData,
                            isReminderEnabled: toogle,
                            content: [
                                {
                                    insert: reminderData?.contentString,
                                },
                            ],
                        },
                    },
                },
            }
            let resp = await fetch(
                `${process.env.REACT_APP_BASE_URL}/daybook/addNestedData`,
                {
                    method: 'POST',
                    body: JSON.stringify(bodyData),
                    headers: {
                        Authorization: `Bearer ${tokenData}`,
                        'Content-Type': 'application/json',

                        'Access-Control-Allow-Origin': '*',
                        'access-control-allow-credentials': 'true',
                        'access-control-allow-origin': '*',
                    },
                }
            )
            if (resp.ok) {
                toast.success('Automated Reminders -Edited  Data sent !')
            } else {
                toast.error('Error sending data.')
            }
            resp = await resp.json()
            if (resp?.detail == 'Invalid token, please login again') {
                signOut()
            }
            getBills()
        } catch (err) {
            console.log(err)
        } finally {
            setIsOpen(false)
        }
    }

    return (
        <div>
            <Button icon={<HiOutlinePencil />} onClick={() => getData()}>
                Edit...
            </Button>
            <Dialog
                className="-mt-10"
                isOpen={dialogIsOpen}
                onClose={onDialogClose}
                onRequestClose={onDialogClose}
            >
                <div className="flex flex-col h-[100vh] justify-between overflow-y-scroll">
                    <h5 className="mb-4">Automated Reminders - Invoices</h5>
                    <Formik>
                        <Form>
                            <FormContainer>
                                <FormItem label="Send Reminder">
                                    <select
                                        placeholder="Due Date"
                                        type="text"
                                        name="sendReminder"
                                        className="border-[1px] px-2 py-3 w-full rounded-lg"
                                        value={reminderData?.dueDate}
                                        onChange={(e) =>
                                            setReminderData({
                                                ...reminderData,
                                                dueDate: e.target.value,
                                            })
                                        }
                                    >
                                        <option value="After (due date)">
                                            After (due date)
                                        </option>
                                        <option value="Before (due date)">
                                            Before (due date)
                                        </option>
                                    </select>
                                    <input
                                        type="number"
                                        disabled
                                        placeholder="Day(s)"
                                        className="border-[1px] px-2 py-3 w-full rounded-lg mt-5"
                                        value={reminderData?.dueDays}
                                        onChange={(e) =>
                                            setReminderData({
                                                ...reminderData,
                                                dueDays: e.target.value,
                                            })
                                        }
                                    />
                                </FormItem>
                                <FormItem label="Frequency">
                                    <select
                                        className="border-[1px] rounded-lg py-3 px-2 w-full"
                                        placeholder="Please Select"
                                        name="SELECTEDPERIOD"
                                        value={reminderData?.SELECTEDPERIOD}
                                        onChange={(e) =>
                                            setReminderData({
                                                ...reminderData,
                                                SELECTEDPERIOD: e.target.value,
                                            })
                                        }
                                    >
                                        <option selected disabled>
                                            Select Frequency
                                        </option>
                                        {colourOptions1?.map((item) => (
                                            <option value={item?.value}>
                                                {item?.value}
                                            </option>
                                        ))}
                                    </select>
                                </FormItem>

                                <FormItem label=" Reminder Name">
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        placeholder="Reminder Name"
                                        className="border-[1px] px-2 py-3 w-full rounded-lg mt-1"
                                        value={reminderData?.remainderName}
                                        onChange={(e) =>
                                            setReminderData({
                                                ...reminderData,
                                                remainderName: e.target.value,
                                            })
                                        }
                                    />
                                </FormItem>
                                <FormItem label="To">
                                    <select
                                        placeholder="Due Date"
                                        type="text"
                                        name="sendReminder"
                                        className="border-[1px] px-2 py-3 w-full rounded-lg"
                                        value={reminderData?.mailPermission}
                                        onChange={(e) =>
                                            setReminderData({
                                                ...reminderData,
                                                mailPermission: e.target.value,
                                            })
                                        }
                                    >
                                        <option selected disabled>
                                            Select
                                        </option>
                                        <option value="customer">
                                            Customer
                                        </option>
                                        <option value="Accountmanager">
                                            Acount Manager
                                        </option>
                                        <option value="customerandAccountmanager">
                                            Customer & Account Manager
                                        </option>
                                    </select>
                                </FormItem>

                                <FormItem label="Subject">
                                    <input
                                        name="subject"
                                        placeholder="Reminder for 30 days"
                                        className="border-[1px] px-2 py-3 w-full rounded-lg mt-1"
                                        value={reminderData?.subject}
                                        onChange={(e) =>
                                            setReminderData({
                                                ...reminderData,
                                                subject: e.target.value,
                                            })
                                        }
                                    />
                                </FormItem>
                                <FormItem label="Template">
                                    <select
                                        placeholder="Template"
                                        type="text"
                                        name="template"
                                        className="border-[1px] px-2 py-3 w-full rounded-lg"
                                        onChange={(e) =>
                                            // setReminderData({
                                            //     ...reminderData,
                                            //     template: e.target.value,
                                            // })
                                            setTemplate(e.target.value)
                                        }
                                    >
                                        <option selected disabled>
                                            Select
                                        </option>
                                        <option value="Template1">
                                            Template 1
                                        </option>
                                        <option value="Template2">
                                            Template 2
                                        </option>
                                        <option value="Template3">
                                            Template 3
                                        </option>
                                    </select>
                                </FormItem>
                                <FormItem label="Content">
                                    <JoditEditor
                                        ref={editor}
                                        value={reminderData?.contentString}
                                        onChange={(e) =>
                                            setReminderData({
                                                ...reminderData,
                                                contentString: e,
                                            })
                                        }
                                    />
                                </FormItem>
                                <FormItem className="pb-6">
                                    {/* <Switcher
                                        checked={toogle}
                                        onClick={() => setToogle(!toogle)}
                                    /> */}
                                    <Button
                                        className="ltr:mr-2 rtl:ml-2"
                                        variant="plain"
                                        onClick={onDialogClose}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type="reset"
                                        className="ltr:mr-2 rtl:ml-2"
                                    >
                                        Reset
                                    </Button>
                                    <Button
                                        variant="solid"
                                        onClick={(e) => submitData(e)}
                                    >
                                        Update
                                    </Button>
                                </FormItem>
                            </FormContainer>
                        </Form>
                    </Formik>
                </div>
            </Dialog>
        </div>
    )
}

export default EditReminderInvoice
