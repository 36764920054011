import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Menu } from 'components/ui'

import { Link } from 'react-router-dom'
import { HiOutlineChartSquareBar } from 'react-icons/hi'
import { TbFileDatabase, TbSettings } from 'react-icons/tb'
import { APP_PREFIX_PATH } from 'constants/route.constant'
import { AuthorityCheck } from 'components/shared'
import VerticalCollapsedMenuItem from './VerticalCollapsedMenuItem'
import { themeConfig } from 'configs/theme.config'
import { NAV_ITEM_TYPE_TITLE } from 'constants/navigation.constant'
import useMenuActive from 'utils/hooks/useMenuActive'
import { useTranslation } from 'react-i18next'
import useResponsive from 'utils/hooks/useResponsive'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import {
    SIDE_NAV_WIDTH,
    SIDE_NAV_COLLAPSED_WIDTH,
} from 'constants/theme.constant'
import { IoSyncCircleOutline } from 'react-icons/io5'
import { BsBodyText } from 'react-icons/bs'

const { MenuGroup } = Menu

const VerticalMenuContent = (props) => {
    const { user } = useSelector((state) => state.auth.user)
    const [role, setRole] = useState('')
    const [subRole, setSubRole] = useState('')

    useEffect(() => {
        if (user?.isUser === true && user?.TEAMS) {
            let ROLE = Object.getOwnPropertyNames(user?.TEAMS[0]?.role)[0]

            if (ROLE == 'SALESACCESS') {
                setRole('apps.knowledgeBase')
                setSubRole('')
            } else if (ROLE == 'PURCHASEACCESS') {
                setRole('apps.sales')
                setSubRole('apps.crm')
            } else if (ROLE == 'ACCOUNTANTACCESS') {
                setRole('')
                setSubRole('')
            }
        }
    }, [user])

    const {
        navMode = themeConfig.navMode,
        collapsed,
        routeKey,
        navigationTree = [],
        userAuthority = [],
        onMenuItemClick,
        direction = themeConfig.direction,
    } = props

    const { t } = useTranslation()

    const [defaulExpandKey, setDefaulExpandKey] = useState([])
    const sideNavCollapse = useSelector(
        (state) => state.theme.layout.sideNavCollapse
    )
    const sideNavCollapseStyle = {
        width: SIDE_NAV_COLLAPSED_WIDTH,
        minWidth: SIDE_NAV_COLLAPSED_WIDTH,
    }
    const sideNavStyle = {
        width: SIDE_NAV_WIDTH,
        minWidth: SIDE_NAV_WIDTH,
    }

    const { activedRoute } = useMenuActive(navigationTree, routeKey)

    useEffect(() => {
        if (defaulExpandKey.length === 0 && activedRoute?.parentKey) {
            setDefaulExpandKey([activedRoute?.parentKey])
        }
    }, [activedRoute?.parentKey])
    const { larger, smaller } = useResponsive()
    const menuContent = (
        <VerticalMenuContent
            navMode={navMode}
            collapsed={sideNavCollapse}
            userAuthority={userAuthority}
            direction={direction}
        />
    )

    const getNavItem = (nav) => {
        if (nav.type === NAV_ITEM_TYPE_TITLE) {
            if (nav.subMenu.length > 0) {
                return (
                    <AuthorityCheck
                        key={nav.key}
                        userAuthority={userAuthority}
                        authority={nav.authority}
                    >
                        {smaller && (
                            <div
                                style={
                                    sideNavCollapse
                                        ? sideNavCollapseStyle
                                        : sideNavStyle
                                }
                                className={classNames(
                                    'side-nav',
                                    !sideNavCollapse
                                )}
                            >
                                {!sideNavCollapse ? (
                                    menuContent
                                ) : (
                                    <Link
                                        className="flex  mt-10 items-center hover:bg-gray-300 w-14 px-3 py-2 rounded-lg"
                                        to={`${APP_PREFIX_PATH}/project/dashboard`}
                                    >
                                        <span>
                                            <HiOutlineChartSquareBar className="text-2xl text-gray-600" />
                                        </span>
                                    </Link>
                                )}
                            </div>
                        )}
                        {larger.md && (
                            <div
                                style={
                                    sideNavCollapse
                                        ? sideNavCollapseStyle
                                        : sideNavStyle
                                }
                                className={classNames(
                                    'side-nav',
                                    !sideNavCollapse
                                )}
                            >
                                {!sideNavCollapse ? (
                                    menuContent
                                ) : (
                                    <Link
                                        className="flex items-center hover:bg-gray-300  px-3 w-14 py-2 rounded-lg"
                                        to={`${APP_PREFIX_PATH}/syncstats`}
                                    >
                                        <span>
                                            <IoSyncCircleOutline className="text-2xl text-gray-600" />
                                        </span>
                                    </Link>
                                )}
                            </div>
                        )}
                        {larger.md && (
                            <div
                                style={
                                    sideNavCollapse
                                        ? sideNavCollapseStyle
                                        : sideNavStyle
                                }
                                className={classNames(
                                    'side-nav',
                                    !sideNavCollapse
                                )}
                            >
                                {/* {!sideNavCollapse ? (
                                    menuContent
                                ) : (
                                    <Link
                                        className="flex items-center hover:bg-gray-300  px-3 w-14 py-2 rounded-lg"
                                        to={`${APP_PREFIX_PATH}/synclogs`}
                                    >
                                        <span>
                                            <BsBodyText className="text-2xl text-gray-600" />
                                        </span>
                                    </Link>
                                )} */}
                            </div>
                        )}
                        {larger.md && (
                            <div
                                style={
                                    sideNavCollapse
                                        ? sideNavCollapseStyle
                                        : sideNavStyle
                                }
                                className={classNames(
                                    'side-nav',
                                    !sideNavCollapse
                                )}
                            >
                                {!sideNavCollapse ? (
                                    menuContent
                                ) : (
                                    <Link
                                        className="flex items-center hover:bg-gray-300  px-3 w-14 py-2 rounded-lg"
                                        to={`${APP_PREFIX_PATH}/periodicstats`}
                                    >
                                        <span>
                                            <TbFileDatabase className="text-2xl text-gray-600" />
                                        </span>
                                    </Link>
                                )}
                            </div>
                        )}
                        <MenuGroup label={t(nav.translateKey) || nav.title}>
                            {nav.subMenu.map((subNav) => {
                                return subNav.subMenu.length > 0 &&
                                    subNav.key !== role &&
                                    subNav.key !== subRole ? (
                                    <VerticalCollapsedMenuItem
                                        key={subNav.key}
                                        nav={subNav}
                                        onLinkClick={onMenuItemClick}
                                        sideCollapsed={collapsed}
                                        userAuthority={userAuthority}
                                        direction={direction}
                                    />
                                ) : (
                                    ''
                                )
                            })}
                        </MenuGroup>
                        {larger.md && (
                            <div
                                style={
                                    sideNavCollapse
                                        ? sideNavCollapseStyle
                                        : sideNavStyle
                                }
                                className={classNames(
                                    'side-nav',
                                    !sideNavCollapse
                                )}
                            >
                                {!sideNavCollapse ? (
                                    menuContent
                                ) : (
                                    <Link
                                        className="flex items-center hover:bg-gray-300  px-3 w-14 py-2 rounded-lg"
                                        to={`${APP_PREFIX_PATH}/settings`}
                                    >
                                        <span>
                                            <TbSettings className="text-2xl text-gray-600" />
                                        </span>
                                    </Link>
                                )}
                            </div>
                        )}
                    </AuthorityCheck>
                )
            } else {
                ; <MenuGroup label={nav.title} />
            }
        }
    }

    return (
        <Menu
            className="px-4 pb-4"
            variant={navMode}
            sideCollapsed={collapsed}
            defaultActiveKeys={activedRoute?.key ? [activedRoute.key] : []}
            defaultExpandedKeys={defaulExpandKey}
        >
            {navigationTree.map((nav) => getNavItem(nav))}
        </Menu>
    )
}

VerticalMenuContent.propTypes = {
    navMode: PropTypes.oneOf(['light', 'dark', 'themed', 'transparent']),
    collapsed: PropTypes.bool,
    routeKey: PropTypes.string,
    navigationTree: PropTypes.array,
    userAuthority: PropTypes.array,
    direction: PropTypes.oneOf(['rtl', 'ltr']),
}

export default VerticalMenuContent
