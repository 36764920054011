import React from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const TemplateEditor = ({
    selectedCategoryName,
    templateName,
    subject,
    templateContent,
    onTemplateNameChange,
    onSubjectChange,
    onTemplateContentChange,
    onSaveTemplate,
}) => {
    const handlePlaceholderInsert = (placeholderValue) => {
        const editor = quillRef.current.getEditor()
        const selection = editor.getSelection()
        if (selection) {
            editor.insertText(selection.index, placeholderValue)
            editor.setSelection(selection.index + placeholderValue.length)
        }
    }

    const userPlaceholders = [
        { label: 'Email', value: '%UserEmail%' },
        { label: 'Name', value: '%UserName%' },
    ]
    const companyPlaceholders = [
        { label: 'Name', value: '%CompanyName%' },
        { label: 'User Invite Link', value: '%UserInviteLink%' },
        { label: 'Invited Role', value: '%InvitedRole%' },
        { label: 'Email', value: '%CompanyEmail%' },
        { label: 'Phone', value: '%CompanyPhone%' },
        {
            label: 'Website',
            value: '%CompanyWebsite%',
        },
        { label: 'Password Reset Link', value: '%ResetPasswordLink%' },
    ]

    const quillRef = React.useRef(null)

    return (
        <div className="w-3/4 p-4">
            <h2 className="mb-4 font-semibold text-lg">
                {selectedCategoryName}
            </h2>
            {/* <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Template Name:</label>
        <input
          type="text"
          className="block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          value={templateName}
          onChange={(e) => onTemplateNameChange(e.target.value)}
        />
      </div> */}
            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                    Subject:
                </label>
                <input
                    type="text"
                    className="block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    value={subject}
                    onChange={(e) => onSubjectChange(e.target.value)}
                />
            </div>
            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                    Insert Placeholder:
                </label>
                <div className="mt-1 relative">
                    <select
                        className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                        onChange={(e) =>
                            handlePlaceholderInsert(e.target.value)
                        }
                    >
                        <option value="">Select Placeholder</option>
                        <optgroup label="User">
                            {userPlaceholders.map((placeholder) => (
                                <option
                                    key={placeholder.value}
                                    value={placeholder.value}
                                >
                                    {placeholder.label}
                                </option>
                            ))}
                        </optgroup>
                        <optgroup label="Company">
                            {companyPlaceholders.map((placeholder) => (
                                <option
                                    key={placeholder.value}
                                    value={placeholder.value}
                                >
                                    {placeholder.label}
                                </option>
                            ))}
                        </optgroup>
                    </select>
                </div>
            </div>
            <ReactQuill
                theme="snow"
                ref={quillRef}
                value={templateContent}
                onChange={onTemplateContentChange}
            />
            <button
                style={{ backgroundColor: '#133386' }}
                className="mt-4 bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
                onClick={onSaveTemplate}
            >
                Save Template
            </button>
        </div>
    )
}

export default TemplateEditor
