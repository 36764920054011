import React, { useEffect, useRef, useState } from 'react'
import { Button, FormItem, FormContainer, Dialog } from 'components/ui'
import { Form, Formik } from 'formik'
import { HiOutlinePencilAlt } from 'react-icons/hi'
import { useSelector } from 'react-redux'
import { v4 as uuid } from 'uuid'
import JoditEditor from 'jodit-react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const colourOptions1 = [
    { value: 'EVERYDAY', label: 'Everyday', color: '#00B8D9' },
    { value: 'BYWEEKLY', label: 'Byweekly', color: '#00B8D9' },
    { value: 'WEEKLY', label: 'Weekly', color: '#00B8D9' },
]

const ReminderFormInvoice = ({ getReminder }) => {
    const editor = useRef()
    const [dialogIsOpen, setIsOpen] = useState(false)
    var tokenData = JSON.parse(localStorage.getItem('setuFiToken'))
    const { user, companyData } = useSelector((state) => state.auth.user)
    const [toogle, setToogle] = useState(false)
    const [customerEmail, setCustomerEmail] = useState([])
    const [cc, setcc] = useState([])
    const [bcc, setbcc] = useState([])
    const [dropdownEmail, setDropDownEmail] = useState([])
    const [time, setTime] = useState('')

    const Template1 =
        'Dear __party name__ , You might have missed the payment date and the invoice is now overdue by __days__ days of amount __amount__. Not to worry at all! View your invoice and take the easy way out by making an payment. If you have already paid,please accept our apologies and kindly ignore this payment reminder. Your Opening Balance is : __opening_balance__.'
    const Template2 =
        'Dear __party name__, We wanted to remind you that the payment date for your invoice has passed, and it is now overdue by __days__ days, with an outstanding amount of __amount__. There’s no need for concern! Simply view your invoice for a straightforward solution to make your payment. Should you have already completed this payment, we wholeheartedly apologize for the oversight. In such a case, please disregard this reminder. Your Opening Balance was: __opening_balance__ .'
    const Template3 =
        'Dear __party name__, It has come to our attention that the payment for your invoice is now significantly overdue by __days__ days, involving an amount of __amount__. We understand how easy it is to overlook such matters, and we’re here to provide a simple solution for settling your invoice. If your payment has already been processed, please accept our sincere apologies for this reminder and kindly ignore it. Your Opening Balance remains: __opening_balance__.'

    const [template, setTemplate] = useState('')
    const [reminderData, setReminderData] = useState({
        remainderName: '',
        dueDate: 'After (due date)',
        dueDays: '30',
        subject: '',
        contentString: '',
        // contentString:
        //     'Dear __party name__ , You might have missed the payment date and the invoice is now overdue by __days__ days of amount __amount__. Not to worry at all! View your invoice and take the easy way out by making an payment. If you have already paid,please accept our apologies and kindly ignore this payment reminder. Your Opening Balance is : __opening_balance__.',
        email: user?.email,
        type: 'invoice',
        to: [],
        mailPermission: '',
        SELECTEDPERIOD: '',
        id: uuid(),
    })
    const minutes = []
    for (let i = 0; i <= 59; i++) {
        const formattedMinute = i.toString().padStart(2, '0')
        minutes.push(formattedMinute)
    }

    const hours = []
    for (let i = 1; i <= 24; i++) {
        const formattedHour = i.toString().padStart(2, '0')
        hours.push(formattedHour)
    }

    useEffect(() => {
        if (template === 'Template1') {
            setReminderData({
                ...reminderData,
                contentString: Template1,
            })
        }
        if (template === 'Template2') {
            setReminderData({
                ...reminderData,
                contentString: Template2,
            })
        }
        if (template === 'Template3') {
            setReminderData({
                ...reminderData,
                contentString: Template3,
            })
        }
    }, [template])

    useEffect(() => {
        const getCustomerEmail = async () => {
            setCustomerEmail([])
            try {
                let resp = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/getallusers/${companyData?.databaseName}/LEDGER/${tokenData}`,
                    {
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${tokenData}`,
                            'Content-Type': 'application/json',
                            'access-control-allow-credentials': 'true',
                            'access-control-allow-origin': '*',
                        },
                    }
                )
                resp = await resp.json()
                let Email = []
                for (let item of resp) {
                    if (item?.REMAINDERSETTINGS?.contacts) {
                        for (let contact of item?.REMAINDERSETTINGS?.contacts) {
                            if (contact?.EMAIL) {
                                Email.push(contact?.EMAIL)
                            }
                        }
                    }
                }
                setCustomerEmail(Email)
            } catch (err) {
                console.log(err)
            }
        }
        getCustomerEmail()
    }, [companyData])

    const openDialog = () => {
        setIsOpen(true)
    }

    const onDialogClose = (e) => {
        e.preventDefault()
        setIsOpen(false)
    }

    // const submitData = async (e) => {
    //     e.preventDefault()
    //     try {
    //         let bodyData = {
    //             database_name: 'superDatabase',
    //             collection_name: 'users',
    //             token: tokenData,
    //             filter: {
    //                 email: user?.email,
    //                 'companies.GUID': companyData?.GUID,
    //             },
    //             fields: {
    //                 OVERDUEREMINDER: {
    //                     [reminderData?.dueDate === 'Before (due date)'
    //                         ? reminderData?.dueDays
    //                         : parseInt(reminderData?.dueDays) + 1]: {
    //                         ...reminderData,
    //                         isReminderEnabled: toogle,
    //                         to: [...dropdownEmail, ...cc, ...bcc],
    //                         content: [
    //                             {
    //                                 insert: reminderData?.contentString,
    //                             },
    //                         ],
    //                     },
    //                 },
    //             },
    //         }

    //         let resp = await fetch(
    //             `${process.env.REACT_APP_BASE_URL}/addNestedData`,
    //             {
    //                 method: 'PATCH',
    //                 body: JSON.stringify(bodyData),
    //                 headers: {
    //                     Authorization: `Bearer ${tokenData}`,
    //                     'Content-Type': 'application/json',

    //                     'Access-Control-Allow-Origin': '*',
    //                     'access-control-allow-credentials': 'true',
    //                 },
    //             }
    //         )

    //         resp = await resp.json()
    //         toast.success('Automated Reminders - Invoices  Data sent !')
    //         getReminder()
    //     } catch (err) {
    //         console.log(err)
    //     } finally {
    //         setIsOpen(false)
    //         setReminderData({
    //             remainderName: '',
    //             dueDate: 'After (due date)',
    //             dueDays: '30',
    //             subject: '',
    //             contentString:
    //                 'Dear __party name__ , You might have missed the payment date and the invoice is now overdue by __days__ days of amount __amount__. Not to worry at all! View your invoice and take the easy way out by making an payment. If you have already paid,please accept our apologies and kindly ignore this payment reminder. Your Opening Balance is : __opening_balance__.',

    //             email: user?.email,
    //             type: 'invoice',
    //             to: [],
    //             mailPermission: '',
    //             SELECTEDPERIOD: '',
    //             id: uuid(),
    //         })
    //     }
    // }

    // const submitData = async (e) => {
    //     e.preventDefault()

    //     const requestBody = {
    //         email: user?.email,
    //         companyGuid: companyData?.Guid,
    //         companyData: {
    //             OVERDUEREMINDER: {
    //                 [reminderData?.dueDate === 'Before (due date)'
    //                     ? reminderData?.dueDays
    //                     : parseInt(reminderData?.dueDays) + 1]: {
    //                     ...reminderData,
    //                     isReminderEnabled: toogle,
    //                     to: [...dropdownEmail, ...cc, ...bcc],
    //                     content: [
    //                         {
    //                             insert: reminderData?.contentString,
    //                         },
    //                     ],
    //                 },
    //             },
    //         },
    //     }
    //     try {
    //         let resp = await fetch(
    //             `${process.env.REACT_APP_BASE_URL}/daybook/updateCompantStatus?db=superDatabase`,
    //             {
    //                 method: 'POST',
    //                 body: JSON.stringify(requestBody),
    //                 headers: {
    //                     Authorization: `Bearer ${tokenData}`,
    //                     'Content-Type': 'application/json',

    //                     'Access-Control-Allow-Origin': '*',
    //                     'access-control-allow-credentials': 'true',
    //                     'access-control-allow-origin': '*',
    //                 },
    //             }
    //         )
    //         console.log('resp-----', resp)
    //         if (resp.ok) {
    //             toast.success('Automated Reminders - Invoices  Data sent !')
    //             setIsOpen(false)
    //         } else {
    //             toast.error('Error Preference data.')
    //         }
    //     } catch (e) {
    //         console.log(e)
    //         toast.error('Error sending data.')
    //     }
    // }

    const submitData = async (e) => {
        e.preventDefault()
        try {
            let bodyData = {
                database_name: 'superDatabase',
                collection_name: 'users',
                token: tokenData,
                filter: {
                    email: user?.email,
                    'companies.Guid': companyData?.Guid,
                },
                fields: {
                    OVERDUEREMINDER: {
                        [reminderData?.dueDate === 'Before (due date)'
                            ? reminderData?.dueDays
                            : parseInt(reminderData?.dueDays) + 1]: {
                            ...reminderData,
                            isReminderEnabled: toogle,
                            to: [...dropdownEmail, ...cc, ...bcc],
                            content: [
                                {
                                    insert: reminderData?.contentString,
                                },
                            ],
                        },
                    },
                },
            }

            let resp = await fetch(
                `${process.env.REACT_APP_BASE_URL}/daybook/addNestedData`,
                {
                    method: 'POST',
                    body: JSON.stringify(bodyData),
                    headers: {
                        Authorization: `Bearer ${tokenData}`,
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'access-control-allow-credentials': 'true',
                    },
                }
            )

            resp = await resp.json()
            toast.success('Automated Reminders - Invoices Data sent !')
            getReminder()
        } catch (err) {
            console.log(err)
        } finally {
            setIsOpen(false)
            setReminderData({
                remainderName: '',
                dueDate: 'After (due date)',
                dueDays: '30',
                subject: '',
                // contentString:
                //     'Dear __party name__ , You might have missed the payment date and the invoice is now overdue by __days__ days of amount __amount__. Not to worry at all! View your invoice and take the easy way out by making an payment. If you have already paid,please accept our apologies and kindly ignore this payment reminder. Your Opening Balance is : __opening_balance__.',
                contentString: '',
                email: user?.email,
                type: 'invoice',
                to: [],
                mailPermission: '',
                SELECTEDPERIOD: '',
                id: uuid(),
            })
        }
    }
    const handleSetRemainder = (e) => {
        setReminderData({
            ...reminderData,
            dueDays: e.target.value >= 0 ? e.target.value : e.target.value * -1,
        })
    }

    const handleResetClick = () => {
        setReminderData({
            remainderName: '',
            dueDate: 'After (due date)',
            dueDays: '30',
            subject: '',
            contentString: '',
            email: user?.email,
            type: 'invoice',
            to: [],
            mailPermission: '',
            SELECTEDPERIOD: '',
            id: uuid(),
        })
        setTemplate('')
        setDropDownEmail([])
    }

    return (
        <div>
            <ToastContainer />
            <Button
                style={{ background: '#133386' }}
                icon={<HiOutlinePencilAlt />}
                variant="solid"
                onClick={() => openDialog()}
            >
                New Reminder
            </Button>
            <Dialog
                className="-mt-10"
                isOpen={dialogIsOpen}
                onClose={onDialogClose}
                onRequestClose={onDialogClose}
            >
                <div className="flex flex-col h-[100vh] justify-between overflow-y-scroll">
                    <h5 className="mb-4">Automated Reminders - Invoices</h5>
                    <Formik>
                        <Form>
                            <FormContainer>
                                <FormItem label="Send Reminder">
                                    <select
                                        placeholder="Due Date"
                                        type="text"
                                        name="sendReminder"
                                        className="border-[1px] px-2 py-3 w-full rounded-lg"
                                        value={reminderData?.dueDate}
                                        onChange={(e) =>
                                            setReminderData({
                                                ...reminderData,
                                                dueDate: e.target.value,
                                            })
                                        }
                                    >
                                        <option value="After (due date)">
                                            After (due date)
                                        </option>
                                        <option value="Before (due date)">
                                            Before (due date)
                                        </option>
                                    </select>
                                    <input
                                        type="number"
                                        min="0"
                                        required
                                        placeholder="Day(s)"
                                        className="border-[1px] px-2 py-3 w-full rounded-lg mt-5"
                                        value={reminderData?.dueDays}
                                        // onChange={(e) =>
                                        //     setReminderData({
                                        //         ...reminderData,
                                        //         dueDays:
                                        //             e.target.value >= 0
                                        //                 ? e.target.value
                                        //                 : e.target.value * -1,
                                        //     })
                                        // }
                                        onChange={handleSetRemainder}
                                    />
                                </FormItem>
                                <FormItem label="Frequency">
                                    <select
                                        className="border-[1px] rounded-lg py-3 px-2 w-full"
                                        placeholder="Please Select"
                                        name="SELECTEDPERIOD"
                                        onChange={(e) =>
                                            setReminderData({
                                                ...reminderData,
                                                SELECTEDPERIOD: e.target.value,
                                            })
                                        }
                                    >
                                        <option selected disabled>
                                            Select Frequency
                                        </option>
                                        {colourOptions1?.map((item) => (
                                            <option value={item?.value}>
                                                {item?.value}
                                            </option>
                                        ))}
                                    </select>
                                </FormItem>

                                <FormItem label=" Reminder Name">
                                    <input
                                        required
                                        type="text"
                                        autoComplete="off"
                                        placeholder="Reminder Name"
                                        className="border-[1px] px-2 py-3 w-full rounded-lg mt-1"
                                        value={reminderData?.remainderName}
                                        onChange={(e) =>
                                            setReminderData({
                                                ...reminderData,
                                                remainderName: e.target.value,
                                            })
                                        }
                                    />
                                </FormItem>
                                <FormItem label="To">
                                    <select
                                        placeholder="Due Date"
                                        type="text"
                                        name="sendReminder"
                                        className="border-[1px] px-2 py-3 w-full rounded-lg"
                                        onChange={(e) =>
                                            setReminderData({
                                                ...reminderData,
                                                mailPermission: e.target.value,
                                            })
                                        }
                                    >
                                        <option selected disabled>
                                            Select
                                        </option>
                                        <option value="customer">
                                            Customer
                                        </option>
                                        <option value="Accountmanager">
                                            Acount Manager
                                        </option>
                                        <option value="customerandAccountmanager">
                                            Customer & Account Manager
                                        </option>
                                    </select>
                                    <input
                                        type="text"
                                        required
                                        placeholder="cc"
                                        className="border-[1px] px-2 py-3 w-full rounded-lg mt-5"
                                        onChange={(e) =>
                                            setcc([
                                                ...e.target.value.split(','),
                                            ])
                                        }
                                    />
                                    use comma(,) to separate more than one email
                                    address
                                    <input
                                        type="text"
                                        required
                                        placeholder="bcc"
                                        className="border-[1px] px-2 py-3 w-full rounded-lg mt-5"
                                        onChange={(e) =>
                                            setbcc([
                                                ...e.target.value.split(','),
                                            ])
                                        }
                                    />
                                    use comma(,) to separate more than one email
                                    address
                                </FormItem>
                                <FormItem label="Subject">
                                    <input
                                        required
                                        name="subject"
                                        placeholder="Reminder for 30 days"
                                        className="border-[1px] px-2 py-3 w-full rounded-lg mt-1"
                                        value={reminderData?.subject}
                                        onChange={(e) =>
                                            setReminderData({
                                                ...reminderData,
                                                subject: e.target.value,
                                            })
                                        }
                                    />
                                </FormItem>
                                <FormItem label="Template">
                                    <select
                                        placeholder="Template"
                                        type="text"
                                        name="template"
                                        className="border-[1px] px-2 py-3 w-full rounded-lg"
                                        onChange={(e) =>
                                            // setReminderData({
                                            //     ...reminderData,
                                            //     template: e.target.value,
                                            // })
                                            setTemplate(e.target.value)
                                        }
                                    >
                                        <option selected disabled>
                                            Select
                                        </option>
                                        <option value="Template1">
                                            Template 1
                                        </option>
                                        <option value="Template2">
                                            Template 2
                                        </option>
                                        <option value="Template3">
                                            Template 3
                                        </option>
                                    </select>
                                </FormItem>
                                <FormItem label="Content">
                                    <JoditEditor
                                        ref={editor}
                                        value={reminderData?.contentString}
                                        onChange={(e) =>
                                            setReminderData({
                                                ...reminderData,
                                                contentString: e,
                                            })
                                        }
                                    />
                                </FormItem>
                                <FormItem className="pb-6">
                                    {/* <Switcher onChange={(e) => setToogle(e)} /> */}
                                    <Button
                                        className="ltr:mr-2 rtl:ml-2"
                                        variant="plain"
                                        onClick={onDialogClose}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type="reset"
                                        className="ltr:mr-2 rtl:ml-2"
                                        onClick={(e) => handleResetClick()}
                                    >
                                        Reset
                                    </Button>
                                    <Button
                                        style={{
                                            backgroundColor: '#133386', // You can replace 'blue' with your desired color
                                            color: 'white', // Optional: Set text color
                                            // Add any other styles you need
                                        }}
                                        variant="solid"
                                        type="submit"
                                        onClick={(e) => submitData(e)}
                                    >
                                        Save
                                    </Button>
                                </FormItem>
                            </FormContainer>
                        </Form>
                    </Formik>
                </div>
            </Dialog>
        </div>
    )
}

export default ReminderFormInvoice
