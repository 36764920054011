import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import {
    apiSignIn,
    apiSignOut,
    apiSignUp,
    apiDeleteUser,
} from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import { userData } from 'store/auth/userSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'

function useAuth() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const query = useQuery()

    const { token, signedIn } = useSelector((state) => state?.auth?.session)
    const { user } = useSelector((state) => state?.auth?.user)
    const signIn = async (data) => {
        try {
            const resp = await apiSignIn(data)
            if (resp?.token) {
                localStorage.setItem(
                    'setuFiToken',
                    JSON?.stringify(resp?.token)
                )
                localStorage.setItem('setuFiTime', new Date().getTime())
                dispatch(onSignInSuccess(resp?.token))
                dispatch(userData(resp?.userData))
                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )
                return resp
            } else {
                return resp
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    // const signIn = async (data) => {
    //     try {
    //         const response = await apiSignIn(data)
    //         if (response?.token) {
    //             localStorage.setItem(
    //                 'setuFiToken',
    //                 JSON.stringify(response?.token)
    //             )
    //             localStorage.setItem('setuFiTime', new Date()?.getTime())

    //             dispatch(onSignInSuccess(response?.token))
    //             dispatch(userData(response?.userData))

    //             const redirectUrl = query?.get(REDIRECT_URL_KEY)
    //             navigate(
    //                 redirectUrl ? redirectUrl : appConfig?.authenticatedEntryPath
    //             )
    //             return response
    //         } else {
    //             return {
    //                 status: 'failed',
    //                 message: 'Token not found in response data',
    //             }
    //         }
    //     } catch (error) {
    //         console.error('Sign-in failed:', error)

    //         const errorMessage =
    //             error?.response?.data?.message || 'Sign-in failed'

    //         return {
    //             status: 'failed',
    //             message: errorMessage,
    //         }
    //     }
    // }

    const deleteUser = async (data) => {
        try {
            const resp = await apiDeleteUser(data)
            if (resp) {
                return resp
            } else {
                return resp
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const signUp = async (values) => {
        try {
            const resp = await apiSignUp(values)
            if (resp?.status === true) {
                const { token } = resp
                dispatch(onSignInSuccess(token))
                if (resp?.userData) {
                    dispatch(
                        setUser(
                            resp.userData || {
                                avatar: '',
                                userName: 'Anonymous',
                                authority: ['USER'],
                                email: '',
                            }
                        )
                    )
                }
                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )
                return {
                    status: true,
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        navigate(appConfig.unAuthenticatedEntryPath)
        localStorage.removeItem('setuFiToken')
        localStorage.removeItem('setuFiTime')
    }

    const signOut = async () => {
        await apiSignOut()
        handleSignOut()
    }

    return {
        authenticated: token && signedIn,
        signIn,
        signUp,
        signOut,
        deleteUser,
    }
}

export default useAuth
