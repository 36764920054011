import React, { useState } from 'react'
import { Radio } from 'components/ui'
import { MdOutlineModeEditOutline } from 'react-icons/md'
import { Input, Button, Dialog, FormItem, FormContainer } from 'components/ui'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'

const MIN_UPLOAD = 1

const EditMailServer = ({ updatetext, getAllMailData }) => {
    const { user } = useSelector((state) => state.auth.user)
    var tokenData = JSON.parse(localStorage.getItem('setuFiToken'))
    const [dialogIsOpen, setIsOpen] = useState(false)
    const [radioValue, setRadioValue] = useState()

    const openDialog = () => {
        setIsOpen(true)
    }

    const onDialogClose = (e) => {
        setIsOpen(false)
    }

    const onChange = (val) => {
        let data = {
            TRANSACTION: false,
            REPLY: false,
            NOREPLY: false,
        }
        for (let item in data) {
            if (val == item) {
                data[item] = true
            }
        }
        setRadioValue(data)
    }
    const submitMailData = async (val) => {
        let bodyData = {
            collection_name: 'mail servers',
            database_name: 'superDatabase',
            token: tokenData,
            data: { ...val, ...radioValue },
        }

        try {
            let resp = await fetch(
                `${process.env.REACT_APP_BASE_URL}/adddata/`,
                {
                    method: 'POST',
                    body: JSON.stringify(bodyData),
                    headers: {
                        Authorization: `Bearer ${tokenData}`,
                        'Content-Type': 'application/json',

                        'Access-Control-Allow-Origin': '*',
                        'access-control-allow-credentials': 'true',
                    },
                }
            )
            resp = await resp.json()
            toast.success('Mail Saved successfully!')
            onDialogClose()
        } catch (err) {
            console.log(err)
            toast.error('Error from Server side!.')
        } 
    }

    return (
        <div>
            <Button
                variant="solid"
                className="bg-[#133386]"
                onClick={() => openDialog()}
            >
                {updatetext}
            </Button>
            <Dialog
                isOpen={dialogIsOpen}
                onClose={onDialogClose}
                onRequestClose={onDialogClose}
            >
                <div className="flex flex-col h-full justify-between">
                    <h5 className="mb-4">Mail Server</h5>
                    <div className="max-h-96 overflow-y-auto">
                        <Formik
                            enableReinitialize
                            initialValues={{
                                CREATEDBY: user?.name,
                                ISSUPERADMIN: true,
                                ACCOUNTNAME: '',
                                EMAIL: '',
                                SMTPUSERNAME: '',
                                SMTPPASSWORD: '',
                                HOST: '',
                                PORT: '',
                            }}
                            // validationSchema={validationSchema}
                            onSubmit={(values, { setSubmitting }) => {
                                submitMailData(values)
                            }}
                        >
                            {({ values, touched, errors, resetForm }) => (
                                <Form>
                                    <FormContainer>
                                        <FormItem
                                            label="Account Name"
                                            asterisk
                                            invalid={
                                                errors.input && touched.input
                                            }
                                            errorMessage={errors.input}
                                        >
                                            <Field
                                                type="text"
                                                name="ACCOUNTNAME"
                                                placeholder="Account Name"
                                                component={Input}
                                            />
                                        </FormItem>
                                        <FormItem
                                            label="Email"
                                            asterisk
                                            invalid={
                                                errors.input && touched.input
                                            }
                                            errorMessage={errors.input}
                                        >
                                            <Field
                                                type="text"
                                                name="EMAIL"
                                                placeholder="Email"
                                                component={Input}
                                            />
                                        </FormItem>
                                        <FormItem
                                            label="SMTP Username"
                                            asterisk
                                            invalid={
                                                errors.input && touched.input
                                            }
                                            errorMessage={errors.input}
                                        >
                                            <Field
                                                type="text"
                                                name="SMTPUSERNAME"
                                                placeholder="SMTP Username"
                                                component={Input}
                                            />
                                        </FormItem>
                                        <FormItem
                                            label="Password"
                                            asterisk
                                            invalid={
                                                errors.input && touched.input
                                            }
                                            errorMessage={errors.input}
                                        >
                                            <Field
                                                type="text"
                                                name="SMTPPASSWORD"
                                                placeholder="Password"
                                                component={Input}
                                            />
                                        </FormItem>
                                        <FormItem
                                            label="Host"
                                            asterisk
                                            invalid={
                                                errors.input && touched.input
                                            }
                                            errorMessage={errors.input}
                                        >
                                            <Field
                                                type="text"
                                                name="HOST"
                                                placeholder="Host"
                                                component={Input}
                                            />
                                        </FormItem>
                                        <FormItem
                                            label="Port"
                                            asterisk
                                            invalid={
                                                errors.input && touched.input
                                            }
                                            errorMessage={errors.input}
                                        >
                                            <Field
                                                type="text"
                                                name="PORT"
                                                placeholder="Port"
                                                component={Input}
                                            />
                                        </FormItem>
                                        <FormItem
                                            label="Associated With"
                                            asterisk
                                            invalid={
                                                errors.input && touched.input
                                            }
                                            errorMessage={errors.input}
                                        >
                                            <Radio.Group
                                                // value={value}
                                                onChange={onChange}
                                            >
                                                <Radio value={'TRANSACTION'}>
                                                    Transactions
                                                </Radio>
                                                <Radio value={'REPLY'}>
                                                    Reply
                                                </Radio>
                                                <Radio value={'NOREPLY'}>
                                                    No Reply
                                                </Radio>
                                            </Radio.Group>
                                        </FormItem>
                                    </FormContainer>
                                    <div className="text-right mt-6 mr-4">
                                        <Button variant="solid" type="submit">
                                            Save
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default EditMailServer
