import React from 'react'
import { BsBodyText } from 'react-icons/bs'
import {
    HiOutlineChartSquareBar,
    HiOutlineUserGroup,
    HiOutlineTrendingUp,
    HiOutlineUserCircle,
    HiOutlineBookOpen,
    HiOutlineCurrencyDollar,
    HiOutlineShieldCheck,
    HiOutlineUserAdd,
    HiOutlineKey,
    HiOutlineLockClosed,

} from 'react-icons/hi'
import { IoSyncCircleOutline } from 'react-icons/io5'
import { TbFileDatabase, TbSettings } from "react-icons/tb"

const navigationIcon = {
    dashboard: <HiOutlineChartSquareBar />,
    reports: <HiOutlineUserGroup />,
    sales: <HiOutlineTrendingUp />,
    tasks: <HiOutlineCurrencyDollar />,
    purchase: <HiOutlineBookOpen />,
    account: <HiOutlineUserCircle />,
    signIn: <HiOutlineShieldCheck />,
    signUp: <HiOutlineUserAdd />,
    forgotPassword: <HiOutlineLockClosed />,
    resetPassword: <HiOutlineKey />,
    settings: <TbSettings />,
    syncStats: <IoSyncCircleOutline />,
    periodicStats: <TbFileDatabase />,
    syncLogs: <BsBodyText />
}

export default navigationIcon
