import React, { useEffect, useRef, useState } from 'react'
import { Button, FormItem, FormContainer, Dialog } from 'components/ui'
import { Form, Formik } from 'formik'
import { HiOutlinePencilAlt } from 'react-icons/hi'
import { useSelector } from 'react-redux'
import { v4 as uuid } from 'uuid'
import { Switcher } from 'components/ui'
import { ToastContainer, toast } from 'react-toastify'
import JoditEditor from 'jodit-react'
import 'react-toastify/dist/ReactToastify.css'

const ReminderFormBill = ({ getBills }) => {
    const editor = useRef()
    const [dialogIsOpen, setIsOpen] = useState(false)
    var tokenData = JSON.parse(localStorage.getItem('setuFiToken'))
    const { user, companyData } = useSelector((state) => state.auth.user)
    const [toogle, setToogle] = useState(false)
    const [customerEmail, setCustomerEmail] = useState([])
    const [cc, setcc] = useState([])
    const [bcc, setbcc] = useState([])
    const [dropdownEmail, setDropDownEmail] = useState([])
    const [time, setTime] = useState('')

    const [reminderData, setReminderData] = useState({
        remainderName: '',
        dueDate: 'After (due date)',
        dueDays: '30',
        subject: '',
        contentString:
            'Dear __party name__ , You might have missed the payment date and the bill is now overdue by __days__ days of amount __amount__. Not to worry at all! View your bill and take the easy way out by making an payment. If you have already paid,please accept our apologies and kindly ignore this payment reminder. Your Opening Balance is : __opening_balance__.',

        content: [],
        email: user?.email,
        type: 'bill',
        to: [],
        id: uuid(),
    })

    useEffect(() => {
        const getCustomerEmail = async () => {
            setCustomerEmail([])
            try {
                let resp = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/getallusers/${companyData?.databaseName}/LEDGER/${tokenData}`,
                    {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${tokenData}`,
                            'Content-Type': 'application/json',

                            'Access-Control-Allow-Origin': '*',
                            'access-control-allow-credentials': 'true',
                            'access-control-allow-origin': '*',
                        },
                    }
                )
                resp = await resp.json()
                let Email = []
                for (let item of resp) {
                    if (item?.REMAINDERSETTINGS?.contacts) {
                        for (let contact of item?.REMAINDERSETTINGS?.contacts) {
                            if (contact?.EMAIL) {
                                Email.push(contact?.EMAIL)
                            }
                        }
                    }
                }
                setCustomerEmail(Email)
            } catch (err) {
                console.log(err)
            } finally {
                getBills()
                setReminderData({
                    remainderName: '',
                    dueDate: 'After (due date)',
                    dueDays: '30',
                    subject: '',
                    contentString:
                        'Dear __party name__ , You might have missed the payment date and the bill is now overdue by __days__ days of amount __amount__. Not to worry at all! View your bill and take the easy way out by making an payment. If you have already paid,please accept our apologies and kindly ignore this payment reminder. Your Opening Balance is : __opening_balance__.',
                    content: [],
                    email: user?.email,
                    type: 'bill',
                    to: [],
                })
            }
        }
        getCustomerEmail()
    }, [companyData])

    const openDialog = () => {
        setIsOpen(true)
    }

    const onDialogClose = (e) => {
        e.preventDefault()
        setIsOpen(false)
    }
    const selectToEmail = (value) => {
        if (value == 'customer') {
            setDropDownEmail(customerEmail)
        } else if (value == 'Accountmanager') {
            setDropDownEmail([user?.email])
        } else {
            setDropDownEmail([...customerEmail, user?.email])
        }
    }

    const submitData = async (e) => {
        e.preventDefault()
        try {
            let bodyData = {
                database_name: 'superDatabase',
                collection_name: 'users',
                token: tokenData,
                filter: {
                    email: user?.email,
                    'companies.GUID': companyData?.GUID,
                },
                fields: {
                    OVERDUEREMINDER: {
                        [reminderData?.dueDays]: {
                            ...reminderData,
                            isReminderEnabled: toogle,
                            to: [...dropdownEmail, ...cc, ...bcc],
                            content: [
                                {
                                    insert: reminderData?.contentString,
                                },
                            ],
                        },
                    },
                },
            }
            let resp = await fetch(
                `${process.env.REACT_APP_BASE_URL}/patchnestedfield`,
                {
                    method: 'PATCH',
                    body: JSON.stringify(bodyData),
                    headers: {
                        Authorization: `Bearer ${tokenData}`,
                        'Content-Type': 'application/json',

                        'Access-Control-Allow-Origin': '*',
                        'access-control-allow-credentials': 'true',
                        'access-control-allow-origin': '*',
                    },
                }
            )
            if (resp.ok) {
                // Show success toast
                toast.success('Automated Reminders - Bill  Data sent !')
            } else {
                // Show error toast
                toast.error('Error sending data.')
            }
            resp = await resp.json()
            getBills()
        } catch (err) {
            console.log(err)
        } finally {
            setIsOpen(false)
        }
    }

    const handleTimeChange = (e) => {
        setTime(e.target.value)
    }

    return (
        <div>
            <ToastContainer />
            <Button
                icon={<HiOutlinePencilAlt />}
                variant="solid"
                onClick={() => openDialog()}
            >
                New Reminder
            </Button>
            <Dialog
                className="-mt-10"
                isOpen={dialogIsOpen}
                onClose={onDialogClose}
                onRequestClose={onDialogClose}
            >
                <div className="flex flex-col h-[100vh] justify-between overflow-y-scroll ">
                    <h5 className="mb-4">Automated Reminders - Bills</h5>
                    <Formik>
                        <Form>
                            <FormContainer>
                                <FormItem label="Send Reminder">
                                    <select
                                        placeholder="Due Date"
                                        type="text"
                                        name="sendReminder"
                                        className="border-[1px] px-2 py-3 w-full rounded-lg"
                                        value={reminderData?.dueDate}
                                        onChange={(e) =>
                                            setReminderData({
                                                ...reminderData,
                                                dueDate: e.target.value,
                                            })
                                        }
                                    >
                                        <option value="After (due date)">
                                            After (due date)
                                        </option>
                                        <option value="Before (due date)">
                                            Before (due date)
                                        </option>
                                    </select>
                                    <input
                                        type="number"
                                        min="0"
                                        placeholder="Day(s)"
                                        className="border-[1px] px-2 py-3 w-full rounded-lg mt-5"
                                        value={reminderData?.dueDays}
                                        onChange={(e) =>
                                            setReminderData({
                                                ...reminderData,
                                                dueDays:
                                                    e.target.value >= 0
                                                        ? e.target.value
                                                        : e.target.value * -1,
                                            })
                                        }
                                    />
                                    <div className="mt-5">
                                        <label
                                            htmlFor="time"
                                            className="text-md font-semibold mr-3"
                                        >
                                            Select a time:
                                        </label>
                                        <input
                                            type="time"
                                            id="time"
                                            name="time"
                                            value={time}
                                            onChange={handleTimeChange}
                                            className="border rounded-md p-2 mt-2 w-32"
                                        />
                                    </div>
                                </FormItem>
                                <FormItem label=" Reminder Name">
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        placeholder="Reminder Name"
                                        className="border-[1px] px-2 py-3 w-full rounded-lg mt-1"
                                        value={reminderData?.remainderName}
                                        onChange={(e) =>
                                            setReminderData({
                                                ...reminderData,
                                                remainderName: e.target.value,
                                            })
                                        }
                                    />
                                </FormItem>
                                <FormItem label="To">
                                    <select
                                        placeholder="Due Date"
                                        type="text"
                                        name="sendReminder"
                                        className="border-[1px] px-2 py-3 w-full rounded-lg"
                                        onChange={(e) =>
                                            selectToEmail(e.target.value)
                                        }
                                    >
                                        <option selected disabled>
                                            Select
                                        </option>
                                        <option value="customer">
                                            Customer
                                        </option>
                                        <option value="Accountmanager">
                                            Acount Manager
                                        </option>
                                        <option value="customerandAccountmanager">
                                            Customer & Account Manager
                                        </option>
                                    </select>
                                    <input
                                        type="text"
                                        required
                                        placeholder="cc"
                                        className="border-[1px] px-2 py-3 w-full rounded-lg mt-5"
                                        onChange={(e) =>
                                            setcc([
                                                ...e.target.value.split(','),
                                            ])
                                        }
                                    />
                                    use comma(,) to separate more than one email
                                    address
                                    <input
                                        type="text"
                                        required
                                        placeholder="bcc"
                                        className="border-[1px] px-2 py-3 w-full rounded-lg mt-5"
                                        onChange={(e) =>
                                            setbcc([
                                                ...e.target.value.split(','),
                                            ])
                                        }
                                    />
                                    use comma(,) to separate more than one email
                                    address
                                </FormItem>
                                <FormItem label="Subject">
                                    <input
                                        name="subject"
                                        placeholder="Reminder for 30 days"
                                        className="border-[1px] px-2 py-3 w-full rounded-lg mt-1"
                                        value={reminderData?.subject}
                                        onChange={(e) =>
                                            setReminderData({
                                                ...reminderData,
                                                subject: e.target.value,
                                            })
                                        }
                                    />
                                </FormItem>
                                <FormItem label="Content">
                                    <JoditEditor
                                        ref={editor}
                                        value={reminderData?.contentString}
                                        onChange={(e) =>
                                            setReminderData({
                                                ...reminderData,
                                                contentString: e,
                                            })
                                        }
                                    />
                                </FormItem>
                                <FormItem className="pb-6">
                                    <Switcher onChange={(e) => setToogle(e)} />
                                    <Button
                                        className="ltr:mr-2 rtl:ml-2"
                                        variant="plain"
                                        onClick={onDialogClose}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type="reset"
                                        className="ltr:mr-2 rtl:ml-2"
                                    >
                                        Reset
                                    </Button>
                                    <Button
                                        variant="solid"
                                        onClick={(e) => submitData(e)}
                                    >
                                        Save
                                    </Button>
                                </FormItem>
                            </FormContainer>
                        </Form>
                    </Formik>
                </div>
            </Dialog>
        </div>
    )
}

export default ReminderFormBill
