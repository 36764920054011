import React, { useEffect, useState } from 'react'
// import { Table } from 'components/ui'
import { Card } from 'components/ui'

import { Checkbox } from 'components/ui'

// const { Tr, Th, Td, TBody } = Table

const SettingsPermission = ({ setSettingPermission, companyPermission }) => {
    const [checkboxList, setCheckboxList] = useState([])
    useEffect(() => {
        setCheckboxList(companyPermission?.SettingPermission)
    }, [companyPermission])

    const onCheckboxChange = (options) => {
        setCheckboxList(options)
    }
    useEffect(() => {
        setSettingPermission(checkboxList)
    }, [checkboxList])

    return (
        <Card>
            <h1 className="text-[14px] w-full bg-slate-100 pl-5">Settings</h1>
            <div className="mt-5">
                <Checkbox.Group
                    className="grid grid-cols-3 gap-5"
                    onChange={onCheckboxChange}
                    value={checkboxList}
                >
                    <Checkbox value=" Update Organization Profile">
                        Update Organization Profile
                    </Checkbox>
                    <Checkbox value="Users">Users </Checkbox>
                    <Checkbox value="Export Data">Export Data</Checkbox>
                    <Checkbox value="General Preferences">
                        General Preferences
                    </Checkbox>
                    <Checkbox value=" Accountant Preferences">
                        Accountant Preferences{' '}
                    </Checkbox>
                    <Checkbox value="Taxes">Taxes</Checkbox>
                    <Checkbox value="Payments Terms">Payments Terms</Checkbox>
                    <Checkbox value="Templates">Templates </Checkbox>
                    <Checkbox value="Email Template">Email Template</Checkbox>
                    <Checkbox value="Reporting Tag">Reporting Tag </Checkbox>
                    <Checkbox value="Manage Integration">
                        Manage Integration
                    </Checkbox>
                    <Checkbox value="Automation">Automation</Checkbox>
                </Checkbox.Group>
            </div>
        </Card>
    )
}

export default SettingsPermission
