import React from 'react'
import classNames from 'classnames'
import {
    HiOutlineChartSquareBar,
    HiOutlineUsers,
    HiOutlineMailOpen,
} from 'react-icons/hi'
import { VscFeedback } from 'react-icons/vsc'
import { FaHockeyPuck } from 'react-icons/fa'
import { TbSettings } from 'react-icons/tb'
import { GiScales } from 'react-icons/gi'
import { APP_PREFIX_PATH } from 'constants/route.constant'
import { ScrollBar } from 'components/ui'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
    SIDE_NAV_WIDTH,
    SIDE_NAV_COLLAPSED_WIDTH,
    NAV_MODE_DARK,
    NAV_MODE_THEMED,
    NAV_MODE_TRANSPARENT,
    SIDE_NAV_CONTENT_GUTTER,
    LOGO_X_GUTTER,
} from 'constants/theme.constant'
import Logo from 'components/template/Logo'
import navigationConfig from 'configs/navigation.config'
import VerticalMenuContent from 'components/template/VerticalMenuContent'
import useResponsive from 'utils/hooks/useResponsive'
import { useSelector } from 'react-redux'

const sideNavStyle = {
    width: SIDE_NAV_WIDTH,
    minWidth: SIDE_NAV_WIDTH,
}

const sideNavCollapseStyle = {
    width: SIDE_NAV_COLLAPSED_WIDTH,
    minWidth: SIDE_NAV_COLLAPSED_WIDTH,
}

const SideNav = () => {
    const themeColor = useSelector((state) => state.theme.themeColor)
    const primaryColorLevel = useSelector(
        (state) => state.theme.primaryColorLevel
    )
    const navMode = useSelector((state) => state.theme.navMode)
    const mode = useSelector((state) => state.theme.mode)
    const direction = useSelector((state) => state.theme.direction)
    const currentRouteKey = useSelector(
        (state) => state.base.common.currentRouteKey
    )
    const sideNavCollapse = useSelector(
        (state) => state.theme.layout.sideNavCollapse
    )
    const userAuthority = useSelector((state) => state.auth.user.authority)

    const { larger } = useResponsive()

    const sideNavColor = () => {
        if (navMode === NAV_MODE_THEMED) {
            return `bg-${themeColor}-${primaryColorLevel} side-nav-${navMode}`
        }
        return `side-nav-${navMode}`
    }

    const logoMode = () => {
        if (navMode === NAV_MODE_THEMED) {
            return NAV_MODE_DARK
        }

        if (navMode === NAV_MODE_TRANSPARENT) {
            return mode
        }

        return navMode
    }

    // const menuContent = (
    //     <VerticalMenuContent
    //         navMode={navMode}
    //         collapsed={sideNavCollapse}
    //         navigationTree={navigationConfig}
    //         routeKey={currentRouteKey}
    //         userAuthority={userAuthority}
    //         direction={direction}
    //     />
    // )

    return (
        <>
            <div className="flex">
                <div>
                    {larger.md && (
                        <div
                            style={
                                sideNavCollapse
                                    ? sideNavCollapseStyle
                                    : sideNavStyle
                            }
                            className={classNames(
                                'side-nav',
                                sideNavColor(),
                                !sideNavCollapse && 'side-nav-expand'
                            )}
                        >
                            <div className="side-nav-header mt-5">
                                <Logo
                                    mode={logoMode()}
                                    type={
                                        sideNavCollapse ? 'streamline' : 'full'
                                    }
                                    gutter={
                                        sideNavCollapse
                                            ? ''
                                            : //  SIDE_NAV_CONTENT_GUTTER
                                            LOGO_X_GUTTER
                                    }
                                />
                            </div>
                            {sideNavCollapse ? (
                                // menuContent
                                <div className=" mt-10 flex flex-col gap-10 items-center">
                                    <Link to="/app/dashboard">
                                        {' '}
                                        <HiOutlineChartSquareBar className="text-[23px] text-gray-600" />
                                    </Link>
                                    {/* <Link to="/user">
                                        {' '}
                                        <HiOutlineUsers className="text-[23px] text-gray-600" />
                                    </Link> */}
                                    <Link to="/license">
                                        {' '}
                                        <FaHockeyPuck className="text-[23px] text-gray-600" />
                                    </Link>
                                    <Link to="/feedback">
                                        {' '}
                                        <VscFeedback className="text-[23px] text-gray-600" />
                                    </Link>
                                    <Link to="/settings">
                                        {' '}
                                        <TbSettings className="text-[23px] text-gray-600" />
                                    </Link>
                                    {/* <Link to="/settings">
                                        {' '}
                                        <GiScales className="text-[23px] text-gray-600" />
                                    </Link> */}
                                    {/* <Link to="/app/Settings">
                                        {' '}
                                        <TbSettings className="text-[23px] text-gray-600" />
                                    </Link> */}
                                </div>
                            ) : (
                                <div className="side-nav-content">
                                    <ScrollBar autoHide direction={direction}>
                                        <div className="flex flex-col mt-5 gap-3">
                                            <Link
                                                className="flex  mt-2  items-center hover:bg-gray-300 mx-4 px-3 py-2 rounded-lg"
                                                to="/app/dashboard"
                                            >
                                                <span>
                                                    <HiOutlineChartSquareBar className="text-[23px] text-gray-600" />
                                                </span>
                                                <span className="font-semibold text-[14px] text-gray-600 ml-2">
                                                    Dashboard
                                                </span>
                                            </Link>
                                            {/* <Link
                                                className="flex  mt-2  items-center hover:bg-gray-300 mx-4 px-3 py-2 rounded-lg"
                                                to="/user"
                                            >
                                                <span>
                                                    <HiOutlineUsers className="text-[23px] text-gray-600" />
                                                </span>
                                                <span className="font-semibold text-[14px] text-gray-600 ml-2">
                                                    User
                                                </span>
                                            </Link> */}
                                            <Link
                                                className="flex  mt-2 items-center hover:bg-gray-300 mx-4 px-3 py-2 rounded-lg"
                                                to="/license"
                                            >
                                                <span>
                                                    <FaHockeyPuck className="text-[23px] text-gray-600" />
                                                </span>
                                                <span className="font-semibold text-[14px] text-gray-600 ml-2">
                                                    Licence
                                                </span>
                                            </Link>
                                            <Link
                                                className="flex  mt-2  items-center hover:bg-gray-300 mx-4 px-3 py-2 rounded-lg"
                                                to="/feedback"
                                            >
                                                <span>
                                                    <VscFeedback className="text-[23px] text-gray-600" />
                                                </span>
                                                <span className="font-semibold text-[14px] text-gray-600 ml-2">
                                                    Feedback
                                                </span>
                                            </Link>
                                            <Link
                                                className="flex  mt-2  items-center hover:bg-gray-300 mx-4 px-3 py-2 rounded-lg"
                                                to="/settings"
                                            >
                                                <span>
                                                    <TbSettings className="text-[23px] text-gray-600" />
                                                </span>
                                                <span className="font-semibold text-[14px] text-gray-600 ml-2">
                                                    Settings
                                                </span>
                                            </Link>
                                            {/* 
                                            <Link
                                                className="flex  mt-2  items-center hover:bg-gray-300 mx-4 px-3 py-2 rounded-lg"
                                                to="/trialBalance"
                                            >
                                                <span>
                                                    <GiScales className="text-[23px] text-gray-600" />
                                                </span>
                                                <span className="font-semibold text-[14px] text-gray-600 ml-2">
                                                    Trial Balance
                                                </span>
                                            </Link> */}

                                            {/* <Link
                                                className="flex mt-2  items-center hover:bg-gray-300 mx-4 px-3 py-2 rounded-lg"
                                                to="/app/Settings"
                                                //   to={`${APP_PREFIX_PATH}/settings`}
                                            >
                                                <span>
                                                    <TbSettings className="text-[23px] text-gray-600" />
                                                </span>
                                                <span className="font-semibold text-[14px] text-gray-600 ml-2">
                                                    Settings
                                                </span>
                                            </Link> */}
                                        </div>
                                    </ScrollBar>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

SideNav.propTypes = {
    themed: PropTypes.bool,
    darkMode: PropTypes.bool,
    themeColor: PropTypes.string,
}

SideNav.defaultProps = {
    themed: false,
    darkMode: false,
    themeColor: '',
}

export default SideNav
