export const usersData = [
    {
        id: '1',
        name: 'Amit Pandita ',
        email: 'Amit@test.com',
    },
    {
        id: '2',
        name: 'Amit Pandita',
        email: 'Amit@test.com',
    },
    {
        id: '3',
        name: 'Amit Pandita',
        email: 'Amit@test.com',
    },
    {
        id: '4',
        name: 'Amit Pandita',
        email: 'Amit@test.com',
    },
    {
        id: '5',
        name: 'Amit Pandita',
        email: 'Amit@test.com',
    },
    {
        id: '6',
        name: 'Amit Pandita',
        email: 'Amit@test.com',
    },
    {
        id: '7',
        name: 'Amit Pandita',
        email: 'Amit@test.com',
    },
]

export const userDetailData = [
    {
        id: '1',
        name: 'Amit Pandita ',
        email: 'Amit@test.com',
        collection: '512',
        saletaerget: '10%',
        collectachive: 'Rs:324',
        invoiceno: 'INV-2023-00123',
        totalinvo: '5',
        collecttarget: 'Rs:2000',
        overallcollectio: 'Rs:5000',
        Sale: '58921',
        colloctionday: '15',
        collectionTrend: '0.00%',
        saleachiev: 'RS:1310',
        role: 'Admin',
        lastOnline: 1623430400,
        status: 'Pending',
        personalInfo: {
            location: 'INDIA',
            title: 'Product Manager',
            birthday: '10/10/1992',
            phoneNumber: '',
            facebook: 'facebook.com/sample',
            twitter: 'twitter.com/sample',
            pinterest: 'pinterest.com/sample',
            linkedIn: 'linkedin/sample',
        },
        orderHistory: [
            {
                id: '#36223',
                item: 'Mock premium pack',
                status: 'pending',
                amount: 39.9,
                date: 1639132800,
            },
            {
                id: '#34283',
                item: 'Business board pro subscription',
                status: 'paid',
                amount: 59.9,
                date: 1636790880,
            },
            {
                id: '#32234',
                item: 'Business board pro subscription',
                status: 'paid',
                amount: 59.9,
                date: 1634090880,
            },
            {
                id: '#31354',
                item: 'Business board pro subscription',
                status: 'paid',
                amount: 59.9,
                date: 1631532800,
            },
        ],
    },
    {
        id: '2',
        name: 'Amit Moreno',
        email: 'Amit@test.com',
        saleachiev: 'Rs:5320',
        colloctionday: '10',
        invoiceno: 'INV-2023-00123',
        totalinvo: '5',
        saletaerget: '10%',
        collectachive: 'Rs:324',
        collecttarget: 'Rs:2000',
        overallcollectio: 'Rs:5000',
        role: 'User',
        lastOnline: 1632393600,
        status: 'Completed',
        collectionTrend: '0.0%',
        Sale: '17181',
        collection: '122',
        personalInfo: {
            location: 'INDIA',
            title: 'Software Engineer',
            birthday: '03/02/1984',
            phoneNumber: '',
            facebook: 'facebook.com/sample',
            twitter: 'twitter.com/sample',
            pinterest: 'pinterest.com/sample',
            linkedIn: 'linkedin/sample',
        },
        orderHistory: [
            {
                id: '#36223',
                item: 'Mock premium pack',
                status: 'pending',
                amount: 39.9,
                date: 1639132800,
            },
            {
                id: '#34283',
                item: 'Business board pro subscription',
                status: 'paid',
                amount: 59.9,
                date: 1636790880,
            },
            {
                id: '#32234',
                item: 'Business board pro subscription',
                status: 'paid',
                amount: 59.9,
                date: 1634090880,
            },
            {
                id: '#31354',
                item: 'Business board pro subscription',
                status: 'paid',
                amount: 59.9,
                date: 1631532800,
            },
        ],
        paymentMethod: [
            {
                cardHolderName: 'Amit Pandita',
                cardType: 'VISA',
                expMonth: '12',
                expYear: '25',
                last4Number: '0392',
                primary: true,
            },
            {
                cardHolderName: 'Amit Pandita',
                cardType: 'MASTER',
                expMonth: '06',
                expYear: '25',
                last4Number: '8461',
                primary: false,
            },
        ],
        subscription: [
            {
                plan: 'Business board pro',
                status: 'Completed',
                billing: 'monthly',
                nextPaymentDate: 1639132800,
                amount: 59.9,
            },
        ],
    },

    {
        id: '3',
        name: 'Amit Pandita Hale',
        email: 'Amit@test1.com',
        role: 'User',
        saletaerget: '10%',
        collectachive: 'Rs:324',
        collecttarget: 'Rs:2000',
        overallcollectio: 'Rs:5000',
        lastOnline: 1633452800,
        status: 'Pending',
        colloctionday: '8',
        saleachiev: 'Rs:1220',
        collection: '322',
        collectionTrend: '1.00%',
        invoiceno: 'INV-2023-00123',
        totalinvo: '5',
        Sale: '12121',
        personalInfo: {
            location: 'INDIA',
            title: ' Manager',
            birthday: '03/06/1991',
            phoneNumber: '',
            facebook: 'facebook.com/sample',
            twitter: 'twitter.com/sample',
            pinterest: 'pinterest.com/sample',
            linkedIn: 'linkedin/sample',
        },
        orderHistory: [
            {
                id: '#36223',
                item: 'Mock premium pack',
                status: 'pending',
                amount: 39.9,
                date: 1639132800,
            },
            {
                id: '#34283',
                item: 'Business board pro subscription',
                status: 'paid',
                amount: 59.9,
                date: 1636790880,
            },
            {
                id: '#32234',
                item: 'Business board pro subscription',
                status: 'paid',
                amount: 59.9,
                date: 1634090880,
            },
            {
                id: '#31354',
                item: 'Business board pro subscription',
                status: 'paid',
                amount: 59.9,
                date: 1631532800,
            },
        ],
        paymentMethod: [
            {
                cardHolderName: 'Amit Pandita',
                cardType: 'VISA',
                expMonth: '12',
                expYear: '25',
                last4Number: '0392',
                primary: true,
            },
            {
                cardHolderName: 'Amit Pandita',
                cardType: 'MASTER',
                expMonth: '06',
                expYear: '25',
                last4Number: '8461',
                primary: false,
            },
        ],
        subscription: [
            {
                plan: 'Business board pro',
                status: 'active',
                billing: 'monthly',
                nextPaymentDate: 1639132800,
                amount: 59.9,
            },
        ],
    },
]
