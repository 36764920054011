import React from 'react'
import { Card } from 'components/ui'
import LicenseTable from './LicenseTable'
import LicenseSidebar from './LicenseSidebar'
const SuperAdminLicense = () => {
    return (
        <div className="m-5">
            <Card>
                <LicenseTable />
                {/* <LicenseSidebar /> */}
            </Card>
        </div>
    )
}

export default SuperAdminLicense
